import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Establishment from "./pages/Establishment";
import FactoryCompliances from "./pages/FactoryCompliances";
import IndustrialLaw from "./pages/IndustrialLaw";
import PayrollCompliance from './pages/PayrollCompliance'
import RiskCompliance from "./pages/RiskCompliance";
import AuditAdvisory from "./pages/AuditAdvisory";
import Layout from "./components/Layout";
import AboutUS from "./pages/AboutUs";
import AdminLayout from "./components/AdminLayout";
import AdminNavbar from "./components/AdminNavbar";
import { Navigate } from "react-router-dom";
import AdminActs from "./pages/AdminActs";
import AdminMenu from "./components/AdminMenu";
import AdminNotification from "./pages/AdminNotification";
import AdminWages from "./pages/AdminWage";
import AdminTax from "./pages/AdminTax";
import AdminWelfare from "./pages/AdminWelfare";
import AdminLogin from "./components/AdminLogin";
import AdminOtp from "./components/AdminOtp";
import EActsRules from "./pages/EActsRules";
import EActStates from "./pages/EActStates";
import ELabour from "./pages/ELabour";
import ELabourState from "./pages/ELabourState";
import EHoliday from "./pages/EHoliday";
import EHolidayState from "./pages/EHolidayState";
import ELeave from "./pages/ELeave";
import ELeaveState from "./pages/ELeaveState";
import EMinimumWage from "./pages/EMinimumWage";
import EMinimumWageState from "./pages/EMinimumWageState";
import ETax from "./pages/ETax";
import ETaxState from "./pages/ETaxState";
import EWelfareState from "./pages/EWelfareState";
import EWelfare from "./pages/EWelfare";
import ELibraryMenu from "./components/ELibraryMenu";
import ELabourCode from "./pages/ELabourCode";
import AdminHoliday from "./pages/AdminHoliday";
import ClientMenu from "./components/ClientMenu";
import ClientDash1 from "./Client/ClientDash1";
import ClientDash4 from "./Client/ClientDash4";
import ClientOverView from "./Client/ClientOverView";
import ClientCompliance from "./Client/ClientCompliance";
import AdminLeave from "./pages/AdminLeave";
import PayrollOutSourcing from "./pages/PayrollOutSourcing";
import ComingSoon from "./pages/ComingSoon";
import { AuthContextProvider } from "./components/AuthContext";
import { TestContextProvider } from "./components/TestContext";
import Loading from "./components/Loading";
import { useEffect, useState } from "react";
import Career from "./pages/Career";
import OurTeam from "./pages/OurTeam";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <BrowserRouter>
        <TestContextProvider>
          <Routes>
            {/* General routes */}
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route path="/AdminLogin" element={<AdminLogin />} />
            <Route path="/Admin/AdminLogin/AdminOtp" element={<AdminOtp />} />

            {/* Admin layout and routes */}
            <Route path="/Admin" element={<AdminLayout />}>
              <Route path="AdminActs" element={<AdminActs />} />
              <Route path="AdminNotification" element={<AdminNotification />} />
              <Route path="AdminWages" element={<AdminWages />} />
              <Route path="AdminTax" element={<AdminTax />} />
              <Route path="AdminWelfare" element={<AdminWelfare />} />
              <Route path="AdminHoliday" element={<AdminHoliday />} />
              <Route path="AdminLeave" element={<AdminLeave />} />
            </Route>

            {/* Client Route */}
            <Route path="/*" element={<ClientRoute />} />
          </Routes>
        </TestContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

function ClientRoute(){

  return(
    <div>
      
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/AboutUs" element={<AboutUS/>} />
          <Route path="/EstablishCompliance" element={<Establishment />} />
          <Route path="/factoryCompliance" element={<FactoryCompliances />} />
          <Route path="/IndustryCompliance" element={<IndustrialLaw />} />
          <Route path="/PayrollCompliance" element={<PayrollCompliance />} />
          <Route path="/RiskCompliance" element={<RiskCompliance />} />
          <Route path="/VendorCompliance" element={<AuditAdvisory />} />
          <Route path="/PayrollOutSource" element={<PayrollOutSourcing />} />
          <Route path="/ActsandRules" element={<EActsRules/>} />
          <Route path="/StateAct/:id" element={<EActStates/>} />
          <Route path="/LabourLaw" element={<ELabour/>} />
          <Route path="/StateLabour/:id" element={<ELabourState/>} />
          <Route path="/HolidayList" element={<EHoliday/>} />
          <Route path="/StateHolidayList/:id" element={<EHolidayState/>} />
          <Route path="/LeaveAndWorkingHour" element={<ELeave/>} />
          <Route path="/StateLeave/:id" element={<ELeaveState/>} />
          <Route path="/MinimumWage" element={<EMinimumWage/>} />
          <Route path="/StateMinimumWage/:id" element={<EMinimumWageState/>} />
          <Route path="/ProfessionalTax" element={<ETax/>} />
          <Route path="/StateProfessionalTax/:id" element={<ETaxState/>} />
          <Route path="/LabourWelfare" element={<EWelfare/>} />
          <Route path="/StateLabourWelfare/:id" element={<EWelfareState/>} />
          <Route path="/ComingSoon" element={<ComingSoon/>} />
          <Route path="/LabourCode" element={<ELabourCode/>} />
          <Route path="/ClientMenu" element={<ClientMenu/>} />
          <Route path="/ClientHome" element={<ClientDash1/>} />
          <Route path="/ClientGraph" element={<ClientDash4/>} />
          <Route path="/ClientOverview" element={<ClientOverView/>} />
          <Route path="/ClientCompliance" element={<ClientCompliance/>} />
          <Route path="/Career" element={<Career/>} />
          <Route path="/OurTeam" element={<OurTeam/>} />
          <Route path='/Path' element={<ELibraryMenu/>}/>
          <Route path="/Privacy" element={<PrivacyPolicy/>}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </div>
  );
}

// function AdminRoute(){

//   return(
//     <div>
//       <TestContextProvider>
//       <AdminLayout>
          
//           <Routes>
//             <Route path="/" element={<HomePage />} />
//             <Route path="AdminActs" element={<AdminActs/>}/>
//             <Route path="AdminNotification" element={<AdminNotification/>}/>
//             <Route path="AdminWages" element={<AdminWages/>}/>
//             <Route path="AdminTax" element={<AdminTax/>}/>
//             <Route path="AdminWelfare" element={<AdminWelfare/>}/>
//             <Route path="AdminHoliday" element={<AdminHoliday/>}/>
//             <Route path="AdminLeave" element={<AdminLeave/>}/>
//           </Routes>
//       </AdminLayout>
//       </TestContextProvider>
      
    
       
//     </div>
//   );
// }
