import React, { useRef } from 'react'
import '../css/PayrollOutsourcing.css'
import Compliance1 from '../Images/Compliance1.jpg'
import Compliance2 from '../Images/Compliance2.jpg'
import Compliance3 from '../Images/Compliance3.jpg'
import Compliance4 from '../Images/Compliance4.jpg'
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useState } from 'react'
import { useEffect } from 'react'
function PayrollOutSourcing() {
    const [visibleItems, setVisibleItems] = useState(3);
    const [viewMore, setViewMore] = useState(false);
    const containerRef = useRef(null);
    
    const law=[{
      id:1,
      title:'Monthly Payroll Processing (Gross to Net)',
      detail1:'Preparation of monthly payroll with detailed employee-wise analysis (basic salary, perquisites, allowances, deductions, net amount payable).',
      detail2:'Maintaining records and tracking payroll-related benefits (Leave Travel Reimbursement, other compensation-linked reimbursements). ',
      detail3:'Employee Self-Service (ESS) for online access to payslips, tax calculations, reimbursement claims, tax declarations, YTD reports, and cumulative reports. ',
      detail4:'Providing reports as required by the company for internal accounting and MIS needs. ',
      detail5:'Preparation of CTC reconciliation. ',
    },
    {
        id:2,
        title:'Employee Query Management ',
        detail1:'Handling employee queries via a dedicated mailbox. ',
        detail2:'Option for online ticket-based query management with monthly reporting. ',
      }, 
      {
        id:3,
        title:'Preparation of Monthly Finance Reports ',
        detail1:'Preparation of bank letters/upload file as a maker. ',
        detail2:'Preparation of payroll JV for company accounting needs. ',
       
      },
      {
        id:4,
        title:'Employee Addition ',
        detail1:'Updating new joiner information and compensation data in the payroll master based on company-provided information. ',
        detail2:'Updating previous employer data for mid-year joiners to accurately calculate payroll withholding.  ',
        deatail3:'Reviewing completed savings and investment declarations submitted online and updating payroll withholding calculations accordingly. ',
      },
      {
        id:5,
        title:'Employee Attrition ',
        detail1:"Processing details regarding an employee's last date of employment and deductions (advances, notice period). ",
        detail2:'Informing the company about pending documentation from the employee and updating final tax withholding calculations accordingly. ',
      },
      {
        id:6,
        title:'Reimbursement Processing ',
        detail1:'Processing claims (part of compensation structure) submitted by employees in accordance with company entitlements, policies, and guidelines. ',
      },
      {
        id:7,
        title:'Gratuity ',
        detail1:'Computing and paying gratuity as per the Gratuity Act. ',
        detail2:'Timely filing of returns (Form L) with statutory authorities.  ',
      },
      {
        id:8,
        title:'Statutory Bonus ',
        detail1:'Computing and paying the statutory bonus. ',
        detail2:'Timely filing of annual returns (Form-D) with statutory authorities.  ',
      },
      {
        id:9,
        title:'Assistance with Employer Withholding Tax Compliances ',
        detail1: {
            heading: 'Withholding Tax on Salary ',
            data: [
              'Computing monthly tax deductions for each employee based on salary, perquisites/benefits, allowances, and reimbursements. ',
              'Preparation of tax deposit challans for income tax if required. ',
            ]
          },
          detail2: {
            heading: 'Preparation of Quarterly Withholding Tax Returns and Withholding Tax Certificates ',
            data: [
              'TDS on salary: Monthly calculation of TDS based on compensation information provided by the company. ',
              'Preparation of quarterly e-TDS returns (Form 24Q) for filing with the Income-tax department. ',
              'Preparation of TDS certificates (Form 16 Part A, Part B & 12BA) for employees. ',
              'Password protection and distribution of Form 16 (Part A and Part B) to employees'
            ]
          },
      },
    ]
    const whyCompliance=[{
        id:1,
        image:Compliance2,
        descName:'Payroll Processing',
        desctitle1:'Single pack of salary inputs in a month in Excel format.',
        desctitle2:'Full and final settlement processing along with payroll. ',  
    },
    {
        id:2,
        image:Compliance1,
        descName:'Invoicing ',
        desctitle1:'Single invoice per month per company. ',
        desctitle2:'Invoice raised based on the total transactions processed. ',
        desctitle3:'Payment of invoices within 15 working days from the invoice date, subject to penal interest for delayed payments. ', 
    },
    {
        id:3,
        image:Compliance3,
        descName:'Standard Practices ',
        desctitle1:'Adherence to all statutory practices. ',
        desctitle2:'Standard reports and formats agreed upon at the time of agreement sign-up. ',
        desctitle3:'Additional costs for report customization and reprocessing due to extra inputs. ',
        desctitle4:'Employee query handling through email. ',
    },
   
]
const handleViewMore = () => {
    if (viewMore) {
      setVisibleItems(3);
    } else {
      setVisibleItems(law.length);
    }
    setViewMore(!viewMore);
  };

  const closeDropdownOnScroll = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
      setViewMore(false);
      setVisibleItems(3);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(closeDropdownOnScroll, {
      root: null,
      threshold: 0,
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, viewMore]);
  return (
    <div>
      <div className='paymain'>
        <div className='outsource-head'>
          <div className='outsource-head-maintext'>Payroll Outsourcing Service</div>
          {/* <div className='outsource-head-text'>Vendor compliance ensures businesses adhere to regulatory and contractual standards in vendor relationships, crucial for maintaining integrity, minimizing risks, and protecting reputation</div> */}
        </div>
        <div className='outsource-aspect' ref={containerRef}>
          <div className='outsource-aspect-text'>Scope of Services</div>
          <div className='outsource-aspect-data'>
          <div className='outsource-aspect-detail'>
  {law.slice(0, visibleItems).map((lawdata) => (
    <div key={lawdata.id} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div className='outsource-aspect-title'>{lawdata.title}</div>
      <ul style={{ margin: '0', paddingLeft: '16px', listStyleType: 'disc' }}>
        {[lawdata.detail1, lawdata.detail2, lawdata.detail3, lawdata.detail4, lawdata.detail5].map((detail, index) => (
          typeof detail === 'string' ? (
            <li key={index} className='outsource-aspect-exp'>{detail}</li>
          ) : detail && typeof detail === 'object' ? (
            <li key={index}>
              <div className='outsource-aspect-exp'>{detail.heading}</div>
              <ul style={{ margin: '0', paddingLeft: '16px', listStyleType: 'disc' }}>
                {detail.data.map((item, i) => (
                  <li key={i} className='outsource-aspect-exp'>{item}</li>
                ))}
              </ul>
            </li>
          ) : null
        ))}
      </ul>
    </div>
  ))}
  {law.length > 3 && (
    <span onClick={handleViewMore} className='risk-view-all'>
      {viewMore ? 'View Less' : 'View All'}
      {viewMore ? <CaretUp size={22} color="#000000" style={{ cursor: 'pointer' }} /> : <CaretDown size={22} color="#000000" style={{ cursor: 'pointer' }} />}
    </span>
  )}
</div>

            <div className='outsource-aspect-image'></div>
          </div>
        </div>

        <div className='outsource-consequence'>
          <div className='outsource-consequence-text'>Key Assumptions</div>
          {whyCompliance?.map((compliance) => (
  <div key={compliance.id} className='outsource-consequence-detail'>
    <img src={compliance.image} className='outsource-consequence-image' alt="" />
    <div className='outsource-consequence-desc'>
      <div className='outsource-consequence-head'>{compliance.descName}</div>
      <ul style={{ margin: '0px', listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>{compliance.desctitle1}</li>
        <li>{compliance.desctitle2}</li>
       {compliance.desctitle3?(<li>{compliance.desctitle3}</li>):(<></>)} 
      </ul>
    </div>
  </div>
))}

        </div>
      </div>
    </div>
  )
}

export default PayrollOutSourcing