import React, { useEffect, useState } from 'react'
import '../css/EWelfare.css'
import { BellRinging, CaretDown, CaretUp, Coins, Gavel, HandCoins, PencilSimpleLine, Scales, XCircle } from '@phosphor-icons/react'
import TaxSub1 from '../Images/ProfessionalTaxSub.jpg'
import TaxSub2 from '../Images/ProfessionalTaxSub2.png'
import ELibraryMenu from '../components/ELibraryMenu'
import IndiaMap from '../components/IndiaMap'
import Welfare from '../Images/Welfare.png'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import coming from '../Images/ComingSoon.png'
function EWelfare() {
//   const taxwhy=[{
//     id:1,
//     icon:<HandCoins size={32} color="#403183"/>,
//     title:'Income Distribution',
//     desc:'It is a source of revenue for state governments, helping fund various public  services and infrastructure.',
// },
// {
//     id:2,
//     icon:<Coins size={32} color="#403183" />,
//     title:'Revenue Generation',
//     desc:'It is a source of revenue for state governments, helping fund various public  services and infrastructure.',
// },
// {
//     id:3,
//     icon:<Scales size={32} color="#403183"/>,
//     title:'Compliance with Law',
//     desc:'It is a source of revenue for state governments, helping fund various public  services and infrastructure.',
// },{
//   id:4,
//   icon:<Scales size={32} color="#403183"/>,
//   title:'Compliance with Law',
//   desc:'It is a source of revenue for state governments, helping fund various public  services and infrastructure.',
// }]
 const taxwhy=[{
  id:1,
  icon:<HandCoins size={32} color="#403183"/>,
  title:'Provide social security:',
  desc:' Enhance the welfare and living conditions of workers.',
},
{
  id:2,
  icon:<Coins size={32} color="#403183" />,
  title:'Improve working conditions:',
  desc:' Fund activities that contribute to safer and healthier work environments.',
},
{
  id:3,
  icon:<Scales size={32} color="#403183"/>,
  title:'Support worker development: ',
  desc:'Promote educational and skill development programs for workers.',
},{
id:4,
icon:<Scales size={32} color="#403183"/>,
title:'Assist in emergencies:',
desc:'Provide financial assistance during medical emergencies, accidents, or other unforeseen circumstances.',
}]
const penalty=[{
  id:'01',
  desc:'Registering with the state Labour Welfare Board'
},
{
  id:'02',
  desc:"Deducting the employee's contribution from salaries."
},
{
  id:'03',
  desc:"Matching the employee's contribution with the employer's share."
},
{
  id:'04',
  desc:'Depositing the total contributions to the Labour Welfare Board within the stipulated time.'
},]
const [reminder, setReminder]=useState(false)
const handleReminder=()=>{
    setReminder(!reminder)
}

const FileData=[{
  id:1,
  FileName:"Title of File",
  File:"file.pdf",
  States:"Bihar",
  StateId:4,
  UpdateDate:'29 July 2024',
  UploadedData:'Recent',
  Time:'12:03:45'
},
{
  id:2,
  FileName:"Title of File",
  File:"file.pdf",
  States:"Karnataka",
  StateId:11,
  UpdateDate:'29 July 2024',
  UploadedData:'Recent',
  Time:'12:03:45'
},
{
  id:3,
  FileName:"Title of File",
  File:"file.pdf",
  States:" Assam",
  StateId:3,
  UpdateDate:'29 July 2024',
  UploadedData:'Recent',
  Time:'12:03:45'
},

]
const getStatesFromFileData = (fileData) => {
  const stateNames = fileData.flatMap(item => item.States.split(',').map(state => state.trim()));
  console.log("Yolo");
  return Array.from(new Set(stateNames)); 
 
};
const getStateIdFromFileData = (fileData) => {
  const stateId = fileData.flatMap(item => item.StateId);
  console.log("Yolo");
  return Array.from(new Set(stateId)); 
 
};
const stateId=getStateIdFromFileData(FileData);
const states = getStatesFromFileData(FileData);
const [stateList,setStateList]=useState([]);
  const token=''
  const getStateName=async()=>{
    try{
      const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
        method:'GET',
        headers:{
          Authorization:`Bearer${token}`,
          "Content-Type":'application/json',
        }, 
      })
    
      if(response.ok){
        const data=await response.json();
        setStateList(data);   
      }
    }
    catch{
  console.log("error");
    }
  }
  useEffect(()=>{
    getStateName();
  },[])
const [showTimerDropdown, setShowTimerDropdown] = useState(false);
    const [time, setTime] = useState('10:00');

    const toggleTimerDropdown = () => {
        setShowTimerDropdown(!showTimerDropdown);
    };
    const [selectedStates, setSelectedStates]=useState([]);
    const [stateDrop,setStateDrop]=useState(false)
    const handleStateDropDown=()=>{
        setStateDrop(!stateDrop);
    }
    const handleStateSelect=(name)=>{
        setSelectedStates(prev => 
            prev.includes(name) ? prev.filter(state => state !== name) : [...prev, name]
        );
    }
    const [isOpen, setIsOpen] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };
    const [isOpenA, setIsOpenA] = useState(false);

    const toggleContentA = () => {
        setIsOpenA(!isOpenA);
    };
  return (
    // <div style={{display:'flex',maxWidth:'1280px',overflow:'hidden'}}>
    <div className='EWelfareTop'>
    <div className='EWelfareMain'>
        <div className='EWelfareHead'>
            <div className='ELabourName'>
                <HandCoins className='ELabourGavel'/>
                <div className='ELabourNameFull'>Labour Welfare Fund</div>
            </div>
            <div className='EWelfareDetail'> The Labour Welfare Fund (LWF) in India is a statutory contribution managed by individual state governments  aimed at improving the working conditions and welfare of laborers. The LWF's primary goal is to provide social  security and enhance the living standards of workers. Each state has its own legislation governing the Labour  Welfare Fund, leading to variations in contribution rates, applicability, and benefits.
            </div>
        </div>
        <div className='EWelfareAll'>
        <div className='EWelfareWhy'>
            <div className='EWelfareWhyHead'>Needs For Labour Welfare Fund </div>
            <div className='EWelfareSingle'>
                {taxwhy?.map((why,id)=>(
                   <div className='EWelfareSingleData' key={id}>
                        <div className='EWelfareSingleHead'>{why?.icon}{why?.title}</div>
                        <div className='EWelfareSingleDesc'>{why?.desc}</div>
                    </div> 
                ))}  
            </div>

        </div>
        
        <div className='EWelfare-remainder'>
                    <div className='EWelfare-remainder-head'><BellRinging size={32} color="#403183" />Set reminder for  the upcoming notifications from different states now!</div>
                    <div className='EWelfare-remainder-button' onClick={handleReminder}>Set reminder </div>
        </div>
        {reminder && <div className="EWelfare-overlay"></div>}
        {reminder&&(
                <div className='EWelfareReminderPop'>
                  
                  <div className='Ecoming-soon'> <XCircle size={20} color="rgb(217, 214, 230)" weight="fill"  onClick={handleReminder} style={{cursor:'pointer'}}/></div>
                       <div className='EWelfareRemButtons'>

                    <div className='title-soon' >Coming Soon</div>
                    <div className='before-img' ><img src={coming} className='img-coming' /></div>
                    </div>
                </div>
            )
            }
            <div className='ETaxIndia'><IndiaMap page='Welfare'/></div> 
        <div className='EWelfareSCope'>
        <div className='EWelfareScopeData'>
            <div className='EWelfareScopeDetail'>
                <div
                    className='EWelfareScopeHead'
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center',justifyContent:'space-between' }}
                >
                    Scope
                    {!isOpenA?(<CaretDown size={22} onClick={toggleContentA} style={{ marginLeft: '8px' }} />):(<CaretUp size={22} onClick={toggleContentA} style={{ marginLeft: '8px' }} />)}
                </div>
                <div className='EWelfareScopeDesc'>
                    <ul style={{paddingLeft:'16px',margin:'8px 0px'}}>
                        {/* The first point is always shown */}
                        <li style={{margin:'5px 0px'}}><b>Health and medical facilities: </b> Financial aid for medical treatments, hospitals, and healthcare services.</li>
                        {/* The remaining points are conditionally rendered based on isOpen */}
                        {isOpenA && (
                            <>
                                <li style={{margin:'5px 0px'}}><b>Educational facilities: </b> Scholarships, school supplies, and educational support for workers' children.</li>
                                <li style={{margin:'5px 0px'}}><b>Housing:</b> Assistance in acquiring housing or improving living conditions.</li>
                                <li style={{margin:'5px 0px'}}><b>Vocational training:</b> Skill development and training programs to improve employability.</li>
                            </>
                        )}
                    </ul>
                    <div className='EWelfareScopeNum'>1</div>
                </div>
            </div>
            
        </div>
        <div className='EWelfareScopeData'>
            <div className='EWelfareScopeDetail'>
                <div
                    className='EWelfareScopeHead'
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent:'space-between'}}
                >
                    Applicability
                    {!isOpen?(<CaretDown size={22} onClick={toggleContent} style={{ marginLeft: '8px' }} />):(<CaretUp size={22} onClick={toggleContent} style={{ marginLeft: '8px' }} />)}
                </div>
                <div className='EWelfareScopeDesc'>
                    <ul style={{paddingLeft:'16px',margin:'8px 0px'}}>
                        {/* The first point is always shown */}
                        <li style={{margin:'5px 0px'}}><b>Establishments:</b> Factories, shops, commercial establishments, motor transport undertakings, and other specified workplaces.</li>
                        {/* The remaining points are conditionally rendered based on isOpen */}
                        {isOpen && (
                            <>
                                <li style={{margin:'5px 0px'}}><b>Employees:</b> Workers engaged directly or through contractors in establishments where the LWF is applicable.</li>
                                <li style={{margin:'5px 0px'}} ><b>Threshold:</b> Some states have a minimum threshold number of employees for the fund's applicability.</li>
                            </>
                        )}
                    </ul>
                    <div className='EWelfareScopeNum'>2</div>
                </div>
            </div>
           
        </div>
        </div>
        <div className='EWelfareConclusion'>
                <div className='EWelfareConcData'>
                    <div className='EWelfareConcHead'>Conclusion</div>
                    <div className='EWelfareConcDesc'>The contribution towards the Labour Welfare Fund varies by state. Generally, contributions are made by both the employer and the employee, and sometimes the state government.</div> 
                </div> 
                <div className='EWelfareConcImage'><img className='EWelfareConcImg' src={Welfare}/></div>
        </div>
        <div className='EWelfareConclusion'>
                <div className='EWelfareConcData'>
                    <div className='EWelfareConcHead'>Compliance and penalties</div>
                    <div className='EWelfareConcDesc'>Failure to comply with the Labour Welfare Fund regulations can result in penalties, including fines and legal action.</div> 
                </div> 
        </div>
        <div className='EWelfarePenalty'>
          {penalty?.map((penalty)=>(
             <div className='EWelfarePenaltyData'>
                <div className='EWelfarePenaltyNum'>{penalty.id}</div>
                <div className='EWelfarePenaltyDesc'>{penalty.desc}</div>
              </div>
          ))}
             
        </div>
        </div>
    </div>
    </div>
  )
}

export default EWelfare