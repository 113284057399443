
const hostname = window.location.hostname;
 
let notificationApiUrl;
let holidayApiUrl;

 
if (hostname.includes("localhost") || hostname.includes("devwebapp")) {
  notificationApiUrl = "https://fn-tt-notification-dev.azurewebsites.net";
  holidayApiUrl = "https://fn-tt-holidaylist-dev.azurewebsites.net";
}

  else {
  notificationApiUrl = "https://fn-tt-notification-prod.azurewebsites.net";
  holidayApiUrl = "https://fn-tt-holidaylist-prod.azurewebsites.net";
}
 
export { notificationApiUrl, holidayApiUrl};