import React, { useContext, useEffect, useRef, useState } from 'react';
import '../css/AdminWelfare.css';
import AdminMenu from '../components/AdminMenu';
import { CaretDown, Check, FilePlus, Paperclip, PencilSimple, Trash, UploadSimple, XCircle } from '@phosphor-icons/react';
import { json, useNavigate } from 'react-router-dom';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { v4 as uuidv4 } from 'uuid';  
import { TestContext } from '../components/TestContext';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toast.css";
function AdminWelfare() {
    const [popOpen, setPopOpen] = useState(false);
    const [stateDropDown, setStateDropDown] = useState(false);
    const [StateName, setStateName] = useState('');
    const [fileName, setFileName] = useState('');
    const fileInputRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [fileContent, setFileContent] = useState("Content");
    const [linkContent, setLinkContent] = useState("Content");
    const [loading,setLoading]=useState(false)
    const [stateList,setStateList]=useState([]);
     const token=''
     const {isLogin} = useContext(TestContext);
     const navigate = useNavigate();
     useEffect(()=>{    
        console.log(isLogin);    
        if(!isLogin){
            navigate('/AdminLogin')
            }   
         },[])
    const handlePop = () => {
        console.log("Open ");
        setPopOpen(true);
    };

    const toggleStateDropdown = () => {
        setStateDropDown(!stateDropDown);
        console.log("True");
    };
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]) 
    const handleStateClick = (name,id) => {
        setStateName(name);
        setStateDropDown(false);
        setAct('');
        setApplicability('');
        setFrequency('');
        setRule('');
        setInfoFileContent( []); 
        setInfoLinkContent(''); 
        setStateId(id);
        setIsInfoEditing(false)
        
        setWelfare([]);
        console.log(id);
    };


    const [savedData, setSavedData] = useState(null);

    


    const getStateName=async()=>{
        setLoading(true)
        try{
           const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
               method:'GET',
               headers:{
                 Authorization:`Bearer${token}`,
                 "Content-Type":'application/json',
               }, 
             })
             console.log(response);
             if(response.ok){
               const data=await response.json();
               // console.log(data);
               setStateList(data)
               setLoading(false)
             }
           }
           catch{
       console.log("error");
           }
        }
        useEffect(()=>{
         getStateName();
        },[])
        
    //    ---------GET FILE-----------
    const [welfare,setWelfare]=useState('')
    const [stateId,setStateId]=useState()
    const getWelfare=async()=>{
        if(stateId!==0 && stateId!==undefined){
        setLoading(true)
        try{
            const response= await fetch(`${holidayApiUrl}/api/GetLabourWelfare?StateId=${stateId}`,{
                method:'GET',
                headers:{
                    Authorization:`Bearer${token}`,
                    "Content-Type":'application/json',
                },
            })
            console.log(response);
            if(response.ok){
                const data=await response.json();
                setWelfare(data);
                setLoading(false)
                setUploadedFiles([])
                
            }   
            
        }
        catch{
            console.log("Error");
            
        }
    }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await getWelfare();
                await getTax();
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };
    
        if (stateId) {
            fetchData();
        }
    }, [stateId]);
    
   
   
    // ---------------- POST WELFARE--------------------------------------//

    const [professionalRates, setProfessionalRates] = useState([
        { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' },
        { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' },
        { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' },
      ]);
      
      const handleWelfareInput = (index, field, value) => {
        setProfessionalRates(prevRates => 
          prevRates.map((rate, i) => 
            i === index ? { ...rate, [field]: value } : rate
          )
        );
      };
      
      const postProf = async () => {
        setLoading(true)
        try {
          const professionalRatesPayload = professionalRates.filter(rate => {
            return rate.Category || rate.EmployeeContribution || rate.EmployerContribution || rate.TotalContribution || rate.Deductiondate || rate.LatedateForsubmission;
          }).map(rate => ({
            stateid: stateId, 
            userid: 'usera', 
            Category: rate.Category || '',
            EmployeeContribution: rate.EmployeeContribution || '',
            EmployerContribution: rate.EmployerContribution || '',
            TotalContribution: rate.TotalContribution || '',
            Deductiondate: rate.Deductiondate || '',
            LatedateForsubmission: rate.LatedateForsubmission || '',
          }));
      
          if (professionalRatesPayload.length === 0) {
            console.warn("No valid data to send.");
            return;
          }
      
          const response = await fetch(`${holidayApiUrl}/api/AddLabourWelfare`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(professionalRatesPayload),
          });
      
          if (response.ok) {
            console.log("Success:", await response.json());
            toast.success("Data uploaded successfully!");
            setPopOpen(!popOpen);
            setProfessionalRates([ { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' }, { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' }, { Category: '', EmployeeContribution: '', EmployerContribution: '', TotalContribution: '', Deductiondate: '', LatedateForsubmission: '' }])
            getWelfare();
            getTax();
            setLoading(false)
          } else {
            console.error("Error:", response.statusText);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
      
      
    // ----------------- PUT METHOD ---------------
    const handleWelfareChange = (index, field, value) => {
        console.log(`Updating ${field} for item ${index} with value: ${value}`);
        
        const newData = [...welfare];
        
        const newValue = (field === 'salary' || field === 'ptAmount') 
            ? (value === '' ? 0 : Number(value)) 
            : value;
        
        newData[index] = { ...newData[index], [field]: newValue };
        console.log(newData,"New data");
        
        setWelfare(newData); 
    };
    
    const [welfareEdit, setwelfareEdit] = useState(false);
    const [originalWelfareData, setOriginalWelfareData] = useState(welfare);
    
    const handleWelfareEdit = (id) => {
  console.log(id, "THIS IS IDDD");
  
      setOriginalWelfareData(welfare);
      handleSaveClick(id);
    };
    
    const handleSaveClick = async (index) => {
        setwelfareEdit(false);
    
        // Check if the row is new or existing
        const updatedRow = welfare[index];
        const originalRow = originalWelfareData[index];
        console.log(updatedRow);
        
        // Prepare payload for new rows (if no ID exists)
        if (!updatedRow.id) {
            const newRowPayload = [{
                stateid: stateId,
                userid: 'admin',
                Category: updatedRow.category || '',
                EmployeeContribution: updatedRow.employeeContribution|| '',
                EmployerContribution: updatedRow.employerContribution|| '',
                TotalContribution:updatedRow.totalContribution || '',
                Deductiondate: updatedRow.deductiondate || '',
                LatedateForsubmission: updatedRow.latedateForsubmission || '',
            }];
            await postProfNew(newRowPayload);
        } else {
            // Check if the row has changed (field-by-field comparison)
            if (JSON.stringify(updatedRow) !== JSON.stringify(originalRow)) {
                await editWelfare(updatedRow);
            } else {
                console.log("No changes detected.");
            }
        }
    };
    

      const editWelfare = async (updatedRow) => {
        console.log("HIIi", updatedRow.id);
        setLoading(true)
        try {
            const response = await fetch(`${holidayApiUrl}/api/UpdateLabourWelfare`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "id": updatedRow.id,
                    "stateid": stateId,
                    "userid": "admin",
                    "Category": updatedRow.category||'',
                    "EmployeeContribution": updatedRow.employeeContribution || '',
                    "EmployerContribution": updatedRow.employerContribution || '',
                    "TotalContribution": updatedRow.totalContribution || '',
                    "Deductiondate": updatedRow.deductiondate||'',
                    "LatedateForsubmission": updatedRow.latedateForsubmission||'',
                })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setEditIndex(null);
            getTax();
            getWelfare();
            setOriginalWelfareData(welfare)
            console.log('Success:', data);
            toast.success("Data uploaded successfully!");
            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const postProfNew = async (data) => {
        setLoading(true)
        try {
            const formattedData = data.map(row => ({
                ...row,
                Deductiondate:row.Deductiondate||'',
                LatedateForsubmission: row.LatedateForsubmission || '',
            }));
    console.log(formattedData ,"FORMATTED");
    
            const response = await fetch(`${holidayApiUrl}/api/AddLabourWelfare`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formattedData),
            });
    
            if (response.ok) {
                console.log("Success:", await response.json());
                toast.success("Data uploaded successfully!");
                getWelfare();
                getTax();
                setEditIndex(null);
                setLoading(false)
            } else {
                console.error("Error:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    

    // --------- INFO EDIT --------------------------------------------------------------------------------------------------------------

    const [act, setAct] = useState('');
    const [frequency, setFrequency] = useState('');
    const [applicability, setApplicability] = useState('');
    const [rule, setRule] = useState('');
    const [infoLinkContent, setInfoLinkContent] = useState(''); 
    const [infoFileContent, setInfoFileContent] = useState([]); 
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [taxRefund, setTaxRefund] = useState([]);
    const [isInfoEditing, setIsInfoEditing] = useState(false);
    const [filteredData,setFilteredData]=useState(null)
    const getTax = async () => {
        if(stateId!==0 && stateId!==undefined){
        setLoading(true)
        try {
            const response = await fetch(`${holidayApiUrl}/api/GetTaxAndFundFile?StateId=${stateId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setTaxRefund(data);
                if (data.length > 0 && stateId !== 0 && stateId !== 'undefined') {
                    const filtered = data.filter(item => item.folder === 'LabourWelfare' && item.act !== '');
                    setFilteredData(filtered); // Store filtered data
                    if (filtered.length > 0) {
                      console.log(filtered.length-1);
                      
                        setAct(filtered[filtered.length-1].act || '');
                        setApplicability(filtered[filtered.length-1]?.applicability || '');
                        setFrequency(filtered[filtered.length-1]?.frequency || '');
                        setRule(filtered[filtered.length-1]?.rule || '');
                        setInfoLinkContent(filtered[filtered.length-1]?.link || '');
                        setUploadedFiles([])
                        
                    }
                    const filedata=filtered.filter(item=>  item.fileName.split('.').pop()!=='txt')
                    if (filedata.length > 0) {
                        // Store both id and fileName
                        const filesWithId = filedata.map(item => ({
                            id: item.id,          // Assuming `id` exists in each item
                            fileName: item.fileName
                        }));
                        setInfoFileContent(filesWithId); // Store both id and fileName
                    }
                      
                    setLoading(false)
                }
               
                   
                }
            
        } catch (error) {
            console.log("Error fetching tax data", error);
        }
    }
    };

    useEffect(() => {
            getTax();
       
    }, [stateId]);


    const handleInfoEditClick = () => {
        setIsInfoEditing(!isInfoEditing);
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        console.log(files);
        const specialCharPattern = /[&$₹/^+*%!@#]/;
        if (specialCharPattern.test(files[0]?.name)) {
            console.log("Error: File name should not contain & or $ characters.");
            toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
            return;
            
        }
        setUploadedFiles([]); 
        setTimeout(() => {
            if (files.length ) {
                setUploadedFiles(prevFiles => {
                    const updatedFiles = [...files]; 
                    setInfoFileContent(updatedFiles.map(file => file.name));
                    return updatedFiles;
                });
            }
        }, 0);
      };
   
console.log(uploadedFiles,"xcfghjbkl");

   
    const handleSaveInfo=()=>{
        console.log("Yes");
        handleSave();
        
    }
    const handleSave = async () => { 
        console.log("hey");
        setLoading(true); // Set loading to true at the start
      
        const timestamp = Date.now(); 
        let formData = new FormData();
        
        // Append common fields to FormData
        formData.append('Frequency', frequency);
        formData.append('Applicability', applicability);
        formData.append('Rule', rule);
        formData.append('Act', act);
        formData.append('Title', ''); // Initialize Title
        formData.append('state', stateId);
        formData.append('IsImportant', false);
        formData.append('AdditionalLink', infoLinkContent); 
        formData.append('RequestedBy', "admin");
        formData.append('Folder', 'LabourWelfare');
        formData.append('Task', '');
        formData.append('LastDayfiling', '');
        formData.append('Type', 4);
        formData.append('EffectiveDate', '0001-01-01T00:00:00');
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        let attachmentFile = null;
        let newFileName = '';
        if (uploadedFiles && uploadedFiles.length > 0) {
          // Handle file upload
          const uploaded = uploadedFiles[0].name;
          console.log(uploadedFiles, 'MostRecent');
          
          const fileExtension = uploaded.split('.').pop(); 
          const baseName = uploaded.substring(0, uploaded.lastIndexOf('.'));
          const timestampSuffix = timestamp.toString().slice(-4); 
          const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
          
          console.log('File Extension:', fileExtension);
          console.log('Base Name:', baseName);
          console.log('New File Name:', newFileName);
          
          formData.append('Title', baseName); 
          formData.append('Attachment', uploadedFiles[0]); 
          formData.append('FileNameWithExtension', newFileName);
        } else {
          // Handle placeholder file
          console.log(filteredData,"THIS Is FilteredData");
          
        //   const fileName = filteredData[0].fileName;
        //   const fileExtension = fileName.split('.').pop(); 
        //   const baseName = fileName.substring(0, fileName.lastIndexOf('.')); 
          const timestampSuffix = timestamp.toString().slice(-4); 
        //   const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
          
          const placeholderBlob = new Blob(['Placeholder content'], { type: 'text/plain' }); 
          const placeholderFile = new File([placeholderBlob], 'placeholder.txt', { type: 'text/plain' });
          console.log(placeholderFile,"This is placeholder file");
          
          const fileExtension = placeholderFile.name.split('.').pop();
          const baseName = placeholderFile.name.substring(0, placeholderFile.name.lastIndexOf('.')); 
            const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
          formData.append('Title', baseName); 
          formData.append('Attachment', placeholderFile);
          formData.append('FileNameWithExtension', newFileName);
        }
        if (attachmentFile) {
            formData.append('Attachment', attachmentFile);
            formData.append('FileNameWithExtension', newFileName);
          }
        
        try {
          const response = await fetch(`${notificationApiUrl}/api/TaxAndFundFile`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });
          
          if (response.ok) {
            const data = await response.json();
            console.log("Success:", data);
            toast.success("Data Uploaded successfully!");
            getTax();
            setIsInfoEditing(false);
            
           
          } else {
            console.log("Error:", response.statusText);
          }
        } catch (error) {
          console.log("Error:", error);
        } finally {
          setLoading(false); // Ensure loading is set to false after request
        }
      };
      
 
    const [editIndex, setEditIndex] = useState(null);
    const handleEditFileClick = (index) => {
        setEditIndex(index);
    };
    
    const handleCancelClick = () => {
        setEditIndex(null); 
    };  
    const handleFileDownload = async (file) => {
        setLoading(true)
        try {
          const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'LabourWelfare'}&FileName=${file.fileName}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'text/plain'
            }
          });
      
          if (response.ok) {
            const textResponse = await response.text();
            const fileUrl = textResponse.replace("Url=", "");
      
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false)
          } else {
            console.error("Failed to fetch the file URL:", response.status, response.statusText);
          }
        } catch (error) {
          console.error("Error occurred while downloading the file:", error);
        }
      };
      
      const handleWebsiteClick = (url) => {
        const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
        window.open(formattedUrl, '_blank');
      };
      const [newRowData, setNewRowData] = useState({
        category: '',
        employeeContribution: 0,
        employerContribution: 0,
        totalContribution: 0,
        deductiondate: '',
        latedateForsubmission: ''
    });
    
    // Add the new row data when saving
    const handleAddNewRow = () => {
        setWelfare([...welfare, newRowData]);
        setNewRowData({
            category: '',
            employeeContribution: '',
            employerContribution: '',
            totalContribution: '',
            deductiondate: '',
            latedateForsubmission: ''
        });
      
    setEditIndex(welfare.length);    
    };
    const handleInfoFileDelete=async(index)=>{
        console.log(index,"This is index of deleete");
        setLoading(true)
        try {
            const response = await fetch(`${holidayApiUrl}/api/deleteTaxAndFund?id=${index}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                // setFilingEditReturns(prev => prev.filter((_, i) => i !== index));
                console.log("Item deleted successfully.");
                toast.success("File deleted successfully!");
                setUploadedFiles([])
               await getWelfare();
               await getTax();
               
               setLoading(false)
                
            } else {
                console.error("Failed to delete item:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    }

    const handleWelafreDelete=async(id,index)=>{
        console.log(id,"WElfaredelete Id");
        setLoading(true)
        if (!id) {
            // Remove the new row from the local state (frontend-only deletion)
            setWelfare(prevWelfare => prevWelfare.filter((_, i) => i !== index));
            setLoading(false); // Stop loading once it's removed
            return;
        }
        try {
            const response = await fetch(`${holidayApiUrl}/api/DeleteWelFareFundData?id=${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                // setFilingEditReturns(prev => prev.filter((_, i) => i !== index));
                console.log("Item deleted successfully.");
                toast.success("File deleted successfully!");
               await getWelfare();
               await getTax();
               setLoading(false)
                
            } else {
                console.error("Failed to delete item:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    }
    return (
        <div className="AdminLabour">
            {/* <AdminMenu /> */}
            <ToastContainer />
            <div style={{ width: `calc(100% - 200px)`,minHeight:'1000px' }}>
                <div className="Admin-Labour">
                    <div className="Labour-detail">
                        <div className="Labour-detail1-name">Labour welfare fund</div>
                        <div className="Labour-search" onClick={toggleStateDropdown}>
                            <span className="Labour-state-Name">{StateName === '' ? 'Select State to view record' : StateName}</span>
                            <CaretDown size={20} />
                        </div>
                        {stateDropDown && (
                            <div className="dropdown-state-Labour">
                                {stateList.filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state)).map((option) => (
                                    <div className="dropdown-item-state-Labour" key={option.id} onClick={() => { handleStateClick(option.state, option.id) }}>
                                        {option.state}
                                    </div>
                                ))}
                            </div>
                        )}
                        {popOpen && <div className="Welfare-overlay"></div>}
                         {popOpen && (
                <div className="Labour-popup">
                    <div className="Labour-pop-Uplaod">
                        <span>Upload New File</span>
                        <XCircle size={20} onClick={() => { setPopOpen(false) }} style={{cursor:'pointer'}} />
                    </div>
                    <div className='Labour-pop-detail'>
                        <div className='Labour-detail-head'>Labour Welfare fund Contribution</div>
                        <div className='Labour-data'>
    <table className='Labour-table'>
        <thead>
            <tr>
                <th>Category</th>
                <th>Employee Contribution</th>
                <th>Employer Contribution</th>
                <th>Total Contribution</th>
                <th>Date of Deduction</th>
                <th>Late Date for Submission</th>
            </tr>
        </thead>
        <tbody>
            {professionalRates.map((entry, index) => (
                <tr key={index}>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.Category}
                            onChange={(e) => handleWelfareInput(index, 'Category', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.EmployeeContribution}
                            onChange={(e) => handleWelfareInput(index, 'EmployeeContribution', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.EmployerContribution}
                            onChange={(e) => handleWelfareInput(index, 'EmployerContribution', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.TotalContribution}
                            onChange={(e) => handleWelfareInput(index, 'TotalContribution', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.Deductiondate}
                            onChange={(e) => handleWelfareInput(index, 'Deductiondate', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='Labour-entry'
                            value={entry.LatedateForsubmission}
                            onChange={(e) => handleWelfareInput(index, 'LatedateForsubmission', e.target.value)}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

                    </div>
                    <div className="Labour-pop-mark">
                        <div className="Labour-pop-imp" onClick={() => { setPopOpen(false) }}>Cancel</div>
                        <div className="Labour-pop-upload" onClick={postProf}>Save and Upload</div>
                    </div>
                </div>
            )}
                    </div>

                    {StateName !== '' && (
                        <div>
                            <div className="Labour-detail2">
                                <div className="Labour-detail1-name">{StateName}</div>
                                
                            </div>
                            <div className="Labour-state">

{/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                          
<div className='WelfareStateDetail'>
            <div className='WelfareStateDetailHead'>
                Information
                {isInfoEditing ? (
    <Check
      size={32}
      color='#898989'
      onClick={handleSaveInfo}
      style={{cursor:'pointer'}}
    />
) : (
    <PencilSimple size={32} color='#898989' onClick={handleInfoEditClick} style={{cursor:'pointer'}}/>
)}

            </div>

            {/* Act Field */}
            <div className='WelfareStateDetailSub'>
                <div className='WelfareStateDetailName'>Act</div>
                <div className='WelfareDetailSubCont'>
                    {isInfoEditing ? (
                        <input
                            type='text'
                            placeholder="Enter Act"
                            value={act}
                            onChange={(e) => setAct(e.target.value)}
                            className='WelfareFilename'
                            style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px",width:'100%'}}
                        />
                    ) : (
                        act
                    )}
                </div>
            </div>

            {/* Rule Field */}
            <div className='WelfareStateDetailSub'>
                <div className='WelfareStateDetailName'>Rule</div>
                <div className='WelfareDetailSubCont'>
                    {isInfoEditing ? (
                        <input
                            type='text'
                            placeholder="Enter Rule"
                            value={rule}
                            onChange={(e) => setRule(e.target.value)}
                            style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"14px",width:'100%'}}
                        />
                    ) : (
                        rule
                    )}
                </div>
            </div>

            {/* Applicability Field */}
            <div className='WelfareStateDetailSub'>
                <div className='WelfareStateDetailName'>Applicability</div>
                <div className='WelfareDetailSubCont'>
                    {isInfoEditing ? (
                        <input
                            type='text'
                            placeholder="Enter Applicability"
                            value={applicability}
                            onChange={(e) => setApplicability(e.target.value)}
                            style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"14px",width:'100%'}}
                        />
                    ) : (
                        applicability
                    )}
                </div>
            </div>

            {/* Frequency Field */}
            <div className='WelfareStateDetailSub'>
                <div className='WelfareStateDetailName'>Frequency</div>
                <div className='WelfareDetailSubCont'>
                    {isInfoEditing ? (
                        <input
                            type='text'
                            placeholder="Enter Frequency"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                            style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"14px",width:'100%'}}
                        />
                    ) : (
                        frequency
                    )}
                </div>
            </div>

            {/* File Section */}
            <div className='WelfareStateDetailSub'>
  <div className='WelfareStateDetailName'>Form</div>
  <div className='WelfareDetailSubCont1'>
    {infoFileContent.map((fileName, index) => (
      <div key={index} className='WelfareFilename'>
        {fileName.id ?(
            <div onClick={() => handleFileDownload(fileName)} className='WelfareFiletitle' >
          {fileName.fileName.split('_').slice(0, -1).join('_') + '.' + fileName.fileName.split('_').slice(-1)[0].split('.').pop()}
        </div>
        ):( <div onClick={() => handleFileDownload(fileName)} className='WelfareFiletitle'>
        {fileName.split('.').slice(0, -1).join('.') + '.' + fileName.split('.').pop()}
      </div>)}
        
        {isInfoEditing && index !== infoFileContent.length - 1 && fileName.id && (
          
          <Trash
          size={16}
          color="#898989"
          onClick={() => handleInfoFileDelete(fileName.id)}
          style={{cursor:"pointer"}}
        />
        )}
      </div>
    ))}

    {isInfoEditing && (
      <input
        id='file-upload-input'
        type='file'
       
        onChange={handleFileUpload}
        className='WelfareFilename'
        style={{ backgroundColor: 'transparent', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"14px",width:'100%'}}
      />
    )}
  </div>
</div>


            {/* Website Section */}
            <div className='WelfareStateDetailSub'>
                <div className='WelfareStateDetailName'>Website</div>
                <div className='WelfareDetailSubCont1'>
                    {isInfoEditing ? (
                        <input
                            type='text'
                            value={infoLinkContent}
                            onChange={(e) => setInfoLinkContent(e.target.value)}
                            style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"14px",width:'100%'}}
                        />
                    ):(
                       <div onClick={() => handleWebsiteClick(infoLinkContent)} className='WelfareFilename'> {infoLinkContent}</div>
                    )}
                </div>
            </div>
        </div>


{/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
  
                            {welfare.length===0?(
                                <div className='WelfareFileUpload'>
                                <div className="Labour-State-Record">No Record</div>
                                <div className="Labour-detail1-upload" onClick={handlePop}><FilePlus size={24} color="#009746" /> Upload File</div>
                            </div>
                            ):(
                                
                                <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                                    
                                <div className="Labour-detail-head">Labour Welfare Fund Contribution {welfareEdit ? (
                                    <Check size={32} color='#898989' onClick={handleSaveClick} />

                                    ) : (
                                    <div onClick={() => { handleAddNewRow() }} className='AddNewRowWelfare'>Add New Row</div>
                                    )}</div>
                            <div className="Labour-data">
    <table className="Labour-table">
        <thead>
            <tr>
                <th>Category</th>
                <th>Employer Contribution</th>
                <th>Employee Contribution</th>
                <th>Total Contribution</th>
                <th>Date of Deduction</th>
                <th>Last Date for Submission</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {welfare?.map((welfare, id) => (
                <tr key={id}>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.category}
                                onChange={(e) => handleWelfareChange(id, 'category', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.category}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.employeeContribution}
                                onChange={(e) => handleWelfareChange(id, 'employeeContribution', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.employeeContribution}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.employerContribution}
                                onChange={(e) => handleWelfareChange(id, 'employerContribution', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.employerContribution}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.totalContribution}
                                onChange={(e) => handleWelfareChange(id, 'totalContribution', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.totalContribution}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.deductiondate.split("T")[0]}
                                onChange={(e) => handleWelfareChange(id, 'deductiondate', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.deductiondate.split("T")[0]}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <input
                                type='text'
                                className='tax-entry'
                                value={welfare.latedateForsubmission.split("T")[0]}
                                onChange={(e) => handleWelfareChange(id, 'latedateForsubmission', e.target.value)}
                                style={{ fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
                            />
                        ) : (
                            <span>{welfare.latedateForsubmission.split("T")[0]}</span>
                        )}
                    </td>
                    <td>
                        {editIndex === id ? (
                            <div style={{display:'flex', gap:'5px'}}>
                                <button onClick={() => handleWelfareEdit(id)} className="edit-button">Save</button>
                                <button onClick={handleCancelClick} className="edit-button">Cancel</button>
                            </div>
                        ) : (
                            <div style={{display:'flex', gap:'5px'}}>
                                <button onClick={() => handleEditFileClick(id)} className="edit-button">Edit</button>
                                <button onClick={() => handleWelafreDelete(welfare.id, id)} className="edit-button">Delete</button>
                            </div>
                        )}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>


                              </div>
                                
                            
                            )}
                            
                            </div>
                        </div>
                    )}
                </div>
               
            </div>
            {loading && <div style={{zIndex:'9999' ,position:'absolute',top:'38%', left:'38%'}}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
        </div>
    );
}

export default AdminWelfare;
