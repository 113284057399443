import React , {useState} from 'react'
import OTPInput from 'react-otp-input';
import '../css/AdminLogin.css'
import TalentTraverseLogo from '../Images/TalentTraverseLogo.png'

function AdminOtp(){
    const [otp, setOtp] = useState("");

    const handleChange = (otp) => {
      setOtp(otp);
    };
  
    const renderInput = (props) => {
        return (
            <input
                {...props}
                style={{
                    width: "40px",
                    height: "48px",
                    margin: "0 5px",
                    fontSize: "18px",
                    border: "1px solid rgba(137, 137, 137, 0.55)",
                    outlineColor:'rgba(64, 49, 131, 1)',
                    borderRadius: "8px",
                    textAlign: "center",
                }}
                focusStyle={{
                    border: "1px solid rgba(137, 137, 137, 0.55)",
                    outline: "none",
                }}
            />
        );
    };

       return(
        <div className='AdminLogin'>
        <div className='loginModal'>
            <div className='login-company-data'>
                <img src={TalentTraverseLogo} className='login-company-logo'/>
                Talent Traverse HR Solutions PVt. Ltd
            </div>
            <div className='login-welcome'>
                Welcome
                <span className='login-login'>Log in!</span>
            </div>
            <div className='login-box'>
                <div className='login-head'>Please enter the 4 digits OTP that we just sent on +91******7261</div>
                <div className='loginOtp'>
                    <OTPInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    renderInput={renderInput}
                    separator={<span>-</span>}
                    inputStyle={{
                    width: "40px",
                    height: "48px",
                    margin: "0 5px",
                    fontSize: "18px",
                    border: "1px solid #ccc",
                    borderRadius: "8px", 
                    textAlign: "center",
                    }}
                    focusStyle={{
                        border: "1px solid rgba(64, 49, 131, 1)",
                        outline: "none",
                    }}/>
                    <div className='login-otp-timer'>Request new OTP again in<span className='login-timer'> 30 secs</span></div>
                </div>
               
                <div className='login-otp-cont'>Login</div>
                <div className='login-terms-cont'>By continuing, you agree that you have read and accept our <span style={{fontWeight:'600'}}>Terms & Condition </span>and <span style={{fontWeight:'600'}}>Privacy Policy</span></div>
            </div>
            </div>
    </div>
        
       )
}
export default AdminOtp;