import React from 'react'
import Navbar from '../components/Navbar.js';
import Footer from '../components/Footer.js';
import Hero from './Hero.js';
import Query from './Query.js';
import Companymv from './Companymv.js';
import '../css/HomePage.css'

function HomePage() {
  return (
      <div className='Home-page'>
      <Hero/>
      <Companymv/>
      <Query/>
     </div>
  )
}

export default HomePage
