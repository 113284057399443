import React from 'react'
import '../css/ClientDash1.css'
import ClientMenu from '../components/ClientMenu'
import Home3 from '../Images/Home3.png'
import Home4 from '../Images/Home4.png'
function ClientDash4() {
  return (
    <div style={{ display: 'flex', maxWidth: '1280px',width:'1100vw', justifyContent:'center' }}>
    <div className='ClientHome'>
        <ClientMenu/>
        <div className='ClientHomeComp'>
            <div className='ClientWelcome'>
                <div className='ClientName'>Hello Ankit!</div>
                <div className='ClientGreet'>Good Morning</div>
            </div>
            <div className='ClientHome'>Home</div>
            <div className='ClientHome4Img' >
                <div style={{width:'100%'}}><img src={Home3} style={{width:'inherit'}}/></div>
                <div style={{width:'100%'}}><img src={Home4} style={{width:'inherit'}}/></div>
            </div>
        </div> 
    </div>
    </div>
  )
}

export default ClientDash4