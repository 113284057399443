import React, { useEffect, useRef, useState } from 'react'
import '../css/ETax.css'
import { BagSimple, BellRinging, CaretDown, CaretUp, Coins, Gavel, HandCoins, PencilSimpleLine, Scales, XCircle } from '@phosphor-icons/react'
import TaxSub1 from '../Images/ProfessionalTaxSub.jpg'
// import TaxSub2 from '../Images/ProfessionalTaxSub2.png'
import TaxSub2 from '../Images/TaxSub2.png'
import ELibraryMenu from '../components/ELibraryMenu'
import IndiaMap from '../components/IndiaMap.js'
import TimePicker from 'react-time-picker'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from "@mui/material";
import coming from '../Images/ComingSoon.png'
function ETax() {
    const [loading,setLoading]=useState(false)
    const taxwhy=[{
        id:1,
        icon:<HandCoins size={32} color="#403183"/>,
        title:'Income Redistribution',
        desc:'Helps in the redistribution of wealth within the state by taxing those with an income.',
    },
    {
        id:2,
        icon:<Coins size={32} color="#403183" />,
        title:'Revenue Generation',
        desc:'It is a source of revenue for state governments, helping fund various public services and infrastructure.',
    },
    {
        id:3,
        icon:<Scales size={32} color="#403183"/>,
        title:'Compliance with Law',
        desc:'It is mandatory under the Constitution of India, allowing states to levy this tax.',
    }]

      const FileData=[{
        id:1,
        FileName:"Title of File",
        File:"file.pdf",
        States:"Karnataka",
        StateId:11,
        UpdateDate:'29 July 2024',
        UploadedData:'Recent',
        Time:'12:03:45'
    },
    {
        id:2,
        FileName:"Title of File",
        File:"file.pdf",
        States:"Arunachal Pradesh",
        StateId:2,
        UpdateDate:'29 July 2024',
        UploadedData:'Recent',
        Time:'12:03:45'
    },
  
]
const getStatesFromFileData = (fileData) => {
    const stateNames = fileData.flatMap(item => item.States);
    return Array.from(new Set(stateNames)); 
   
};
const getStateIdFromFileData = (fileData) => {
    const stateId = fileData.flatMap(item => item.StateId);
    return Array.from(new Set(stateId)); 
   
};
const states = getStatesFromFileData(FileData);
const stateId=getStateIdFromFileData(FileData);
const [reminder, setReminder]=useState(false)
const handleReminder=()=>{
    setReminder(!reminder)
}


const [stateList,setStateList]=useState([]);
  const token=''
  const getStateName=async()=>{
    try{
      const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
        method:'GET',
        headers:{
          Authorization:`Bearer${token}`,
          "Content-Type":'application/json',
        }, 
      })
    
      if(response.ok){
        const data=await response.json();
        setStateList(data);   
      }
    }
    catch{
  console.log("error");
    }
  }
  useEffect(()=>{
    getStateName();
  },[])
const [showTimerDropdown, setShowTimerDropdown] = useState(false);
    const [time, setTime] = useState('10:00');

    const toggleTimerDropdown = () => {
        setShowTimerDropdown(!showTimerDropdown);
    };
    const [selectedStates, setSelectedStates]=useState([]);
    const [stateDrop,setStateDrop]=useState(false)
    const handleStateDropDown=()=>{
        setStateDrop(!stateDrop);
    }
    const handleStateSelect=(name)=>{
        setSelectedStates(prev => 
            prev.includes(name) ? prev.filter(state => state !== name) : [...prev, name]
        );
    }
   const  [compView,setCompView]=useState(false);
    const [nonCompView,setNonCompView]=useState(false)
    const handleViewAllC=()=>{
        setCompView(!compView);
        setNonCompView(false)
    }
    const handleViewAllN=()=>{
        setCompView(false);
        setNonCompView(!nonCompView)
    }
  
  return (
    <div className='ETaxTop'>
     {/* <div style={{maxWidth:'1280px'}}> */}
    

    <div className='ETaxMain'>
        <div className='ETaxHead'>
            <div className='ETaxNameMain'>
                <BagSimple className='ETaxGavel'/>
                <div className='ETaxNameFull'>Professional Tax</div>
            </div>
            {/* <div className='ETaxDetail'>Professional Tax is a tax levied by state governments in India on individuals earning an income from various  professions, trades, callings, and employments. This tax is collected to generate revenue for the state and is  one of the sources of income for the state government. The Professional Tax rates, applicability, and  regulations vary from state to state</div> */}
        </div>
        <div className='ETaxAllData'>
        <div className='ETaxWhy'>
            <div className='ETaxWhyMain'>
                <div style={{display:'flex',flexDirection:'column',gap:'32px'}}>
                <div className='ETaxWhyHead'>Why Professional Tax?</div>
                <div className='ETaxWhyData'>
                    <div className='ETaxWhyDesc'>Professional Tax is a tax levied by state governments in India on individuals earning an income from various  professions, trades, callings, and employments. This tax is collected to generate revenue for the state and is  one of the sources of income for the state government. The Professional Tax rates, applicability, and  regulations vary from state to state</div>
                </div>
                </div>
                <div className='ETaxWhyImage'><img src={TaxSub1} className='ETaxWhyImg'/></div>
            </div>
            <div className='ETaxSingle'>
                {taxwhy?.map((why,id)=>(
                   <div className='ETaxSingleData' key={id}>
                        <div className='ETaxSingleHead'>{why?.icon}{why?.title}</div>
                        <div className='ETaxSingleDesc'>{why?.desc}</div>
                    </div> 
                ))}  
            </div>

        </div>
       
        <div className='ETaxMapDetail'>
            <div className='ETaxMinimum-remainder'>
                        <div className='ETaxMinimum-remainder-head'><BellRinging size={32} color="#403183" />Set reminder for  the upcoming notifications from different states now!</div>
                        <div className='ETaxMinimum-remainder-button' onClick={handleReminder}>Set reminder </div>
                        {reminder && <div className="ETax-overlay"></div>}
                        {reminder&&(
                <div className='ETaxReminderPop'>
                  
                       <div className='Tax-Remainder' > <XCircle size={20} color="rgb(217, 214, 230)" weight="fill"  onClick={handleReminder} style={{cursor:'pointer'}}/></div>
                       <div className='ETaxRemButtons'>

                    <div className='title-Tax-soon' >Coming Soon</div>
                    <div className='back-remainder' ><img src={coming} className='Img-Tax'/></div>
                    </div>
                </div>
            )
            }
            </div>
           
            <div className='ETaxMapDropDown'>
            </div>
           <div className='ETaxIndia'><IndiaMap  page='Tax'/></div> 
        </div>
        <div className='ETaxSCope'>
            <div className='ETaxScopeData'>
                <div className='ETaxSData'>
                <div className='ETaxScopeHead'>Scope</div>
                <div className='ETaxScopeDetail'>
                     <div className='ETaxScopeDesc'><b>Employees : </b> Both private and public sector employees</div>
                     
                </div>
                </div>
                 
                <div className='ETaxScopeNum'>1</div>  
            </div>
            <div className='ETaxScopeData'>
                <div className='ETaxSData'>
                <div className='ETaxScopeHead'>Applicability</div>
                <div className='ETaxScopeDetail'>    
                    <div className='ETaxScopeDesc'><b> Salaried Employees : </b> Professional Tax is deducted by the employer from the salary of employees</div>
                    
                </div>
                </div>
                <div className='ETaxScopeNum'>2</div>
            </div>
        </div>
        <div className='ETaxAdmin'>
            <div className='ETaxAdminData'>
                <div className='ETaxAdminHead'>Administration</div>
                <div className='ETaxAdminDesc'>Professional Tax is administered by the respective state governments through designated departments.  Employers are responsible for deducting Professional Tax from their employees' salaries and remitting it to the  state government. Self-employed individuals must register and pay Professional Tax directly to the state  authorities.</div>
            </div>
            <div className='ETaxAdminComp'>
                <div className='ETaxCompliance'>
                    <div className='ETaxCompDetail' >
                        <div className='ETaxComplianceData'>
                            <div className='ETaxComplianceHead'>Compliance </div>
                            <div className='ETaxComplianceDesc'>Employer Responsibilities</div>
                        </div>
                        <div className='ETaxComplinaceView' onClick={handleViewAllC}>  {compView
        ?(<CaretUp size={18} style={{cursor:'pointer'}} color="#000000"/>):(<CaretDown size={18} style={{cursor:'pointer'}} color="#000000" />)}</div>
                    </div>
                        {compView &&(
                            <div className='ETaxCompDescAll'>
                                <li>Register with the state Professional Tax Department.</li> 
                                    <li>Deduct Professional Tax from employees' salaries.</li> 
                                    <li>Remit the collected tax to the state government within the stipulated time frame.</li> 
                                    <li>File returns as required by state law.</li> 
                                </div>
                        )   
                            }
                </div>
                <div className='ETaxNonCompliance'>
                    <div className='ETaxNonDetail' >
                    <div className='ETaxNonComplianceData'>
                            <div className='ETaxNonComplianceHead'>Non - Compliance</div>
                            <div className='ETaxNonComplianceDesc'>Penalties</div>
                        </div>
                        <div className='ETaxComplinaceView' onClick={handleViewAllN}> {nonCompView
        ?(<CaretUp size={18} style={{cursor:'pointer'}} color="#000000"/>):(<CaretDown size={18} style={{cursor:'pointer'}} color="#000000" />)}</div>

                    </div>
                    {nonCompView &&(
                        <div  className='ETaxCompDescAll'>
                            {/* <ul className='ETaxUl'> */}
                            <li className='ETaxLi'>Fines: Monetary penalties for late payment or non-payment of Professional Tax.</li> 
                                <li className='ETaxLi'>Interest: Interest charges on overdue amounts.</li> 
                                <li className='ETaxLi'>Legal Action: Prosecution under relevant state laws for persistent non-compliance.</li> 
                            {/* </ul> */}
                              
                            </div>
                    )   
                        }
                </div>
                
            </div>
        </div>
        <div className='ETaxConclusion'>
                <div className='ETaxConcData'>
                    <div className='ETaxConcHead'>Conclusion</div>
                    <div className='ETaxConcDesc'>Professional Tax is an important revenue source for state governments in India and is levied on individuals  earning an income through various means. The rates, applicability, and regulations vary by state, and  compliance is mandatory to avoid penalties. Employers and self-employed individuals must stay informed  about the specific requirements in their respective states to ensure proper compliance.</div> 
                </div> 
                <div className='ETaxConcImage'><img className='ETaxConcImg' src={TaxSub2}/></div>
            </div>
        </div>
        {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
    </div>
    </div>
  )
}

export default ETax