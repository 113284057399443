
import { useState, useEffect } from 'react';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
export const useStateImages = (token) => {
  const [stateImages, setStateImages] = useState({});

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'StateSymbol'}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'text/plain',
          },
        });

        if (response.ok) {
          const textResponse = await response.text();
          const jsonResponse = JSON.parse(textResponse); 
          const imagesMap = jsonResponse.reduce((acc, symbol) => {
            const cleanedUrl = symbol.Url.replace("Url=", "");
            acc[symbol.stateid] = cleanedUrl; 
            // console.log(acc);
            
            return acc;
          }, {});

          setStateImages(imagesMap); // Store the map in state
        } else {
          console.error('Failed to fetch state symbols: ', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching state symbols:', error);
      }
    };

    fetchSymbols();
  }, [token]);

  return stateImages;
};

export const useStateBanner = (token)=>{
  const [stateBanner,setStateBanner]=useState({});
  useEffect(()=>{
    const fetchBanner= async ()=>{
      try{
        const response= await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'Statebanner'}`,{
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'text/plain',
          },
        });

        if (response.ok) {
          const textResponse = await response.text();
          const jsonResponse = JSON.parse(textResponse); 
          const imagesMap = jsonResponse.reduce((acc, symbol) => {
            const cleanedUrl = symbol.Url.replace("Url=", "");
            acc[symbol.stateid] = cleanedUrl;             
            return acc;
          }, {});

          setStateBanner(imagesMap); // Store the map in state
        } else {
          console.error('Failed to fetch state banner: ', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching state banner:', error);
      }
    };

    fetchBanner();
  }, [token]);

  return stateBanner;
};

export const useStateMap = (token)=>{
  const [stateMap,setStateMap]=useState({});
  useEffect(()=>{
    const fetchMap= async ()=>{
      try{
        const response= await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'StateMap'}`,{
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'text/plain',
          },
        });

        if (response.ok) {
          const textResponse = await response.text();
          const jsonResponse = JSON.parse(textResponse); 
          const imagesMap = jsonResponse.reduce((acc, symbol) => {
            const cleanedUrl = symbol.Url.replace("Url=", "");
            acc[symbol.stateid] = cleanedUrl;             
            return acc;
          }, {});

          setStateMap(imagesMap); // Store the map in state
        } else {
          console.error('Failed to fetch state banner: ', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching state banner:', error);
      }
    };

    fetchMap();
  }, [token]);

  return stateMap;
};

