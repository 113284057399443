import React, { useContext, useState } from "react";
import "../css/AdminLogin.css";
import TalentTraverseLogo from "../Images/TalentTraverseLogo.png";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
// import { useContext } from 'react';
import { AuthContext } from "./AuthContext";
import { TestContext } from "./TestContext";
import AdminFrame from "../Images/AdminFrame.png";

function AdminLogin() {
  // const [password, setPassword] = useState(true);
  const [retypePassword, setRetypePassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const {isLogin, setIsLogin} = useContext(AuthContext)
  const { isLogin, setIsLogin } = useContext(TestContext);
  // const {a}=useContext(AuthContext)
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    const trimmedEmail = email.trim();
    if (
      trimmedEmail.endsWith("@talentTraverse.com") &&
      password === "Admin@1234"
    ) {
      setIsLogin(true);
      navigate("/Admin/AdminActs");
    } else {
      alert("Invalid email or password");
    }
  };

  const toggleRetypePassword = () => {
    setRetypePassword(!retypePassword);
  };

  return (
    <div className="admin-main">
      <div className="admin-design">
        <div className="admin-log">
          <div className="login-comp">
            <div
              className="logo-design"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                className="comp-logo"
                loading="lazy"
                src={TalentTraverseLogo}
                alt=""
              />
            </div>
            <div>
              <div className="admin-title">
                Talent Traverse HR Solutions PVt. Ltd
              </div>
              <div className="title-design">Beyond Boundaries</div>
            </div>
          </div>

          <div className="login-page">
            <div className="title-login-design">LOGIN</div>
            <div className="Login-credential">
              <div className="company-email">
                <div className="title-text-one">Company Name / Email ID</div>
                <div className="company-emailinput">
                  <input
                    className="textfiled-one"
                    placeholder="Enter Email ID"
                    style={{ border: "none", outline: "none" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="company-email">
                <div className="title-text-one">Password</div>
                <div className="login-input-feild">
                  <input
                    className="textfiled-two"
                    placeholder="Enter Password"
                    style={{ border: "none", outline: "none" }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span onClick={togglePassword} style={{ cursor: "pointer" }}>
                    {showPassword ? <Eye size={22} color="rgba(0, 0, 0, 0.2)" /> : <EyeSlash size={22} color="rgba(0, 0, 0, 0.2)"/>}
                  </span>
                </div>


              </div>
            </div>

            {/* <div className="button-design">
              <div className="button-title">LOGIN</div>
            </div> */}
            <div className="login-otp" onClick={handleLogin}>
              Login
            </div>
            <div className="login-terms">
              By continuing, you agree that you have read and accept our{" "}
              <u
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/Privacy");
                }}
              >
                Terms & Condition and Privacy Policy
              </u>
            </div>
          </div>
        </div>
        <div className="admin-img">
          {/* <img className="img-logo" loading="lazy" src={AdminFrame} alt="" /> */}
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
