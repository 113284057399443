import { CaretDown, CaretUp, Flag, Gavel, HandCoins, MagnifyingGlass, Note, Scales } from '@phosphor-icons/react'
import React, { useEffect, useRef, useState } from 'react'
import '../css/ELabour.css'
import { useLocation, useNavigate } from 'react-router-dom'
import ELibraryMenu from '../components/ELibraryMenu'
import {useStateImages} from '../components/StateEmblem';
import {useStateMap} from '../components/StateEmblem';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material'
function ELabour() {
    const notification=[{
        id:1,
        icon:<Gavel size={30} color='#403183'/>,
        Name:'Amendments to Existing Laws',
        desc:'Changes to existing labor laws'
    },
    {
        id:2,
        icon:<Scales size={30} color='#403183'/>,
        Name:'New Legislation',
        desc:'Introduction of new labor laws or regulations that impact employment practices'
    },
    {
        id:3,
        icon:<Note size={30} color='#403183'/>,
        Name:'Clarifications and Guidelines',
        desc:'Detailed explanations and guidelines on how to implement or comply  with specific provisions of labor laws.'
    },]
    const navigate=useNavigate();
    const handleNavigate=(id)=>{
        console.log(id);
        navigate(`/StateLabour/${id}`)
    }
    const [stateSelect, setStateSelect]=useState('');
    const [stateDropDown,setStateDropDown]=useState(false);
    const stateDrop=()=>{
        setStateDropDown(!stateDropDown);
    }
    const [loading, setLoading] = useState(false);

    const [stateList,setStateList]=useState([]);
    const token=''
    const stateImages = useStateImages(token);
   
    const getStateName=async()=>{
      setLoading(true);
 
        try{
         
            const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
            method:'GET',
            headers:{
                Authorization:`Bearer${token}`,
                "Content-Type":'application/json',
            }, 
            })
        
            if(response.ok){
            const data=await response.json();
            const sortedData = data.sort((a, b) => 
                a.state.toLowerCase().localeCompare(b.state.toLowerCase())
            );
            setStateList(sortedData)
            setLoading(false);
            }
        }
        catch{
        console.log("error");
        }
    }
  
    useEffect(()=>{
        getStateName();
    },[])

  
// ---------- SEARCH ------------//
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedState,setSelectedState]=useState(null)
    const handleState = (state) => {
        console.log(state);
        setSelectedState(state);
        setSearchQuery(state.state); // Set the search bar value to the selected state's name
        setStateDropDown(false);
    };
    
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setSelectedState(null); // Reset selected state when typing
        setStateDropDown(true); // Show dropdown on input change
      };
    const normalizeStateName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
      };
      const filteredStates = (stateList || []).filter((state) => {
        return state?.state?.toLowerCase().includes(searchQuery.toLowerCase());
      }) || [];
      const stateMap = useStateMap(token);
      const fetchAllActData = async () => {
        // setLoading(true);
     
        try {
          
          const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'notification'}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          });
          if (response.ok) {
            const data = await response.json();
            return data;
            
          }
          // setLoading(false);
        } catch (error) {
          console.error('Failed to fetch act data:', error);
        }
        return [];
      };
      
      useEffect(() => {
        if (hasFetchedData.current) return; // Skip if data has been fetched
      
        const fetchData = async () => {
          if (filteredStates.length === 0) return; // Exit if there are no states
      
          const counts = {};
          const updates = {};
      
          const allData = await fetchAllActData();
      
          filteredStates.forEach(state => {
            const stateData = allData.filter(item => item.stateId === state.id);
            counts[state.id] = stateData.length;
      
            const latestDate = stateData.reduce((latestDate, item) => {
              const itemDate = new Date(item.createDate);
              const latestDateObj = latestDate === 'NA' ? new Date('1970-01-01T00:00:00Z') : new Date(latestDate);
      
              if (isNaN(itemDate.getTime())) {
                console.error('Invalid date:', item.createDate);
                return latestDate; // Skip invalid dates
              }
      
              return itemDate > latestDateObj ? item.createDate : latestDate;
            }, 'NA');
      
            updates[state.id] = latestDate !== 'NA' ? formatDate(latestDate) : 'NA';
          });
      
          setFilteredStateCounts(counts);
          setStateUpdates(updates);
          hasFetchedData.current = true; // Mark data as fetched
        };
      
        fetchData();
      }, [filteredStates]);
    
      const [filteredStateCounts, setFilteredStateCounts] = useState({});
      const [stateUpdates, setStateUpdates] = useState({});
      const hasFetchedData = useRef(false);
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }).format(date);
      };

      const toggleDropdown = () => {
        setStateDropDown(!stateDropDown);
        // setStateDropDown(prev => !prev);
    };

    const handleOutsideClick = (e) => {
      if (e.target.closest('.EActs-search') === null) {
          setStateDropDown(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => document.removeEventListener('click', handleOutsideClick);
  }, []);
      
   
  return (
    <div className='ELabourTop'>
        <div className='ELabourMain'>
            <div className='ELabourHead'>
                <div className='ELabourName'>
                    <Gavel className='ELabourGavel'/>
                    <div className='ELabourNameFull'>Labour law and Gazette notification</div>
                </div>
                <div className='ELabourDetail'>Labor Law Gazette Notifications in India are official publications issued by the government to communicate  updates, amendments, and new regulations related to labor laws. These notifications are published in the  Official Gazette of India and are crucial for ensuring that employers, employees, and other stakeholders stay  informed about the legal requirements they must adhere to.</div>
            </div>
            <div className='ELabourContainer'>
                <div className='ELabour-notif-types'>
                    <div className='ELabour-notif-head'>Common types of notifications include</div>
                    <div className='ELabour-all-detail'>
                    {notification?.map((notif)=>(
                            <div className='ELabour-all-notif'>
                                <div className='ELabour-notif-name'><span className='ELabour-notif-count'>{notif.icon}</span>{notif.Name}</div>
                                <div className='ELabour-notif-desc'>{notif.desc}</div>
                            </div>
                      
                    ))}
                      </div>
                </div>
              

                
                <div className='ELabour-state-search'>

                {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
                
                <div className='ELabour-notif-head'>Search for States and Union Territories</div>
                <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>
                <div className="EActs-search"   onClick={toggleDropdown}>
                        <input placeholder="Search for States and Union Territories" type="search" className="EActs-searchbar"  value={searchQuery}
                        onChange={handleSearchChange}
                      
                        onFocus={() => setStateDropDown(true)}
                         />
                        {stateDropDown ? (
                            <CaretUp size={20} color="#4d4d4d"  style={{cursor:'pointer'}} />
                            ) : (
                            <CaretDown size={20} color="#4d4d4d"   style={{cursor:'pointer'}}/>
                            )}
                          {stateDropDown && (
                      <div className="ELabour-dropdown">
                        {filteredStates.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      }).map((state, index) => (
                          <div key={index} className="ELabourDropName" onClick={() => handleState(state)}>
                            {state.state}
                          </div>
                        ))}
                      </div>

                    )}  
                    </div> 

                    

                    <div className='ELabourData'>
                    {selectedState ? (
    (() => {
      const background = stateMap[selectedState.id];
      return (
        <div
          style={{
            minWidth: '245px',
            maxHeight: '250px',
            gap: '0px',
            borderRadius: '8px',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'rgba(78, 175, 78, 0.15)', 
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '24px 16px',
            cursor:'pointer'
          }}
          key={selectedState.id}
          onClick={() => { handleNavigate(selectedState.id) }}
        >
          <div className='ELabourStateFLag'>
            {stateImages[selectedState.id] ? (
              <img
                src={stateImages[selectedState.id]}
                alt={selectedState.state}
                style={{ height: 'inherit', width: 'inherit' }}
              />
            ) : (
              <span>No image available</span>
            )}
          </div>
          <div className='ELabourStateName'>{selectedState.state}</div>
          <div className='ELabourUpdate'>Number of notifications - {filteredStateCounts[selectedState.id] || 'NA'}</div>
          <div className='ELabourSatetNumber'>Recent upload: {stateUpdates[selectedState.id] || 'N/A'}</div>
        </div>
      );
    })()
  ) : (
    filteredStates.sort((a, b) => {
      if (a.state === "Central") return -1; 
      if (b.state === "Central") return 1;
      return a.state.localeCompare(b.state); 
    }).map((state) => {
      const background = stateMap[state.id];
      const stateNumber = filteredStateCounts[state.id] || 'NA';
      const uploadDate = stateUpdates[state.id] || 'N/A';

      return (
        <div
        style={{
          minWidth: '245px',
          maxHeight: '250px',
            gap: '0px',
            borderRadius: '8px',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'rgba(78, 175, 78, 0.15)',  
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '24px 16px',
            cursor:'pointer'
           
            // backgroundRepeat:'no-repeat'
          }}
          key={state.id}
          onClick={() => { handleNavigate(state.id) }}
        >
          <div className='ELabourStateFLag'>
            {stateImages[state.id] ? (
              <img
                src={stateImages[state.id]}
                alt={state.state}
                style={{ height: 'inherit', width: 'inherit' }}
              />
            ) : (
              <span>No image available</span>
            )}
          </div>
          <div className='ELabourStateName'>{state.state}</div>
          <div className='ELabourUpdate'>Number of notifications - {stateNumber}</div>
          <div className='ELabourStateNumber'>Recent upload: {uploadDate}</div>
        </div>
      );
    })
  )}
                        
                    </div> 
                </div>
                   
                </div> 
            
        </div>
        </div>
    </div>
  )
}

export default ELabour