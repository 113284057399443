import React, { useEffect, useRef, useState } from 'react';
import '../css/EActsStates.css';
import { CaretDown, CaretLeft, CaretUp, FileArrowDown, MagnifyingGlass } from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function EActStates() {
  const { id } = useParams();
  const params = useParams();
  const uniqueId = params.id;
  console.log(uniqueId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [filePresent, setFilePresent] = useState(false);
  const [stateName, setStateName] = useState('');
  const [stateId, setStateId] = useState();
  const [stateList, setStateList] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState(fileData);
  const [searchQuery, setSearchQuery] = useState('');
  const token = '';
  const [backgroundImage, setBackgroundImage] = useState(null);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
  // const handleSelectAll = () => {
  //   if (!selectAll) {
  //     setSelectedFiles(fileData.map(file => file.id));
  //   } else {
  //     setSelectedFiles([]);
  //   }
  //   setSelectAll(!selectAll);
  // };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const acts = fileData.filter((file) => file.fileSource === 'acts');
      setSelectedFiles(acts.map((file) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleFileSelect = (id) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter(fileId => fileId !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
 
  useEffect(() => {
    const filterFiles = () => {
        let filtered = fileData;

        if (searchQuery) {
            filtered = filtered.filter(file =>
                file.fileTitle.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        filtered.sort((a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate));

        setFilteredFiles(filtered);
    };

    filterFiles();
}, [ searchQuery, fileData]);


const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};
  const handleFileDownload = async (file) => {
    console.log(file);
   
    try {
    
      const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'acts'}&FileName=${file.fileName}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'text/plain'
        }
      });
  
      if (response.ok) {
        const textResponse = await response.text();
        const fileUrl = textResponse.replace("Url=", "");
  
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
       
      } else {
        console.error("Failed to fetch the file URL:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };
  const downloadSelectedFiles = async () => {
    for (let id of selectedFiles) {
        const file = fileData.find(file => file.id === id);
        if (file) {
            await handleFileDownload(file);
        } else {
            console.error(`File with ID ${id} not found.`);
        }
    }
};
  const handleBack = () => {
    navigate('/ActsandRules');
  };

  const getStateName = async () => {
    
    try {
      setLoading(true)
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStateList(data);
        setStateName(data?.[uniqueId - 1]?.state);
        setLoading(false)
      }
    } catch {
      console.log("Error");
    }
  };

  const handleStateClick = (name, sId) => {
    setStateName(name);
    setStateId(sId);
    console.log(sId);
    getFile(sId);
    setDropdownVisible(false)
  };

  const getFile = async (id) => {

    console.log(id);
    try {
      setLoading(true)
      const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'acts'}&StateId=${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });
      if (response.ok) {
        setLoading(false)
        const data = await response.json();
        // console.log(data);
        setFileData(data);
        setFilePresent(true);
        
      }
    } catch {
      console.log("Error");
    }
  };

  useEffect(() => {
    getStateName();
    getFile(id);
  }, []);
  useEffect(() => {
    if (stateId) {
        getFile(stateId);
        navigate(`/StateAct/${stateId}`, { replace: true }); 
    } else {
        getFile(id);
    }
}, [stateId]);
  const actsFileCount = fileData && Array.isArray(fileData) ? fileData.filter(file => file.fileSource === 'acts').length : 0;
// 
const stateBanner = useStateBanner(stateId);
console.log(stateBanner);

// const backgroundImage = stateBanner[id] || StateImage ; 
// console.log(backgroundImage);
const dropdownRef = useRef(null);
const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
    }
};
useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);


  return (
    <div className='EActState-Top'>
      <div className='EActState-main'>
        <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`
               }} className='EActsBackground'>
          
          <div className='EActState-maintext' onClick={toggleDropdown}>{stateName} {!dropdownVisible ? <CaretDown size={24} /> : <CaretUp size={24} />}</div>
        </div>
        {dropdownVisible && (
  <div className='EActState-dropdown' ref={dropdownRef}>
    {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((states, index) => (
        <div key={index} className='EActStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

        <div className='EActState-Data'>
        <div className='EActBack' onClick={handleBack}><CaretLeft size={24} />Back</div>
        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
          <div className="EActState-search"><MagnifyingGlass size={20} color="#4d4d4d" /><input placeholder="Search for Acts" type="search" value={searchQuery} className="EActState-searchbar" onChange={handleSearchChange} /></div>
          <div className="EActState-FilteringDetail">
            <div className="EActState-select"><input type="checkbox" className="EActState-select-checkbox" checked={selectAll} onChange={handleSelectAll} />Select All</div>
            <div className="EActState-delete-download" onClick={downloadSelectedFiles}><span className="EActStateDownload"><FileArrowDown size={20} color="#009746"  />Download file</span><div className='EActStateLength'>{actsFileCount} Results found</div></div>
          </div>
          <div className="EActState-Detail">
            {filePresent && filteredFiles.map((file,id)=>(
              file.fileSource==='acts' &&(
              <div key={id} className="EActState-InfoAll">
                <input type="checkbox" className="EActState-select-checkbox" checked={selectedFiles.includes(file.id)} onChange={() => handleFileSelect(file.id)} />
                <div className="EActState-FileInfo">
                  <div className="EActState-FileData">
                    <div className="EActState-FileDownload">{file.fileTitle}</div>
                    <div onClick={()=>{handleFileDownload(file)}} className='EActState-DownloadP'><FileArrowDown size={20} color="#FFFFFF"/> Download</div>
                  </div>
                  <div className="EActState-File-Detail">
                    <div className="EActState-StateDate">
                      <div className="EActState-file-date">Updated as on {file.createDate.split("T")[0]}</div>
                    </div>
                  </div>
                </div>
              </div>)
            ))}
          </div>
        </div>
        </div>
        
      </div>
  );
}

export default EActStates;
