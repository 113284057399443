import React, { useState } from "react";
import "../css/OurTeam.css";
import Man2 from "../Images/Man2.jpg";
import Man from "../Images/Man.jpg";
import Man2New from "../Images/Man2New.jpg";


function OurTeam() {

    const teamMembers = [
        {
          name: "Prasanna Kumar",
          role: "Managing Director",
          image: Man,
          description: `Prasanna Kumar is a seasoned expert with over 25 years of experience in corporate compliance and labour laws.
          He holds a bachelor’s degree in commerce from Mysore University and has extensive knowledge in PAN India labour regulations, including industrial and employment laws. 
          He has successfully led large teams, built a nationwide consulting partner network, and managed compliance for 400+ clients across India. 
          His leadership continues to drive success in regulatory compliance and business growth across the country.`,
        },
        {
          name: "Sundar Raj",
          role: "Head of Compliance",
          image: Man2New,
          description: `Sundar Raj is the Head of Compliance with over 17 years of experience in statutory compliance.
          He is an expert in Labour law compliance, back-office operations, and audit management, ensuring that organizations strictly adhere to all statutory requirements. 
          He holds a Bachelor of Arts degree from Indira Gandhi Open University and has a proven track record of effectively managing day-to-day business operations. 
          His skills in enhancing internal and external communication make him a driving force behind compliance excellence across the organization.`,
        },
      ];
      

  return (
    <div className="main-team">

<div className="main-carrer">
      <div className="back-image">
        <div className="Team-padding">
        <div className="OurTeam-maintext">Our Team Of Experts</div>
        <div className="OurTeam-text">Be part of our incredible team, to make a difference!</div>
        </div>
      
      </div>

      <div className='about-quote-Team'>
            <div className='about-open-comma-Team'>“</div>
            <div className='about-content-Team'>“Good teams incorporate teamwork into their culture, creating the building blocks for success.”  <br/>- Prasanna Kumar</div>
        </div>
<div className="Part-Team">
      {teamMembers.map((member, index) => (
        <div className="Team-Column" key={index}>
          <div className="Team-gap">
            <div
              className="main-image"
              style={{
                backgroundImage: `url(${member.image})`,
                backgroundSize: "cover",
                backgroundPosition: "32%",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="Team-gap-down">
              <div className="Team-Name">{member.name}</div>
              <div className="Team-Role">{member.role}</div>
            </div>
          </div>

          <div className="Team-Column-Brief">
            {/* <div className="icon-quotes-Team">“</div> */}
            <div className="Detail-team1">{member.description}</div>
          </div>
        </div>
      ))}
    </div>

    </div>

    </div>
  
  );
}

export default OurTeam;
