import React from 'react'
import '../css/FactoryCompliances.css'
import EstHandshake from '../Images/EstHandshake.png'
import EstOrder from '../Images/EstOrder.png'
import EstNotedown from '../Images/EstNotedown.png'
import EstScale from '../Images/EstScale.png'
import EstScale2 from '../Images/EstScale2.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Establishment1 from '../Images/Establishment1.jpg'
import Establishment2 from '../Images/Establishment2.jpg'
import Establishment3 from '../Images/Establishment3.jpg'
import Establishment4 from '../Images/Establishment4.jpg'
import Establishment5 from '../Images/Establishment5.jpg'

function FactoryCompliances() {
    const services=[{
        id:1,
        service:'Obtaining amending and renewing Registration Certificates'
    },
    {
        id:2,
        service:'Periodical preparation and submission of all statutory registers and returns as per statutory periodicity'
    },
    {
        id:3,
        service:'Remitting statutory Support'
    },
    {
        id:4,
        service:'Support for accident-Related compliance'
    },
    {
        id:5,
        service:'Providing compliance support for the Principal Employer within the defined scope'
    },
    {
        id:6,
        service:'Advising on the establishment of statutory committees'
    },
    {
        id:7,
        service:'Maintaining notice board compliances under applicable labour laws.Audit of Labour Law Compliance'
    },
    {
        id:8,
        service:'Labour laws compliances audits of vendors'
    },
    {
        id:9,
        service:'Support in drafting responses to government notices & observations'
    },
    {
        id:10,
        service:'Liaising with government authorities during or after inspections/ visits'
    }]
    const whyCompliance=[{
        id:1,
        image:Establishment1,
        descName:' Legal Compliance',
        desctitle1:' Regulations: ',
        desc1:' Factories must follow laws like the Factories Act, 1948, which ensure safe and fair working conditions.',
        desctitle2:' Avoiding Penalties: ',
        desc2:' Non-compliance can lead to fines, legal action, or shutdowns'
    },
    {
        id:2,
        image:Establishment2,
        descName:' Worker Safety and Health',
        desctitle1:' Accident Prevention: ',
        desc1:' Compliance helps reduce workplace accidents and injuries.',
        desctitle2:' Health Protection: ',
        desc2:' Ensures good ventilation, hygiene, and overall worker health' 
    },
    {
        id:3,
        image:Establishment3,
        descName:' Ethical and Social Responsibility',
        desctitle1:' Fair Treatment: ',
        desc1:"Ensures fair wages, reasonable hours, and humane working conditions.",
        desctitle2:' Reputation: ',
        desc2:'Socially responsible factories build trust and a good reputation' 
    },
    {
        id:4,
        image:Establishment4,
        descName:' Environmental Protection',
        desctitle1:' Sustainability:',
        desc1:" Adheres to environmental laws, reducing pollution and promoting sustainability.",
        desctitle2:' Community Well-being: ',
        desc2:' Protects local communities and ecosystems'
    },
    {
        id:5,
        image:Establishment5,
        descName:' Quality Assurance',
        desctitle1:'Product Quality: ',
        desc1:"Compliance ensures consistent and safe product standards.",
        desctitle2:'Market Access: ',
        desc2:"Meeting international standards can open up global markets.",
        desctitle3:' Brand Image: ',
        desc3:"Compliance protects the brand's reputation internationally"
    },
]
  return (
    <div>
      <div className='factory-main' >
      <div className='factory-head'>
        <div className='factory-head-maintext'>Factory Compliances</div>
        <div className='factory-head-text'>Adhering to factory compliances in India ensures safety, environmental responsibility, and operational integrity</div>
      </div>
      <div style={{display:'flex', flexDirection:'column', gap:'24px'}}>
        <div className='factory-whoneed-text'>Who Needs Factory Compliance Services?</div>
        <div className='factory-whoneeds'>
        <div className='factory-whoneed-data'>
            <div className='factory-whoneed-detail'>Factory compliance in India is crucial for the safety of workers, protecting the environment, and maintaining efficiency. Following regulations about occupational safety, environmental impact, and quality standards helps avoid legal issues and penalties, while also boosting the organization's reputation and trust. This responsible operation benefits the economy and society. Facility compliance means following the laws, standards, and ethical practices in factory operations. This includes ensuring worker safety, environmental sustainability, and product safety. The Factories Act of 1948 sets rules for safety and health in factories and docks. It covers work hours, sanitation, mealtimes, holidays, wages, and cleanliness. The goal is to create a safe and compliant work environment, which enhances credibility and provides a competitive edge. Factory inspectors can check workplaces anytime and impose fines for breaking labor laws. Operating without the necessary permits or licenses risks closure and heavy penalties. Keeping up with frequent legal changes can be challenging for business owners, but it is essential for legal operation in India.</div>
        </div>
        <div className='factory-whoneed-imgdiv'></div>
      </div>
      </div>
      <div className='factory-services' style={{display:'flex', flexDirection:'column',  gap:'24px'}}> 
            <div className='factory-service-text'>Our Services </div>
            <div className='factory-service-detail'>
                <div className='factory-service-data-row'>
                    {services.filter(service => service.id <= 5).map((service) => (
                    <div className='factory-service-data1' key={service.id}>
                        <div className='factory-service-number'>{service.id}</div>
                        <div className='factory-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                
                <div className='factory-service-data-row'>
                    {services.filter(service => service.id > 5).map((service) => (
                    <div className='factory-service-data2' key={service.id}>
                        <div className='factory-service-number'>{service.id}</div>
                        <div className='factory-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                </div>
       </div>
       <div className='factory-why'>
        <div className='factory-why-text'>Why do you need  Factory Compliance Services?</div>
        {whyCompliance?.map((compliance, id) => (
    <div key={id} className="factory-why-detail">
        <img src={compliance.image} className="factory-why-image" />
        <div className="factory-why-desc">
            <div className="factory-why-head">{compliance.descName}</div>
            <ul style={{ listStyleType: 'disc' , margin:'0px',paddingLeft:'20px'}}>
                <li style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: '700' }}>{compliance.desctitle1}</span>{compliance.desc1}
                </li>
                <li style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: '700' }}>{compliance.desctitle2}</span>{compliance.desc2}
                </li>
                {compliance.desc3?(
                    <li style={{ marginBottom: '8px' }}>
                    <span style={{ fontWeight: '700' }}>{compliance.desctitle3}</span>{compliance.desc3}
                </li>
                ):(<div></div>)}
                
            </ul>
        </div>
    </div>
))}

      </div>
      </div>
    </div>
  )
}

export default FactoryCompliances
