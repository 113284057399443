import React from 'react';
import loadingImage from '../Images/TalentTraverseLogo.png'; 

const Loading = () => {
  return (
    <div style={styles.loadingContainer}>
      <img src={loadingImage} alt="Loading..." style={styles.image} />
    </div>
  );
};

const styles = {
    loadingContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    //   backgroundColor: 'rgba(217, 214, 230, 0.34)', // Background with low opacity
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    //    backgroundColor: 'rgba(0, 0, 0, 0.3)',
       background:'transperent'
    },
    image: {
      width: '100px', 
      height: '100px',
      opacity: 0.5, 
    },
  };

export default Loading;
