import React,{useCallback, useContext, useEffect, useRef, useState} from 'react';
import '../css/AdminTax.css'
import AdminMenu from '../components/AdminMenu';
import { CaretDown, Check, FilePlus, Paperclip, PencilSimple, Trash, TrendUp, XCircle } from '@phosphor-icons/react';
import { Form, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { TestContext } from '../components/TestContext';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toast.css";
function AdminTax() {
    const [popOpen, setPopOpen]=useState(false);
    const [stateDropDown, setStateDropDown]=useState(false);
    const [StateName, setStateName]=useState('');
    const [fileName, setFileName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
  
    const [stateList,setStateList]=useState([])
    const [stateTitle,setStateTitle]=useState(false);
    const [stateId,setStateId]=useState();
    const [taxData,setTaxdata]=useState('')
    const [taxRefund,setTaxRefund]=useState([])
    const [loading,setLoading]=useState(false)
     const token=''
     const handlePop = () => {
     
      console.log("Open ");
      setPopOpen(true);
      window.scrollTo(0, 0);
    };
    useEffect(()=>{
      window.scrollTo(0,0);
    },[]) 
    const toggleStateDropdown=() => {
        setStateDropDown(!stateDropDown);
        console.log("True");
      };
    const handleStateClick=(name,id)=>{
      // getTax();
      // getRefund()
        setStateName(name);
        setStateDropDown(false);
        setStateTitle(true)
        setStateId(id)
        console.log(id);
        setTaxdata([])
            setTaxRefund([]);
            setIsEditing(false);
            setAct('');
            setApplicability( '');
            setFrequency( '');
            setRule( '');
            setInfoLinkContent( ''); 
            setInfoFileContent([]);
            // getTax(id);
            // getRefund(id)
            setInfoData([])
            setIsInfoEditing(false);
        
    }
    const {isLogin} = useContext(TestContext);
    const navigate = useNavigate();
    useEffect(()=>{    
        console.log(isLogin);    
        if(!isLogin){
            navigate('/AdminLogin')
            }   
         },[])
    const getRefund = async () => {
      if(stateId!==0 && stateId!==undefined){
      setLoading(true)
      
        try {
            const response = await fetch(`${holidayApiUrl}/api/GetProfessionalTax?StateId=${stateId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'application/json',
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                setLoading(false)
                console.log(data);
                setTaxdata(data);
    
            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }}
    };
    const fileInputRef = useRef(null);
    const getStateName=async()=>{
      setLoading(true)
         try{
            const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
                method:'GET',
                headers:{
                  Authorization:`Bearer${token}`,
                  "Content-Type":'application/json',
                }, 
              })
              console.log(response);
              if(response.ok){
                const data=await response.json();
                // console.log(data);
                setLoading(false)
                setStateList(data)
              }
            }
            catch{
        console.log("error");
            }
         }
         useEffect(()=>{
          getStateName();
         },[])
         const handleEditClick = () => {
            setIsEditing(!isEditing);
          };
          const handleLinkChange = (event) => {
            setLinkContent(event.target.value);
          };
          
   
    // ----------PUT METHOD-----------------
            // ---prof edit-----
            const handleWelfareChange = (index, field, value) => {
              console.log(`Updating ${field} for item ${index} with value: ${value}`);
              
              const newData = [...taxData];
          
              const newValue = (field === 'salary' || field === 'ptAmount')
                  ? (value === '' ? '' : isNaN(Number(value)) ? value : Number(value))
                  : value;
          
              newData[index] = { ...newData[index], [field]: newValue };
              setTaxdata(newData); 
          };
          
const today = new Date();
const formatNumber = (num) => String(num).padStart(2, '0');

const [profEdit, setProfEdit] = useState(false);
const [originalTaxData, setOriginalTaxData] = useState(taxData);
const [rows, setRows] = useState([]);
const [edit,setEdit]=useState(false)
const handleProfessionalEdit = useCallback(() => {
  setEdit(false);
  setOriginalTaxData(taxData);

  if (!profEdit) {
    // Adding a new row when pencil icon is clicked and edit mode is activated
    setRows(prevRows => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        salary: '',
        ptAmount: '',
        remark: '',
        createdDate: formattedDate,
      }
    ]);
  }
}, []);
// const handleCancelClick = () => {
//   setEditIndex(null); 
// };
const handleSaveClick = async (index) => {
  // Exit edit mode
  setEditIndex(null); 
  setEditIndexP(null);
  
  console.log(index);
  
  // Find the row with the matching id
  const updatedRow = taxData.find(row => row.id === index);
  
  console.log(updatedRow, "UpdatedRow");

  const originalRow = originalWelfareData[index];
  console.log(originalWelfareData, "Original Row");

  const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  // If there are changes in the row, proceed
  if (!deepEqual(updatedRow, originalRow)) {
    // If the row is new (no ID), create a new entry
    if (!updatedRow.id) {
      const newRowPayload = [{
        StateID: stateId, 
        UserID: `admin`, 
        Salary: updatedRow.salary || '',
        PTAmount: updatedRow.ptAmount || '',
        Remark: updatedRow.remark || '',
        CreatedDate: new Date().toISOString(), 
      }];
    
      await postProfNew(newRowPayload);
    } else {
      // If the row exists, update it
      console.log(updatedRow);
      
      await editProf(updatedRow, index);
    }
  } else {
    console.log("No changes detected.");
  }
};

const postProfNew = async (data) => {
  setLoading(true)
  try {
    const response = await fetch(`${holidayApiUrl}/api/AddProfessionalTax`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(data), 
    });
  
    if (response.ok) {
      setLoading(false)
      console.log(response);
      console.log("Success");
      toast.success("Data uploaded successfully!");
      getRefund();
      getTax();
     
    } else {
      console.error("Error:", response.statusText);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const yyyy = today.getFullYear();
const mm = formatNumber(today.getMonth() + 1); 
const dd = formatNumber(today.getDate());
const hh = formatNumber(today.getHours());
const min = formatNumber(today.getMinutes());
const ss = formatNumber(today.getSeconds());
const formattedDate = `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}`;

const editProf = async (updatedRow, index) => {
  setLoading(true)
  try {
    const response = await fetch(`${holidayApiUrl}/api/UpdateProfessionalTax`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "ID": updatedRow.id || index,  // Use the row ID or index
        "StateID": stateId,
        "UserID": "admin",
        "Salary": updatedRow.salary,
        "PTAmount": updatedRow.ptAmount,
        "Remark": updatedRow.remark,
        "CreatedDate": formattedDate
      })
    });
   
    if (response.ok) {
      console.log("Successfully updated the row.");
      
      setLoading(false)
      toast.success("Data uploaded successfully!");
      await getTax();
      await getRefund();
    } else {
      console.error("Error updating the row:", response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

useEffect(() => {
  if (profEdit) {
    console.log(profEdit,"ProfEdit",editIndex);
    
    handleSaveClick(editIndex);  // Pass the correct row index to save
  }
}, [profEdit]);

useEffect(() => {
  const fetchData = async () => {
    await getTax();
    await getRefund();
  };

  fetchData();
}, [stateId]);

    const [fileContent, setFileContent] = useState('');
  const [linkContent, setLinkContent] = useState("Content"); 

//   -----------POST -------------------

const [professionalRates, setProfessionalRates] = useState([
    { salary: '', ptAmount: '', remark: '' },
    { salary: '', ptAmount: '', remark: '' },
    { salary: '', ptAmount: '', remark: '' },
  ]);
  
  const handleProfessionalInputChange = (index, field, value) => {
    setProfessionalRates(prevRates => 
      prevRates.map((rate, i) => 
        i === index ? { ...rate, [field]: value } : rate
      )
    );
  };
  
  const postProf = async () => {
    setLoading(true)
    try {
      console.log("Entered");
  
      const professionalRatesPayload = professionalRates
        .filter(rate => rate.salary || rate.ptAmount || rate.remark) 
        .map((rate, index) => ({
          StateID: stateId, 
          UserID: `admin`, 
          Salary: rate.salary|| '',
          PTAmount: rate.ptAmount|| '',
          Remark: rate.remark || '',
          CreatedDate: new Date().toISOString(), 
        }));
  
      if (professionalRatesPayload.length === 0) {
        console.log("No valid data to send");
        return;
      }
  
      const payload = JSON.stringify(professionalRatesPayload);
    console.log(payload);
    
      const response = await fetch(`${holidayApiUrl}/api/AddProfessionalTax`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', 
        },
        body: payload, 
      });
    
      if (response.ok) {
        console.log(response);
        setLoading(false)
        setProfessionalRates([
          { salary: '', ptAmount: '', remark: '' },
          { salary: '', ptAmount: '', remark: '' },
          { salary: '', ptAmount: '', remark: '' },
        ])
       
        console.log("Success");
        toast.success("Data uploaded successfully!");
       
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
        //----POST FILING --------
  const [filingReturns, setFilingReturns] = useState([
    { task: '', lastDayfiling: '' },
    { task: '', lastDayfiling: ''},
    { task: '', lastDayfiling: '' },
  ]);
  
  const handleFilingInputChange = (index, field, value) => {
    setFilingReturns(prevFilingReturns => prevFilingReturns.map((item, id) =>
      id === index ? { ...item, [field]: value } : item
    ));
  };
  console.log(filingReturns);
  
const [fileAttach,setFileAttach]=useState(null)
  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const fullName = file.name;
      if (specialCharPattern.test(fullName)) {
        console.log("Error: File name should not contain & or $ characters.");
        toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
        return;
    }
      const fileNameWithExtension = file.name;
      console.log(fileNameWithExtension);
      
      handleFilingInputChange(index, 'FileNameWithExtension', fileNameWithExtension);
      setFileAttach(file)
    }
  };
  const postFund = async () => {
    setLoading(true)
    try {
      console.log("Entered");
  
      console.log(filingReturns);
      
      const filledFilingReturns = filingReturns.filter(fund =>
        fund.task.trim() || fund.lastDayfiling.trim() 
      );
  
      console.log(filledFilingReturns);
  
      for (const fund of filledFilingReturns) {
        console.log(fund, "FUND");
  
        const formData = new FormData();
        const timestamp = new Date().getTime().toString().slice(-4);
  
        let uniqueFileName = `${timestamp}`;
        if (fund?.FileNameWithExtension) {
          const fileNameWithoutExtension = fund.FileNameWithExtension.split('.').slice(0, -1).join('.');
          const fileExtension = fund.FileNameWithExtension.split('.').pop();
          uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
          formData.append('Attachment', fileAttach);
          formData.append('Title', fund.FileNameWithExtension); 
          formData.append('FileNameWithExtension', uniqueFileName);
        }
        if(!fund.FileNameWithExtension){
      
          const timestampSuffix = timestamp.toString().slice(-4); 
            
                
                const placeholderBlob = new Blob(['Placeholder content'], { type: 'text/plain' }); 
                const placeholderFile = new File([placeholderBlob], 'placeholder.txt', { type: 'text/plain' });
                console.log(placeholderFile,"This is placeholder file");
                
                const fileExtension = placeholderFile.name.split('.').pop();
                const baseName = placeholderFile.name.substring(0, placeholderFile.name.lastIndexOf('.')); 
                  const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
                formData.append('Title', `${baseName}.${fileExtension}`); 
                formData.append('Attachment', placeholderFile);
                formData.append('FileNameWithExtension', newFileName);
        }
        
        formData.append('Task', fund.task || '');
        formData.append('LastDayfiling', fund.lastDay || '');
        formData.append('Frequency', '');
        formData.append('Applicability', ''); 
        formData.append('Rule', ''); 
        formData.append('Act', '');

        formData.append('state', stateId);
        formData.append('IsImportant', false);
        formData.append('AdditionalLink', '');
        formData.append('RequestedBy', 'admin');
        formData.append('Folder', 'ProfessionalTax');
        formData.append('Type', 4);

      
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        formData.append('EffectiveDate', '0001-01-01T00:00:00');

        console.log(uniqueFileName);
        
        const response = await fetch(`${notificationApiUrl}/api/TaxAndFundFile`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
  
        if (response.ok) {
            
           setLoading(false)
           setFilingReturns( [{ task: '', lastDayfiling: '' },
            { task: '', lastDayfiling: ''},
            { task: '', lastDayfiling: '' }])
          console.log("Success for entry", fund);
          toast.success("Data uploaded successfully!");
        } else {
          console.error("Error for entry", fund, ":", response.statusText);
        }
      }
  
      // Optionally, handle state updates or UI changes after all requests are done
      setPopOpen(!popOpen);
      getTax();
      getRefund();
      setPreviousFileDetails([])
  
    } catch (error) {
      console.log("Error", error);
    }
  };
  
  const handleSubmit = () => {
    postProf();
    setTimeout(() => {
      postFund();
    }, 3000); 
    
  };


// -----------------------------INFORMATION-----------------------------------------//
const [act, setAct] = useState('');
const [frequency, setFrequency] = useState('');
const [applicability, setApplicability] = useState('');
const [rule, setRule] = useState('');
const [infoLinkContent, setInfoLinkContent] = useState('');
const [infoData, setInfoData] = useState([]); 
const [filteredData,setFilteredData]=useState(null)
const [loadingtax,setLoadingTax]=useState(false)
const getTax = async () => {
  
  setLoading(true)
  
  if(stateId!==0 && stateId!==undefined){
    // setLoadingTax(true)
    try {
        const response = await fetch(`${holidayApiUrl}/api/GetTaxAndFundFile?StateId=${stateId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();

              if (data.length > 0 && stateId !== 0 && stateId !== 'undefined') {
                const filtered = data.filter(item => item.folder === 'ProfessionalTax' && item.act !== '');
                setFilteredData(filtered); 
                if (filtered.length > 0) {
                  console.log(filtered.length-1);
                  
                    setAct(filtered[filtered.length-1].act || '');
                    setApplicability(filtered[filtered.length-1]?.applicability || '');
                    setFrequency(filtered[filtered.length-1]?.frequency || '');
                    setRule(filtered[filtered.length-1]?.rule || '');
                    setInfoLinkContent(filtered[filtered.length-1]?.link || '');
                    
                }
                const filedata=filtered.filter(item=>  item.fileName.split('.').pop()!=='txt')
                if (filedata.length > 0) {
                  // Store both id and fileName
                  const filesWithId = filedata.map(item => ({
                      id: item.id,          // Assuming `id` exists in each item
                      fileName: item.fileName
                  }));
                  setInfoFileContent(filesWithId); // Store both id and fileName
              }
                
                setLoading(false);
                // setLoadingTax(false);
              }
              const taskData = data.filter(item => item.task !== '' && item.task !== null);
          
              if (taskData.length > 0 && stateId !== 0 && stateId !== 'undefined') {
                  console.log(taskData, "This is data");
                  setInfoData(taskData);
              }

              else{
                setInfoData([])
              }
        }
    } catch (error) {
        console.log("Error fetching tax data", error);
    }}
};

const handleInfoEditClick = () => {
    setIsInfoEditing(!isInfoEditing);
};

const [uploadedFiles, setUploadedFiles] = useState([]);
const [infoFileContent, setInfoFileContent] = useState([]);
const [isInfoEditing, setIsInfoEditing] = useState(false);

const handleFileUpload = (event) => {
  const files = Array.from(event.target.files);
  console.log(files);
  const specialCharPattern = /[&$₹/^+*%!@#]/;
  if (specialCharPattern.test(files[0]?.name)) {
      console.log("Error: File name should not contain & or $ characters.");
      toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
      return;
  }
  setUploadedFiles([]); 
  setTimeout(() => {
      if (files.length) {
          setUploadedFiles(prevFiles => {
              const updatedFiles = [...files]; 
              setInfoFileContent(updatedFiles.map(file => file.name));
              return updatedFiles;
          });
      }
  }, 0);
};
const handleSave = async () => { 
  console.log("hey");
  
  const timestamp = Date.now();
  let formData = new FormData();
  formData.append('Frequency', frequency);
  formData.append('Applicability', applicability);
  formData.append('Rule', rule);
  formData.append('Act', act);
  formData.append('state', stateId);
  formData.append('IsImportant', false);
  formData.append('AdditionalLink', infoLinkContent); 
  formData.append('RequestedBy', "admin");
  formData.append('Folder', 'ProfessionalTax');
  formData.append('Task', '');
  formData.append('LastDayfiling', '');
  formData.append('Type', 4);
  formData.append('EffectiveDate', '0001-01-01T00:00:00');
  formData.append('ReleasedDate', '0001-01-01T00:00:00');
  
  let attachmentFile = null;
  let newFileName = '';
  
  if (uploadedFiles && uploadedFiles.length > 0) {
    // Handle new file upload
    console.log(uploadedFiles,"Entered into if condition");
    
    const uploaded = uploadedFiles[0].name;
    const fileExtension = uploaded.split('.').pop();
    const baseName = uploaded.substring(0, uploaded.lastIndexOf('.'));
    const timestampSuffix = timestamp.toString().slice(-4);
    newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
    attachmentFile = uploadedFiles[0];
    
    console.log('File Extension:', fileExtension);
    console.log('Base Name:', baseName);
    console.log('New File Name:', newFileName);
    // formData.append('Title', baseName); 
    // formData.append('Attachment', uploadedFiles[0]); 
    // formData.append('FileNameWithExtension', newFileName);
  } else {
    // Handle no new file scenario\
    console.log("Entered into else condition");
    
    const timestampSuffix = timestamp.toString().slice(-4); 
        //   const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
          
          const placeholderBlob = new Blob(['Placeholder content'], { type: 'text/plain' }); 
          const placeholderFile = new File([placeholderBlob], 'placeholder.txt', { type: 'text/plain' });
          console.log(placeholderFile,"This is placeholder file");
          
          const fileExtension = placeholderFile.name.split('.').pop();
          const baseName = placeholderFile.name.substring(0, placeholderFile.name.lastIndexOf('.')); 
            const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
          formData.append('Title', baseName); 
          formData.append('Attachment', placeholderFile);
          formData.append('FileNameWithExtension', newFileName);
  }
  
  if (attachmentFile) {
    formData.append('Attachment', attachmentFile);
    formData.append('FileNameWithExtension', newFileName);
  }

  try {
    setLoading(true);
    const response = await fetch(`${notificationApiUrl}/api/TaxAndFundFile`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.ok) {
      const data = await response.json();
      console.log("Success:", data);
     
      setLoading(false);
      toast.success("Data uploaded successfully!");
      getTax();
      setIsInfoEditing(false);
      setUploadedFiles([])
    } else {
      console.log("Error:", response.statusText);
    }
  } catch (error) {
    console.log("Error:", error);
  }
};






// ------------------------------------------------------------------
const [filingEditReturns, setFilingEditReturns] = useState([
    { Task: '', LastDayfiling: '', FileNameWithExtension: '' },
]);
const [editIndex, setEditIndex] = useState(null);

const handleFilingEditInputChange = (index, field, value) => {
    setInfoData(prevInfoData => 
        prevInfoData.map((item, id) =>
            id === index ? { ...item, [field]: value } : item
        )
    );
    console.log((prevFilingEditReturns => 
      prevFilingEditReturns.map((item, id) =>
          id === index ? { ...item, [field]: value } : item
      )));
    
    setFilingEditReturns(prevFilingEditReturns => 
        prevFilingEditReturns.map((item, id) =>
            id === index ? { ...item, [field]: value } : item
        )
    );
};

const [fileNewName,setFileNewName]=useState(null)
const [newfile,setNewFile]=useState(false)
const specialCharPattern = /[&$₹/^+*%!@#]/;

const handleEditFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      
    const fullName = file.name;
      if (specialCharPattern.test(fullName)) {
        console.log("Error: File name should not contain & or $ characters.");
        toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
        return;
    }
      const fileNameWithExtension = file.name;
      handleFilingEditInputChange(index, 'fileName', fileNameWithExtension);
      setFileAttach(file)
      setFileNewName(fileNameWithExtension)
      setNewFile(true)
    }
    else{
      console.log("ENTERED THIES ELSEEEE");
      
      setFileNewName('')
    }
  };
  const [previousFileDetails, setPreviousFileDetails] = useState({});

const handleEditFileClick = (index,fileName) => {
  console.log(fileName,"FILE NAME HEEE");
  // const fileNames=fileName.split('_').slice(0, -1).join('_') + '.' + fileName.split('_').slice(-1)[0].split('.').pop()
  // console.log(fileNames);
  
  setPreviousFileDetails( fileName.title );
    setEditIndex(index);
};
console.log(previousFileDetails);

const [editIndexP,setEditIndexP]=useState(null)
const handleEditFileClickP = (index) => {
  setEditIndexP(index);
}

const handleCancelClick = () => {
    setEditIndex(null); 
    setEditIndexP(null)
};
const timestamp = new Date().getTime().toString().slice(-4);
 const [uniqueFileName,setUniqueFileName]=useState('')
 
 const createFormData = (index, file) => {
  const formData = new FormData();
  const item = infoData[index];
  const timestamp = new Date().getTime().toString().slice(-4);
  
  // Generate unique file name
 
  const uniqueId = uuidv4();
  console.log(item?.fileName,"New name");
  
  const fileName = item?.title || 'defaultFileName';
  const firstFileName = fileName.split(',')[0].trim();
  let finalFileNameWithExtension;
  if (newfile) {
    finalFileNameWithExtension = uniqueFileName;
  } else if (file.id && previousFileDetails) {
    finalFileNameWithExtension = file.title;
  } else {
    finalFileNameWithExtension = item.title;
  }
  // Determine the file to append
  if(newfile  ){
    const fileNameWithoutExtension = fileNewName.split('.').slice(0, -1).join('.');
    const fileExtension = fileNewName.split('.').pop();
    const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
  
    formData.append('Attachment', fileAttach);
    console.log("HERE COMES THE IF!!");
    if(fileNewName!==''){
      formData.append('Title', fileNewName);
      formData.append('FileNameWithExtension', uniqueFileName);
    }
    else{
      formData.append('Title', previousFileDetails);
    }
    
    
  }
 else if (file.id && previousFileDetails) {
  const data = file;
  console.log(previousFileDetails,"Emtered Efle sid");
  const fileNameWithoutExtension = data?.fileName.split('.').slice(0, -1).join('.');
  const fileExtension = data?.fileName.split('.').pop();
  const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;

    
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    formData.append('Attachment', blob, data.fileName);
    formData.append('Title', previousFileDetails);
    formData.append('FileNameWithExtension', uniqueFileName);
 }
  else {
    console.log(fileAttach,file,"ELSE ATTACHMENT");
    const timestampSuffix = timestamp.toString().slice(-4); 
            
                
    const placeholderBlob = new Blob(['Placeholder content'], { type: 'text/plain' }); 
    const placeholderFile = new File([placeholderBlob], 'placeholder.txt', { type: 'text/plain' });
    console.log(placeholderFile,"This is placeholder file");
    
    const fileExtension = placeholderFile.name.split('.').pop();
    const baseName = placeholderFile.name.substring(0, placeholderFile.name.lastIndexOf('.')); 
      const newFileName = `${baseName}_${timestampSuffix}.${fileExtension}`;
    formData.append('Title', `${baseName}.${fileExtension}`); 
    formData.append('Attachment', placeholderFile);
    formData.append('FileNameWithExtension', newFileName);
    // formData.append('Title', previousFileDetails||item.title);
    // formData.append('Attachment', fileAttach); 
  }
console.log(previousFileDetails,"THis is file name ");

  // Append additional fields
  formData.append('Frequency', '');
  formData.append('Applicability', '');
  formData.append('Rule', '');
  formData.append('Act', '');
  // formData.append('Title', previousFileDetails||fileNewName);
  formData.append('state', stateId);
  formData.append('IsImportant', true);
  formData.append('AdditionalLink', 'gh.com');
  formData.append('RequestedBy', 'admin');
  formData.append('Folder', 'ProfessionalTax');
  formData.append('Task', item?.task || '');

  formData.append('LastDayfiling', item?.lastDayfiling || '');
  formData.append('Type', 4);
  formData.append('ReleasedDate', '0001-01-01T00:00:00');
  formData.append('EffectiveDate', '0001-01-01T00:00:00');

  return formData;
};


const handleFileSaveClick = async (index,item) => {
  
  try {
      setLoading(true);
      
      const formData = createFormData(index,item);
      
      // Check if a new file was uploaded
      // if (!formData.has('file')) {
      //     // Use the previously stored file if no new file was uploaded
      //     const prevFile = previousFileDetails;
      //     if (prevFile) {
      //         formData.append('file', prevFile);
      //     }
      // }

      console.log("FormData to be sent:", formData);
      const response = await fetch(`${notificationApiUrl}/api/TaxAndFundFile`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData,
      });

      if (response.ok) {
          
          setFilingEditReturns([]);
          setEditIndex(null);
          setIsEditing(false);
          console.log("Success");
          handleFileDeleteClick(index);
          setInfoData([]);
          setPreviousFileDetails([])
          setLoadingTax(true)
          setFileNewName('')
          setNewFile(false)
          setTimeout(async () => {
            try {
                await getTax(); // Fetch tax data
                setLoadingTax(false)
                setLoading(false)
            } catch (error) {
                console.error("Error fetching tax data:", error);
            } finally {
                setLoadingTax(false); // Set loading to false after fetching tax data
            }
        }, 3000);
        toast.success("Data uploaded successfully!");
      } else {
          console.error("Error:", response.statusText);
      }
  } catch (error) {
      console.error("Error:", error);
  }
};


const handleFileDeleteClick = async (index) => {
  console.log(index);
  setLoading(true);
  if (!infoData[index].id) {
    console.log();
    
    // Remove the new row from the local state (frontend-only deletion)
    setInfoData(prevWelfare => prevWelfare.filter((_, i) => i !== index));
    setLoading(false); // Stop loading once it's removed
    return;
}
  try {
    const response = await fetch(`${holidayApiUrl}/api/deleteTaxAndFund?id=${infoData[index].id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setUploadedFiles([])
      console.log("Item deleted successfully.");
      // toast.success("File deleted successfully!");
      // toast.success("File deleted successfully!");
      // Directly update the infoData state
      setInfoData(prev => prev.filter((_, i) => i !== index));
      setFilingEditReturns(prev => prev.filter((_, i) => i !== index));
      setPreviousFileDetails(null)
      // Optionally refetch the tax data
      // getRefund();
      // getTax();
    } else {
      console.error("Failed to delete item:", response.statusText);
    }
  } catch (error) {
    console.error("Error deleting item:", error);
  } finally {
    setLoading(false);
  }
};


const handleKeyDown = (e, index) => {
    if (e.key === 'Enter' && index === filingEditReturns.length - 1) {
        e.preventDefault(); 
        addNewRow();
    }
};

const addNewRow = () => {
    setFilingEditReturns([...filingEditReturns, { Task: '', LastDayfiling: '', FileNameWithExtension: '' }]);
    setEditIndex(filingEditReturns.length);
};
const [newEntry, setNewEntry] = useState({ task: '', lastDayfiling: '', fileName: '' });
const [newEntryP,setNewEntryP]=useState({salary:'',ptAmount:'',remark:''})
// const handleAddNewRow = () => {
//   setInfoData([...infoData, newEntry]);
//   setNewEntry({ task: '', lastDayfiling: '', fileName: '' });
//   setIsEditing(false);
// };
const handleAddNewRow = () => {
  setInfoData([...infoData, newEntry]);
  setNewEntry({ task: '', lastDayfiling: '', fileName: '' });
  setIsEditing(false);
  setEditIndex(infoData.length);
};
const handleAddNewRowP = () => {
  setTaxdata([...taxData, newEntryP]);
  setNewEntryP({ salary: '', ptAmount: '', remark: '' });
  setEditIndexP(taxData.length);
};
const handleFileDownload = async (file) => {
  console.log(file.fileName);
  
  setLoading(true)
  try {
    const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'ProfessionalTax'}&FileName=${file.fileName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'text/plain'
      }
    });

    if (response.ok) {
      const textResponse = await response.text();
      const fileUrl = textResponse.replace("Url=", "");

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false)
    } else {
      console.error("Failed to fetch the file URL:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

const handleWebsiteClick = (url) => {
  const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
  window.open(formattedUrl, '_blank');
};
const [welfareEdit, setwelfareEdit] = useState(true);
    const [originalWelfareData, setOriginalWelfareData] = useState(taxData);
    
    const handleWelfareEdit = () => {
        // setwelfareEdit(true);
        handleAddNewRowP();
      setOriginalWelfareData(taxData);
    };
    const addProfessionalRate = () => {
      setProfessionalRates([...professionalRates, { salary: '', ptAmount: '', remark: '' }]);
    };
    
    const addFilingReturn = () => {
      setFilingReturns([...filingReturns, { task: '', lastDay: '', form: '' }]);
    };
    
    const handleInfoFileDelete=async(index)=>{
      console.log(index,"This is index of deleete");
      setLoading(true)
      try {
          const response = await fetch(`${holidayApiUrl}/api/deleteTaxAndFund?id=${index}`, {
              method: 'DELETE',
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          });
  
          if (response.ok) {
              // setFilingEditReturns(prev => prev.filter((_, i) => i !== index));
              setLoading(false)
              setUploadedFiles([])
              console.log("Item deleted successfully.");
              toast.success("File deleted successfully!");
            
             await getRefund() ;
             await getTax();
              
          } else {
              console.error("Failed to delete item:", response.statusText);
          }
      } catch (error) {
          console.error("Error deleting item:", error);
      }
  }

  const  handleDeleteClickP=async(index)=>{
    console.log(index,"This is index of deleete");
      setLoading(true)
      try {
        const response = await fetch(`${holidayApiUrl}/api/deleteProfectionalTax?id=${index}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            // setFilingEditReturns(prev => prev.filter((_, i) => i !== index));
            setLoading(false)
            
            console.log("Item deleted successfully.");
            toast.success("File deleted successfully!");
           await getRefund() ;
           await getTax();
            
        } else {
            console.error("Failed to delete item:", response.statusText);
        }
    } catch (error) {
        console.error("Error deleting item:", error);
    }
  }
  return (
    <div className="AdminTaxes">
            {/* <AdminMenu/> */}
            <ToastContainer />
            <div style={{width: `calc(100% - 200px)`,minHeight:'1000px'}}>
            <div className="Admin-Tax">
            <div className="Tax-detail">
                <div className="Tax-detail1-name">Professional Tax Uploads</div>
                <div className="Tax-search" onClick={toggleStateDropdown}>
                    <span className="Tax-state-Name">{StateName===''?'Select State':StateName}</span>
                    <CaretDown size={20}/>
                </div>
                {stateDropDown && (
                    <div className="dropdown-state-tax">
                        {stateList.filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state)).map((option, index) => (
                            <div className="dropdown-item-state-tax" key={index} onClick={()=>{handleStateClick(option.state, option.id)}}>
                                {option.state}
                            </div>
                        ))}
                    </div>
                )}
                
                
                {popOpen && <div className="Tax-overlay"></div>}
                {popOpen && (
  <div className="Tax-popup">
    <div className="Tax-pop-Uplaod">
      <span>Upload</span>
      <XCircle size={20} onClick={() => setPopOpen(false)} />
    </div>
    <div className='Tax-pop-detail'>
      <div className='Tax-detail-head'>Professional Rates</div>
      <div className='Tax-data'>
    <table className='Tax-table'>
        <thead>
            <tr>
                <th>Salary</th>
                <th>PT Amount</th>
                <th>Remarks</th>
            </tr>
        </thead>
        <tbody>
            {professionalRates.map((entry, index) => (
                <tr key={index}>
                    <td>
                        <input
                            className='tax-entry'
                            value={entry.salary}
                            onChange={(e) => handleProfessionalInputChange(index, 'salary', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='tax-entry'
                            value={entry.ptAmount}
                            onChange={(e) => handleProfessionalInputChange(index, 'ptAmount', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='tax-entry'
                            value={entry.remark}
                            onChange={(e) => handleProfessionalInputChange(index, 'remark', e.target.value)}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
    <div className='AddButton'>
    <div onClick={addProfessionalRate} className='add-row'>
        Add Row
    </div>
    </div>
</div>

    </div>
    <div className='Tax-pop-detail'>
    <div className='Tax-detail-head'>Filing Of Returns</div>
    <table className='Tax-table'>
        <thead>
            <tr>
                <th>Task</th>
                <th>Last Day of Filing Returns</th>
                <th>Form</th>
            </tr>
        </thead>
        <tbody>
            {filingReturns.map((entry, index) => (
                <tr key={index}>
                    <td>
                        <input
                            className='tax-entry'
                            value={entry.task}
                            onChange={(e) => handleFilingInputChange(index, 'task', e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            className='tax-entry'
                            value={entry.lastDay}
                            onChange={(e) => handleFilingInputChange(index, 'lastDay', e.target.value)}
                        />
                    </td>
                    <td>
                        <div className="custom-file-area" onClick={() => document.getElementById(`file-input-${index}`).click()}>
                            <input
                                className='tax-entry1'
                                type='file'
                                id={`file-input-${index}`}
                                onChange={(e) => handleFileChange(index, e)}
                            />
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
    <div className='AddButton'>
    <div onClick={addFilingReturn} className='add-row'>
        Add Row
    </div>
    </div>
</div>

    <div className="Tax-pop-mark">
      <div className="Tax-pop-imp" onClick={() => setPopOpen(false)}>Cancel</div>
      <div className="Tax-pop-upload" onClick={handleSubmit}>Save and Upload</div>    
    </div>
  </div>
)}

            
            </div>
            {StateName!=='' &&(
                <div>
                    <div className="Tax-detail2">
                        <div className="Tax-detail1-name">{StateName}</div>
                    </div>
                    <div className="Tax-state">
    {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

    <div className='TaxStateDetail'>
        <div className='TaxStateDetailHead'>
            Information
            {isInfoEditing ? (
                <Check size={32} color='#898989' onClick={handleSave} style={{cursor:'pointer'}}  />
            ) : (
                <PencilSimple size={32} color='#898989' onClick={handleInfoEditClick} style={{cursor:'pointer'}} />
            )}
        </div>

        {/* Act Field */}
        <div className='TaxStateDetailSub'>
            <div className='TaxStateDetailName'>Act</div>
            <div className='TaxDetailSubCont'>
                {isInfoEditing ? (
                    <input
                        type='text'
                        value={act}
                        onChange={(e) => setAct(e.target.value)}
                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px"}}
                    />
                ) : (
                    act
                )}
            </div>
        </div>

        {/* Rule Field */}
        <div className='TaxStateDetailSub'>
            <div className='TaxStateDetailName'>Rule</div>
            <div className='TaxDetailSubCont'>
                {isInfoEditing ? (
                    <input
                        type='text'
                        value={rule}
                        onChange={(e) => setRule(e.target.value)}
                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px"}}
                    />
                ) : (
                    rule
                )}
            </div>
        </div>

        {/* Applicability Field */}
        <div className='TaxStateDetailSub'>
            <div className='TaxStateDetailName'>Applicability</div>
            <div className='TaxDetailSubCont'>
                {isInfoEditing ? (
                    <input
                        type='text'
                        value={applicability}
                        onChange={(e) => setApplicability(e.target.value)}
                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px"}}
                    />
                ) : (
                    applicability
                )}
            </div>
        </div>

        {/* Frequency Field */}
        <div className='TaxStateDetailSub'>
            <div className='TaxStateDetailName'>Frequency</div>
            <div className='TaxDetailSubCont'>
                {isInfoEditing ? (
                    <input
                        type='text'
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px"}}
                    />
                ) : (
                    frequency
                )}
            </div>
        </div>

        {/* File Section */}
        <div className='TaxStateDetailSub'>
    <div className='TaxStateDetailName'>Form</div>
    <div className='TaxDetailSubCont'>
        {infoFileContent.map((fileName, index) => (
            <div key={index} className='TaxInfoFile'>
                <Paperclip size={18} color='black' />
                {fileName.id ?(
            <div onClick={() => handleFileDownload(fileName)} className='TaxFilename'>
          {fileName.fileName.split('_').slice(0, -1).join('_') + '.' + fileName.fileName.split('_').slice(-1)[0].split('.').pop()}
        </div>
        ):( <div onClick={() => handleFileDownload(fileName)} className='TaxFilename'>
           {/* {fileName.fileName.split('_').slice(0, -1).join('_') + '.' + fileName.fileName.split('_').slice(-1)[0].split('.').pop()} */}
        {fileName.split('.').slice(0, -1).join('.') + '.' + fileName.split('.').pop()}
      </div>)}
                {isInfoEditing && index !== infoFileContent.length - 1 && fileName.id && (
         
          <Trash
          size={16}
          color="#898989"
          onClick={() => handleInfoFileDelete(fileName.id)}
          style={{cursor:"pointer"}}
        />
        )}
            </div>
            
        ))}
        {isInfoEditing && (
            <input
                className='TaxDetailSubCont'
                id='file-upload-input'
                type='file'
                onChange={handleFileUpload}
                style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #ccc',
                    outline: 'none',
                    fontFamily: 'Ariel,sans-serif, sanserif',
                    fontSize: "16px",
                    width:'100%'
                }}
            />
        )}
    </div>
</div>

        {/* Website Section */}
        <div className='TaxStateDetailSub'>
            <div className='TaxStateDetailName'>Website</div>
            <div className='TaxDetailSubCont'>
              
           
                    <div style={{display:'flex'}}>
                    <Paperclip size={18} color='green'/>
                    {isInfoEditing ? (
                    <input
                    className='TaxDetailSubCont'
                        // key={index}
                        type='text'
                        value={infoLinkContent}
                        onChange={(e) => setInfoLinkContent(e.target.value)}
                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none' ,fontFamily:'Ariel,sans-serif, sanserif', fontSize:"16px",width:'100%'}}
                    />):(<div onClick={() => handleWebsiteClick(infoLinkContent)}>{infoLinkContent}</div>)}
                    </div>
                
               
               
            </div>
        </div>
    </div>


{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
{taxData.length===0 && infoData.length==0?(
                        <div className='TaxFileUpload'>
                        <div className="Tax-State-Record">No Record  </div>
                        <div className="Tax-detail1-upload" onClick={handlePop}><FilePlus size={24} color="#009746" /> Upload File</div>
                    </div>
                    ):(
                    <div className='Tax-table-all'>
                      {/*  */}
                    <div className='Tax-pop-detail'>
                    <div className='Tax-detail-head'>Professional Rates {!profEdit ? (
                   
                    // <PencilSimple size={32} color='#898989' />
                    <div  onClick={handleWelfareEdit} className='AddNewRowTax'>Add New Row</div>
                    ) : (
                        <Check size={32} color='#898989' onClick={handleSaveClick} />
                    )} </div>
                     <div className='Tax-data'>
            <table className='Tax-table'>
                <thead>
                    <tr>
                        <th className='Tax-detail-name'>Salary</th>
                        <th className='Tax-detail-name'>PT Amount</th>
                        <th className='Tax-detail-name'>Remarks</th>
                        <th className='Tax-detail-name'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {taxData?.map((welfare, id) => (
                        <tr key={id}>
                            <td>
                                {editIndexP === id ? (
                                    <input
                                        type='text'
                                        className='EditInput'
                                        value={welfare.salary}
                                        onChange={(e) => handleWelfareChange(id, 'salary', e.target.value)}
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{welfare.salary}</span>
                                )}
                            </td>
                            <td>
                                {editIndexP === id ? (
                                    <input
                                        type='text'
                                        className='EditInput'
                                        value={welfare.ptAmount}
                                        onChange={(e) => handleWelfareChange(id, 'ptAmount', e.target.value)}
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{welfare.ptAmount}</span>
                                )}
                            </td>
                            <td>
                                {editIndexP === id ? (
                                    <input
                                        type='text'
                                        className='EditInput'
                                        value={welfare.remark}
                                        onChange={(e) => handleWelfareChange(id, 'remark', e.target.value)}
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{welfare.remark}</span>
                                )}
                            </td>
                            <td>
                                {editIndexP === id ? (
                                    <div style={{display:'flex',gap:'5px'}}>
                                        <button onClick={() => handleSaveClick(welfare.id)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Save</button>
                                        <button onClick={handleCancelClick} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Cancel</button>
                                    </div>
                                ) : (
                                    <div style={{display:'flex',gap:'5px'}}>
                                        <button onClick={() => handleEditFileClickP(id)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Edit</button>
                                        <button onClick={() => handleDeleteClickP(welfare.id)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Delete</button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

                </div>
{/* --------------------------------------------------------------------------------------------------------------------- */}
<div className='Tax-pop-detail'>
      <div className='Tax-detail-head'>
        Last day of Filing
        {!isEditing ? (
          <div onClick={() => { handleAddNewRow() }} className='AddNewRowTax'>Add New Row</div>
        ) : (
          <Check
            size={32} color='#898989'
            onClick={() => setIsEditing(true)} 
          />
        )}

      </div>
      <div className='Tax-data'>
            <table className='Tax-table'>
                <thead>
                    <tr>
                        <th>Task</th>
                        <th>Last Day of Filing Returns</th>
                        <th>Form</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {infoData.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type='text'
                                        value={item.task}
                                        onChange={(e) => handleFilingEditInputChange(index, 'task', e.target.value)}
                                        className='EditInput'
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{item.task}</span>
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type='text'
                                        value={item.lastDayfiling}
                                        onChange={(e) => handleFilingEditInputChange(index, 'lastDayfiling', e.target.value)}
                                        className='EditInput'
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{item.lastDayfiling}</span>
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type='file'
                                        onChange={(e) => handleEditFileChange(index, e)}
                                        className='EditInput'
                                        style={{ backgroundColor: 'transparent', border: '1px solid #ccc', outline: 'none', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
                                    />
                                ) : (
                                    <span>{item?.title!=='placeholder.txt'?(item?.title):('') || previousFileDetails?.title}</span>
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <div style={{display:'flex',gap:'5px'}}>
                                        <button onClick={() => handleFileSaveClick(index, item)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Save</button>
                                        <button onClick={handleCancelClick} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Cancel</button>
                                    </div>
                                ) : (
                                    <div style={{display:'flex',gap:'5px'}}>
                                        <button onClick={() => handleEditFileClick(index, item)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Edit</button>
                                        <button onClick={() => handleFileDeleteClick(index)} style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',cursor:'pointer' }}>Delete</button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
          
        </div>

        
    </div>
{/* ----------------------------------------------------------------------------------------------------------------------- */}

                </div>
                    )}
                    
                    {loadingtax && (
                <div style={{ zIndex: '9999', width: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress style={{ color: 'rgba(64, 49, 131, 1)' }} />
                </div>
            )}

                    </div>
                   
                </div>
            )}
            
            </div>
            </div>
            {loading && <div style={{zIndex:'9999' ,position:'absolute',top:'50%', left:'50%'}}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
        </div>
  )
}

export default AdminTax