import React from 'react'
import '../css/ClientDash1.css'
import ClientMenu from '../components/ClientMenu'
import Home1 from '../Images/Home1.png'
import Home2 from '../Images/Home2.png'
function ClientDash1() {
  return (
    <div style={{ display: 'flex', maxWidth: '1280px',width:'1100vw', justifyContent:'center' }}>
    <div className='ClientHome'>
        <ClientMenu/>
        <div className='ClientHomeComp'>
            <div className='ClientWelcome'>
                <div className='ClientName'>Hello Ankit!</div>
                <div className='ClientGreet'>Good Morning</div>
            </div>
            <div className='ClientHome'>Home</div>
            <div className='ClientHomeImg' >
                <div style={{width:'100%'}}><img src={Home1} style={{width:'inherit'}}/></div>
                <div style={{width:'100%'}}><img src={Home2} style={{width:'inherit'}}/></div>
            </div>
        </div> 
    </div>
    </div>
  )
}

export default ClientDash1