import React from 'react';
import '../css/AuditAdvisory.css'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function AuditAdvisory() {
    const services=[{
        id:1,
        service:'Regulatory Risk Assessment',
        servicedata:' Identifies and evaluates regulatory risks based on industry, location, size, and operations to prioritize compliance efforts and resource allocation.'
    },
    {
        id:2,
        service:'Gap Analysis',
        servicedata:' Evaluates current policies and procedures against regulations and best practices to identify non compliance areas and develop corrective actions'
    },
    {
        id:3,
        service:'Internal Control Testing',
        servicedata:' Assesses the effectiveness of internal controls to ensure on-going compliance and mitigate regulatory risks'
    },
    {
        id:4,
        service:'Policy and Procedure Review',
        servicedata:' Reviews and updates policies and procedures to align with regulatory requirements and industry standards'
    },
    {
        id:5,
        service:'Industry-Specific Expertise',
        servicedata:' Provides specialized knowledge in industry-specific regulations to offer tailored guidance and recommendations'
    },
    {
        id:6,
        service:'Compliance Training and Awareness',
        servicedata:' Provides tailored training to educate employees on regulations, best practices, and compliance policies to foster a culture of compliance.'
    },
    {
        id:7,
        service:'Remediation and Corrective Action Plans',
        servicedata:'Develops plans to address non-compliance issues, outlining specific steps and timelines for resolution.'
    },
    {
        id:8,
        service:"Third-Party Risk Management",
        servicedata:" Assesses third-party vendors' compliance to ensure alignment with regulations and protect the organization from potential violations."
    },
    {
        id:9,
        service:"Reporting and Documentation",
        servicedata:' Produces detailed reports on findings, recommendations, and corrective actions to maintain an audit trail and demonstrate compliance'
    },
    ]
   
  return (
      <div className='audit-main' >
      <div className='audit-head'>
        <div className='audit-head-maintext'>Vendor Compliance Management</div>
        <div className='audit-head-text'>Our services ensure compliance with standards through structured assessments and on demand expertise</div>
      </div>
      <div style={{display:'flex', flexDirection:'column', gap:'24px'}}>
        <div className='audit-whoneed-text'>About</div>
        <div className='audit-whoneeds'>
        <div className='audit-whoneed-data'>
            <div className='audit-whoneed-detail'>Our assessment services provide a structured approach to compliance needs, leveraging various information, regulatory and contractual standards. Assessment engagements follow a standardized methodology, resulting in required and/or custom deliverables based on the assessed standard and reporting requirements. For specific organizational needs, such as business process re-architecture or company acquisition, our customizable advisory services offer targeted expertise in particular standards. Our consultants act as an on demand extension of your team, providing the necessary knowledge to navigate relevant security standards. Compliance audits ensure that an organization or individual adheres to established standards, regulations, and guidelines. This involves a thorough examination and verification of records, processes, and practices to ensure alignment with applicable laws and industry standards. The goal is to confirm that the audited entity operates transparently, ethically, and legally, building trust with stakeholders and demonstrating a commitment to accountability and regulatory compliance.</div>
        </div>
        <div className='audit-whoneed-imgdiv'></div>
      </div>
      </div>
      <div className='audit-services' style={{display:'flex', flexDirection:'column',  gap:'24px'}}> 
            <div className='audit-service-text'>Our Services </div>
            <div className='audit-service-detail'>
                <div className='audit-service-data-row'>
                    {services.filter(service => service.id <= 5).map((service) => (
                    <div className='audit-service-data1' key={service.id}>
                        <div className='audit-service-number'>{service.id}</div>
                        <div>
                        <div className='audit-service-explain1'>{service.service}</div>
                        <div className='audit-service-explain'>{service.servicedata}</div>
                        </div>
                    </div>
                    ))}
                </div>
                
                <div className='audit-service-data-row'>
                    {services.filter(service => service.id > 5).map((service) => (
                    <div className='audit-service-data2' key={service.id}>
                        <div className='audit-service-number'>{service.id}</div>
                        <div>
                        <div className='audit-service-explain1'>{service.service}</div>
                        <div className='audit-service-explain'>{service.servicedata}</div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
       </div>
      </div>
  )
}

export default AuditAdvisory
