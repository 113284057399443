import React, { useState, useContext, useEffect }  from "react";
import '../css/AdminMenu.css'
import { CalendarDots, CurrencyInr, FileArrowUp, Gavel, HandCoins, Users } from "@phosphor-icons/react";
import { TabContext } from "./AdminLayout";
import { useLocation, useNavigate } from "react-router-dom";



function AdminMenu(){
    const location = useLocation();
    const [active, setActive]=useState(null);
    const [mouseOver,setMouseOver]=useState(false);
    const navigate =useNavigate();
    const menuItem=[{
        id:1,
        icon:<FileArrowUp size={32} color="#fdfdfc" />,
        iconName:"Upload",
        route: "/Admin "
    },
    {
        id:2,
        icon:<FileArrowUp size={32} color="#fdfdfc" />,
        iconName:"Acts",
        route: "/Admin/AdminActs"
    },
    {
        id:3,
        icon:<Gavel size={32} color="#fdfdfc" />,
        iconName:"Notifications",
        route: "/Admin/AdminNotification"
    },
    {
        id:4,
        icon:<HandCoins size={32} color="#fdfdfc" />,
        iconName:"Minimum Wage",
        route: "/Admin/AdminWages"
    },
    {
        id:5,
        icon:<Users size={32} color="#fdfdfc" />,
        iconName:"Professional Tax",
        route: "/Admin/AdminTax"
    },
    {
        id:6,
        icon:<CurrencyInr size={32} color="#fdfdfc" />,
        iconName:"Labour Welfare Fund",
        route: "/Admin/AdminWelfare"
    },
    {
        id:7,
        icon:<CalendarDots size={32} color="#fdfdfc" />,
        iconName:"Holiday List",
        route: "/Admin/AdminHoliday"
    },
    {
        id:8,
        icon:<CalendarDots size={32} color="#fdfdfc" />,
        iconName:"Leave and Working Hours",
        route: "/Admin/AdminLeave"
    },
]
useEffect(() => {
    const currentActive = location.state?.active;
    if (currentActive) {
        setActive(currentActive);
    } else {
        setActive(2);
    }
}, [location]); 
const handleClick = (id, route) => {
    console.log(`Clicked ID: ${id}`);
    setActive(prevId => (prevId === id ? null : id));
    navigate(route, { state: { active: id } });
};
    
   
  const handleMouseOver = () => {
    setMouseOver(true);
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };
    return(
        <div className="AdminMenu" onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}>
         {menuItem?.map((menu) => (
            <div key={menu.id} className={`AdminMenuDetail${active===menu.id?'active':''} `} onClick={()=>handleClick(menu.id,menu.route)}>
            <div className={`AdminIcon${active===menu.id?'active':''} `} >{menu.icon}</div>
            <div className={`AdminIconName${mouseOver?'active':''}`} >{menu.iconName}</div>
            </div>
      ))}
        </div>
    );
}
export default AdminMenu;