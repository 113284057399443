import React, { useEffect, useState } from 'react'
import '../css/ETaxState.css'
import { CaretDown, CaretLeft, CaretUp, Paperclip } from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function ETaxState() {
  const { name, id } = useParams();
  console.log(name);
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
      console.log("Hii");
    };
    const handleback=()=>{
      navigate('/ProfessionalTax')
    }
    useEffect(()=>{
      window.scrollTo(0,0)
    },[]);
    const params=useParams();
    const uniqueId=id;
    const [stateName, setStateName]=useState(name);
    const [stateId, setStateId]=useState(id);
    const handleStateClick=(name,id)=>{
      if (name !== stateName || id !== stateId) {
    console.log(id);
      setStateName(name);
      setStateId(id);
      setDropdownVisible(false)
      setAct('');
      setApplicability( '');
      setFreq('');
      setARule('');
      setInfoLinkContent('');
      setInfoFileContent([]);
      setInfoData([]);
      setProf([]);
      }
    }
    const [stateList,setStateList]=useState([]);
  const token=''
  const getStateName=async()=>{
    try{
      const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
        method:'GET',
        headers:{
          Authorization:`Bearer${token}`,
          "Content-Type":'application/json',
        }, 
      })
    
      if(response.ok){
        const data=await response.json();
        setStateList(data);
        // console.log(data?.[uniqueId+1]?.state);
        
        setStateName(data?.[uniqueId-1]?.state);
       
      }
    }
    catch{
  console.log("error");
    }
  }
  // ----------- GET INFO ---------------
  const [info,setInfo]=useState([])
  const [act,setAct]=useState()
  const [rule,setARule]=useState()
  const [applicability,setApplicability]=useState()
  const [freq,setFreq]=useState();
  const [infoLinkContent,setInfoLinkContent]=useState('')
  const [infoFileContent,setInfoFileContent]=useState([]);
  const [infoData,setInfoData]=useState([])
  const [filteredData,setFilteredData]=useState([])
  const [loadingData,setLoadingData]=useState(false);
  const [tableData,setTableData]=useState(false);
  const getinfo= async(id)=>{
      try{
        setLoadingData(true);
          const response=await fetch(`${holidayApiUrl}/api/GetTaxAndFundFile?StateId=${id}`,{
              method:'GET',
              headers:{
                  Authorization:`Bearer${token}`,
                  "Content-Type":'application/json'
              }
          })
          if(response.ok){
              const data=await response.json();
              if (data.length > 0 && stateId !== 0 && stateId !== 'undefined') {
                const filtered = data.filter(item => item.folder === 'ProfessionalTax' && item.act !== '');
                setFilteredData(filtered); 
                if (filtered.length > 0) {
                  console.log(filtered.length-1);
                  
                    setAct(filtered[filtered.length-1].act || '');
                    setApplicability(filtered[filtered.length-1]?.applicability || '');
                    setFreq(filtered[filtered.length-1]?.frequency || '');
                    setARule(filtered[filtered.length-1]?.rule || '');
                    setInfoLinkContent(filtered[filtered.length-1]?.link || '');
                   
                }
                
                const filedata=filtered.filter(item=>  item.fileName.split('.').pop()!=='txt')
                console.log(filedata.map(item => item.fileName));
                
            
                if (filedata.length > 0) {  // Check filedata.length instead of filedata > 0
                  setInfoFileContent(filedata.map(item => item.fileName));
                }
                setTableData(true)
              
            }
            else{
              setTableData(false)
            }
              
              const taskData = data.filter(item => item.task !== '' && item.task !== null);
          
              if (taskData.length > 0 && stateId !== 0 && stateId !== 'undefined') {
                  console.log(taskData, "This is data");
                  setInfoData(taskData);
              }
              setLoadingData(false);
          }
      }
      catch{

      }
  }
console.log(infoFileContent);

  const [prof,setProf]=useState([])
  const getProf=async(id)=>{
    try{
      setLoading(true);
      const response=await fetch(`${holidayApiUrl}/api/GetProfessionalTax?StateId=${id}`,
        {
          method:'GET',
          headers:{
            Authorization:`Bearer${token}`,
            "Content-Type":'application/json'
          }
        }
      )
      if(response.ok){
        const data=await response.json();
        setProf(data)
        setLoading(false);
      }
    }
    catch{

    }
  }

  const [fund,setFund]=useState([]);
  const getFund=async(Id)=>{
    try{
      setLoading(true);
      const response=await fetch(`${holidayApiUrl}/api/GetTaxAndFundFile?StateId=${Id}`,
        {
          method:'GET',
          headers:{
            Authorization:`Bearer${token}`,
            "Content-Type":'application/json'
          }
        }
      )
      if(response.ok){
        const data=await response.json();
        setFund(data)
        setLoading(false);
      }
    }
    catch{
      console.log("Error");
      
    }
  }
  useEffect(()=>{
    getStateName();

  },[])
  useEffect(()=>{
    getinfo(stateId)
    getProf(stateId);
    getFund(stateId);
    navigate(`/StateProfessionalTax/${stateId}`, { replace: true }); 
  },[stateId])
  const stateBanner = useStateBanner(stateId);
console.log(stateBanner);

// const backgroundImage = stateBanner[id] || StateImage ; 
// console.log(backgroundImage);
const handleFileDownload = async (fileName,id) => {
  console.log(fileName,id);
  
  try {
    const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'ProfessionalTax'}&FileName=${fileName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'text/plain'
      }
    });

    if (response.ok) {
      const textResponse = await response.text();
      const fileUrl = textResponse.replace("Url=", "");

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Failed to fetch the file URL:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};


const handleWebsiteClick = (url) => {
  const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
  window.open(formattedUrl, '_blank');
};
const [backgroundImage, setBackgroundImage] = useState(null);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);
const handleOutsideClick = (e) => {
  if (e.target.closest('.ETaxState-maintext') === null) {
    setDropdownVisible(false);
  }
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => document.removeEventListener('click', handleOutsideClick);
}, []);
const groupDataByTaskAndFiling = (data) => {
  const groupedData = {};

  data.forEach(item => {
    const key = `${item.task}-${item.lastDayfiling}`;
    
    if (!groupedData[key]) {
      groupedData[key] = { task: item.task, lastDayfiling: item.lastDayfiling, forms: [], fileNames: [] };
    }

    groupedData[key].forms.push(item.title);
    groupedData[key].fileNames.push(item.fileName); // Store file name
  });
console.log(groupedData, "this is grouped data");

  return Object.values(groupedData);
};

  return (
    <div style={{width:'1280px',overflow:"hidden"}} >
    <div className='ETaxState-main'>
        <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`,
               
               }} className='ETax-back'>
            <div className='ETaxState-maintext' onClick={toggleDropdown}>{stateName}{!dropdownVisible?<CaretDown size={32}/>:<CaretUp size={32}/>}</div>
        </div>
        {dropdownVisible && (
  <div className='ETaxState-dropdown'>
    {stateList.filter(option => option.state !== "Central")
      ?.slice()
      .sort((a, b) => a.state.localeCompare(b.state))
      .map((states, index) => (
        <div key={index} className='ETaxStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

        <div className='ETaxState-Data'>
        
        <div className='ETaxBack' onClick={handleback}><CaretLeft size={30} />Back</div>
             {tableData ?(
            <div className='ETaxState-list'>
            <div className='ETaxState-listHead'>Information</div>
            <div className='ETaxStateDetail'>
            <div className='ETaxStateDetailHead'>{stateName}</div>
            <div className='tableTaxDiv'>
            {loadingData && (
            <div
            style={{
            display: "flex",
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            position:'absolute'
            }}
            >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
            </div>
            )}
            <table className='ETaxResponsiveTable'>
            <tbody>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Act</td>
            <td className='ETaxDetailSubCont'>{act}</td>
            </tr>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Rule</td>
            <td className='ETaxDetailSubCont'>{rule}</td>
            </tr>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Applicability</td>
            <td className='ETaxDetailSubCont'>{applicability}</td>
            </tr>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Frequency</td>
            <td className='ETaxDetailSubCont'>{freq}</td>
            </tr>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Form</td>
            <td className='ETaxDetailSubCont'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            {infoFileContent.map((info, id) => (
            <div
            key={id}
            className='ETaxDetailSubCont1'
            onClick={() => handleFileDownload(info)}
            style={{ cursor: 'pointer' }}
            >
            <Paperclip size={18} color='black' />
            {info.split('_').slice(0, -1).join('_') + '.' + info.split('_').slice(-1)[0].split('.').pop()}
            </div>
            ))}
            </div>
            </td>
            </tr>
            <tr className='ETaxStateDetailSub'>
            <td className='ETaxStateDetailName'>Website</td>
            <td className='ETaxDetailSubCont'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
            className='ETaxDetailSubCont1'
            onClick={() => handleWebsiteClick(infoLinkContent)}
            style={{ cursor: 'pointer' }}
            >
            <Paperclip size={18} color='black' />{infoLinkContent}
            </div>
            </div>
            </td>
            </tr>
            </tbody>
            </table>
            </div>

            </div>
            <div className='tableTaxDiv' style={{position:'relative'
            }}>
            {/* {loading && (
          <div
            style={{
              display: "flex",
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )} */}
            {prof.length!==0 &&(
            <div className='tableWrapper'>
            <table className='ETaxProf'>
              {/* <h1>Professional</h1> */}
              <thead>
                <tr className='ETaxTR'>
                  <th className='ETaxProfHead'>Salary</th>
                  <th className='ETaxProfHead'>PT Amount</th>
                  <th className='ETaxProfHead'>Remark</th>
                </tr>
              </thead>
              <tbody>
                {prof.length!==0 && (prof?.map((item) => (
                  <tr key={item.id}>
                    <td className='ETaxProfData'>{item.salary}</td>
                    <td className='ETaxProfData'>{item.ptAmount}</td>
                    <td className='ETaxProfData'>{item.remark}</td>
                  </tr>
                )))}
              </tbody>
            </table> 
            </div>
            )}
            </div>

            <div className='tableTaxDiv'>
            {infoData.length !== 0 && (
            <div className='tableWrapper'>
            <table className='ETaxProf'>
            <thead>
            <tr className='ETaxTR'>
            <th className='ETaxProfHead'>Task</th>
            <th className='ETaxProfHead'>Last Day of Filing</th>
            <th className='ETaxProfHead'>Forms</th>
            </tr>
            </thead>
            <tbody>
            {groupDataByTaskAndFiling(infoData).map((item, index) => (
            <tr key={index}>
            <td className='ETaxProfData'>{item.task}</td>
            <td className='ETaxProfData'>{item.lastDayfiling}</td>
            <td className='ETaxProfData'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            {item.forms.map((form, idx) => (
            <div 
            key={idx} 
            style={{ cursor: 'pointer' }} 
            onClick={() => handleFileDownload(item.fileNames[idx],idx)}
            >
            {/* {form} */}
            {form!=='placeholder.txt'?(form):('')}
            </div>
            ))}
            </div>
            </td>
            </tr>
            ))}
            </tbody>

            </table>
            </div>
            )}
            </div>




            </div>
             ):(
              <div className='ETaxState-list'>
            <div className='ETaxState-listHead'>No Information</div>
            {loadingData && (
            <div
            style={{
            display: "flex",
            width: "80%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            position:'absolute'
            }}
            >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
            </div>
            )}
            </div>
             )}
            {/* {loading && (
          <div
            style={{
              display: "flex",
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )} */}
        </div>
    </div>
</div>
  )
}

export default ETaxState