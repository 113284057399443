import React from "react";
import '../css/AdminNavbar.css'
import { useNavigate } from "react-router-dom";
import TalentTraverse from '../Images/TalentTraverseLogo.png'
import AdminPic from '../Images/AdminPic.jpg'

function AdminNavbar(){
    const navigate=useNavigate();
    const handleAdminNavigate = (path) => {
        window.scrollTo(0, 0); 
        navigate(path);
    };
    return(
        <div className="Admin-navbar">
            <div className='admin-nav-company' onClick={()=>{handleAdminNavigate('/')}}>
                <img src={TalentTraverse} alt="Talent Traverse Logo" className='nav-company-logo' />
                <div style={{display:'flex',flexDirection:'column', gap:'5px'}}>

                <div className='nav-company-name'>Talent Traverse HR Tech Solutions Pvt. Ltd</div>
                <div style={{fontFamily:'Ariel,sans-serif , sanserif',fontSize:'16px', color:'rgb(0, 151, 70)', fontWeight:'400'}}>BEYOND BOUNDARIES</div>
                </div>
            </div>
            <div className="Admin-profile">
                <img src={AdminPic} className="Admin-pic"/>
                <div className="Admin-detail">
                    <div className="Admin-name">Admin</div>
                    <div className="Admin-post">Tech Lead</div>

                </div>
            </div>
        </div>
    );
}
export default AdminNavbar;