import React,{useState,useEffect,useRef} from 'react'
import '../css/RiskCompliance.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import LegalConsequence from '../Images/LegalConsequence.png';
import OperationConsequnece from '../Images/OperationalCinsequence.png';
import FinancialConsequnece from '../Images/FinancialConsequence.png';
import ReputationDamage from '../Images/ReputationDamage.png';
import Compliance1 from '../Images/Compliance1.jpg'
import Compliance2 from '../Images/Compliance2.jpg'
import Compliance3 from '../Images/Compliance3.jpg'
import Compliance4 from '../Images/Compliance4.jpg'
import { CaretDown, CaretUp } from '@phosphor-icons/react';
function RiskCompliance() {
    const [visibleItems, setVisibleItems] = useState(3);
    const [viewMore, setViewMore] = useState(false);
    const containerRef = useRef(null);
    const services=[{
        id:1,
        service:'Ensure vendor compliance in Factories or Establishments'
    },
    {
        id:2,
        service:'Amendment of principal employer registration certificates'
    },
    {
        id:3,
        service:'Maintenance of records, registers, forms & notices under various legislations'
    },
    {
        id:4,
        service:'Ensure minimum wages and timely remittance of ESI, EPF, and LWF payments'
    },
    {
        id:5,
        service:'Regulatory Compliance: Confirm vendors follow relevant laws and regulations.'
    },
    {
        id:6,
        service:'Training and Communication: Educate vendors on compliance requirements and updates.'
    },
    {
        id:7,
        service:'Support in drafting responses to government Notices & Observations'
    },
    {
        id:8,
        service:'Liaising with government authorities during or after inspections / visits or otherwise'
    },
    {
        id:9,
        service:'Enables visibility and transparency across the organization'
    },
    {
        id:10,
        service:'Documentation Review: Ensure vendors provide all required documents (certifications, licenses,  contracts).'
    },
    {
      id:11,
      service:'Issue Resolution: Help vendors resolve compliance issues promptly.'
  },
  {
    id:12,
    service:'Performance Monitoring: Track vendor metrics (delivery, quality) to ensure standards are met.'
}]
    const law=[{
      id:1,
      title:'Purpose of the Contract Labour Act ',
      detail1:'It exists to ensure that indirect employees (contract labourers) receive fair treatment and benefits despite not being direct employees of the principal employer. This includes various roles such as housekeeping, maintenance, security, and transportation',
    },
    {
      id:2,
      title:' Government Authority under the Act  ',
      detail1:'Section 10 grants the government the power to prohibit the use of contract labour in specific processes or operations, regardless of the number of workers involved. Disputes arising under this Act cannot be referred to an Industrial Tribunal ',
    },  
    {
      id:3,
      title:'Social Legislation ',
      detail1:"The Act is described as a piece of social legislation aimed at the welfare of labourers. It obligates employers to provide certain amenities for the health and welfare of contract labourers. " ,
    }, 
      {
        id:4,
        title:'Compliance Requirements ',
        detail1:" Sections 16, 17, 18, and 19 of the Act, along with corresponding rules, outline obligations for employers regarding amenities, wages, and record-keeping. Principal employers and contractors must maintain detailed registers and records of contract labour employed and the work performed. " ,
      },
    ]
    const whyCompliance=[{
        id:1,
        image:Compliance1,
        descName:'Legal Consequences',
        desctitle1:'Fines and Penalties:',
        desc1:' Substantial fines for non-compliance.',
        desctitle2:'Contract Termination: ',
        desc2:'Loss of business due to contract termination.',
        desctitle3:'Legal Proceedings:',
        desc3:' Costly and time-consuming lawsuits '     
    },
    {
        id:2,
        image:Compliance2,
        descName:'Financial Consequences ',
        desctitle1:'Monetary Losses: ',
        desc1:'Significant fines, penalties, and legal fees.',
        desctitle2:'Compensation: ',
        desc2:'Required to pay damages to clients.',
        desctitle3:'Loss of Business: ',
        desc3:'Impact on current and future business opportunities.'     
    },
    {
        id:3,
        image:Compliance3,
        descName:'Operational Consequences ',
        desctitle1:'Service Disruption: ',
        desc1:"Interruptions in service delivery.",
        desctitle2:'Increased Scrutiny: ',
        desc2:'More frequent audits and inspections.',
        desctitle3:'Operational Delays: ',
        desc3:'Delays due to legal and corrective actions.'       
    },
    {
        id:4,
        image:Compliance4,
        descName:'Reputation Damage',
        desc1:'Difficulty in attracting and retaining clients.',
    },
]
const handleViewMore = () => {
    if (viewMore) {
      setVisibleItems(3); 
    } else {
      setVisibleItems(law.length); 
    }
    setViewMore(!viewMore);
  };
  const closeDropdownOnScroll = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
      setViewMore(false);
      setVisibleItems(3);
    }
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(closeDropdownOnScroll, {
      root: null,
      threshold: 0,
    });
  
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
  
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, viewMore]);
  return (
    <div>
      <div className='risk-main'  >
      <div className='risk-head'>
        <div className='risk-head-maintext'>Compliance Risk Audit</div>
        <div className='risk-head-text'>Vendor compliance ensures businesses adhere to regulatory and contractual standards in vendor relationships, crucial for maintaining integrity, minimizing risks, and protecting reputation</div>
      </div>
      <div className='risk-aspect' ref={containerRef}>
            <div className='risk-aspect-text'>Aspects of  Compliance Audit</div>
            <div className='risk-aspect-data'>
               <div className='risk-aspect-detail'>
               {law.slice(0, visibleItems).map((lawdata, id) => (
                 <div key={id} className='risk-service-lists'>
                  <div className='risk-aspect-title'>{lawdata.title}</div>
                    <div>
                    <div className='risk-aspect-exp'>{lawdata.detail1}</div>
                    <div className='risk-aspect-exp'>{lawdata.detail2}</div>
                  </div>
                  
                 </div>
                ))}
                   {law.length > 3  && (
                  <span onClick={handleViewMore} className='risk-view-all'>
                    {viewMore ? 'View Less' : 'View All'}
                    {viewMore ? <CaretUp size={18} color="#000000" style={{cursor:'pointer'}}/> : <CaretDown size={18} color="#000000" style={{cursor:'pointer'}}/>}
                  </span>
                )} 
                </div> 
               <div className='risk-aspect-image'></div>
            </div>
      </div>
      <div className='risk-services' style={{display:'flex', flexDirection:'column',  gap:'24px'}}> 
            <div className='risk-service-text'>Our Services </div>
            <div className='risk-service-detail'>
                <div className='risk-service-data-row'>
                    {services.filter(service => service.id <= 6).map((service) => (
                    <div className='risk-service-data1' key={service.id}>
                        <div className='risk-service-number'>{service.id}</div>
                        <div className='risk-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                
                <div className='risk-service-data-row'>
                    {services.filter(service => service.id > 6).map((service) => (
                    <div className='risk-service-data2' key={service.id}>
                        <div className='risk-service-number'>{service.id}</div>
                        <div className='risk-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                </div>
       </div>
       <div className='risk-consequence'>
        <div className='risk-consequence-text'>Consequences of vendor non-compliance</div>
        {whyCompliance?.map((compliance, id) => (
  <div key={id} className="risk-consequence-detail">
    <img src={compliance.image} className="risk-consequence-image" />
    <div className="risk-consequence-desc">
      <div className="risk-consequence-head">{compliance.descName}</div>
      <ul style={{ margin: '0px', listStyleType: 'disc', paddingLeft: '20px' }}>
        <li><span style={{ fontWeight: '700' }}>{compliance.desctitle1}</span>{compliance.desc1}</li>
        {compliance.desctitle2 ? (
          <>
           <li><span style={{ fontWeight: '700' }}>{compliance.desctitle2}</span>{compliance.desc2}</li>
           <li><span style={{ fontWeight: '700' }}>{compliance.desctitle3}</span>{compliance.desc3}</li>
          </>
         ):(<div></div>)}
      </ul>
    </div>
  </div>
))}

      </div>
       </div>
    </div>
  )
}

export default RiskCompliance
