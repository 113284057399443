import React, { useEffect } from 'react';
import '../css/Companymv.css';
import Law from '../Images/Law.png';
import { ArrowLeft, ArrowRight, UsersThree, Warning } from '@phosphor-icons/react';
import Collab from '../Images/Collab.png';
import CheckFile from '../Images/Check File.png';
import Services from '../Images/Services.png';
import Workspace from '../Images/Workspace.png';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import Client1 from '../Images/Client1.jpg'
import MissionVision from '../Images/MissionVision.png'
import Benifits1 from '../Images/Benifits1.jpg'
import Benifits2 from '../Images/Benifits2.jpg'
import Benifits3 from '../Images/Benifits3.jpg'
import Benifits4 from '../Images/Benifits4.jpg'
import ben1 from '../Images/ben1.png'
import ben2 from '../Images/ben2.png'
import ben3 from '../Images/ben3.png'
import ben4 from '../Images/ben4.png'
function Companymv() {
  const services = [
    {
      id: 1,
      image: Collab,
      head: "Consulting and Advisory Services",
      explaination: 
'We review labor law compliance, identify risks, offer mitigation strategies, provide training, draft policies, and align requirements with industry standards and locations.'
    },
    {
      id: 2,
      image: Workspace,
      head: "Assignment Based Services",
      explaination: 'Our Assignment-Based Services cover inspection closures, drafting policies and guidelines, preparing standing orders, and managing statutory registrations and amendments.'
    },
    {
      id: 3,
      image: CheckFile,
      head: "Audit and Assessment Services",
      explaination: 'Our Audit and Assessment Services include contractor due diligence, labor law audits, compliance reviews, and comprehensive audits for effective risk management.'
    },
    {
      id: 4,
      image: Services,
      head: "Maintenance Services",
      explaination: "Our Maintenance Services include configuring SOWs, ongoing support, and an initial compliance health check."
    },
    {
      id: 5,
      image: Collab,
      head: "Regulatory Updates",
      explaination: 'Our Regulatory Updates service ensures you stay informed about the latest labor law changes and compliance requirements.'
    },
  ];

  const benefits = [
    {
      id: "01",
      // img:Benifits1,
      icon:ben1,
       head: "Risk Mitigation",
      explaination: " Proactive management reduces the risk of penalties and operational disruptions due to non-compliance."
    },
    {
      id: "02",
      // img:Benifits2,
      icon:ben2,
     head: "Efficiency and Time Savings",
      explaination: " Outsourcing compliance tasks frees up internal resources, allowing focus on core business activities."
    },
    {
      id: "03",
      // img:Benifits3,
      icon:ben3,

      
      head: "Strategic Guidance",
      explaination: " Offers strategic insights and support aligned with business goals, navigating regulatory changes effectively."
    },
    {
      id: "04",
      // img:Benifits4,
      icon:ben4,
      
      head: "Expertise and Compliance Knowledge",
      explaination: "Access to expert understanding of establishment, factory, payroll, and industrial laws ensures up-to-date compliance, minimizing legal risks."
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay:true,
    slidesToScroll: 2,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '150px',
        },
      },
      {
        breakpoint: 800,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 680,
        settings: {
          centerPadding: '70px',
        },
      },
      {
        breakpoint: 620,
        settings: {
          centerPadding: '65px',
        },
      },
      {
        breakpoint: 530,
        settings: {
          centerPadding: '15px',
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          centerPadding: '30px',
        },
      },
    ],
  };
  const sliderRef = React.useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  const clients = [
    {
      id:1,
      name:'John Smith',
      position:'Company Secretary',
      review:"Talent Traverse has significantly improved our HR processes with their customized solutions. Their expertise and proactive approach have streamlined our operations.",
      img:Client1
    },
    {
      id:2,
      name:'John Smith',
      position:'Company Secretary',
      review:"The team at Talent Traverse is incredibly professional and responsive. They’ve been instrumental in helping us manage compliance and talent acquisition efficiently",
      img:Client1
    },
    {
      id:3,
      name:'John Smith',
      position:'Company Secretary',
      review:"Our partnership with Talent Traverse has been invaluable. Their tailored HR strategies have boosted our workforce management.",
      img:Client1
    },
    {
      id:4,
      name:'John Smith',
      position:'Company Secretary',
      review:"Talent Traverse offers innovative solutions that have enhanced our HR operations. Their reliability is unmatched.",
      img:Client1
    },
    {
      id:5,
      name:'John Smith',
      position:'Company Secretary',
      review:"The support we’ve received from Talent Traverse is outstanding. They truly understand our business needs and deliver accordingly",
      img:Client1
    },
    {
      id:6,
      name:'John Smith',
      position:'Company Secretary',
      review:"Their efficient service has allowed us to focus more on our core business while they handle our HR complexities seamlessly.",
      img:Client1
    },
    
  ];
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <div className='mvd-main'>
      <div className='services'>
        <div className='mv-heading'>Our Services</div>
        <div className='mv-heading-data'>
          {services.map((service) => (
            <div className='service-detail' key={service.id}>
              <div className='services-detail-img'>
                <img src={service.image} className='services-detail-image' alt={service.head} />
              </div>
              <div className='service-detail-head'>{service.head}</div>
              <div className='service-para'><div className='service-detail-explain'>{service.explaination}</div></div>
            </div>
          ))}
        </div>
      </div>
      <div className='services'>
        <div className='mv-heading'>Benefits</div>
        <div className="benefits">
          <div className="odd-benefits">
            {benefits
              .filter((benefit) => parseInt(benefit.id) <= 2)
              .map((benefit) => (
                <div key={benefit.id} className='benefits-one'>
                  {/* <img src={benefit.img} style={{height:'162px',width:'179px',borderRadius:'8px',objectFit: 'cover',transform: 'scale(1.2)'}}/> */}
                  <div className='benefits-gap'>
                    <img src={benefit.icon} style={{height:'50px',width:'50px'}}/>
                    <div className='benefit-name'>{benefit.head}</div>
                    <div className='benefit-explaination'>{benefit.explaination}</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="odd-benefits">
            {benefits
              .filter((benefit) => parseInt(benefit.id) > 2)
              .map((benefit) => (
                <div key={benefit.id} className='benefits-one'>
                 {/* <div> <img src={benefit.img} style={{height:'161px',width:'179px',objectFit: 'cover',transform: 'scale(1.2)',borderRadius:'8px',}}/></div> */}
                  <div className='benefits-gap'>
                  <div><img src={benefit.icon} style={{height:'47px',width:'50px'}}/></div>
                    <div className='benefit-name'>{benefit.head}</div>
                    <div className='benefit-explaination'>{benefit.explaination}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='services'>
        <div className='mv-heading' id='OurMission'>Our Aim</div>
        <div className='aim-data'>
          <div className='aim-detail'>The aim of the company is to be a leading technology-focused firm specializing in Labour Law Compliances, HR operations, and Payroll Outsourcing. It provides comprehensive regulatory compliance support to businesses, helping them mitigate legal risks and focus on growth.</div>
          <img src={Law} className='law-scale' alt='Law' />
        </div>
      </div>
      <div className='services'>
        <div className='mv-heading' >Our Mission and Vision</div>
        <div className='mission'>
          <div className='mission-data'>
            <div className='mission-info'>
             
                We aim to be a leading partner in regulatory compliance by offering expert solutions in Governance, Risk, Audit, and Compliance. Using our advanced tech platform, we provide tailored compliance solutions that address each client’s specific needs. Our approach focuses on predicting and addressing potential challenges early to prevent them from becoming problems. By working closely with our clients, we ensure our solutions add real value and effectively manage their unique compliance challenges.
              
            </div>
            <div className='MissionImage'><img src={MissionVision} style={{height:'200px',opacity: '20%'}}/></div>
            
            {/* <div className='mission-info'>
              <UsersThree className='mission-ppl' color="rgba(254, 204, 7, 0.80)" weight="fill" />
              <div className='mission-detail'>
                <div className='mission-name'>Proactive HR Strategies</div>
                The company focuses on anticipating and addressing HR challenges, helping clients navigate complexities with effective, customized strategies.
              </div>
            </div> */}
          </div>
          
        </div>
      </div>
      {/* <div className='services'>
        <div className='mv-heading'>Our Clients</div>
        <div className='clients' >
          <Slider {...settings} ref={sliderRef}>
            {clients.map((client, id) => (
              <div className='client'  style={{
                width: '50%',
                display: 'flex',
                padding: '10px',
                alignItems: 'center'
            }}>
                <div className='client-quote' key={id} style={{
        backgroundColor: id % 2 === 0 ? '#FFFFFF' : '#FBD12D', 
      }}>
                 <div className='client-quoteDetail' style={{minHeight:'130px'}}>{client.review}</div> 
                    <div className='client-detail'>
                        <div className='Client-profile' ><img src={client.img} style={{border: id % 2===0 ? '1px solid #FBD12D' : '1px solid #FFFFFF'}}/></div>
                        <div >
                          <div className='client-name' style={{color: id % 2===0 ? '#FBD12D' : '#FFFFFF'}}>{client.name}</div>
                          <div className='client-position'>{client.position}</div>
                        </div>
                    </div>
                </div>
              </div>
            ))}
          </Slider>
          <div id='Query'></div>
        </div>
        
      </div> */}
      <div id='Query'></div>
    </div>
  );
}

export default Companymv;
