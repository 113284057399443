import React,{useState, useEffect, useRef, useContext} from 'react'
import '../css/AdminHoliday.css'
import AdminMenu from "../components/AdminMenu";
import { CaretDown, CloudArrowUp,  FilePlus,  Paperclip,  Trash,  XCircle } from "@phosphor-icons/react";
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/toast.css'; 
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { TestContext } from '../components/TestContext';
import { CircularProgress } from "@mui/material";
import * as XLSX from 'xlsx';
function AdminHoliday() {
    const {isLogin} = useContext(TestContext);
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false)
    useEffect(()=>{    
        console.log(isLogin);    
        if(!isLogin){
            navigate('/AdminLogin')
            }   
         },[])
    const location = useLocation();
    // 

    useEffect(()=>{
        window.scrollTo(0,0);
      },[]) 
    const requiredHeaders = ['Holiday', 'Date', 'Day', 'Type', 'Remarks'];
    const [isFileValid, setIsFileValid] = useState(false);
    const validateFileHeaders = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
    
            const isValid = requiredHeaders.every(header => headers.includes(header));
            if (!isValid) {
                toast.error('Invalid file. The required headers are missing.');
                setFileName('');
                setIsFileValid(false); // Set validation status to false
                return false;
            } else {
                toast.success('File is valid!');
                setIsFileValid(true); // Set validation status to true
                return true;
            }
        };
        reader.readAsArrayBuffer(file);
    };
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
         
            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                validateFileHeaders(file);
                const fullName = file.name;
                const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
                setFileName(baseName);
            } else {
                toast.error('Please upload an Excel file.');
            }
        }
    };
    // 
    const [stateId,setStateId]=useState(null);
    const [stateDropDown, setStateDropDown]=useState(false);
    const [StateName, setStateName]=useState('');
   const token=''
    const toggleStateDropdown=() => {
        setStateDropDown(!stateDropDown);
      };
    const handleStateClick=(name, id)=>{
        setStateName(name);
        setStateDropDown(false)
        setStateId(id);
        getFile(id);
    }
// ------------------------- GET ALL STATES --------------------------//
    const [stateList,setStateList]=useState([]);
    const getState=async()=>{
        setLoading(true)
        try{
           
            const response= await fetch(`${notificationApiUrl}/api/GetAllState`,{
                method:'GET',
                header:{
                    Authorization:`Bearer ${token}`,
                    "Content-Type":'application/json;'
                }
            })
            if(response.ok){
                const data=await response.json();
                setLoading(false)
                setStateList(data);
            }
        }
        catch{
            console.error("Some Error")
        }
    }
    
//-------------------------POP UP & FILE UPLOAD----------------------------------//
    const [popOpen, setPopOpen]=useState(false);
    const [fileName,setFileName]=useState('');
    const [additionalLink,setAdditionalLink]=useState('');
    const fileInputRef = useRef(null);
    const handlePop=()=>{
    setPopOpen(true);
    setEffectiveDate(null)
    }
    const handleUploadClick = () => {
        fileInputRef.current.click();
        };
        const [showEffectiveDatePicker, setShowEffectiveDatePicker] = useState(false);
        const [effectiveDate, setEffectiveDate] = useState(null);
        
        
        const handleDateSelect = (date) => {
            const selectedDate = new Date(date);
            const formattedDate = formatUploadDate(selectedDate);
            
            // Set the effective date with the formatted date
            setEffectiveDate(formattedDate);
            
            // Close the date picker
            setShowEffectiveDatePicker(false);
        };
        
        const handleDateClick = () => {
            setShowEffectiveDatePicker(!showEffectiveDatePicker);
        };
        
        const formatUploadDate = (date) => {
            console.log(date);
            
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
            
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          };
          

          const createFormData = () => {
            const formData = new FormData();
            const file = fileInputRef.current.files[0];
            if (!file) {
                console.error('No file selected');
                return formData;
            }
            const timestamp = new Date().getTime().toString().slice(-4);
            const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
            const fileExtension = file.name.split('.').pop();
            const uniqueFileName = `${fileNameWithoutExtension}_${stateId}_${timestamp}.${fileExtension}`;
        
            formData.append('Attachment', file);
            formData.append('FileNameWithExtension', uniqueFileName);
            formData.append('Folder', 'holidaylist');
            formData.append('state', stateId || '');
            formData.append('RequestedBy', 'admin'); 
            formData.append('Title', fileName || '');
            formData.append('Type', 2);
            formData.append('IsImportant', false);
            formData.append('FileDiscription', '');
            formData.append('AdditionalLink', additionalLink || '');
            formData.append('EffectiveDate', effectiveDate || '0001-01-01T00:00:00');
            formData.append('ReleasedDate', '0001-01-01T00:00:00');
        
            // Log FormData contents for debugging
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
        
            return formData;
        };
        
        
        const uploadFile = async () => {
            if (!isFileValid) {
                toast.error('Please upload a valid file before uploading.');
                return;
            }
        
            setLoading(true);
            try {
                const formData = createFormData();
                if (!formData) {
                    setLoading(false);
                    return;
                }
        
                const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    body: formData,
                });
                await new Promise((resolve) => setTimeout(resolve, 3000)); 
                setLoading(false);
                setPopOpen(false);
                setEffectiveDate('0001-01-01T00:00:00');
        
                if (response.ok) {
                    toast.success("File uploaded successfully!");
                    setFileName('');
                    getFile(stateId); // Refresh the file list
                } else {
                    console.error(`HTTP error: ${response.status}`);
                    toast.error('File upload failed.');
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed.');
            }
        };
        
        
    
//-------- GET FILE ----------------//
    const [fileData,setFileData]=useState(null);
    const [filePresent,setFilePresent]=useState(false)
    const getFile = async (id) => {
        setLoading(true)
        try {
            const response = await fetch(`${holidayApiUrl}/api/GetHolidayByStateId?StateId=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=utf-8',
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setLoading(false)
                setFileData(data);
               console.log(data.length); 
                if(data.length!==0){
                    setFilePresent(true);
                }
                else{
                    setFilePresent(false)
                }
                
            } else {
                console.error(`HTTP error: ${response.status}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    
    useEffect(()=>{
        getState();
    },[])
    // useEffect(() => {
    //     if (activeState) {
    //         console.log(`Active state: ${activeState}`);
    //     }
    // }, [activeState]);

    const [popFileOpen, setPopFileOpen]=useState(false);
    const [fileNamePdf,setFileNamePdf]=useState('');
    const pdffileInputRef = useRef('');
    const handleFilePop=()=>{
    setPopFileOpen(true);
    }
    const handleFileUploadClick = () => {
        pdffileInputRef.current.click();
    };

    const handleFilePdfChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            const fullName = file.name;
            const specialCharPattern = /[&$₹/^+*%!@#]/;
            
        if (specialCharPattern.test(fullName)) {
          console.log("Error: File name should not contain & or $ characters.");
          toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
          return;
      }
            if (file.type === 'application/pdf') { // Correct MIME type check for PDF
                console.log('Uploaded file:', file);

                // Extracting the base name of the file
                const fullName = file.name;
                const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
                setFileNamePdf(baseName);
            } else {
                console.log('Error: Not a PDF file');
            }
        }
    };
    
    const createFormDataPdf = () => {
        
        const formData = new FormData();
        const file = pdffileInputRef.current.files[0];
        const timestamp = new Date().getTime().toString().slice(-4);
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;

        console.log(file);
        formData.append('Attachment', file || '');
        formData.append('FileNameWithExtension', uniqueFileName);
        formData.append('Folder', 'holidaylistPdf');
        formData.append('state', stateId);
        formData.append('RequestedBy', 'admin'); 
        formData.append('Title', fileNamePdf || '');
        formData.append('IsImportant', false);
        formData.append('AdditionalLink', '');
        formData.append('Type', 5);
        formData.append('FileDiscription', '');
        formData.append('EffectiveDate', '0001-01-01T00:00:00');
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        return formData;
      };
    const uploadFilePdf=async()=>{
        setLoading(true)
        try{
            const formData = createFormDataPdf();
            const response= await fetch(`${notificationApiUrl}/api/Uploadmedia`,{
                method:'POST',
                headers:{
                    Authorization: `Bearer ${token}`
                },
                body:formData,
            })
            setLoading(false)
            getPdfFile(stateId);
            setPopFileOpen(false);
            getPdfFile(stateId)
            toast.success("File uploaded successfully!");
            setFileNamePdf('');
           
        }
        catch{
            console.error("Error!!");
        }
    }
const [pdfdata,setPdfData]=useState(null)
    const getPdfFile=async(id)=>{
        console.log(id,"This is id");
        
        try{
            const response= await fetch(`${notificationApiUrl}/api/GetFileByStateId?Foldername=${'holidaylistPdf'}&StateId=${id}`,{
                method:'GET',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            if(response.ok){
                const data=await response.json();
                const filteredData = data.filter(item => item.fileName.endsWith('.pdf'));
                setPdfData(filteredData);
            }
        }
        catch{

        }
    }
    console.log(pdfdata);
  
    const handleFileDownload = async (fileName) => {
      try {
        const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'holidaylistPdf'}&FileName=${fileName}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'text/plain'
          }
        });
    
        if (response.ok) {
          const textResponse = await response.text();
          const fileUrl = textResponse.replace("Url=", "");
    
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to fetch the file URL:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while downloading the file:", error);
      }
    };
    useEffect(()=>{
getPdfFile(stateId);
    },[stateId])

    // const handleDelete=async(fileName)=>{
    //     setLoading(true)
    //     try{
    //         const response = await fetch(`${holidayApiUrl}/api/DeleteFileAndData?Folder=${`holidaylistPdf`}&FileName=${fileName}`,{
    //             method:'DELETE',
    //             headers:{
    //                 "Content-Type":'application/json'
    //             }
    //         })
    //         if(response.ok){
              
    //             getFile(stateId);
    //             getPdfFile(stateId);
    //             setLoading(false)
    //         }
    //     }
    //     catch{
    //         console.log("Error");
            
    //     }
    // }

    const handleDelete = async (fileName) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${holidayApiUrl}/api/DeleteFileAndData?Folder=${`holidaylistPdf`}&FileName=${fileName}`,
                {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": 'application/json',
                    },
                }
            );
    
            if (response.ok) {
                // Refresh files
                getFile(stateId);
                getPdfFile(stateId);
    
                // Show success message
                toast.success("File deleted successfully!");
    
                setLoading(false);
            } else {
                // Show error message if deletion fails
                toast.error(`Failed to delete file: ${response.statusText}`);
                setLoading(false); // Ensure loading state is reset
            }
        } catch (error) {
            console.error("Error:", error);
    
            // Show error message if an exception occurs
            toast.error("An error occurred while deleting the file.");
            setLoading(false); // Ensure loading state is reset
        }
    };

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setStateDropDown(false); // Close the dropdown if clicked outside
        }
      };
    
      useEffect(() => {
        if (stateDropDown) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [stateDropDown]);
    
    
    return(
        <div className="AdminHoliday">
            {/* <AdminMenu activeState={StateName}/> */}
            <div style={{width: `calc(100% - 200px)`,minHeight:'1000px'}}>
            <div className="Admin-Holiday">
            <ToastContainer />
            <div className="Holiday-detail">
                <div className="Holiday-detail1-name">Holiday List</div>
                <div ref={dropdownRef}>

                <div className="Holiday-search" onClick={toggleStateDropdown} >
                    <span className="Holiday-state-Name">{StateName===''?'Select State to view record':StateName}</span>
                    <CaretDown size={20}/>
                </div>

                {stateDropDown && (
  <div className="Holiday-dropdown-state">
    {stateList
      .filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state))
      .map((option, index) => (
        <div
          className="Holiday-dropdown-item-state"
          key={index}
          onClick={() => {
            handleStateClick(option.state, option.id);
          }}
        >
          {option.state}
        </div>
      ))}
  </div>
)}


                </div>
               
                {popOpen && <div className="Holiday-overlay"></div>}
                {popOpen && (
  <>
    <div className="Holiday-overlay"></div>
    <div className="Holiday-popup">
      <div className="Holiday-pop-Uplaod">
        <span>Upload</span>
        <XCircle size={20} onClick={() => { setPopOpen(false) }} />
      </div>
      <div className="Holiday-pop-UplaodFile" onClick={handleUploadClick}>
        <CloudArrowUp size={100} color="#a8b8b9" weight="bold" />
        {fileName === '' ? (
          <div>Uploaded file of 10MB limit</div>
        ) : (
          <div>File Selected Successfully</div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".xlsx"
          onChange={handleFileChange}
        />
      </div>
      <div onClick={handleDateClick} className='HolidayDateFil'>
    {effectiveDate ? `Effective Date: ${effectiveDate}` : 'Select Effective Date'}
    <CaretDown size={20} />
</div>
{showEffectiveDatePicker && (
    <div className="date-picker-container" style={{ display: 'flex', alignItems: 'start', position: 'absolute', top: '260px', right: '38px' }}>
        <DatePicker
            calendarClassName="custom-calendar"
            className="gc-withimage-dateinput"
            selected={effectiveDate ? new Date(effectiveDate) : null} // Convert the date string back to Date object
            name="effectiveDate"
            onChange={handleDateSelect}
            showPopperArrow={false}
            dateFormat="dd/MM/yyyy"
            inline
        />
    </div>
)}

      <div className="Holiday-pop-mark">
        <div className="Holiday-pop-imp" onClick={() => { setPopOpen(false) }}>Cancel</div>
        <div className="Holiday-pop-upload" onClick={uploadFile}>Upload</div>    
      </div>
    </div>
  </>
)}
 {popFileOpen && (
  <>
    <div className="Holiday-overlay"></div>
    <div className="Holiday-popup">
      <div className="Holiday-pop-Uplaod">
        <span>Upload</span>
        <XCircle size={20} onClick={() => { setPopFileOpen(false) }} />
      </div>
      <div className="Holiday-pop-UplaodFile" onClick={handleFileUploadClick}>
        <CloudArrowUp size={60} color="#a8b8b9" weight="bold" />
        {fileNamePdf === '' ? (
          <div>Uploaded file of 10MB limit</div>
        ) : (
          <div>File Selected Successfully</div>
        )}
        <input
          type="file"
          ref={pdffileInputRef}
          style={{ display: 'none' }}
          accept=".pdf"
          onChange={handleFilePdfChange}
        />
      </div>
      {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
      <div className="Holiday-pop-mark">
        <div className="Holiday-pop-imp" onClick={() => { setPopFileOpen(false) }}>Cancel</div>
        <div className="Holiday-pop-upload" onClick={uploadFilePdf}>Upload</div>    
      </div>
    </div>
  </>
)}
            </div>
            {StateName!=='' ?(
                !filePresent
            ?(<div>
                <div className="Holiday-detail2">
                    <div className="Holiday-detail1-name">{StateName}</div>
                    
                </div>
                <div className="Holiday-state">
                    <div className="Holiday-State-Record">No Record</div>
                    
                    <div style={{display:'flex'
                        }}>

                <div className="Holiday-detail1-upload" onClick={handlePop}><FilePlus size={24} color="#009746" />Add new Holiday</div>
                        <div className="Holiday-detail1-upload12" onClick={handleFilePop}><FilePlus size={24} color="#009746" /> Upload PDF File</div>
                        </div> 
                </div>
            </div>) :(
                <>
                <div className="Holiday-detail2">
                 <div className="Holiday-detail1-name">{StateName}</div>
                 <div style={{display:'flex',gap:'8px'}}>
                         <div className="Holiday-detail1-upload" onClick={handlePop}><FilePlus size={24} color="#009746" />Add new Holiday</div>
                         <div className="Holiday-detail1-upload12" onClick={handleFilePop}><FilePlus size={24} color="#009746" /> Upload PDF File</div>
                 </div>
             </div>
                <div className="HolidayApplicable">
                   
                <table className="responsive-table">
                <thead>
            <tr className="sticky-header-Adminholiday">
                    <th>Holiday</th>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Type</th>
                    <th>Remark</th>
            </tr>
            </thead>
            <tbody>
    {fileData?.map((file, index) => (
        <tr key={index}>
            <td>{file.holiday}</td>
            <td>{file.date}</td>
            <td>{file.day}</td>
            <td>{file.type}</td>
            <td>{file.remark}</td>
        </tr>
    ))}
</tbody>

            </table>
            
            </div>
            <div style={{display:'flex', justifyContent:'space-between', width:'100%'
                        }}>
<div className='AdminHolidayPDFAll'>        
                    {pdfdata && pdfdata.map((pdf,index)=>(
                         <div className='AdminHolidayPdfDownloadData'>
                         <div onClick={() => handleFileDownload(pdf?.fileName)} className='AdminHolidayPdfName'>
                       <Paperclip/>  {
                        pdf.fileName
                          .replace(/_\d{4}(?=\.\w+$)/, '')
                      }
                        </div>
                       {/* <div className='AdminHolidayDownload' onClick={()=>{handleDelete(pdf.fileName)}}>DELETE</div> */}
                       <Trash
                  size={20}
                  color="#898989"
                  onClick={()=>{handleDelete(pdf.fileName)}}
                  style={{cursor:"pointer"}}
                />
                       </div>

                    ))                   
                     } 
                  </div> 
                     
                        </div> 
                </>
                 
            )               
                
            ):(
                <div></div>
              
            )}
           
            </div>
           
            </div>
            {loading && <div style={{zIndex:'9999' ,position:'absolute',top:'50%', left:'50%'}}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
        </div>
  )
}

export default AdminHoliday