import React, { Children, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BackToTop from "./BackToTop";

function Layout({children}){
    useEffect(()=>{
        window.scrollTo(0,0)
      },[]);
    return(
        <div>
            <Navbar/>
            <div style={{display:'flex',justifyContent:'center',width:'100%', marginBottom:'60px'}}>
                {children}
            </div>
            <BackToTop/>
            <Footer/>
        </div>
    );
}
export default Layout;