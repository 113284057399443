import React from 'react';
import '../css/Query.css';
import { WhatsappLogo } from '@phosphor-icons/react';
import { useFormik } from "formik";
import * as yup from "yup";

function Query() {
    const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
    const fullNameRegExp =
      /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;
  
    const validationSchema = yup.object({
      email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .matches(emailRegExp, "Email is not valid")
        .trim()
        .required("Email is required"),
      name: yup
        .string("Enter Your First Name")
        .matches(fullNameRegExp, "Name is invalid")
        .trim()
        .required("Name is required"),
      message: yup.string("Enter Your Query").trim().required("Query is required"),
    });
  
    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        message: "",
      },
      
      validationSchema: validationSchema,
      onSubmit: async (values, { resetForm }) => {
        console.log("Form values:", values);
        try {
          const response = await fetch(
            `https://prod-24.centralindia.logic.azure.com:443/workflows/173ce183db9b4e84a65ab24163c08b09/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=2fed72en0SFIboxKvGOZnWWmNfKq--Z6apvCntSbtRs`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: values.name.trim(),
                email: values.email,
                message: values.message.trim(),
              }),
            }
          );
          if (response.ok) {
            console.log("Success");
          }
          if (response.status === 204) {
            resetForm();
            alert("Form submitted successfully!");
          } else {
            console.error("Failed to submit");
            alert("Failed to submit the form. Please try again.");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("An error occurred. Please try again.");
        }
      },
    });

    return (
        <div className='Query'>
            <div className='message-query'>
                <div className='message-data'>
                    <div className='message-text'>
                        <div className='text1'>Any Queries ?</div>
                        <div className='text2'>Reach to us for any queries</div>
                    </div>
                    <form onSubmit={formik.handleSubmit} className='message-form'>
                        <div className='message-feild'>
                            <label className='message-label'>Name *</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                className='message-input-feild'
                                placeholder='Name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="error" style={{fontFamily:'Ariel,sans-serif,sanserif'}}>{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div className='message-feild'>
                            <label className='message-label'>Email *</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                className='message-input-feild'
                                placeholder='Email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="error">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className='message-feild'>
                            <label className='message-label'>Message *</label>
                            <textarea
                                id="message"
                                name="message"
                                required
                                className='message-input-textarea'
                                placeholder='Message'
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            ></textarea>
                            {formik.touched.message && formik.errors.message ? (
                                <div className="error">{formik.errors.message}</div>
                            ) : null}
                        </div>
                        <div className='message-submit'>
                            <button type="submit" className='message-submit-button'>Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='whatsapp-query'>
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <div className='whatsapp-content1'>
                        Connect with us through <span style={{color: '#009746', fontWeight: '600', textAlign: 'center'}}>WhatsApp </span><WhatsappLogo color="#009746" weight='fill' className='whatsappiconq'  />
                    </div>
                    <div className='whatsapp-content2'>Reaching out to us has never been this easier!</div>
                </div>            
                <a target="_blank" href="https://wa.me/+919986863847" style={{textDecoration: 'none'}}>
                    <button className='whatsapp-chat'>Chat With Us</button>
                </a>
            </div>
        </div>
    )
}

export default Query;

// import React from "react";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import "../Style/Contactus2.css";

// function ContactUs2() {
//   const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
//   const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
//   const fullNameRegExp =
//     /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;

//   const validationSchema = yup.object({
//     email: yup
//       .string("Enter your email")
//       .email("Enter a valid email")
//       .matches(emailRegExp, "Email is not valid")
//       .trim()
//       .required("Email is required"),
//     phone: yup
//       .string()
//       .matches(phoneRegExp, "Phone number is not valid")
//       .required("Phone number is required")
//       .min(10, "Phone number must consist of 10 digits")
//       .max(10, "Phone number must consist of 10 digits")
//       .trim(),
//     name: yup
//       .string("Enter Your First Name")
//       .matches(fullNameRegExp, "Name is invalid")
//       .trim()
//       .required("Name is required"),
//     query: yup.string("Enter Your Query").trim().required("Query is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       name: "",
//       phone: "",
//       email: "",
//       query: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values, { resetForm }) => {
//       console.log("Form values:", values);
//       try {
//         const response = await fetch(
//           `https://prod2-13.centralindia.logic.azure.com:443/workflows/e1249cd6fcbe4dc8b7788c2edccaa99d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KkLUnijNo2ohs_l3ViuyjUS74K2BGbHzhfTDFedMsP0`,
//           {
//             method: "POST",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               name: values.name.trim(),
//               email: values.email,
//               phone: values.phone.trim(),
//               message: values.query.trim(),
//             }),
//           }
//         );
//         if (response.status === 204) {
//           resetForm();
//           alert("Form submitted successfully!");
//         } else {
//           console.error("Failed to submit");
//           alert("Failed to submit the form. Please try again.");
//         }
//       } catch (error) {
//         console.error("Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     },
//   });

//   return (
//     <div className="_bodyContact_Us2_MainDiv">
//       <div className="contact_Us_form">
//         <div className="containerform">
//           <div className="_body_Contact_head_">
//             Contact
//             <span className="_body_Contact_blue"> Us</span>
//           </div>
//           <form onSubmit={formik.handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="name" className="form-label">
//                 <div className="Form-deatils-styles-text">
//                   Full Name <span className="f-d-s-t-s">*</span>
//                 </div>
//               </label>
//               <input
//                 type="text"
//                 value={formik.values.name}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="form-control"
//                 id="name"
//                 name="name"
//                 placeholder="Enter Full Name"
//               />
//               {formik.touched.name && formik.errors.name ? (
//                 <div className="error">{formik.errors.name}</div>
//               ) : null}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="phone" className="form-label">
//                 <div className="Form-deatils-styles-text">
//                   Mobile Number <span className="f-d-s-t-s">*</span>
//                 </div>
//               </label>
//               <input
//                 type="text"
//                 value={formik.values.phone}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="form-control"
//                 id="phone"
//                 name="phone"
//                 placeholder="Enter Phone Number"
//               />
//               {formik.touched.phone && formik.errors.phone ? (
//                 <div className="error">{formik.errors.phone}</div>
//               ) : null}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="email" className="form-label">
//                 <div className="Form-deatils-styles-text">
//                   Email <span className="f-d-s-t-s">*</span>
//                 </div>
//               </label>
//               <input
//                 type="email"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="form-control"
//                 id="email"
//                 name="email"
//                 placeholder="Enter Email Address"
//               />
//               {formik.touched.email && formik.errors.email ? (
//                 <div className="error">{formik.errors.email}</div>
//               ) : null}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="query" className="form-label">
//                 <div className="Form-deatils-styles-text">
//                   Query <span className="f-d-s-t-s">*</span>
//                 </div>
//               </label>
//               <input
//                 type="text"
//                 value={formik.values.query}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="form-control"
//                 id="query"
//                 name="query"
//                 placeholder="Enter your Query"
//               />
//               {formik.touched.query && formik.errors.query ? (
//                 <div className="error">{formik.errors.query}</div>
//               ) : null}
//             </div>

//             <button type="submit" className="submit-btn">
//               Submit
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ContactUs2;
 