import React from 'react'
import '../css/ClientOverview.css'
import ClientMenu from '../components/ClientMenu'
function ClientOverView() {
  return (
        <div style={{ display: 'flex', width: '1280px', justifyContent:'center' }}>
            <div className='ClientOverView'>
                    <ClientMenu/>
                    <div className='ClientOverComp'>
                        <div className='OClientWelcome'>
                            <div className='OClientName'>Hello Ankit!</div>
                            <div className='OClientGreet'>Good Morning</div>
                        </div>
                        <div className='ClientOverviewHead'>Overview</div>
                        <div className='ClientO-ComingSoon'>
                            Coming Soon
                        </div>
                    </div> 
                </div>
        </div>
      )
}

export default ClientOverView