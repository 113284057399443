import React, { useEffect, useRef, useState } from 'react'
import '../css/EHolidayState.css'
import { CalendarDots, CaretDown, CaretLeft, CaretUp, FileArrowDown, MagnifyingGlass, Paperclip } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom';
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function EHolidayState() {
    const navigate=useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        console.log("Hii");
      };
      const handleback=()=>{
        navigate('/HolidayList')
      }
      useEffect(()=>{
        window.scrollTo(0,0)
      },[]);
  const uniqueId=params.id;
  const [stateName, setStateName]=useState('');
  const [stateId, setStateId]=useState(null);
  const handleStateClick=(name,id)=>{
    setPdfData(null)
    setFileData(null)
  console.log(id);
    setStateName(name);
    setStateId(id);
    setDropdownVisible(false)
    getFile(id);
    getPdfFile(id)
    fetchAllActsData(id)
    
  }
  const [stateList,setStateList]=useState([]);
const token=''
const getStateName=async()=>{

  try{
    setLoading(true)
    
    const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
      method:'GET',
      headers:{
        Authorization:`Bearer${token}`,
        "Content-Type":'application/json',
      }, 
    })
  
    if(response.ok){
      const data=await response.json();
      setStateList(data);
      setStateName(data?.[uniqueId-1]?.state);
      setLoading(false)
     
    }
  }
  catch{
console.log("error");
  }
}
useEffect(()=>{
    getStateName();
    getFile(id);
    getPdfFile(id)
    fetchAllActsData(id)
  },[])
  useEffect(() => {
    if (stateId) {
        getFile(stateId);
        navigate(`/StateHolidayList/${stateId}`, { replace: true }); 
    } else {
        getFile(id);
    }
}, [stateId]);
// ----------GET FILE ---------------------------//
const [fileData,setFileData]=useState(null);
const [filePresent,setFilePresent]=useState(false)
const getFile = async (id) => {
    try {
      setLoading(true)
        const response = await fetch(`${holidayApiUrl}/api/GetHolidayByStateId?StateId=${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setFileData(data);
           console.log(data.length); 
            if(data.length!==0){
                setFilePresent(true);
            }
            else{
                setFilePresent(false)
            }
            setLoading(false)
        } else {
            console.error(`HTTP error: ${response.status}`);
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
};
const stateBanner = useStateBanner(stateId);
console.log(stateBanner);

// const backgroundImage = stateBanner[id] || StateImage ; 
// console.log(backgroundImage);
const dropdownRef = useRef(null);
const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
    }
};
useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
// const [backgroundImage, setBackgroundImage] = useState(StateImage);

useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);
const [pdfdata,setPdfData]=useState(null)
    const getPdfFile=async(id)=>{
      console.log(id);
      if(id!== null && id!==undefined){
        try{
            const response= await fetch(`${notificationApiUrl}/api/GetFileByStateId?Foldername=${'holidaylistPdf'}&StateId=${id}`,{
                method:'GET',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            if(response.ok){
                const data=await response.json();
                const filteredData = data.filter(item => item.fileName.endsWith('.pdf'));
                setPdfData(filteredData);
            }
        }
        catch{

        }}
    }
    console.log(pdfdata);
    useEffect(()=>{

      getPdfFile(stateId)
    },[stateId])
    const handleFileDownload = async (fileName) => {
      try {
        const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'holidaylistPdf'}&FileName=${fileName}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'text/plain'
          }
        });
    
        if (response.ok) {
          const textResponse = await response.text();
          const fileUrl = textResponse.replace("Url=", "");
    
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to fetch the file URL:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while downloading the file:", error);
      }
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const formatDates = (dateString) => {
      console.log(dateString);
      
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }).format(date);
    }
    const [Datedata,setDatedata]=useState([]);
    const [EffDate,setEffDate]=useState([]);
    const fetchAllActsData = async (id) => {
 
      if(id!== null && id!==undefined){
      try {
        const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'holidayList'}&stateId=${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data,"this is data");
          setDatedata(data);
          if(data.length!==0){
          setEffDate(data[data.length-1])}

        }
      } catch (error) {
        console.error('Failed to fetch acts data:', error);
      }}
    };
    console.log(Datedata);
    useEffect(()=>{
      fetchAllActsData(stateId)
    },[stateId])
  return (
    <div style={{ width: '1280px' , overflow:'hidden'}}>
        <div className='EHolidayState-main'>
            <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`,
              
               }} className=' EHoliday-back'>
                
                <div className='EHolidayState-maintext' onClick={toggleDropdown}>{stateName}{!dropdownVisible?<CaretDown className='icon-holiday' size={32}/>:<CaretUp  className='icon-holiday' size={32}/>}</div>
            </div>
            {dropdownVisible && (
  <div className='EHolidayState-dropdown' ref={dropdownRef}>
    {stateList.filter(option => option.state !== "Central")
      ?.slice() // Create a copy of the array to avoid mutating the original stateList
      .sort((a, b) => a.state.localeCompare(b.state)) // Sort alphabetically based on the state name
      .map((states, index) => (
        <div key={index} className='EHolidayStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

            <div className='EHolidayState-Data'>
            <div className='EHolidayBack' onClick={handleback}><CaretLeft className='icon-holiday' size={30} />Back</div>
            {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
                
<div className='EHolidayState-list'>
    {fileData?.[0] && (
        <div className='DatesHoliday'>
            <div className='EffectiveDateH' style={{ fontFamily: "Arial, sans-serif" }}> 
                <CalendarDots className='HolidayDateicon'/> Effective from Date: {EffDate.effectiveDate==='0001-01-01T00:00:00'|| EffDate.length===0?'NA':formatDate(EffDate.effectiveDate)}
            </div>
            <div className='UpdatedDateH' style={{ fontFamily: "Arial, sans-serif" }}>
                <CalendarDots className='HolidayDateicon'/> Updated As On: {formatDate(fileData[0].createdDate)}
            </div>
        </div>
    )}

    {filePresent ? (
        <div className="table-container">
            <table className="HolidayState-responsive-table">
                <thead>
                    <tr className="sticky-header-holiday">
                        <th className='HolidatTHead'>Holiday</th>
                        <th className='HolidatTHead'>Date</th>
                        <th className='HolidatTHead'>Day</th>
                        <th className='HolidatTHead'>Type</th>
                        <th className='HolidatTHead'>Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {fileData?.map((file, id) => (
                        <tr key={id}>
                            <td>{file.holiday}</td>
                            <td>{file.date}</td>
                            <td>{file.day}</td>
                            <td>{file.type}</td>
                            <td>{file.remark}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
        </div>
    ) : (
        <div className='EState-NoHoliday'>No Holidays Recorded</div>
    )}

    <div className='EHolidayPDFAll'>        
        {pdfdata && pdfdata.map((pdf, index) => (
            <div className='EHolidayPdfDownloadData' key={index}>
                <div onClick={() => handleFileDownload(pdf?.fileName)} className='EHolidayPdfName'>
                    <Paperclip/> {pdf.fileName.replace(/_\d{4}(?=\.\w+$)/, '')}
                </div>
                {/* <div className='EHolidayDownload' onClick={() => handleFileDownload(pdf?.fileName)}>Download</div> */}
                <div className='EHolidayDownload' onClick={() => handleFileDownload(pdf?.fileName)}><FileArrowDown size={20} color="#009746"  />Download File</div>
            </div>
        ))}
    </div>
</div>

            </div>

        </div>
    </div>
  )
}

export default EHolidayState