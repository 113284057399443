import React, { useState,useEffect, useRef } from 'react'
import '../css/IndustrialLaw.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { CaretDown, CaretUp, Leaf, SuitcaseSimple, Warning, Windmill } from '@phosphor-icons/react'
import IndustryLawSub from '../Images/IndustrialLawSub.jpg'

function IndustrialLaw() {
    const [viewLaw, setViewLaw] = useState(null);

    const viewLawDetail = (id) => {
      setViewLaw((prevId) => (prevId === id ? null : id));
    };
    const [viewLegislateData,setViewLegislateData]=useState(false);
    const [isCaretDownVisible, setIsCaretDownVisible] = useState(true);
    const [visibleServices, setVisibleServices] = useState(10);
    const [viewMore, setViewMore] = useState(false);
    const [expandedLegislateId, setExpandedLegislateId] = useState(null);

    const services=[{
        id:1,
        service:'Consent To Establish'
    },
    {
        id:2,
        service:'Hazardous Waste Registration'
    },
    {
        id:3,
        service:'Solid Waste Registration'
    },
    {
        id:4,
        service:'Plastic waste Registation'
    },
    {
        id:5,
        service:'Fire NOC under Construction'
    },
    {
        id:6,
        service:'Consent To Operate'
    },
    {
        id:7,
        service:'Biomedical Waste Registration'
    },
    {
        id:8,
        service:'E-Waste Registration'
    },
    {
        id:9,
        service:'Lift License'
    },
    {
        id:10,
        service:'Fire NOC Post Completion'
    },
    {
        id:11,
        service:'Factory License'
    },
    {
        id:12,
        service:'BOCW Registration'
    },
    {
        id:13,
        service:'Boiler Registration'
    },
    {
        id:14,
        service:' Electrical Equipment Installation Permission'
    },
    {
        id:15,
        service:'Municipal Corporation NOC'
    },
    {
        id:16,
        service:'PWD/Development Authority NOC'
    },

    {
        id:17,
        service:'Gram Panchayat NOC'
    },
    {
        id:18,
        service:'Ground Water Extraction NOC'
    },
    {
        id:19,
        service:'FSSAI License'
    },
    {
        id:20,
        service:'Legal Metrology Registration'
    },


    {
        id:21,
        service:'Weights and Measures Stamping '
    },


    {
        id:22,
        service:'PESO Approvals Pre and Post - SMPV'
    },
    // {
    //     id:23,
    //     service:'BOCW License'
    // },
    {
        id:23,
        service:'PESO Approvals Pre and Post - GCR'
    },
    {
        id:24,
        service:'Signage License'
    },
    {
        id:25,
        service:'Drug License'
    },
    {
        id:26,
        service:'Cosmetics License'
    },{
        id:27,
        service:'Environmental Clearance'
    },
    {
        id:28,
        service:'Insecticides License'
    },
    {
        id:29,
        service:'APMC Registration'
    },
    {
        id:30,
        service:'Trade License'
    },
    {
        id:31,
        service:'Pasara License'
    },


]

    const law=[{
        id:1,
        title:'Industrial Law',
        detail1:'Governs Industrial Enterprises',
        detail2:'Covers legal topics like employment laws, environmental concerns, contracts, industrial relations, and worker safety regulations',
        detail3:'Policies vary by industry and buisness',
        detail4:'Includes various acts under Industrial Compliance Services'
    },
    {
        id:2,
        title:'Purpose of Industrial Laws:',
        detail1:'Ends unfair labour practices',
        detail2:'Provides rights, privileges, obligations, and responsibilities for the workforce',
        detail3:"Helps both workers and management understand their rights, duties, obligations,and liabilities",
    },  
    {
        id:3,
        title:'Compliance in Business:',
        detail1:'Involves following relevant laws and regulations specific to an industry',
        detail2:'May require adhering to complex sets of laws affecting operations significantly',
    },  
    {
        id:4,
        title:'Process Compliance:',
        detail1:'Involves regulating and maintaining industry standards and guidelines.',
        detail2:'Non-compliance can result in penalties or jail time for company officers',
    },  
      ]
    const legislation=[{
        id:1,
        img:<SuitcaseSimple className='img-industrial' size={32} color="#403183" weight="fill" />,
        title:'Legislations Concerned with Workplaces',
        detail:['The Factories Act, 1948 (Amended 1976, 1987 & 2001) and the State Factories Rules of respective States ',
            'The Mines Act, 1952 (Amended 1984)',
            'The Mines Rules, 1955 (Amended 1989)',
            'The Coal Mines Regulations, 1957',
            'The Metallic-Ferrous Mines Regulation, 1961',
            'Mines Vocational Rules, 1966 >The Oil Mines Regulations, 1984 (Amended 1996) ',
            'The Mines Rescue Rules, 1985 >The Dock Workers (Safety, Health & Welfare) Act, 1986',
            'The Dock Workers (Safety, Health and Welfare) Regulations, 1989',
            'Building and Other Construction Workers (Regulation of Employment& Conditions of Service) Central Rules, 1998',
            'The Building and Other Construction Workers’ Welfare Cess Act, 1996 and Rules 1998 >Indian Port Act, 1908 and Rules there under >The Plantations Labour Act, 1951.']
    },
    {
        id:2,
        img:<Windmill  className='img-industrial' size={32} color="#403183" />,
        title:'Legislations Concerned with Activities / Energy',
        detail:['The Electricity Act, 2003 ',
            'The Indian Electricity Rules, 1956',
            'The Indian Boilers Act, 1923 (Amended 1960)',
            'The Indian Boilers Regulations, 1950 (Amended 1997)',
            'Boilers Rules of various States',
            'Motor Vehicles Act, 1988 and Motor Vehicles (Central) Rules, 1989  ',
            'The Motor Transport Workers Act, 1961 & Rules, 1964',]
    },
    {
        id:3,
        img:<Leaf  className='img-industrial' size={32} color="#403183"  />,
        title:'Legislations Concerned with Environment',
        detail:['The Factories Act,  The Water (Prevention and Control of Pollution) Act, 1974 and the Rules1975. (Amended 1976, 1987 & 2001) and the State Factories Rules of respective States ',
            'The Air (Prevention and Control of Pollution) Act, 1981 and the Rules 1982.',
            'The Environment (Protection) Act,1986 & the Rules 1986',
            'The Manufacture, Storage & Import of Hazardous Chemicals Rules 1989',
            'The Hazardous Wastes (Management, Handling & Trans-boundary Movement) Rules, 2008.',
            'The Chemical Accidents (Emergency Planning, Preparedness & Response) Rules 1996. ',
            'The Bio-Medical Waste (Management &Handling) Rules 1998.',
            'The Plastic Manufacture, Sale and Usage Rules 1999.',
            'The Noise Pollution (Regulation & Control) Rules, 2000.',
            'The Municipal Solid Waste (Management & Handling) Rules 20003.',
            'The Batteries (Management & Handling) Rules 2000.',
            'The Ozone Depleting Substance (Regulation and Control) Rules 2000.',
            "The Rules for the Manufacture, Use, Import, Export and Storage of Hazardous Micro Organisms/Genetically Engineered organism or all, 1998.",]
        },
    {
        id:4,
        img:<Warning  className='img-industrial' size={32} color="#403183" />,
        title:'Legislations Concerned with Hazardous / Dangerous Substances',
        detail:['The Dangerous Machines (Regulation) Act, 1983',
            'The Fatal Accidents Act, 1855',
            'The Explosives Act, 1884 (Amended 1983))',
            'The Explosives Rules, 1983 (Amended 2002)',
            'The Gas Cylinders Rules, 2004',
            'The Static and Mobile Pressure Vessels ‘Unfired) Rules, 1981 (Amended 2002) ',
            'The Petroleum Act, 1934',
            'The Petroleum Rules, 2002',
            'The Calcium Carbide Rules, 1987',
            'The Insecticides Act, 1968 & Rules, 1971.',
        'The Poison’s Act, 1919 & Rules, 1972',
        'Major Accidents Hazard Control Rules, 1997.',
        'Drugs & Cosmetics Acts & Rules, 1995.',]
    },]
      const handleViewMore = () => {
          if (viewMore) {
              setVisibleServices(10);
          } else {
              setVisibleServices(services.length);
          }
          setViewMore(!viewMore);
    };

    const containerRef = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting && viewLaw !== null) {
              setViewLaw(null);
            }
          });
        },
        { threshold: 0.1 }
      );
  
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
  
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, [viewLaw]);
    

    const viewLegislate = (id) => {
        setExpandedLegislateId((prevId) => (prevId === id ? null : id));
    };


  return (
    <div>
      <div className='industry-main'>
      
      <div className='industry-head'>
        <div className='industry-head-maintext'>Industrial Law Compliance Support</div>
        <div className='industry-head-text'>Industrial Law governs enterprise operations with diverse regulations, ensuring fair labour practices and compliance to avoid penalties</div>
      </div>
      <div className='industry-law' ref={containerRef}>
      <div className='industry-law-text'>Aspects of Industrial Law and Compliance</div>
      <div className='industry-law-ev'>
      <div className="industry-law-detail">
  {law.map((lawdata, index) => (
    <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div className="industry-law-title">
        {lawdata.title}
        {/* {lawdata.detail3 && (
          <span onClick={() => viewLawDetail(lawdata.id)}>
            {viewLaw !== lawdata.id ? (
              <CaretDown size={22} style={{ cursor: 'pointer' }} color="#000000" />
            ) : (
              <CaretUp size={22} style={{ cursor: 'pointer' }} color="#000000" />
            )}
          </span>
        )} */}
      </div>
      <ul style={{ margin: '0px', listStyleType: 'disc', paddingLeft: '4px' }}>
        <li className="industry-law-exp">{lawdata.detail1}</li>
        <li className="industry-law-exp">{lawdata.detail2}</li>
           {lawdata.detail3 ?( <li className="industry-law-exp">{lawdata.detail3}</li>):(<></>)}
           {lawdata.detail4 ? ( <li className="industry-law-exp">{lawdata.detail4}</li>):(<></>)} 

       
      </ul>
    </div>
  ))}
</div>

        <div className='industry-law-image'></div>
      </div>
    </div>
      <div className='industry-scope'>
        <div className='industry-scope-text'>Licenses And Registrations </div>
        <div className='industry-scope-detail'>
                {services?.slice(0, visibleServices).map((service, id) => (
                    <div className='industry-scope-data' key={id}>
                        <div className='industry-scope-number'>{service.id}</div>
                        <div className='industry-scope-explain'>{service.service}</div>
                    </div>
                ))}
            </div>
            <div><span onClick={handleViewMore} className='industry-view-all'>{viewMore?'View Less':'View All'}{viewMore
        ?(<CaretUp size={22} style={{cursor:'pointer'}} color="#000000"/>):(<CaretDown size={22} style={{cursor:'pointer'}} color="#000000" />)}</span></div>
    </div>
    <div className='industry-legislation'>
            <div className='industry-legis-text'>Type Of Legislations</div>
            {legislation?.map((legislate) => (
                <div className='industry-legis-data' key={legislate.id}>
                    <div className='industry-legis-item'>
                        <div className='industry-legis-title'>
                            <div className='industry-legis-img'>
                                <span>{legislate.img}</span> {legislate.title}
                            </div>
                            <div
                                onClick={() => viewLegislate(legislate.id)}
                                className='industry-view-all'
                            >
                                {expandedLegislateId === legislate.id ? 'View Less' : 'View All'}
                                {expandedLegislateId === legislate.id ? (
                                    <CaretUp size={22} style={{ cursor: 'pointer' }} color="#000000" />
                                ) : (
                                    <CaretDown size={22} style={{ cursor: 'pointer' }} color="#000000" />
                                )}
                            </div>
                        </div>
                        {expandedLegislateId === legislate.id && (
                            <>
                                <ul className='industry-legis-detail'>
                                    {legislate.detail.map((item, index) => (
                                        item.trim() && <li key={index} style={{ listStyle: 'disc'}}>{item.trim()}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default IndustrialLaw
