import React, { useEffect, useState, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import indiaMapSVG from '../map/india.svg'; // Adjust the path to your SVG file
import '../css/IndiaMap.css';
import { CaretDown, CaretUp, MapPinSimple } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";

function IndiaMap({ page }) {
  const [apiData, setApiData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [hoveredState, setHoveredState] = useState(null);
  const [clickedState, setClickedState] = useState(null); 
  const [showApplicable, setShowApplicable] = useState(false);
  const [showNonApplicable, setShowNonApplicable] = useState(false);
  const [selectedStateOptions, setSelectedStateOptions] = useState(null);
  const token = '';
  const navigate = useNavigate();
  const tableRef = useRef(null); // Create a ref for the hovered table
// console.log(page);
const tableRef3 = useRef(null);
  // useEffect(()=>{
  //   window.scrollTo(0,0)
  // },[]);
  // Fetch API data
  const callApi = async () => {
    try {
      const api = page === 'Tax'
        ? `${holidayApiUrl}/api/GetProfessionalTax`
        : `${holidayApiUrl}/api/GetLabourWelfare`;

      const response = await fetch(api, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log('API data fetched successfully');
        setApiData(data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch state list
  const getStateName = async () => {
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStateList(data);
      }
    } catch {
      console.log("Error fetching states");
    }
  };

  useEffect(() => {
    getStateName();
    callApi();
  }, []);

  // Create a lookup object for state names
  // const stateNameLookup = stateList.reduce((acc, state) => {
  //   acc[state.id] = state.state;
  //   return acc;
  // }, {});

  // Create a lookup object for state positions
  const statePositions = {
    'Andhra Pradesh': { x: 41.2, y: 75 },
    'Arunachal Pradesh': { x: 87, y: 38 },
    'Assam': { x: 78.5, y: 44.3 },
    'Bihar': { x: 59.5, y: 45 },
    'Chhattisgarh': { x: 50.4, y: 55.3 },
    'Goa': { x: 26.1, y: 74.3 },
    'Gujarat': { x: 19.9, y: 54.1 },
    'Haryana': { x: 33.8, y: 35.5 },
    'Himachal Pradesh': { x: 35.8, y: 27.7 },
    'Jharkhand': { x: 58.3, y: 50.5 },
    'Karnataka': { x: 30.2, y: 75.8 },
    'Kerala': { x: 32.5, y: 86.8 },
    'Madhya Pradesh': { x: 37.8, y: 54.5 },
    'Delhi': { x: 34.8, y: 36.5 },
    'Maharashtra': { x: 30.5, y: 59.7 },
    'Manipur': { x: 80.1, y: 49.3 },
    'Meghalaya': { x: 72.7, y: 45.7 },
    'Mizoram': { x: 77.6, y: 53.6 },
    'Nagaland': { x: 84.2, y: 44.3 },
    'Odisha': { x: 54.9, y: 60.9 },
    'Punjab': { x: 29, y: 30 },
    'Chandigarh': { x: 33, y: 31 },
    'Rajasthan': { x: 28, y: 40 },
    'Sikkim': { x: 66, y: 39 },
    'Tamil Nadu': { x: 39, y: 87.6 },
    'Puducherry': { x: 42, y: 83.6 },
    'Telangana': { x: 40, y: 68 },
    'Tripura': { x: 75, y: 52 },
    'Uttar Pradesh': { x: 45, y: 45 },
    'Uttarakhand': { x: 40, y: 33 },
    'West Bengal': { x: 65, y: 53 },
    'Jammu and Kashmir': { x: 28.4, y: 20.6 },
    'Ladakh': { x: 34.4, y: 13.6 },
    'Lakshadweep': { x: 22, y: 88 }, 
    'Andaman and Nicobar Islands': { x: 78, y: 85 } ,
    'Daman and Diu': { x: 24, y: 61.1 },
    'Dadra and Nagar Haveli': { x: 23, y: 60.1 },
  };

  const [applicableStates, setApplicableStates] = useState([]);
  const [nonApplicableStates, setNonApplicableStates] = useState([]);
  const [stateNameLookup, setStateNameLookup] = useState({});

  useEffect(() => {
    const lookup = stateList.reduce((acc, state) => {
      acc[state.id] = state.state;
      return acc;
    }, {});
    setStateNameLookup(lookup);
  
    console.log("State Lookup:", lookup);
  }, [stateList]);
  
  useEffect(() => {
    console.log("API Data:", apiData);
    console.log("Applicable States:", applicableStates);
    console.log("Non-Applicable States:", nonApplicableStates);
  }, [apiData, applicableStates, nonApplicableStates]);
  
  // Determine applicable and non-applicable states
  useEffect(() => {
    if (apiData.length > 0 && Object.keys(stateNameLookup).length > 0) {
      const applicableStates = apiData.map(item => {
        const stateIdField = page === 'Tax' ? item.stateID : item.stateid;
        return stateNameLookup[stateIdField];
      }).filter(name => name);
  
      const uniqueApplicableStates = [...new Set(applicableStates)];
      setApplicableStates(uniqueApplicableStates);
      setNonApplicableStates(Object.keys(statePositions).filter(state => !uniqueApplicableStates.includes(state)));
    }
  }, [apiData, stateNameLookup, page]);
  

  const getStateColor = (stateName) => {
    return applicableStates.includes(stateName) ? '#D9D6E6' : '#E7E7E73D';
  };

  const handleNavigate = (stateName) => {
    console.log(stateName);
    
    const state = stateList.find(state => state.state === stateName);

    if (state) {
        const id = state.id;
        console.log();
        
        if (page === 'Tax') {
            navigate(`/StateProfessionalTax/${id}`, { state: { name: stateName } });
        } else {
            navigate(`/StateLabourWelfare/${id}`, { state: { name: stateName } });
        }
    } else {
        console.log(`State with name "${stateName}" not found.`);
    }
  };

  // Add an event listener to close the table when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        // console.log("HEREEEEEEEEEEEE");
        
        setHoveredState(null);
        setClickedState(null); 
      }
    };
    

    if (hoveredState) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hoveredState]);

  
  // Refs for dropdowns
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const clickedTableRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowApplicable(false); // Close applicable dropdown
    }
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setShowNonApplicable(false); // Close non-applicable dropdown
    }
      if (clickedTableRef.current && !clickedTableRef.current.contains(event.target)) {
        setClickedState(null); // Close clicked state table
      }
    
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{ window.scrollTo(0,0) },[]);
  // const handleStateClick = (stateName) => {
  //   if (page === 'Tax') {
      
  //     if (windowWidth >= 800) {
  //       handleNavigate(stateName);
  //     } else {
  //       setClickedState(stateName); // Keep the dropdown open
  //     }
  //   } else {
  //     handleNavigate(stateName); // Navigate for Welfare page
  //   }
  // };
  const handleStateClick = (event, stateName) => {
        handleNavigate(stateName); // Navigate for Welfare page
    }
const [hovered,setHovered]=useState(null)
    const handleOptionSelect = (option) => {
      setSelectedStateOptions(null); // Close modal after selecting option
      setHoveredState(option); // Navigate to the selected option
      if(page==='Welfare'){
        handleNavigate(option)
      }
    };
    const modalRef = useRef(null);
    useEffect(() => {
      const handleClickOutsideModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setSelectedStateOptions(null); // Close modal
        }
      };
  
      document.addEventListener('mousedown', handleClickOutsideModal);
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideModal);
      };
    }, []);
  return (
    <div className="ETaxMapAll">
      <div className='EMapStates'>
      <div className='EMapApplicable' onClick={() => {
  setShowApplicable(!showApplicable);
  setShowNonApplicable(false);
}}>
  Applicable States and Union Territories
  {showApplicable ? <CaretUp size={22} /> : <CaretDown size={22} />}
  {showApplicable && (
    <div className="stateDropdown" ref={dropdownRef}>
      {applicableStates
        .slice() // Create a copy of the array to avoid mutating the original
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
        .map((stateName, idx) => (
          <div key={idx} onClick={(event) => handleStateClick(event, stateName)}>
            {stateName}
          </div>
        ))}
    </div>
  )}
</div>
<div className='EMapNonApplicable' onClick={() => {
  setShowNonApplicable(!showNonApplicable);
  setShowApplicable(false);
}}>
  Non Applicable States and Union Territories
  {showNonApplicable ? <CaretUp size={22} /> : <CaretDown size={22} />}
  {showNonApplicable && (
    <div className="stateDropdownnon" ref={dropdownRef2}>
      {nonApplicableStates
        .slice() // Create a copy of the array to avoid mutating the original
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
        .map((stateName, idx) => (
          <div key={idx} style={{ cursor: 'default' }}>
            {stateName}
          </div>
        ))}
    </div>
  )}
</div>

      </div>
      {windowWidth >= 800 && (
      <ReactSVG
        src={indiaMapSVG}
        beforeInjection={(svg) => {
          svg.querySelectorAll('path').forEach((path) => {
            const stateName = path.getAttribute('name');
            if (stateName) {
              const fillColor = getStateColor(stateName);
              path.setAttribute('fill', fillColor);
              path.style.cursor = applicableStates.includes(stateName) ? 'pointer' : 'default';

              if (stateName === 'Tamil Nadu' && page === 'Tax') {
                path.addEventListener('click', () => {
                  setSelectedStateOptions({ stateName, options: ['Tamil Nadu', 'Puducherry'] });
                  setHoveredState(null); // Reset hovered state to avoid conflicts
                  setHovered(stateName); 
                });
              } else if (stateName === 'Punjab' ) {
                path.addEventListener('click', () => {
                  setSelectedStateOptions({ stateName, options: ['Punjab', 'Chandigarh'] });
                  setHoveredState(null); // Reset hovered state to avoid conflicts
                  setHovered(stateName); 
                });
              } else {
                // For other states, handle regular click and hover behavior
                path.addEventListener('mouseup', () => setHoveredState(stateName));
                path.addEventListener('mousedown', () => setHoveredState(null));
              }
            }
          });
        }}
        className='map'
      />)}
      {windowWidth >= 800 &&applicableStates.map((stateName, idx) => {
  const position = statePositions[stateName];
  if (!position) return null;
  return (
    <div key={idx}>
     {page === 'Welfare' && (
  <div
    className="invisibleClickableDiv"
    onClick={() => {
      console.log('Invisible div clicked');
      handleNavigate(stateName);
    }}
    style={{
      top: `${position.y}%`,
      left: `${position.x}%`,
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      zIndex: 1000,
      width: '20px',
      height: '20px',
    }}
  />
)}

      
      <div className="mapPin" style={{ top: `${position.y}%`, left: `${position.x}%` ,display:'flex',
    flexDirection:'column'}}>
        <MapPinSimple size={24} />
        <div   >{stateName}</div>
      </div>
    </div>
  );
})}
{selectedStateOptions && (
  <div className="state-options-modal"   style={{ top: `${statePositions[hovered]?.y || 0}%`, left: `${statePositions[hovered]?.x -5 || 0}%` ,display:'flex',gap:'6px', zIndex:'99'}} ref={modalRef} >
    {selectedStateOptions.options.map((option, idx) => (
      <div key={idx} className='stateTableName' onClick={() => handleOptionSelect(option)} style={{cursor:'pointer'}}>
        {option}
      </div>
    ))}
  </div>
)}
      {hoveredState && page === 'Tax' && (
        <div
          className="stateTable"
          style={{ top: `${statePositions[hoveredState]?.y + 10 || 0}%`, left: `${statePositions[hoveredState]?.x + 5 || 0}%` }}
          ref={tableRef} // Attach the ref to the table
        >
          <div className='stateTableName'>
            
            {hoveredState}</div>
          <table className='stateTable-detail' onClick={() => handleNavigate(hoveredState)}>
            <thead className='stateTable-detail-head'>
              <tr className='MapHeadRow'>
                <th className='MapTHead'>Salary</th>
                <th className='MapTHead'>PT Amount</th>
                <th className='MapTHead'>Remark</th>
              </tr>
            </thead>
            <tbody className='stateTable-detail-head'>
              {apiData
                .filter((data) => {
                  const stateIdField = page === 'Tax' ? data.stateID : data.stateid;
                  return stateNameLookup[stateIdField] === hoveredState;
                })
                .slice(0, 5) // Limit to 5 rows
                .map((data, index) => (
                  <tr key={index} className='MapDataRow'>
                    <td className='MapTData'>{data.salary}</td>
                    <td className='MapTData'>{data.ptAmount}</td>
                    <td className='MapTData'>{data.remark}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {hoveredState && page === 'Welfare' && (
        <div
          className="stateTableW"
          style={{ top: `${statePositions[hoveredState]?.y  || 0}%`, left: `${statePositions[hoveredState]?.x  || 0}%` , backgroundColor:'transparent',boxShadow:'none' }}
          ref={tableRef} // Attach the ref to the table
          onClick={() => {
            console.log('Invisible div clicked');
            handleNavigate(hoveredState);
          }}
        >
        </div>
      )}
         {/* {windowWidth < 800 && clickedState && page === 'Tax' && (
        <div className="stateTable" ref={clickedTableRef} onClick={()=>{handleNavigate(clickedState)}} >
          <div className='stateTableName'>{clickedState}</div>
          <table className='stateTable-detail' >
            <thead className='stateTable-detail-head'>
              <tr className='MapHeadRow'>
                <th className='MapTHead'>Salary</th>
                <th className='MapTHead'>PT Amount</th>
                <th className='MapTHead'>Remark</th>
              </tr>
            </thead>
            <tbody className='stateTable-detail-head'>
              {apiData
                .filter((data) => stateNameLookup[page === 'Tax' ? data.stateID : data.stateid] === clickedState)
                .map((data, index) => (
                  <tr key={index}>
                    <td className='MapTData'>{data.salary}</td>
                    <td className='MapTData'>{data.ptAmount}</td>
                    <td className='MapTData'>{data.remark}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )} */}
      
    </div>
  );
}

export default IndiaMap;
