import { BagSimple, BellRinging, CalendarDots, ClockUser, CurrencyInr, FileArrowUp, Gavel, HandCoins, Users } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react'
import '../css/ELibraryMenu.css'
import { useNavigate } from 'react-router-dom';

function ELibraryMenu() {
   
    const [mouseOver,setMouseOver]=useState(false);
    const navigate =useNavigate();
    const menuItem=[{
        id:1,
        icon:<Gavel size={32} color="#fdfdfc" />,
        iconName:"Acts and Rules",
        route:'/ActsandRules'
       
    },
    {
        id:2,
        icon:<BagSimple size={32} color="#fdfdfc" />,
        iconName:"Professional Tax",
        route:'/ProfessionalTax'

    },
    {
        id:3,
        icon:<Users size={32} color="#fdfdfc" />,
        iconName:"Labour Welfare",
        route:'/LabourLaw'
    },
    {
        id:4,
        icon:<HandCoins size={32} color="#fdfdfc" />,
        iconName:"Leave",
        route:'/LeaveAndWorkingHour'
    },
    {
        id:5,
        icon:<ClockUser size={32} color="#fdfdfc" />,
        iconName:"Minimum Wage",
        route:'/MinimumWage'
    },
    {
        id:6,
        icon:<BellRinging size={32} color="#fdfdfc" />,
        iconName:"Labour Welfare Fund",
        route:'/LabourWelfare'
    },
    {
        id:7,
        icon:<CalendarDots size={32} color="#fdfdfc" />,
        iconName:"Labour Welfare Fund",
        route:'/HolidayList'
    },]
    const [active, setActive]=useState(menuItem[0].id);
    const handleClick = (id, route) => {
        console.log(`Clicked ID: ${id}`);
        setActive(prevId => (prevId === id ? null : id));
        navigate(route, { state: { active: id } })
    };
    // const [active, setActive] = useState(menuItem[0].id);

    // const handleClick = (id, route) => {
    //   if (active !== id) { // Only navigate if the clicked item is different from the current active one
    //     setActive(id);
    //     navigate(route);
    //   }
    // };
    // useEffect(() => {
    //     if (active !== null) {
    //       const route = menuItem.find(menu => menu.id === active)?.route;
    //       if (route){
    //         console.log(route);
    //         navigate(route);
    //       }
    //     }
    //   }, [setActive]);
    
    //   const handleClick = (id) => {
    //     setActive(id);
    //   };
    
    const handleMouseOver = () => {
        setMouseOver(true);
      };
    
      const handleMouseOut = () => {
        setMouseOver(false);
      };
  return (
    <div className="ELibMenu" onMouseEnter={handleMouseOver}
    onMouseLeave={handleMouseOut}>
     {menuItem?.map((menu) => (
        <div key={menu.id} className={`ELibMenuDetail${active===menu.id?'active':''} `} onClick={()=>handleClick(menu.id,menu.route)}>
            <div className={`ELibIcon${active===menu.id?'active':''} `} >{menu.icon}</div>
            <div className={`ELibIconName${mouseOver?'active':''}`} >{menu.iconName}</div>
        </div>
  ))}
    </div>
  )
}

export default ELibraryMenu