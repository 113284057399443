import React, { useEffect, useState } from 'react';
import '../css/EActsRules.css';
import { Alien, CaretDown, CaretUp, Gavel, MagnifyingGlass } from '@phosphor-icons/react';
import { useLocation, useNavigate } from 'react-router-dom';
import ELibraryMenu from '../components/ELibraryMenu';
import {useStateImages} from '../components/StateEmblem';
import {useStateMap} from '../components/StateEmblem';
import { useStateCount } from '../components/StateEmblem';
import { useRef } from 'react';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import edge from '../Images/edge.png'
import { CircularProgress } from '@mui/material';
function EActsRules() {
    
    const states = [
        { id: 1, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 2, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 3, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 4, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 5, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 6, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
        { id: 7, stateName: 'Karnataka', stateNumber: 'Number of acts - 23', stateUpdate: "Number of acts - 23" },
    ];
    const token='';
    const stateImages = useStateImages(token);
    const [stateList,setStateList]=useState([]);
    const [loading, setLoading] = useState(false);
    const getStateName=async()=>{
      setLoading(true)
        try{
            const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
            method:'GET',
            headers:{
                Authorization:`Bearer${token}`,
                "Content-Type":'application/json',
            }, 
            })
            if(response.ok){
            const data=await response.json();
            const sortedData = data.sort((a, b) => 
                a.state.toLowerCase().localeCompare(b.state.toLowerCase())
            );
            setStateList(sortedData)
            setLoading(false)
            }
        }
        catch{
        console.log("error");
        }
        }
    useEffect(()=>{
        getStateName()
    },[])
    const navigate=useNavigate();
    const handleNavigate=(id)=>{
        navigate(`/StateAct/${id}`)
    }

// ----------------- SEARCH -----------------------------//
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedState,setSelectedState]=useState(null)
    const [stateDropDown, setStateDropDown] = useState(false);
    const handleState = (state) => {
        setSelectedState(state);
        setSearchQuery(state.state); // Set the search bar value to the selected state's name
        setStateDropDown(false);
      };
    
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setSelectedState(null); // Reset selected state when typing
        setStateDropDown(true); // Show dropdown on input change
      };
      const filteredStates = stateList.filter((state) =>
        state.state.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const normalizeStateName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
      };
      //
      const stateMap = useStateMap(token);
      const [actCoubt,setActCount]=useState(null)
      const fetchAllActsData = async () => {
        setLoading(true)
        try {
          const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'acts'}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          });
          if (response.ok) {
            const data = await response.json();
            setLoading(false)
            return data;
          }
        } catch (error) {
          console.error('Failed to fetch acts data:', error);
        }
        return [];
      };
      
      useEffect(() => {
        if (hasFetchedData.current) return; // Skip if data has been fetched
      
        const fetchData = async () => {
          setLoading(true)
          if (filteredStates.length === 0) return; // Exit if there are no states
      
          const counts = {};
          const updates = {};
      
          const allData = await fetchAllActsData();
      
          for (const state of filteredStates) {
            const stateData = allData.filter(item => item.stateId === state.id);
            const stateCount = stateData.length;
            counts[state.id] = stateCount;
      
            const latestDate = stateData.reduce((latestDate, item) => {
              const itemDate = new Date(item.createDate);
              const latestDateObj = latestDate === 'NA' ? new Date('1970-01-01T00:00:00Z') : new Date(latestDate);
      
              if (isNaN(itemDate.getTime())) {
                console.error('Invalid date:', item.createDate);
                return latestDate;
              }
      
              return itemDate > latestDateObj ? item.createDate : latestDate;
            }, 'NA');
      
            updates[state.id] = latestDate !== 'NA' ? formatDate(latestDate) : 'NA';
          }
      
          setFilteredStateCounts(counts);
          setStateUpdates(updates);
          hasFetchedData.current = true;
          setLoading(false)
        };
      
        fetchData();
      }, [filteredStates]);
      
      const [filteredStateCounts, setFilteredStateCounts] = useState({});
const [stateUpdates, setStateUpdates] = useState({});
const hasFetchedData = useRef(false);
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date);
};


// useEffect(() => {
//   if (hasFetchedData.current) return; // Skip if data has been fetched

//   const fetchData = async () => {
//     if (filteredStates.length === 0) return; // Exit if there are no states

//     const counts = {};
//     const updates = {};

//     for (const state of filteredStates) {
//       const data = await fetchActCount(state.id);
//       if (data && data.length > 0) {
//         counts[state.id] = data.length;
//         const latestDate = data.reduce((latestDate, item) => {
//           const itemDate = new Date(item.createDate);
//           const latestDateObj = latestDate === 'NA' ? new Date('1970-01-01T00:00:00Z') : new Date(latestDate);

//           if (isNaN(itemDate.getTime())) {
//             console.error('Invalid date:', item.createDate);
//             return latestDate;
//           }

//           return itemDate > latestDateObj ? item.createDate : latestDate;
//         }, 'NA');

//         updates[state.id] = latestDate !== 'NA' ? formatDate(latestDate) : 'loading ...';
//       } else {
//         updates[state.id] = 'NA';
//       }
//     }

//     setFilteredStateCounts(counts);
//     setStateUpdates(updates);
//     hasFetchedData.current = true;
//   };

//   fetchData();
// }, [filteredStates]);

const handleOutsideClick = (e) => {
  if (e.target.closest('.EActs-search') === null) {
      setStateDropDown(false);
  }
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => document.removeEventListener('click', handleOutsideClick);
}, []);
  
      
    return (
        <div className='EActsandRulesTop'>
              <div className='EActsandRules'>
            <div className='EActsHead'>
                    <div className='EActsName'>
                        <Gavel className='EGavel' />
                        <div className='EActsNameFull'>Acts and Rules</div>
                    </div>
                    <div className='EActsDetail'>
                        Statutory compliance in India refers to the legal framework within which organizations must operate, in accordance with various statutes and regulations set forth by the government. This includes adherence to labor laws, other regulations that ensure businesses operate fairly, safely, and ethically. These Acts ensure that businesses in India comply with legal standards that protect the interests of employees, consumers, the environment, and other stakeholders. Non-compliance can result in legal penalties, fines, and damage to an organization's reputation.
                    </div>
                </div>
                <div className='EActs-state-search'>
                <div className='EActs-notif-head'>Search for States and Union Territories</div>
                <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>
                <div className="EActs-search" >
                        <input placeholder="Search for States and Union Territories" type="search" className="EActs-searchbar"  value={searchQuery}
                        onChange={handleSearchChange}
                        onClick={() => setStateDropDown(!stateDropDown)}
                        onFocus={() => setStateDropDown(true)}
                         />
                        {stateDropDown ? (
                            <CaretUp size={20} color="#4d4d4d" onClick={() => setStateDropDown(false)} />
                            ) : (
                            <CaretDown size={20} color="#4d4d4d" onClick={() => setStateDropDown(true)} />
                            )}
                          {stateDropDown && (
                      <div className="EAct-dropdown">
                        {filteredStates.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      }).map((state, index) => (
                          <div key={index} className="EActDropName" onClick={() => handleState(state)}>
                            {state.state}
                          </div>
                        ))}
                      </div>

                    )}  
                    </div> 
                    
              <div className='EActsContainer' onClick={() => setStateDropDown(!stateDropDown)} >
              {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
                  <div className='EActsData'>
                    
                  <div className='EActsData'>
                  {selectedState ? (
                  <div
                    className='EActsSingle'
                    style={{
                      backgroundImage: `url(${stateMap[selectedState.id]})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundColor: 'rgba(78, 175, 78, 0.15)',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: '24px 16px',
                      borderRadius: '8px',
                      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                      minWidth: '245px',
            maxHeight: '250px',
            justifyContent:'space-evenly',
            cursor:'pointer'
                    }}
                    key={selectedState.id}
                    onClick={() => handleNavigate(selectedState.id)}
                  >
                    <div className='EActsStateFlag'>
                    {/* {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "9999",
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )} */}
                      {stateImages[selectedState.id] ? (
                        
                        <img
                          src={stateImages[selectedState.id]}
                          alt={selectedState.state}
                          style={{ height: 'inherit', width: 'inherit' }}
                        />
                      ) : (
                        <span>No image available</span>
                      )}
                    </div>
                    <div className='EActStateNames'>{selectedState.state}</div>
                    <div className='EActsStateNumber'>Number of acts - {filteredStateCounts[selectedState.id] || 'NA'}</div>
                    <div className='EActsUpdate'>Recent upload: {stateUpdates[selectedState.id] || 'N/A'}</div>
                  </div>
                  ) : (
                  filteredStates.sort((a, b) => {
                    if (a.state === "Central") return -1; 
                    if (b.state === "Central") return 1;
                    return a.state.localeCompare(b.state); 
                  }).map((state) => {
                    const stateCount = filteredStateCounts[state.id];
                    const stateNumber = stateCount ? stateCount : 'NA';
                    const uploadDate = stateUpdates[state.id] || 'N/A';

                    return (
                      
                      <div
                        className='EActsSingle'
                        style={{
                          backgroundImage: `url(${stateMap[state.id]})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundColor: 'rgba(78, 175, 78, 0.15)',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                          padding: '24px 16px',
                          borderRadius: '8px',
                          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                          minWidth: '245px',
            maxHeight: '250px',
            justifyContent:'space-evenly'
                        }}
                        key={state.id}
                        onClick={() => handleNavigate(state.id)}
                      >
                        <div className='EActsStateFlag'>
                          {stateImages[state.id] ? (
                            <img
                              src={stateImages[state.id]}
                              alt={state.state}
                              style={{ height: 'inherit', width: 'inherit' }}
                            />
                          ) : (
                            <span>No image available</span>
                          )}
                        </div>
                        {/* {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "9999",
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )} */}
                        <div className='EActStateNames'>{state.state}</div>
                        <div className='EActsStateNumber'>Number of acts - {stateNumber}</div>
                        <div className='EActsUpdate'>Recent upload: {uploadDate}</div>
                      </div>
                    );
                  })
                  )}

                  </div>

                  </div>
              </div>  
                </div>
                 
<div>
       
                    </div>
                
            </div>
        </div>
        </div>
      
    );
}

export default EActsRules;

 