import React, { useRef, useState, useEffect, useContext } from "react";
import AdminMenu from "../components/AdminMenu";
import "../css/AdminActs.css";
import {
  CaretDown,
  CloudArrowUp,
  FileArrowDown,
  FilePlus,
  Info,
  MagnifyingGlass,
  Paperclip,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toast.css";
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { useNavigate } from "react-router-dom";
import { TestContext } from "../components/TestContext";
import { CircularProgress } from "@mui/material";

function AdminActs() {
  const [loading, setLoading] = useState(false);
  const { isLogin } = useContext(TestContext);
  const navigate = useNavigate();
  console.log("Test");
  useEffect(() => {
    console.log(isLogin);
    if (!isLogin) {
      navigate("/AdminLogin");
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //---------------------------------GET ALL ACTS------------------------------//
  const [FileData, setfileData] = useState([]);
  const getAllActs = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${notificationApiUrl}/api/GetFileByStateId?FolderName=${"acts"}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        console.log(data);
        setfileData(data);
      }
    } catch {
      console.log("Error");
    }
  };
  const [StateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [StateUpload, setStateUpload] = useState(false);

  const [selectedStates, setSelectedStates] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState(FileData);
  const [stateList, setStateList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  // ------------------------------------Filtering-------------------------------------------//
  const sortOptions = ["Today", "Recent Upload", "All Uploads"];
  const [selectedSortOption, setSelectedSortOption] = useState("All Uploads");
  const [SortDropdownOpen, setSortDropdownOpen] = useState(false);

  const sortFiles = (files, sortOption) => {
    const today = new Date();
    const todayString = today.toDateString();
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(today.getDate() - 3);
    const threeDaysAgoString = threeDaysAgo.toDateString();

    switch (sortOption) {
      case "Recent Upload":
        return [...files]
          .filter((file) => {
            const fileDate = new Date(file.createDate).toDateString();
            return fileDate >= threeDaysAgoString;
          })
          .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

      case "Today":
        return [...files].filter((file) => {
          const fileDate = new Date(file.createDate).toDateString();
          return fileDate === todayString;
        });

      case "All Uploads":
      default:
        return [...files];
    }
  };

  useEffect(() => {
    const filterFiles = () => {
      let filtered = FileData;

      if (selectedStates.length > 0) {
        filtered = filtered.filter(
          (file) => file.stateId && selectedStates.includes(file.stateId)
        );
      }
      if (searchQuery) {
        filtered = filtered.filter((file) =>
          file.fileTitle.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      setFilteredFiles(filtered);
    };

    filterFiles();
  }, [selectedStates, searchQuery, FileData]);

  const handleStateFilterClick = (stateId) => {
    setSelectedStates((prev) =>
      prev.includes(stateId)
        ? prev.filter((s) => s !== stateId)
        : [...prev, stateId]
    );
  };

  const sortedFiles = sortFiles(filteredFiles, selectedSortOption);

  const toggleSortDropdown = () => {
    setSortDropdownOpen((prev) => !prev);
    setStateDropdownOpen(false);
  };

  const handleSortOptionClick = (option) => {
    setSelectedSortOption(option);
    setSortDropdownOpen(true);
  };
  const toggleStateDropdown = () => {
    setStateDropdownOpen((prev) => !prev);
    setSortDropdownOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOutsideClick = (e) => {
    if (
      !e.target.closest(".upload-statefilter") &&
      !e.target.closest(".dropdown-menu-act")
    ) {
      setStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [selectAll, setSelectAll] = useState(false);


  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
  
    if (!selectAll) {
      // Filter the files based on the selected states
      const filteredFiles = FileData.filter((file) => 
        selectedStates.length === 0 || selectedStates.includes(file.stateId)
      );
      // Select only filtered files
      setSelectedFiles(filteredFiles.map((file) => file.fileName));
    } else {
      setSelectedFiles([]); // Deselect all if already selected
    }
  };
  

  const downloadSelectedFiles = async () => {
    const filesToDownload = FileData.filter((file) => 
      selectedFiles.includes(file.fileName) && 
      (selectedStates.length === 0 || selectedStates.includes(file.stateId))
    );
  
    for (let file of filesToDownload) {
      await handleFileDownload(file);
    }
  };

  
  const deleteSelectedFiles = async () => {
    const filesToDelete = FileData.filter((file) =>
      selectedFiles.includes(file.fileName) &&
      (selectedStates.length === 0 || selectedStates.includes(file.stateId))
    );
  
    if (filesToDelete.length === 0) {
      toast.error("No files selected for deletion.");
      return;
    }
  
    for (let file of filesToDelete) {
      await handleDeleteSelected(file);
    }
  
    // Display success toast message after deletion
    toast.success("File(s) deleted successfully!");
  
    // Refresh the file list after deletion
    getAllActs();
  
    // Remove the deleted files from selectedFiles
    const remainingSelectedFiles = selectedFiles.filter(
      (fileName) => !filesToDelete.some((file) => file.fileName === fileName)
    );
    
    setSelectedFiles(remainingSelectedFiles);
  };
  
  

  const handleFileSelect = (fileName) => {
    setSelectedFiles((prev) =>
      prev.includes(fileName)
        ? prev.filter((name) => name !== fileName)
        : [...prev, fileName]
    );
  };

  const handleFileDownload = async (file) => {
    console.log(file);
    setLoading(true);
    try {
      const response = await fetch(
        `${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${"acts"}&FileName=${
          file.fileName
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "text/plain",
          },
        }
      );

      if (response.ok) {
        const textResponse = await response.text();
        const fileUrl = textResponse.replace("Url=", "");

        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } else {
        console.error(
          "Failed to fetch the file URL:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };




  // -------------------------------------------  UPLOAD POPUP -----------------------------------//
  const [stateName, setStateName] = useState([]);
  const [stateChoose, setStateChoose] = useState([]);
  const token = "";
  const [popOpen, setPopOpen] = useState(false);

  const handlePop = () => {
    setPopOpen(true);
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const [file, setFile] = useState("");

  const toggleStateUpload = () => {
    setStateUpload((prev) => !prev);
  };

  // console.log(stateChoose);
  //------------- GET STATE NAME ------------------------//
  const getStateName = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: "GET",
        headers: {
          Authorization: `Bearer${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setStateList(data);
        setLoading(false);
      }
    } catch {
      console.log("error");
    }
  };
  useEffect(() => {
    getStateName();
    getAllActs();
  }, []);

  //  ---------------- UPLOAD FILE ---------------- //
  const [fileName, setFileName] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const fullName = file.name;
        const baseName = fullName.substring(0, fullName.lastIndexOf(".")) || fullName;
        
        // Check for special characters & and $
        const specialCharPattern = /[&$₹/^+*%!@#]/;
        if (specialCharPattern.test(fullName)) {
            console.log("Error: File name should not contain & or $ characters.");
            toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
            return;
        }
        
        setFileName(baseName);

        if (file.type === "application/pdf") {
            console.log("Uploaded file:", file);
        } else {
            console.log("Error: Only PDF files are allowed.");
        }
    }
    setFile(file.name);
};
  console.log(file.name);


 

  
  // const createFormData = (file, id) => {
  //   console.log(file);

  //   const formData = new FormData();

  //   const fileNameWithoutExtension = file.name
  //     .split(".")
  //     .slice(0, -1)
  //     .join(".");
  //   const fileExtension = file.name.split(".").pop();
  //   const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
  //   console.log(file);
  //   formData.append("Attachment", file);
  //   formData.append("FileNameWithExtension", uniqueFileName);
  //   formData.append("Folder", "acts");
  //   formData.append("State", id);
  //   formData.append("RequestedBy", "admin");
  //   formData.append("Title", fileName || "");

  //   formData.append("IsImportant", false);
  //   formData.append("FileDiscription", "");
  //   formData.append("AdditionalLink", additionalLink || "");
  //   formData.append("Type", 1);
  //   formData.append("EffectiveDate", "0001-01-01T00:00:00");
  //   formData.append("ReleasedDate", "0001-01-01T00:00:00");
  //   return formData;
  // };

 
  
  // const uploadFile = async (file, id) => {
  //   setLoading(true);
  //   try {
  //     console.log("Entered");
  //     const formData = createFormData(file, id);
  //     const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formData,
  //     });
  //     if (response.ok) {
  //       console.log(response);
  //       console.log("Success");
  //       toast.success("File uploaded successfully!");
  //       setLoading(false);
  //       setPopOpen(!popOpen);
  //       getAllActs();
  //       setFile("");
  //       setFileName("");
  //       setStateName([]);
  //       setStateChoose([]);
  //     }
  //   } catch {
  //     console.log("Error");
  //   }
  // };

 
  // const handleUploadForAll = () => {
  //   const file = fileInputRef.current.files[0];
  //   if (!file) {
  //     console.error("No file selected");
  //     return;
  //   }
  //   if (!stateChoose.length) {
  //     toast.error("No state selected. Please select a state before uploading.");
  //     return;
  //   }
  
  //   console.log("Selected states:", stateChoose);
  //   console.log("File name:", fileName);
  
  //   stateChoose.forEach((id, index) => {
  //     setTimeout(() => {
  //       uploadFile(file, id, fileName);  // Pass fileName here
  //     }, index * 2000);
  //   });
  // };
 

  const [additionalLink, setAdditionalLink] = useState("");
  const createFormData = (file, id, updatedFileName) => {
    const formData = new FormData();
    const timestamp = new Date().getTime().toString().slice(-4);
    const fileNameWithoutExtension = updatedFileName || file.name.split(".").slice(0, -1).join(".");
    const fileExtension = file.name.split(".").pop();
    const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
  
    formData.append("Attachment", file);
    formData.append("FileNameWithExtension", uniqueFileName);
    formData.append("Folder", "acts");
    formData.append("State", id);
    formData.append("RequestedBy", "admin");
    formData.append("Title", updatedFileName || ""); // Use updatedFileName for Title
    formData.append("IsImportant", false);
    formData.append("FileDiscription", "");
    formData.append("AdditionalLink", additionalLink || "");
    formData.append("Type", 1);
    formData.append("EffectiveDate", "0001-01-01T00:00:00");
    formData.append("ReleasedDate", "0001-01-01T00:00:00");
  
    return formData;
  };

  const uploadFile = async (file, id, updatedFileName) => {
    setLoading(true);
    try {
      console.log("Entered");
      const formData = createFormData(file, id, updatedFileName);  // Pass updatedFileName
      const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        console.log(response);
        console.log("Success");
        toast.success("File uploaded successfully!");
        setLoading(false);
        setPopOpen(!popOpen);
        getAllActs();
        setFile("");
        setFileName("");
        setStateName([]);
        setStateChoose([]);
      }
    } catch {
      console.log("Error");
    }
  };
  

  const handleUploadForAll = () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    if (!stateChoose.length) {
      toast.error("No state selected. Please select a state before uploading.");
      return;
    }
  
    console.log("Selected states:", stateChoose);
    console.log("File name:", fileName);
  
    stateChoose.forEach((id, index) => {
      setTimeout(() => {
        uploadFile(file, id, fileName);  // Pass fileName here
      }, index * 2000);
    });
  };
  
  

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleStateClick = (state, id) => {
    setStateName((prev) =>
      prev.includes(state) ? prev.filter((s) => s !== state) : [...prev, state]
    );
    setStateChoose((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };



  const getTimeOnly = (dateString) => {
    const date = new Date(dateString);
    const indiaTime = new Date(date.getTime() + (5.5 * 60 * 60 * 1000));
    const hours = String(indiaTime.getHours()).padStart(2, '0');
    const minutes = String(indiaTime.getMinutes()).padStart(2, '0');
    const seconds = String(indiaTime.getSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  };

  // const getTimeOnly = (dateString) => {
  //   const date = new Date(dateString);
    
  //   // Convert the date to milliseconds and adjust for IST (UTC + 5.5 hours)
  //   const indiaTime = new Date(date.getTime() + (5.5 * 60 * 60 * 1000));
  
  //   // Format the time in 12-hour HH:MM:SS format
  //   let hours = indiaTime.getHours();
  //   const minutes = String(indiaTime.getMinutes()).padStart(2, '0');
  //   const seconds = String(indiaTime.getSeconds()).padStart(2, '0');
    
  //   // Convert to 12-hour format
  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12 || 12; // Convert hour '0' to '12' for 12 AM/PM
    
  //   return `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  // };
  
  


  const getTodayUploadCount = (data) => {
    const today = new Date().toISOString().split("T")[0];
    return data.filter((item) => item.createDate.split("T")[0] === today)
      .length;
  };
  const normalizeStateName = (name) => {
    return name.toLowerCase().replace(/\s+/g, "");
  };

  const [fileData, setFileData] = useState(FileData);

  // const handleDeleteSingle = async (fileName) => {
  //   try {
  //     const response = await fetch(
  //       `${holidayApiUrl}/api/DeleteFileAndData?Folder=${"acts"}&FileName=${fileName}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       console.error(
  //         `Failed to delete file with FileName ${fileName}:`,
  //         response.statusText
  //       );
  //     } else {
  //       console.log(`File with FileName ${fileName} deleted successfully.`);
  //       getAllActs();

  //       setFileData((prev) =>
  //         prev.filter((file) => file.fileName !== fileName)
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error deleting file:", error);
  //   }
  // };

  const handleDeleteSingle = async (fileName) => {
    console.log(fileName);
    
    try {
      const response = await fetch(
        `${holidayApiUrl}/api/DeleteFileAndData?Folder=${"acts"}&FileName=${fileName}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (!response.ok) {
        console.error(
          `Failed to delete file with FileName ${fileName}:`,
          response.statusText
        );
        toast.error(`Failed to delete file: ${fileName}`);
      } else {
        console.log(`File with FileName ${fileName} deleted successfully.`);
        toast.success(`File deleted successfully: ${fileName}`);
        
        // Refresh the list after deletion
        getAllActs();
  
        // Update state to remove the deleted file from the list
        setFileData((prev) =>
          prev.filter((file) => file.fileName !== fileName)
        );
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Error deleting file.");
    }
  };
  

  const handleDeleteSelected = async (file) => {
    try {
      const response = await fetch(
        `${holidayApiUrl}/api/DeleteFileAndData?Folder=${"acts"}&FileName=${
          file.fileName
        }`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to delete file with FileName ${fileName}:`,
          response.statusText
        );
      } else {
        console.log(`File with FileName ${fileName} deleted successfully.`);
      }

      setSelectedFiles([]);
      setSelectAll(false);

      console.log("Deleted selected files.");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const [showMessage, setShowMessage] = useState(false);

  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };

  const messageRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageRef.current && !messageRef.current.contains(event.target)) {
        setShowMessage(false);
      }
    };

    if (showMessage) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMessage]);


  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (SortDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [SortDropdownOpen]);


  

  return (
    <div className="AdminActs">
      {/* <AdminMenu className="AdminMenu" /> */}
      <div className="admin-width" >
        <div className="Admin-act">
          <ToastContainer />
          <div className="Act-detail">
            <div className="Act-detail1">
              <div className="Act-detail1-name">Act</div>


              <div className="Actsinfo-design">
              <div className="Act-detail1-upload" onClick={handlePop}>
                <FilePlus size={24} color="#009746" /> Upload File
              </div>

              <div style={{ position: "relative", display: "inline-block" }}>
              <Info size={22} color="#009746" style={{cursor:'pointer'}} onClick={toggleMessage} />

              {showMessage && (
        <div className="info-pdf" ref={messageRef} >
          Upload .pdf or .xlsx Files
        </div>
      )}
              </div>

              </div>
           
            </div>
            <div className="Act-search">
              <MagnifyingGlass size={20} color="#4d4d4d" />
              <input
                placeholder="Search for Acts"
                type="search"
                className="Act-searchbar"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="Act-data">
            <div className="ActData-countDetail">
              <div className="uploadedCount">
                {getTodayUploadCount(FileData)} Uploads
                <span className="Upload-count">Today</span>
              </div>
              <div className="Upload-Filter">
                <div
                  className="upload-statefilter"
                  onClick={toggleStateDropdown}
                >
                  State <CaretDown size={20} color="#4d4d4d" />
                  {StateDropdownOpen && (
                    <div className="dropdown-menu">
                      {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((option, index) => (
                        <div className="dropdown-item" key={option.id}>
                          <div className="dropdown-item"  onClick={() => handleStateFilterClick(option.id)}>

                          <input
                            type="checkbox"
                            className="dropdown-item-check"
                            checked={selectedStates.includes(option.id)}
                            onChange={() => handleStateFilterClick(option.id)}
                          />
                          <div className="state-option"  >  {option.state} </div>
                         </div>
                        </div>

                      ))}
                    </div>
                  )}
                </div>

                <div className="upload-sortfilter" onClick={toggleSortDropdown} ref={dropdownRef}>
                  Sort By <CaretDown size={20} color="#4d4d4d" />
                  {SortDropdownOpen && (
                    <div className="dropdown-menu">
                      {sortOptions.map((option, index) => (
                        <div
                          className="dropdown-item"
                          key={index}
                          onClick={() => handleSortOptionClick(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ActData-FilteringDetail">
              <div className="Upload-select">
                <input
                  type="checkbox"
                  className="Upload-select-checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Select All
              </div>
              <div className="Upload-delete-download">
                <Trash
                  size={20}
                  color="#898989"
                  onClick={deleteSelectedFiles}
                  style={{cursor:"pointer"}}
                />
                <span
                  className="UploadDownload"
                  onClick={downloadSelectedFiles}
                >
                  <FileArrowDown size={20} color="#898989"  style={{cursor:"pointer"}} /> Download file
                </span>
              </div>
            </div>
            <div className="ActData-Detail">
              {sortedFiles.map((data) => {
                const state = stateList.find(
                  (state) => state.id === data.stateId
                );
                return (
                  <div key={data.id} className="Act-InfoAll">
                    <input
                      type="checkbox"
                      className="Upload-select-checkbox"
                      checked={selectedFiles.includes(data.fileName)}
                      onChange={() => handleFileSelect(data.fileName)}
                    />
                    <div className="Act-FileInfo">
                      <div className="Act-FileData">
                        <div className="Act-FileName">{data.fileTitle}</div>
                        <div className="add-trash">
                          <Trash
                            size={20}
                            color="#898989"
                            onClick={() => {
                              handleDeleteSingle(data.fileName);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <div
                            className="Act-FileDownload"
                            onClick={() => {
                              handleFileDownload(data);
                            }}
                          >
                            <FileArrowDown size={20} color="#898989"  style={{cursor:"pointer"}} />{" "}
                            
                          </div>
                        </div>
                      </div>
                      <div className="Act-File-Detail">
                        <div className="Act-StateDate">
                          <div className="Act-file-state">
                            Applicable states - {state ? state.state : ""}
                          </div>
                          <div className="Act-file-date">
                            Updated as on {formatDate(data.createDate)}
                          </div>
                        </div>
                        <div className="Act-timeRecent">
                          <div style={{ display: "flex", gap: "16px" }}>
                            <div className="Act-recent"></div>
                            <div className="Act-time">
                              Time {getTimeOnly(data.createDate)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {popOpen && <div className="Acts-overlay"></div>}
          {popOpen && (
            <div className="Act-popup">
              <div className="Act-pop-Uplaod">
                <span>Upload</span>
                <XCircle
                  size={20}
                  onClick={() => {
                    setPopOpen(false);
                    setStateName("");
                    setFileName("");
                    setStateName("");
                    setFile("");
                  }}
                />
              </div>
              <div className="Act-pop-UplaodFile" onClick={handleUploadClick}>
                <CloudArrowUp size={60} color="#a8b8b9" weight="bold" />
                {!file ? (
                  <div>Uploaded file of 10MB limit</div>
                ) : (
                  <div>File Selected Successfully</div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </div>
              <div className="Act-pop-FileName">
            
                 <input
                placeholder="File Name"
                className="Act-pop-input"
                value={fileName}
                onChange={handleFileNameChange} 
              />
                <div className="Act-pop-state" onClick={toggleStateUpload}>
                  {stateName.length === 0
                    ? "Select Applicable State"
                    : stateName.join(", ")}
                  <CaretDown size={20} color="#4d4d4d" />
                  {StateUpload && (
                    <div className="dropdown-menu-act">
                      {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((option, index) => (

                        <div
                          className="dropdown-item-act"
                          key={index}
                          onClick={() => {
                            handleStateClick(option.state, option.id);
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={stateName.includes(option.state)}
                            onClick={() => {
                              handleStateClick(option.state, option.id);
                            }}
                          />
                          {option.state}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="Act-pop-link">
                Add links <Paperclip size={20} color="#6c625b" weight="bold" />
                <input
                  className="act-pop-url"
                  onChange={(e) => setAdditionalLink(e.target.value)}
                />
              </div>
              <div className="Act-pop-mark">
                <div className="Act-pop-upload" onClick={handleUploadForAll}>
                  Upload
                </div>
              </div>
            </div>
          )}







        </div>
        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "9999",
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminActs;
