import React, { useEffect, useState } from 'react';
import '../css/Hero.css';
import Slider from "react-slick";
import { ArrowLeft, ArrowRight, CaretLeft, CaretRight } from '@phosphor-icons/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { replace } from 'formik';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { useNavigate } from 'react-router-dom';
const CustomNextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <CaretRight size={30} />
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <CaretLeft size={30} />
  </div>
);

function Hero() {
  const slides = [
    {
      heading: "Heading 1",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the."
    },
    {
      heading: "Heading 2",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the."
    },
    {
      heading: "Heading 3",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the."
    },
    {
      heading: "Heading 4",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the."
    }
  ];

  // var settingsForHighlights = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   autoplay:true,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 380,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '0px',
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '5px',
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '60px',
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         centerMode: true,
  //         centerPadding: '110px',
  //       },
  //     },
  //     {
  //       breakpoint: 1054,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         centerMode: true,
  //         centerPadding: '80px',
  //       },
  //     },
  //   ],
  //   nextArrow: <CustomNextArrow />,
  //   prevArrow: <CustomPrevArrow />,
  // };
  

  var settingsForHighlights = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, 
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode for mobile view
          centerPadding: '0px', // No padding to ensure only one slide
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode for this view
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode
          centerPadding: '0px', // Adjust padding for full view of a single slide
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true, // Enable for larger screens if necessary
          centerPadding: '80px', // Adjust as needed for desktop view
        },
      },
    ],
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  
  

  
  const [notifi,setNotifi]=useState([])
  const token=''
 const notification = async()=>{
  try{
    const response=await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'notification'}`,{
      method:'GET',
      headers:{
        Authorization:`Bearer ${token}`,
        "Content-Type":'application/json'
      }
    })
    if(response.ok){
      const data=await response.json();
      const sortedData = data.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
      console.log(sortedData);
      
      setNotifi(sortedData);
      
      
    }
  }
  catch{
    console.log("error");
    
  }
 }
 
 
 useEffect(()=>{
  notification()
 },[])
const [url,setUrl]=useState(null)
const getFileUrl = async (fileName) => {
  try {
    const encodedFileName = encodeURIComponent(fileName);
    const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=notification&FileName=${encodedFileName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'text/plain',
      },
    });

    if (response.ok) {
      const text = await response.text(); // Read response as text
      const cleanUrl = text.replace(/^Url=/, ''); // Remove the 'Url=' prefix
      return cleanUrl;
    } else {
      console.error('Failed to fetch file URL:', response.status, response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching file URL:', error);
    return null;
  }
};

const handleReadMore = async (fileName) => {
  const url = await getFileUrl(fileName);
  if (url) {
    console.log('Downloading file from URL:', url); // Log the URL to verify
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // Suggest a file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error('No URL returned or URL is invalid.');
  }
};

const truncateText = (text, wordLimit) => {
  console.log(text);
  if (!text) return '';
  const sanitizedText = text.replace(/[_-]/g, ' ');
  const words = sanitizedText.split(' ');
  return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
};
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
useEffect(() => {
  const handleResize = () => setWindowWidth(window.innerWidth);
  
  window.addEventListener('resize', handleResize);

  // Cleanup event listener on component unmount
  return () => window.removeEventListener('resize', handleResize);
}, []);
const navigate=useNavigate();
const handleNavigate=(id)=>{
  console.log(id);
  navigate(`/StateLabour/${id}`)
}

  return (
    <div className='Hero'>
      <div className='hero-container'>
        <div className='hero-text'>  
          {/* <div className='hero-inverted'>“</div> */}
          <div className='hero-content-cont'>Talent Traverse </div>
          <div className='hero-content'> Your Trusted Partner in Compliance and Payroll Excellence</div>
          
         
        </div>
        <div className='hero-slider'>
          <div className='hero-slider-content'>Important Highlights !</div>
          <div className='hero-slider-component'>
          <Slider {...settingsForHighlights}>
          {notifi.map((slide, index) => (
  slide.important && (
    <div className='slider-content' key={index}>
      {windowWidth < 450 ? (
        <>
          <div className='slider-data' onClick={() => handleNavigate(slide.stateId)}>
            {truncateText(slide.fileTitle, 2)}
          </div>
          <div className='slider-content-main' onClick={() => handleNavigate(slide.stateId)}>
            {truncateText(slide.fileDescription, 6)}
          </div>
        </>
      ) :  windowWidth < 700 ? (
        <>
        <div className='slider-data' onClick={() => handleNavigate(slide.stateId)}>
          {truncateText(slide.fileTitle, 3)}
        </div>
        <div className='slider-content-main' onClick={() => handleNavigate(slide.stateId)}>
          {truncateText(slide.fileDescription, 8)}
        </div>
      </>
      ): windowWidth < 1025 ? (
        <>
          <div className='slider-data' onClick={() => handleNavigate(slide.stateId)}>
            {truncateText(slide.fileTitle, 5)}
          </div>
          <div className='slider-content-main' onClick={() => handleNavigate(slide.stateId)}>
            {truncateText(slide.fileDescription, 20)}
          </div>
        </>
      ) : (
        <>
          <div className='slider-data' onClick={() => handleNavigate(slide.stateId)}>
            {slide.fileTitle}
          </div>
          <div className='slider-content-main' onClick={() => handleNavigate(slide.stateId)}>
            {slide.fileDescription}
          </div>
        </>
      )}
      <div className='slider-read' onClick={() => handleReadMore(slide.fileName)}>Download</div>
    </div>
  )
))}




          </Slider>
          </div>
        </div>
      </div>
      <div className='hero-quote'>
            <div className='open-comma12'>“</div>
            <div className='quote-content'>With 25+ years of expertise, we offer reliable compliance services, tech-driven payroll solutions, and sustainable EHS advisory. Our industry knowledge keeps your business compliant, payroll accurate, and operations environmentally responsible. We tailor our services to your unique needs, streamlining processes, reducing errors, and saving you time, so you can focus on growing your business.</div>
            {/* <div className='close-comma'>“</div> */}
      </div>
    </div>
  );
}

export default Hero;
