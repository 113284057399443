import React ,{useState, useRef, useEffect, useContext} from "react";
import '../css/AdminWages.css'
import AdminMenu from "../components/AdminMenu";
import { CaretDown, CloudArrowUp, FileArrowDown, FilePlus, MagnifyingGlass, Paperclip, Trash, XCircle } from "@phosphor-icons/react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';import '../css/toast.css'; 
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { TestContext } from "../components/TestContext";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
function AdminWages(){
    const [stateDropDown, setStateDropDown]=useState(false);
    const [StateName, setStateName]=useState('');
    const [StateId,setStateId]=useState(0);
    const [stateList,setStateList]=useState([])
    const token=''
    const params=useParams();
    const {isLogin} = useContext(TestContext);
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false)
    useEffect(()=>{    
        console.log(isLogin);    
        if(!isLogin){
            navigate('/AdminLogin')
            }   
         },[])
         useEffect(()=>{
            window.scrollTo(0,0);
          },[]) 
    const toggleStateDropdown=() => {
        setStateDropDown(!stateDropDown);
        console.log("True");
      };
    const handleStateClick=(name,id)=>{
        setStateName(name);
        setStateId(id)
        setStateDropDown(false);
        getWage(id);
        console.log(name);
    }
    const [minWage,setMinWage]=useState([]);
    const [head,setHead]=useState([]);
    const getWage=async(StateId)=>{
        console.log("Wage entered");
        setLoading(true)
        if (StateId !== 0 && StateId!==undefined && StateId!==null){
        try{
            const response=await fetch(`${holidayApiUrl}/api/GetminWagesByStateId?StateId=${StateId}`,{
                method:'GET',
                headers:{
                  Authorization:`Bearer${token}`,
                  "Content-Type":'application/json',
                }, 
              })
              console.log(response);
              if(response.ok){
                const data=await response.json();
                // console.log(data);

                setMinWage(data)
                if (!data || data.length === 0) return null;
                setHead( Object.keys(data[0]))
                setLoading(false)
              }
              else{
                setLoading(false)
              }

        }        catch{

        }}

    } 
        const getStateName=async()=>{
            setLoading(true)
            try{
              const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
                method:'GET',
                headers:{
                  Authorization:`Bearer${token}`,
                  "Content-Type":'application/json',
                }, 
              })
              console.log(response);
              if(response.ok){
                const data=await response.json();
                // console.log(data);
                setLoading(false)
                setStateList(data)
              }
            }
            catch{
        console.log("error");
            }
         }
         useEffect(()=>{
          getStateName();
         },[])
        
    console.log(StateName);
    const [popOpen, setPopOpen]=useState(false);
    const [fileName,setFileName]=useState('');
    const [additionalLink,setAdditionalLink]=useState('');
    const fileInputRef = useRef(null);
    const handlePop=()=>{
    setPopOpen(true);
    }
    const handleUploadClick = () => {
        fileInputRef.current.click();
        };
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0]; 
    //     if (file) {
    //         if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //             console.log('Uploaded file:', file);
               
    //           } else {
    //             console.log('Error', file);
    //           }
    //         const fullName = file.name;
    //         const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
    //         setFileName(baseName);
    //     }
    //   };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
     
        const allowedExtension = /(\.xlsx)$/i;
        
        if (file) {
            
            if (
                (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
                allowedExtension.test(file.name)
            ) {
                console.log('Uploaded file:', file);
            } else {
                console.log('Error: Invalid file type or extension', file);
                // alert('Please upload a valid Excel (.xlsx) file.');
                toast.error('Please upload a valid Excel (.xlsx) file.');
                event.target.value = '';  
                setFileName('');  
                return; 
            }
    
            
            const fullName = file.name;
            const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
            setFileName(baseName);  
        }
    };
    
    
    const createFormData = () => {
        
        const formData = new FormData();
        const file = fileInputRef.current.files[0];
        const timestamp = new Date().getTime().toString().slice(-4);
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;

        console.log(file);
        formData.append('Attachment', file || '');
        formData.append('FileNameWithExtension', uniqueFileName);
        formData.append('Folder', 'MinWages');
        formData.append('state', StateId);
        formData.append('RequestedBy', 'admin'); 
        formData.append('Title', fileName || '');
        formData.append('IsImportant', false);
        formData.append('AdditionalLink', additionalLink || '');
        formData.append('Type', 3);
        formData.append('FileDiscription', '');
        formData.append('EffectiveDate', effectiveDate||'0001-01-01T00:00:00');
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        return formData;
      };
    const uploadFile=async()=>{
        setLoading(true)
        try{
            const formData = createFormData();
            const response= await fetch(`${notificationApiUrl}/api/Uploadmedia`,{
                method:'POST',
                headers:{
                    Authorization: `Bearer ${token}`
                },
                body:formData,
            })
            setLoading(false)
            getWage(StateId);
            setPopOpen(false);
            toast.success("File uploaded successfully!");
            setFileName('');
           
        }
        catch{
            console.error("Error!!");
        }
    }

    const [showEffectiveDatePicker, setShowEffectiveDatePicker] = useState(false);
    const [showReleaseDatePicker, setShowReleaseDatePicker] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState('');
    // const [releaseDate, setReleaseDate] = useState(null);
    
    const handleDateClick = () => {
        setShowEffectiveDatePicker(!showEffectiveDatePicker);
     
    };
    const formatUploadDate = (date) => {
        console.log(date);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
      
      const handleDateSelect = (date) => {
        const selectedDate = new Date(date);
        const formattedDate = formatUploadDate(selectedDate); 
      setShowEffectiveDatePicker(false)
      console.log(formattedDate,'FormatedDate');
      
          setEffectiveDate(formattedDate);
         
      };
    const [popFileOpen, setPopFileOpen]=useState(false);
    const [fileNamePdf,setFileNamePdf]=useState('');
    const pdffileInputRef = useRef('');
    const handleFilePop=()=>{
    setPopFileOpen(true);
    }
    const handleFileUploadClick = () => {
        pdffileInputRef.current.click();
    };
   
    const handleFilePdfChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {

            if (file.type === 'application/pdf') { // Correct MIME type check for PDF
                console.log('Uploaded file:', file);

                // Extracting the base name of the file
                const fullName = file.name;
                const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
                const specialCharPattern = /[&$₹/^+*%!@#]/;
                if (specialCharPattern.test(fullName)) {
                    console.log("Error: File name should not contain & or $ characters.");
                    toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
                    return;
                }
                setFileNamePdf(baseName);
            } else {
                console.log('Error: Not a PDF file');
            }
        }
    };
    
    const createFormDataPdf = () => {
        
        const formData = new FormData();
        const file = pdffileInputRef.current.files[0];
        const timestamp = new Date().getTime().toString().slice(-4);
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;

        console.log(file);
        formData.append('Attachment', file || '');
        formData.append('FileNameWithExtension', uniqueFileName);
        formData.append('Folder', 'MinWagesPdf');
        formData.append('state', StateId);
        formData.append('RequestedBy', 'admin'); 
        formData.append('Title', fileNamePdf || '');
        formData.append('IsImportant', false);
        formData.append('AdditionalLink', '');
        formData.append('Type', 1);
        formData.append('FileDiscription', '');
        formData.append('EffectiveDate', '0001-01-01T00:00:00');
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        return formData;
      };
    const uploadFilePdf=async()=>{
        setLoading(true)
        try{
            const formData = createFormDataPdf();
            const response= await fetch(`${notificationApiUrl}/api/Uploadmedia`,{
                method:'POST',
                headers:{
                    Authorization: `Bearer ${token}`
                },
                body:formData,
            })
            setLoading(false)
            getPdfFile(StateId);
            setPopFileOpen(false);
            toast.success("File uploaded successfully!");
            setFileNamePdf('');
           
        }
        catch{
            console.error("Error!!");
        }
    }
const [pdfdata,setPdfData]=useState(null)
    const getPdfFile=async(id)=>{
        try{
            const response= await fetch(`${notificationApiUrl}/api/GetFileByStateId?Foldername=${'MinWagesPdf'}&StateId=${id}`,{
                method:'GET',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            if(response.ok){
                const data=await response.json();
                const filteredData = data.filter(item => item.fileName.endsWith('.pdf'));
                setPdfData(filteredData);
            }
        }
        catch{

        }
    }
    console.log(pdfdata);
    const handleFileDownload = async (fileName) => {
        console.log(fileName);
        
        try {
          const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'MinWagesPdf'}&FileName=${fileName}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'text/plain'
            }
          });
      
          if (response.ok) {
            const textResponse = await response.text();
            const fileUrl = textResponse.replace("Url=", "");
      
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error("Failed to fetch the file URL:", response.status, response.statusText);
          }
        } catch (error) {
          console.error("Error occurred while downloading the file:", error);
        }
      };
      useEffect(()=>{
  getPdfFile(StateId);
      },[StateId])
    
    // ---- GET DATA -------

    console.log(head);   
    useEffect(()=>{
        setMinWage([]);
        setHead([]);
        getWage(StateId)
    },[StateId])
    // const handleDelete=async(fileName)=>{
    //     setLoading(true)
    //     try{
    //         const response = await fetch(`${holidayApiUrl}/api/DeleteFileAndData?Folder=${`MinWagesPdf`}&FileName=${fileName}`,{
    //             method:'DELETE',
    //             headers:{
    //                 "Content-Type":'application/json'
    //             }
    //         })
    //         if(response.ok){
    //           getWage(StateId)
    //             getPdfFile(StateId);
    //             setLoading(false)
    //         }
    //     }
    //     catch{
    //         console.log("Error");
            
    //     }
    // }

    const handleDelete = async (fileName) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${holidayApiUrl}/api/DeleteFileAndData?Folder=${`MinWagesPdf`}&FileName=${fileName}`,
                {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": 'application/json',
                    },
                }
            );
    
            if (response.ok) {
                // Refresh data
                getWage(StateId);
                getPdfFile(StateId);
    
                // Show success message
                toast.success("File deleted successfully!");
    
                setLoading(false);
            } else {
                // Show error message if deletion fails
                toast.error(`Failed to delete file: ${response.statusText}`);
                setLoading(false); // Ensure loading state is reset
            }
        } catch (error) {
            console.error("Error:", error);
    
            // Show error message if an exception occurs
            toast.error("An error occurred while deleting the file.");
            setLoading(false); // Ensure loading state is reset
        }
    };

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setStateDropDown(false); // Close the dropdown if clicked outside
        }
      };
    
      useEffect(() => {
        if (stateDropDown) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [stateDropDown]);
    
    return(
        <div className="AdminWages">
            {/* <AdminMenu/> */}
            <div style={{width: `calc(100% - 200px)`,minHeight:'1000px'}}>
            <div className="Admin-Wage">
            <ToastContainer />
            <div className="Wage-detail">
                <div className="Wage-detail-name">Minimum Wages</div>

                <div ref={dropdownRef}>
                <div className="Wage-search" onClick={toggleStateDropdown}>
                    <span className="Wage-state-Name">{StateName===''?'Select State to view record':StateName}</span>
                    <CaretDown size={20}/>
                </div>
                {stateDropDown && (
  <div className="dropdown-state">
    {stateList
      .sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((option, index) => (
        <div
          className="dropdown-item-state"
          key={index}
          onClick={() => {
            handleStateClick(option.state, option.id);
          }}
        >
          {option.state}
        </div>
      ))}
  </div>
)}


                </div>
              
                {popOpen && <div className="Wages-overlay"></div>}
                 {popOpen &&(
                <div className="Wage-popup">
            <div className="Wage-pop-Uplaod">
                <span>Upload</span>
                <XCircle size={20} onClick={()=>{setPopOpen(false);setFileName('')}} />
            </div>
            <div className="Wage-pop-UplaodFile" onClick={handleUploadClick}>
                 <CloudArrowUp size={60} color="#a8b8b9" weight="bold" />
                 {fileName===''? (<div>Uploaded file of 10MB limit</div>):(<div>File Selected Successfully</div>)}
                 <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept=".xlsx" 
                        onChange={handleFileChange}
                    />
            </div>
            <div className="Wage-pop-FileName">
                <input placeholder="Enter File Name" className="Wage-pop-input" value={fileName}/>
            </div>
            <div className="popup-change">
        <div onClick={() => handleDateClick()} className='NotificationDateFil'>
          {effectiveDate ? `Effective Date: ${effectiveDate}` : 'Select Effective Date'}
          <CaretDown size={20} />
        </div>
        {showEffectiveDatePicker && (
          <div className="date-picker-container" style={{ display: 'flex', alignItems: 'start', position: 'absolute', top: '-125px',left:'200px' }}>
            <DatePicker
              calendarClassName="custom-calendar"
              className="gc-withimage-dateinput"
              selected={effectiveDate}
              name="effectiveDate"
              onChange={(date) => handleDateSelect(date)}
              showPopperArrow={false}
              dateFormat="dd/MM/yyyy"
              inline
            />
          </div>
        )}

<div className="Wage-pop-mark">
                <div className="Wage-pop-imp" onClick={()=>{setPopOpen(false)}}>Cancel</div>
                <div className="Wage-pop-upload" onClick={uploadFile}>Upload</div>    
            </div>
      </div>
            {/* <div className="Wage-pop-link">Add links <Paperclip size={20} color="#6c625b" weight="bold" /><input className="wage-link-url" onChange={e => setAdditionalLink(e.target.value)} /></div> */}
            {/* <div className="Wage-pop-mark">
                <div className="Wage-pop-imp" onClick={()=>{setPopOpen(false)}}>Cancel</div>
                <div className="Wage-pop-upload" onClick={uploadFile}>Upload</div>    
            </div> */}
        </div>
            )}
             {popFileOpen  && <div className="Wages-overlay"></div>}
             {popFileOpen &&(
                <div className="Wage-popup">
            <div className="Wage-pop-Uplaod">
                <span>Upload</span>
                <XCircle size={20} onClick={()=>{setPopFileOpen(false);setFileNamePdf('')}} />
            </div>
            <div className="Wage-pop-UplaodFile" onClick={handleFileUploadClick}>
                 <CloudArrowUp size={60} color="#a8b8b9" weight="bold" />
                 {fileNamePdf===''? (<div>Uploaded file of 10MB limit</div>):(<div>File Selected Successfully</div>)}
                 <input
                        type="file"
                        ref={pdffileInputRef}
                        style={{ display: 'none' }}
                        accept=".pdf" 
                        onChange={handleFilePdfChange}
                    />
            </div>
            <div className="Wage-pop-FileName">
                <input placeholder="Enter File Name" className="Wage-pop-input" value={fileNamePdf}/>
            </div>
            {loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
            {/* <div className="Wage-pop-link">Add links <Paperclip size={20} color="#6c625b" weight="bold" /><input className="wage-link-url" onChange={e => setAdditionalLink(e.target.value)} /></div> */}
            <div className="Wage-pop-mark">
                <div className="Wage-pop-imp" onClick={()=>{setPopFileOpen(false)}}>Cancel</div>
                <div className="Wage-pop-upload" onClick={uploadFilePdf}>Upload</div> 

            </div>
        </div>
            )}
            </div>
            {StateName!=='' &&(
                <div style={{width:'100%'}}>
                    <div className="Wage-detail2">
                        <div className="Wage-detail1-name">{StateName}</div>
                        <div style={{display:'flex', gap:'8px'
                        }}>

                        <div className="Wage-detail1-upload" onClick={handlePop}><FilePlus size={24} color="#009746" /> Upload File</div>
                        <div className="Wage-detail1-upload" onClick={handleFilePop}><FilePlus size={24} color="#009746" /> Upload PDF File</div>
                        </div> 
                    </div>
                   
                {minWage.length!==0?(
                     <div className="WageApplicablee">
                     <table className="responsive-table">
                     <thead>
                     <tr className='sticky-headerAdmin'>
                         {head.map((header, index) => (
                             <th key={index}>{header}</th>
                         ))}
                     </tr>
                     </thead>
                     <tbody className="EWageBody">
                     {minWage.map((row, rowIndex) => (
                     <tr key={rowIndex}>
                         {head.map((header, colIndex) => (
                         <td key={colIndex}>{row[header]}</td>
                         ))}
                     </tr>
                     ))}
                     </tbody>
                 </table>
               
                    </div>
                     
                ):(
                    <div className="Wage-state">
                    <div className="Wage-State-Record">No Record</div>
                </div>
                )}
                
                </div>
                
             
                 
              
            )}
            <div className='AdminMinPDFAll'>        
                    {pdfdata && pdfdata.map((pdf,index)=>(
                         <div className='AdminMinPdfDownloadData'>
                         <div onClick={() => handleFileDownload(pdf?.fileName)} className='AdminMinPdfName'>
                       <Paperclip/>  {
                        pdf.fileName
                          .replace(/_\d{4}(?=\.\w+$)/, '')
                      }
                        </div>
                        {/* <div className='AdminMinDownload' onClick={()=>{handleDelete(pdf.fileName)}}>DELETE</div> */}
                        <Trash
                  size={20}
                  color="#898989"
                  onClick={()=>{handleDelete(pdf.fileName)}}
                  style={{cursor:"pointer"}}
                />
                       </div>

                    ))                   
                     } 
                  </div> 
            </div>
           { loading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>}
            </div>
           
        </div>
    );
}
export default AdminWages;