import React , {useState, useEffect,useRef} from 'react';
import '../css/PayrollCompliance.css'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

function PolicyCompliance() {
  const [visibleItems, setVisibleItems] = useState(4);
const [viewMore, setViewMore] = useState(false);
const containerRef = useRef(null);
  const services=[{
    id:1,
    service:'EPF, ESI, PT Codes'
},
{
    id:2,
    service:'Generation of Universal Account Number in EPF'
},
{
    id:3,
    service:'Generation of Insured Person Number in ESI'
},
{
    id:4,
    service:'Generation of Monthly Statutory Challans'
},
{
    id:5,
    service:'Consulting support for availing benefits from ESI & EPF '
},
{
    id:6,
    service:'Coordination with the authorities for inspections and notices'
},
{
    id:7,
    service:'Remittance of Statutory payments under PT and LWF'
},
{
    id:8,
    service:'Preperation & submission of statutory returns under PT & LWF'
},
{
    id:9,
    service:'Preperation and submission of statutory returns under Bonus'
},
{
    id:10,
    service:'Preparation and submission of gratuity forms'
}]
const law=[ 
{
  id:1,
  title:'Provident Fund (PF) and Miscellaneous Provisions Act, 1952',
  detail1:"Employers must contribute to the Employees' Provident Fund (EPF) for employees earning up to a certain limit." ,
  detail2:'Both employer and employee contributions are required'
},  
{
  id:2,
  title:"Employees' State Insurance (ESI) Act, 1948:",
  detail1:'Applicable to employees earning below a specified threshold.',
  detail2:'Employers must contribute to the ESI fund, providing health insurance and other benefits to employees'
}, 
{
  id:3,
  title:" Professional Tax:",
  detail1:'Levied by certain state governments.',
  detail2:'Employers must deduct professional tax from employee salaries and remit it to the state government.'
},  
{
  id:4,
  title:"Labour Welfare Fund:",
  detail1:'Applicable in certain states.',
  detail2:'Employers must contribute to the Labour Welfare Fund, which supports various employee welfare activities'
}
]


const handleViewMore = () => {
  if (viewMore) {
    setVisibleItems(4); 
  } else {
    setVisibleItems(law.length); 
  }
  setViewMore(!viewMore);
};
const closeDropdownOnScroll = (entries) => {
  const [entry] = entries;
  if (!entry.isIntersecting) {
    setViewMore(false);
    setVisibleItems(4);
  }
};

useEffect(() => {
  const observer = new IntersectionObserver(closeDropdownOnScroll, {
    root: null,
    threshold: 0,
  });

  if (containerRef.current) {
    observer.observe(containerRef.current);
  }

  return () => {
    if (containerRef.current) {
      observer.unobserve(containerRef.current);
    }
  };
}, [containerRef, viewMore]);

  return (
    <div>
      <div className='payroll-main'  >
      <div className='payroll-head'>
        <div className='payroll-head-maintext'>Payroll Statutory Compliance</div>
        <div className='payroll-head-text'>Payroll compliance in India involves adhering to various labor laws, tax regulations, and statutory requirements related to employee compensation</div>
      </div>
      <div className='payroll-aspect' ref={containerRef}>
            <div className='payroll-aspect-text'>Aspects of  Payroll Compliance</div>
            <div className='payroll-aspect-data'>
               <div className='payroll-aspect-detail'>
               Ensuring compliance with payroll-related laws and regulations is crucial for businesses in India to avoid legal penalties, fines, and potential litigation. Employers must stay updated with changes in legislation and ensure accurate and timely adherence to all statutory requirements
               {law.slice(0, visibleItems).map((lawdata, id) => (
  <div key={id} style={{ display: 'flex', flexDirection: 'column', gap: '16px'}}>
    <div className='payroll-aspect-title'>{lawdata.title}</div>
    <ul style={{ margin: '0', paddingLeft: '16px', listStyleType: 'disc' }}>
      <li className='payroll-aspect-exp'>{lawdata.detail1}</li>
      <li className='payroll-aspect-exp'>{lawdata.detail2}</li>
    </ul>
  </div>
))}

                 
                </div> 
               <div className='payroll-aspect-image'></div>
            </div>
      </div>
      <div className='payroll-aspect'> 
            <div className='payroll-service-text'>Our Services </div>
            <div className='payroll-service-detail'>
                <div className='payroll-service-data-row'>
                    {services.filter(service => service.id <= 5).map((service) => (
                    <div className='payroll-service-data1' key={service.id}>
                        <div className='payroll-service-number'>{service.id}</div>
                        <div className='payroll-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                
                <div className='payroll-service-data-row'>
                    {services.filter(service => service.id > 5).map((service) => (
                    <div className='payroll-service-data2' key={service.id}>
                        <div className='payroll-service-number'>{service.id}</div>
                        <div className='payroll-service-explain'>{service.service}</div>
                    </div>
                    ))}
                </div>
                </div>
       </div>
       </div>
    </div>
  )
}

export default PolicyCompliance
