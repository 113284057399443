import React from 'react'
import '../css/ComingSoon.css'
function ComingSoon() {
  return (
    <div style={{
        width:'100%',
        height:'100vh',
        // backgroundColor:'rgba(217, 214, 230, 0.34)',
        fontFamily:'Ariel,sans-serif, sans-serif',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        // paddingBottom:'40px',
        color:'rgba(217, 214, 230)',
        fontWeight:'700'
    }} className='comingsoonpage'>ComingSoon</div>
  )
}

export default ComingSoon