import React from 'react'
import '../css/Footer.css'
import TalentTraverseLogo from '../Images/TalentTraverseLogo.png'
import { Clock, Envelope, FacebookLogo, InstagramLogo, MapPin, WhatsappLogo, YoutubeLogo, Phone, LinkedinLogo, XLogo } from '@phosphor-icons/react'


function Footer() {
  return (
    <div className='footer'>
      <div style={{backgroundColor:'rgba(217, 214, 230, 0.34)', display:'flex', justifyContent:'center'}}>
      <div className='footer-content'>
      <img src={TalentTraverseLogo} alt='Talent Traverse Logo' className='company-logo'/>
      <div className='all-footer'>
        <div className='footer-content-one'>
            
            <div className='content-one-data'>
                <div className='content-one'>Talent Traverse HR Tech Solutions Pvt. Ltd</div>
                <div style={{fontFamily:'Ariel,sans-serif , sanserif',fontSize:'18px', color:'rgb(0, 151, 70)', fontWeight:'600'}}>Beyond Boundaries</div>
                <div className='content-two'>We love our customers, so feel free to visit during normal business hours.</div>
                
            </div>
        </div>
        <div className='footer-content-two'>
         
            <div className='content-icons-ad'>
            <MapPin size={24} color="rgba(64, 49, 131, 1)"  weight="light" />998/20, 1st floor, Poornima Complex,<br/> Service Rd, above ICICI Bank, Kilkodungalur, <br/> RPC Layout, Vijayanagar, Bengaluru, Karnataka 560104</div>
            <div className='content-icons-time'>
            <Clock size={24} color="rgba(64, 49, 131, 1)"  weight="light"  />
            9 AM - 6PM</div>
            <div className='content-icon-dunny' style={{height:'16px'}}> {''}</div>

        </div>
        <div className='footer-content-two'>
        <div className='content-icons-time'><Phone size={24} color="rgba(64, 49, 131, 1)"  weight="light"  />
        +91 9986863847</div>
            
            <a
                target="_blank"
                href="https://wa.me/+919986863847"
                style={{textDecoration:'none'}}
              ><div className='content-icons-time'><WhatsappLogo size={24} color="rgba(64, 49, 131, 1)"  weight="light"  />
            Chat with us on WhatsApp</div></a>
            <a href="mailto:query@talent-traverse.com"   style={{ textDecoration: 'none', color: 'inherit' }}><div className='content-icons-time'><Envelope size={24} color="rgba(64, 49, 131, 1)"  weight="light" />
            query@talent-traverse.com</div></a>
            <div className='content-icons'>
                <a 
                href="https://linkedin.com/company/talenttraversehrtechsolution" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <LinkedinLogo size={24} color="rgba(64, 49, 131, 1)" weight="fill" />
               
              </a>
              <a 
                href="https://x.com/Talentraverse" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                 <XLogo size={22} color="rgba(64, 49, 131, 1)" weight="fill" />
               
              </a>
                </div>
        </div>
        
      </div>
      </div>
      </div>
      <div className='footerbox'>
      Copyrights © Talent Traverse. All rights Reserved
      </div>
    </div>
  )
}

export default Footer
