import React, { useEffect, useRef, useState } from 'react'
import '../css/EMinimumWageState.css'
import { CalendarDots, CaretDown, CaretLeft, CaretUp, FileArrowDown, Paperclip } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom';
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function EMinimumWageState() {
    const navigate=useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        console.log("Hii");
      };
      const handleback=()=>{
        navigate('/MinimumWage')
      }
      const { id } = useParams();
      console.log(id);
      useEffect(()=>{
        window.scrollTo(0,0)
      },[]);
      const params = useParams();
  const [stateName, setStateName]=useState('');
  const [stateId, setStateId]=useState(id);
  const handleStateClick=(name,id)=>{
  console.log(id);
    setStateName(name);
    setStateId(id);
    setDropdownVisible(false)
  }

const token=''
const [stateList,setStateList]=useState([]);
const getStateName=async()=>{
  
  try{
    setLoading(true)
    const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
      method:'GET',
      headers:{
        Authorization:`Bearer${token}`,
        "Content-Type":'application/json',
      }, 
    })
  
    if(response.ok){
      const data=await response.json();
      setStateList(data);
      setStateName(data?.[id-1]?.state);
      setLoading(false)
     
    }
  }
  catch{
console.log("error");
  }
}
      useEffect(()=>{
        getStateName();
      },[])
// -------GET --------------
const [minWage,setMinWage]=useState([]);
const [head,setHead]=useState([]);
const getWage = async (id) => {
  setLoading(true)
  try {
    
      const response = await fetch(`${holidayApiUrl}/api/GetminWagesByStateId?StateId=${id}`, {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json',
          },
      });
      
      if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
              setMinWage(data);
              setHead(Object.keys(data[0]));
          } else {
              setMinWage([]); // Clear data if no records are found
              setHead([]);
          }
          setLoading(false)
      }
  } catch (error) {
      console.error("Error fetching wage data:", error);
  }
};
// console.log(minWage);

useEffect(()=>{
  setMinWage([])
  setHead([])
    getWage(stateId)
    navigate(`/StateMinimumWage/${stateId}`, { replace: true }); 
},[stateId])
// state banner
const stateBanner = useStateBanner(stateId);
// const backgroundImage = stateBanner[stateId] || StateImage ; 
// console.log(backgroundImage);
const dropdownRef = useRef(null);
const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
    }
};
useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
const [backgroundImage, setBackgroundImage] = useState(null);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);
const [recent,setRecent]=useState(null)
const getMinWages=async()=>{
  try{
    setLoading(true)
    const response=await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'MinWages'}&StateId=${stateId}`,{
      method:'GET',
      headers:{
        "Content-Type":'application.json'
      }
    })
    if(response.ok){
      const data=await response.json();
      setRecent(data[data.length-1])
      setLoading(false)
    }
  }
  catch{

  }
}

const [pdfdata,setPdfData]=useState(null)
    const getPdfFile=async(id)=>{
        try{
          setLoading(true)
            const response= await fetch(`${notificationApiUrl}/api/GetFileByStateId?Foldername=${'MinWagesPdf'}&StateId=${id}`,{
                method:'GET',
                headers:{
                    'Content-Type':'application/json'
                }
            })
            if(response.ok){
                const data=await response.json();
                const filteredData = data.filter(item => item.fileName.endsWith('.pdf'));
                setPdfData(filteredData);
                setLoading(false)
            }
        }
        catch{

        }
    }
    console.log(pdfdata);
    useEffect(()=>{
      getMinWages();
      getPdfFile(stateId)
    },[stateId])
    const handleFileDownload = async (fileName) => {
      try {
        const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'MinWagesPdf'}&FileName=${fileName}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'text/plain'
          }
        });
    
        if (response.ok) {
          const textResponse = await response.text();
          const fileUrl = textResponse.replace("Url=", "");
    
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to fetch the file URL:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while downloading the file:", error);
      }
    };
  return (
    <div style={{ width: '1280px' , overflow:'hidden'}}>
        <div className='EMinimumState-main'>
            <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`,
               
               }} className='EMinWage-back'>
                
                <div className='EMinimumState-maintext' onClick={toggleDropdown}>{stateName}{!dropdownVisible?<CaretDown size={32}/>:<CaretUp size={32}/>}</div>
            </div>
            {dropdownVisible && (
  <div className='EMinimumState-dropdown' ref={dropdownRef}>
    {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((states, index) => (
        <div key={index} className='EMinimumStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

                <div className='EMinimumBack' onClick={handleback}><CaretLeft size={30} />Back</div>
               
            <div className='EMinimumState-Data'>
              <div className='DateEffectivRelease'>
              <div className='EMinDates'><CalendarDots size={20}/>Effective from {recent?.effectiveDate !=='0001-01-01T00:00:00'?recent?.effectiveDate.split("T")[0]:'NA'}</div>
              <div className='EMinDates'> <CalendarDots size={20}/> Updated on {recent?.createDate!=='0001-01-01T00:00:00'?recent?.createDate.split("T")[0]:'NA'}</div>

              </div>
              {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
            
             
            {minWage.length!==0?(
              <div className="WageApplicableState">
                
              <table className="responsive-table">
             
             <thead>
              <tr className='sticky-header'>
                  {head.map((header, index) => (
                      <th key={index}>{header}</th>
                  ))}
              </tr>
              </thead>
               
              <tbody>
              {minWage.map((row, rowIndex) => (
              <tr key={rowIndex}>
                  {head.map((header, colIndex) => (
                  <td key={colIndex} style={colIndex === 0 ? { fontWeight: "600" } : {}}>{row[header]}</td>
                  ))}
              </tr>
              ))}
              </tbody>
          </table>
             </div>
                    
                ):(
                  <div className="Wage-state1">
                  <div className="Wage-State-Record">No Record</div>
              </div> 
                )}
                 <div className='EMinPDFAll'>        
                    {pdfdata && pdfdata.map((pdf,index)=>(
                         <div className='EMinPdfDownloadData'>
                         <div onClick={() => handleFileDownload(pdf?.fileName)} className='EMinPdfName'>
                       <Paperclip/>  {
                        pdf.fileName
                          .replace(/_\d{4}(?=\.\w+$)/, '')
                      }
                        </div>
                       <div className='EMinDownload' onClick={() => handleFileDownload(pdf?.fileName)}><FileArrowDown size={20} color="#009746"  />Download File</div>
                       </div>

                    ))                   
                     } 
                  </div>
            </div>
        </div>
    </div>
  )
}

export default EMinimumWageState