import React, { useEffect, useRef, useState} from 'react';
import '../css/EHoliday.css';
import { CalendarDots, CaretDown, CaretUp } from '@phosphor-icons/react';
import ELibraryMenu from '../components/ELibraryMenu';
import {useStateImages} from '../components/StateEmblem';
import { useNavigate } from 'react-router-dom';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import {useStateMap} from '../components/StateEmblem';
import { CircularProgress } from '@mui/material';
function EHoliday() {
  const [stateList, setStateList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [stateDropDown, setStateDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = '';
  const navigate = useNavigate();
  const stateImages = useStateImages(token);
  const getStateName = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStateList(data);
        setLoading(false)
      }
    } catch {
      console.log('error');
    }
  };

  useEffect(() => {
    getStateName();
  }, []);

  const handleNavigate = (id) => {
    navigate(`/StateHolidayList/${id}`);
  };

  const handleState = (state) => {
    setSelectedState(state);
    setSearchQuery(state.state); // Set the search bar value to the selected state's name
    setStateDropDown(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setSelectedState(null); // Reset selected state when typing
    setStateDropDown(true); // Show dropdown on input change
  };

  const filteredStates = stateList.filter((state) =>
    state.state.toLowerCase().includes(searchQuery.toLowerCase())
  ).sort((a, b) => a.state.localeCompare(b.state)); 

  const normalizeStateName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '');
  };
  // 
  const stateMap = useStateMap(token);
  const [stateUpdates, setStateUpdates] = useState({});
  const hasFetchedData = useRef(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(date);
  }
  const fetchAllActsData = async () => {
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'holidayList'}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error('Failed to fetch acts data:', error);
    }
    return [];
  };
  
  useEffect(() => {
    if (hasFetchedData.current) return; // Skip if data has been fetched
  
    const fetchData = async () => {
      if (filteredStates.length === 0) return; // Exit if there are no states
  
      const counts = {};
      const updates = {};
  
      const allData = await fetchAllActsData();
  
      for (const state of filteredStates) {
        const stateData = allData.filter(item => item.stateId === state.id);
        const stateCount = stateData.length;
        counts[state.id] = stateCount;
        console.log(stateData);
        
        // Store all effective dates in an array
        const effectiveDates = stateData.map(item => item.effectiveDate);
        
        // Get the last effective date from the array
        const lastEffectiveDate = effectiveDates.length > 0 ? effectiveDates[effectiveDates.length - 1] : '0001-01-01T00:00:00';
  
        // Check if the last effective date is the special case '0001-01-01T00:00:00'
        if (lastEffectiveDate === '0001-01-01T00:00:00') {
          updates[state.id] = 'NA';
        } else {
          // Use your existing formatDate function
          updates[state.id] = formatDate(lastEffectiveDate);
        }
      }
  
      // setFilteredStateCounts(counts);
      setStateUpdates(updates);
      hasFetchedData.current = true;
    };
  
    fetchData();
  }, [filteredStates]);

  const handleOutsideClick = (e) => {
    if (e.target.closest('.EHoliday-search') === null) {
        setStateDropDown(false);
    }
};

useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
}, []);
  return (
    <div className="EHolidayTop">
      <div className="EHolidayMain">
        <div className="EHolidayHead">
          <div className="EHolidayName">
            <CalendarDots className="EHolidayGavel" />
            <div className="EHolidayNameFull">Holiday list</div>
          </div>
          <div className="EHolidayDetail">
            In India, government and public holidays can be broadly categorized into three types: national holidays, regional holidays, and holidays based on religious and cultural observances.
          </div>
        </div>
        <div className="EHoliday-state-search">
          <div className="EHoliday-Search-head">Search for Holiday Details</div>
          <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>
          <div className="EHoliday-search">
            <input
              placeholder="Search for States or Union Territories"
              type="search"
              className="EHoliday-searchbar"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={() => setStateDropDown(true)} 
            />
            {stateDropDown ? (
              <CaretUp size={20} color="#4d4d4d" onClick={() => setStateDropDown(false)} />
            ) : (
              <CaretDown size={20} color="#4d4d4d" onClick={() => setStateDropDown(true)} />
            )}
            
          </div>
          {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}

          {stateDropDown && (
            <div className="EHoliday-dropdown">
              {filteredStates.filter(option => option.state !== "Central").map((state, index) => (
                <div key={index} className="EHolidayDropName" onClick={() => handleState(state)}>
                  {state.state}
                </div>
              ))}
            </div>
          )}

          <div className="EHolidayData">
          {selectedState ? (
  <div
    className="EHolidaySingle"
    style={{
      backgroundImage: `url(${stateMap[selectedState.id]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(78, 175, 78, 0.15)', 
    }}
    key={selectedState.id}
    onClick={() => handleNavigate(selectedState.id)}
  >
    <div className="EHolidayStateFLag">
      {stateImages[selectedState.id] ? (
        <img
          src={stateImages[selectedState.id]}
          alt={selectedState.state}
          style={{ height: 'inherit', width: 'inherit' }}
        />
      ) : (
        <span>No image available</span>
      )}
    </div>
    <div className="EHolidayStateName">{selectedState.state}</div>
    {/* <div className="EHolidayUpdate">Effective date</div> */}
  </div>
) : (
  filteredStates.filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state)).map((state) => (
    <div
      className="EHolidaySingle"
      style={{
        backgroundImage: `url(${stateMap[state.id]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(78, 175, 78, 0.15)', 
      }}
      key={state.id}
      onClick={() => handleNavigate(state.id)}
    >
      <div className="EHolidayStateFLag">
        {stateImages[state.id] ? (
          <img
            src={stateImages[state.id]}
            alt={state.state}
            style={{ height: 'inherit', width: 'inherit' }}
          />
        ) : (
          <span>No image available</span>
        )}
      </div>
      <div className="EHolidayStateName1">{state.state}</div>
      <div className="EHolidayUpdate">Effective date {stateUpdates[state.id] || 'NA'}</div>
    </div>
  ))
)}

          </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default EHoliday;
