import React, { useEffect, useRef } from 'react'
import "../css/PrivacyPolicy.css"
export default function PrivacyPolicy(){
  const termsRef = useRef(null);

  useEffect(() => {
    termsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

    return ( 
<div className="privacy-policy-content">
  <h2  ref={termsRef}>Privacy Policy And Terms & Conditions</h2>

  {/* <h3>Information We Collect</h3>
  <ul className='privacy-policy-ul'>
    <li className='privacy-policy-li'>Name</li>
    <li>Email address</li>
    <li>Phone number</li>
    <li>Business name and address</li>
    <li>Tax ID number</li>
    <li>Payment and bank account information</li>
    <li>Shipping and order information</li>
  </ul> */}

  <h3>Introduction</h3>
  <ul >
    <li>We are a labor compliance and payroll company that creates user-friendly software to simplify work processes for everyone.</li>
  </ul>

  <h3> Purpose</h3>
  <ul >
    <li>Our mission is to offer an online plug-and-play compliance application that makes it easy for organizations to track all compliance requirements as per Indian law. </li>
  </ul>
  <h3>Account Terms</h3>
  <ul >
    <li>You must be at least 18 years old to use this service. </li>
    <li>You need to provide your full legal name, a valid email address, and any other required information to complete the sign-up process. </li>
    <li>You are responsible for keeping your account information private and secure. Talent Traverse is not liable for any loss or damage resulting from your failure to protect your login information, including your password.</li>
    <li>Talent Traverse may contact you via email regarding your account, system updates, or other account-related matters.</li>
    <li>Talent Traverse reserves the right to refuse service to anyone at any time, for any reason.</li>
  </ul>
 
  <h3>Payment Terms</h3>
  <ul >
    <li>We will charge a standard fee based on your account plan. The service is billed in advance and is non-refundable. We do not offer refunds or credits for partial months of service or for periods when you do not use the service. No exceptions. </li>
  </ul>

 <h3>Modifications to the Service</h3>
  <ul >
    <li>Talent Traverse reserves the right to modify, suspend, or discontinue the service at any time, with or without notice. </li>
  </ul>


  <h3>Cancellation and Termination</h3>
  <ul >
    <li>You are responsible for canceling your account. You can do so by contacting your Talent Traverse account manager or emailing us at query@talent-traverse.com. If you cancel, you are responsible for all charges up until the time of cancellation, including full charges for the month in which you discontinue the service. You will not be charged again after cancellation</li>
    <li>Talent Traverse reserves the right to modify or discontinue the service, refuse current or future use of the service, suspend or terminate your account, or remove any content in your account if we believe you have violated these Terms of Service. We will make reasonable efforts to contact you via email before suspending or terminating your account. Any suspected fraudulent, abusive, or illegal activity may be reported to law enforcement. Talent Traverse is not liable to you or any third party for any modifications, suspensions, or discontinuation of the service. </li>
  </ul>


  <h3>Copyright and Ownership</h3>
  <ul >
    <li>Talent Traverse owns all intellectual property rights to protectable components of the service, including but not limited to the name, artwork, interface elements, pre-configured compliance data, individual features, and related documentation. You may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any part of the service owned by Talent Traverse.</li>
    <li> Talent Traverse does not claim ownership of the content you upload or provide to the service.</li>
  </ul>


  <h3>General Conditions</h3>
  <ul >
    <li>The service is provided 'as is' and 'as available' without any warranties, either express or implied. You use the service at your own risk.</li>
    <li>You agree not to resell, duplicate, reproduce, or exploit any part of the service without Talent Traverse's express written permission. </li>
    <li> You may not use the service to store, host, or send unsolicited emails (spam) or SMS messages. </li>
    <li> Talent Traverse will maintain commercially acceptable safeguards to protect the security, confidentiality, and integrity of your data. </li>
    <li> You may not use the service to transmit any viruses, worms, or malicious content.  </li>
    <li> Talent Traverse makes no warranties regarding (i) your ability to use the service, (ii) your satisfaction with the service, (iii) the service's availability at all times, (iv) the accuracy of calculations performed by the service, (v) the accuracy of the acts and laws contained in the service, or (vi) that bugs or errors in the service will be corrected.  </li>
    <li> Talent Traverse and its affiliates are not responsible or liable for any direct, indirect, incidental, consequential, special, exemplary, or punitive damages related to your use of the service. Your only remedy for dissatisfaction with the service is to stop using it.  </li>
    <li> If any part of these Terms of Service is found to be invalid or unenforceable, the remaining provisions will remain in effect.</li>
    <li> Talent Traverse may remove content and accounts containing content we deem unlawful, offensive, threatening, libelous, defamatory, obscene, or otherwise objectionable, or that violates any party’s intellectual property or these Terms of Service. </li>
    <li> Failure by Talent Traverse to enforce any right under these Terms of Service does not constitute a waiver of that right. These Terms of Service represent the entire agreement between you and Talent Traverse and supersede any prior agreements. </li>
    <li> If you have any questions about these Terms of Service, please contact us at query@talent-traverse.com. </li>
  </ul>


  <h3>Governing Law</h3>
  <ul >
    <li>These Terms of Service are governed by the laws of Bangalore, Karnataka, India. You agree to submit to the exclusive jurisdiction of the state courts in Karnataka for any disputes related to your use of the service. </li>
  </ul>

  <h3>User Responsibilities</h3>
  <ul >
    <li>You agree to use the service in a manner consistent with all applicable local, state, national, and international laws and regulations.  </li>
    <li>You are solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all data and content submitted by you to the service. </li>
    <li>You agree not to use the service for any unlawful or prohibited activities, including but not limited to activities related to fraud, spamming, hacking, or the distribution of malware.  </li>
    <li>You acknowledge that Talent Traverse reserves the right to monitor your use of the service to ensure compliance with these terms and applicable laws.  </li>
  </ul>

  <h3>Third-Party Services</h3>
  <ul >
    <li>The service may include integrations with third-party services. Talent Traverse is not responsible for the functionality, availability, or security of these third-party services.  </li>
    <li> Your use of any third-party services accessed through the service is at your own risk, and such use is subject to the terms and conditions of those third-party services.  </li>
  </ul>

  <h3>Changes to Terms</h3>
  <ul >
    <li> Talent Traverse reserves the right to update or modify these Terms of Service at any time. </li>
    <li>If changes are made, we will notify you by email or through the service itself. Your continued use of the service after any such changes constitutes your acceptance of the new terms. </li>
  </ul>
</div>)
}