import React, { useEffect, useState } from 'react'
import '../css/ClientMenu.css'
import { ChalkboardSimple, Files, FloppyDisk, HouseSimple, IdentificationCard, WarningCircle } from '@phosphor-icons/react';
import { useLocation, useNavigate } from 'react-router-dom';
function ClientMenu() {
    const location = useLocation();
    const [active, setActive]=useState(null);
    const [mouseOver,setMouseOver]=useState(false);
    const navigate =useNavigate();
    const menuItem=[{
        id:1,
        icon:<HouseSimple size={32}  />,
        iconName:"Upload",
        route: "/ClientHome"
    },
    {
        id:2,
        icon:<ChalkboardSimple size={32}  />,
        iconName:"Acts",
        route: "/ClientOverview"
    },
    {
        id:3,
        icon:<Files size={32}  />,
        iconName:"Notifications",
        route: "/ClientGraph"
    },
    {
        id:4,
        icon:<Files size={32} />,
        iconName:"Minimum Wage",
        route: "/ClientCompliance"
    },
    {
        id:5,
        icon:<IdentificationCard size={32}  />,
        iconName:"Professional Tax",
        route: "/ClientHome"
    },
    {
        id:6,
        icon:<FloppyDisk size={32} />,
        iconName:"Labour Welfare Fund",
        route: "/ClientHome"
    },
    {
        id:7,
        icon:<WarningCircle size={32}  />,
        iconName:"Holiday List",
        route: "/ClientHome"
    },
]
useEffect(() => {
    const currentActive = location.state?.active;
    if (currentActive) {
        setActive(currentActive);
    }
}, [location]); 
const handleClick = (id, route) => {
    console.log(`Clicked ID: ${id}`);
    setActive(prevId => (prevId === id ? null : id));
    navigate(route, { state: { active: id } });
};
    
   
  const handleMouseOver = () => {
    setMouseOver(true);
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };
    return(
        <div className="ClientMenu" onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}>
         {menuItem?.map((menu) => (
            <div key={menu.id} className={`ClientMenuDetail${active===menu.id?'active':''} `} onClick={()=>handleClick(menu.id,menu.route)}>
            <div className={`ClientIcon${active===menu.id?'active':''} `} >{menu.icon}</div>
            {/* <div className={`ClientIconName${mouseOver?'active':''}`} >{menu.iconName}</div> */}
            </div>
      ))}
        </div>
    );
}

export default ClientMenu