import React, { useState, useEffect, useRef, useContext } from 'react';
import '../css/AdminLeave.css';
import { CaretDown, CloudArrowUp, FilePlus, Info, WarningCircle, XCircle } from "@phosphor-icons/react";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { TestContext } from '../components/TestContext';
import { CircularProgress } from "@mui/material";
import format from '../Images/LeaveFormat.png'
function AdminLeave() {
    const { isLogin } = useContext(TestContext);
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false)
    useEffect(() => {
        if (!isLogin) {
            navigate('/AdminLogin');
        }
    }, [isLogin, navigate]);
    useEffect(()=>{
        window.scrollTo(0,0);
      },[]) 
    const [stateId, setStateId] = useState(null);
    const [stateDropDown, setStateDropDown] = useState(false);
    const [StateName, setStateName] = useState('');
    const [stateList, setStateList] = useState([]);
    const [popOpen, setPopOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [additionalLink, setAdditionalLink] = useState('');
    const fileInputRef = useRef(null);
    const [fileData, setFileData] = useState([]);
    const [filePresent, setFilePresent] = useState(false);

    const token = '';

    const toggleStateDropdown = () => {
        setStateDropDown(!stateDropDown);
    };

    const handleStateClick = (name, id) => {
        setStateName(name);
        setStateDropDown(false);
        setStateId(id);
        setFileData([]);
        getFile(id);
    };

    const getState = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'application/json;'
                }
            });
            if (response.ok) {
                const data = await response.json();
                setLoading(false)
                setStateList(data);
            }
        } catch {
            console.error("Some Error");
        }
    };

    const handlePop = () => {
        setPopOpen(true);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const fullName = file.name;
                const baseName = fullName.substring(0, fullName.lastIndexOf('.')) || fullName;
                setFileName(baseName);
            } else {
                toast.error('Please upload a valid Excel file ( .xlsx)');
                console.log('Error', file);
            }
        }
    };

    const createFormData = () => {
        const formData = new FormData();
        const file = fileInputRef.current.files[0];
        const uniqueId = uuidv4();
        const fileExtension = file.name.split('.').pop();
        const uniqueFileName = `${uniqueId}.${fileExtension}`;
        formData.append('Attachment', file);
        formData.append('FileNameWithExtension', uniqueFileName || '');
        formData.append('Folder', 'TTLeave');
        formData.append('state', stateId);
        formData.append('RequestedBy', 'admin');
        formData.append('Title', fileName || '');
        formData.append('Type', 5);
        formData.append('IsImportant', false);
        formData.append('FileDiscription', '');
        formData.append('AdditionalLink', additionalLink || '');
        formData.append('EffectiveDate', '0001-01-01T00:00:00');
        formData.append('ReleasedDate', '0001-01-01T00:00:00');
        return formData;
    };

    const uploadFile = async () => {
        setLoading(true)
        try {
            const formData = createFormData();
            const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData,
            });
            await new Promise((resolve) => setTimeout(resolve, 3000)); 
            setLoading(false)
            toast.success("File uploaded successfully!");
            setPopOpen(false);
            setFileData([]);
            setFileName('');
            getFile(stateId);
        } catch {
            console.error("Error!!");
        }
    };

    const getFile = async (id) => {
        setLoading(true)
        try {
            const response = await fetch(`${holidayApiUrl}/api/GetLeaveByStateId?StateId=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=utf-8',
                },
            });

            if (response.ok) {
                setLoading(false)
                const data = await response.json();
                
                setFileData(data);
                setFilePresent(data.length !== 0);
            } else {
                console.error(`HTTP error: ${response.status}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        if (stateId) {
            getFile(stateId);
        }
    }, [stateId]);

    useEffect(() => {
        getState();
    }, []);

    // Function to split columns into Leave and Working Hours
    const splitFileData = (data) => {
        if (data.length === 0) return { leaveData: [], workingHoursData: [] };

        const headers = Object.keys(data[0]);
        const leaveColumns = headers.slice(0, 3); // First 3 columns for "Leave"
        const workingHoursColumns = headers.slice(3, 5); // Next 2 columns for "Working Hours"

        const leaveData = data.map(row => {
            const newRow = {};
            leaveColumns.forEach(col => newRow[col] = row[col]);
            return newRow;
        });

        const workingHoursData = data.map(row => {
            const newRow = {};
            workingHoursColumns.forEach(col => newRow[col] = row[col]);
            return newRow;
        });

        return { leaveData, workingHoursData };
    };

    const { leaveData, workingHoursData } = splitFileData(fileData);
    const [info,setInfo]=useState(false)
    const handleInfo=()=>{
        setInfo(true)
    }
    return (
        <div className="AdminLeave">
            <div style={{ width: `calc(100% - 200px)`, minHeight: '1000px' }}>
                <div className="Admin-Leave">
                    <div className="Leave-detail">
                        <div className="Leave-detail1-name">Leave and Working Hours</div>
                        <div className="Leave-search" onClick={toggleStateDropdown}>
                            <span className="Leave-state-Name">{StateName === '' ? 'Select State to view record' : StateName}</span>
                            <CaretDown size={20} />
                        </div>
                        {stateDropDown && (
                            <div className="Leave-dropdown-state">
                                {stateList.filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state)).map((option, index) => (
                                    <div className="Leave-dropdown-item-state" key={index} onClick={() => { handleStateClick(option.state, option.id) }}>
                                        {option.state}
                                    </div>
                                ))}
                            </div>
                        )}
                        {popOpen && <div className="Leave-overlay"></div>}
                        {popOpen && (
                            <div>
                                <div className="Leave-popup">
                                    <div className="Leave-pop-Uplaod">
                                        <span>Upload New File</span>
                                        <XCircle size={20} onClick={() => { setPopOpen(false);setFileName('') }} />
                                    </div>
                                    <div className="Leave-pop-UplaodFile" onClick={handleUploadClick}>
                                        <CloudArrowUp size={100} color="#a8b8b9" weight="bold" />
                                        {fileName === '' ? (<div>Uploaded file of 10MB limit</div>) : (<div>File Selected Successfully</div>)}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept=".xlsx"
                                            onChange={handleFileChange}
                                        />
                                        {loading && (<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>)}
                                    </div>
                                    <div className="Leave-pop-mark">
                                        <div className="Leave-pop-imp" onClick={() => { setPopOpen(false) }}>Cancel</div>
                                        <div className="Leave-pop-upload" onClick={uploadFile}>Upload</div>
                                    </div>
                                </div>
                            </div>
                        )}
                     {info && <div className="Leave-overlay"></div>}
                        {
                            info &&(
                                <div className='Uploadfomat'>
                                    <div className='Uploaddesc'><div><span style={{fontWeight:'600'}}>Note: </span>Upload .xlsx file of this format</div> <XCircle size={20} onClick={() => { setInfo(false)}} style={{cursor:'pointer'}} /></div>
                                    <img src={format} style={{width:'100%'}}/>
                                </div>
                            )
                        }
                    </div>

                    {StateName !== '' && (
                        <div style={{ width: '100%' }}>
        <div className="Leave-detail2" style={{display:'flex',flexDirection:'row'}}>

            <div className="Leave-detail1-name"> {StateName}</div>
            <div className='LeaveInfoUp'>
            <div className="Holiday-detail1-uploadAgain" onClick={handlePop}><FilePlus size={24} color="#009746" />Upload File </div>
            <div onClick={handleInfo}><Info size={22} color="#009746" style={{cursor:'pointer'}} /></div>
            </div>
        </div>
                            {filePresent ? (
                                <>
                                    {leaveData.length > 0 && (
                                        <div className="Leave-detail2">
                                            <div className="Leave-detail1-name">Leave for Shops and Establishment </div>
                                            <div className="WageApplicable" style={{ width: '100%' }}>
                                                <table className="responsive-table">
                                                    <thead className='LeaveTableHead'>
                                                        <tr>
                                                            {Object.keys(leaveData[0] || {}).map((header, index) => (
                                                                <th key={index} className='LeaveheadIn'>{header}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='LeaveBody'>
    {leaveData
        .filter(row => Object.values(row).some(value => value)) // Filter out rows where all values are empty
        .map((row, rowIndex) => (
            <tr key={rowIndex}>
                {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex} className='LeaveheadIn'>{value}</td>
                ))}
            </tr>
        ))}
</tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {workingHoursData.length > 0 && (
                                        <div className="Leave-detail2">
                                            <div className="Leave-detail1-name">Working Hours for Shops and Establishment</div>
                                            <div className="WageApplicable" style={{ width: '100%' }}>
                                                <table className="responsive-table">
                                                    <thead className='LeaveTableHead'>
                                                        <tr>
                                                            {Object.keys(workingHoursData[0] || {}).map((header, index) => (
                                                                <th key={index} className='LeaveheadIn'>{header}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='LeaveBody'>
    {workingHoursData
        .filter(row => Object.values(row).some(value => value)) // Filter out rows where all values are empty
        .map((row, rowIndex) => (
            <tr key={rowIndex}>
                {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex} className='LeaveheadIn'>{value}</td>
                ))}
            </tr>
        ))}
</tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    
                                   
                                </>
                                
                            ) : (
                                <div className="Leave-state">
                                    <div className="Leave-State-Record">No Record</div>
                                    {/* <div className="Holiday-detail1-uploadAgain" onClick={handlePop}><FilePlus size={24} color="#009746" />Add new </div> */}
                                </div>
                            )}
                        </div>
                    )}

                    <ToastContainer />
                </div>
                {loading && (<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center",zIndex:'9999' }}><CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} /></div>)}
            </div>
        </div>
    );
}

export default AdminLeave;
