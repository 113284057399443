import React from 'react'
import '../css/Career.css'
import { SuitcaseSimple } from '@phosphor-icons/react'
import CareerImg from '../Images/Career.png'
function Career() {
    const opening=[
        {
            id:1,
            title:'Executive Payroll ',
            desc:'Responsible for ensuring accurate payroll, audit reports, and timely regulatory compliance filings.',
            icon:<SuitcaseSimple />,
            applyUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSeXhAdWcNmQOKFAMFSZhaNI22Z7x1zCEXCS6qfJ9e2GLpKZAA/viewform?pli=1'
        },
    ]
    const handleApplyNowClick = (url) => {
        window.open(url, '_blank');
    };
  return (
   <div className='Career-Top'>
      <div className='career-main'>
        
      <div className='Career-banner'>
        <div className='Career-head-maintext'>Career At Talent Traverse</div>
        <div className='Career-head-text'>Be part of our incredible team, to make a difference!</div>
      </div>
      <div className='Career-quote'>
            <div className='Career-open-comma'>“</div>
            <div className='Career-content'>"Believe in yourself, and you can achieve anything"
           <br/> - Sundar Raj</div>
        </div>
<div>
<div className='career-opening'>
        <div className='career-openings'>Our Open Positions</div>
            <div className='job'>
            {opening?.map((open, id)=>(
                    <div className='joball'>
                    <div className='job-desc'>
                        <div className='job-icon'>{open.icon}</div>
                        <div className='job-role'>{open.title}<spna className='job-detail'>{open.desc}</spna></div>
                    </div>
                    <div className='job-apply' onClick={() => handleApplyNowClick(open.applyUrl)}>
                        Apply Now
                    </div>
                    </div>
            ))}
            </div>
           
               
           
        </div>
</div>
       
        
      </div>
  </div>

  )
}

export default Career