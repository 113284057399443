import { ArrowUp } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'

export default function BackToTop() {
    const [showButton, setShowButton] = useState(false);

    const handleTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        // Get the scroll position and the height of the document
        const scrollTop = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = document.documentElement.clientHeight;

        // Show the button if the user has scrolled down more than 50%
        if (scrollTop > scrollHeight / 2 - clientHeight) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        showButton && (
            <div
                style={{
                    fontFamily: 'Ariel, sans-serif',
                    background: 'black',
                    color: 'white',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    padding: '8px',
                    position: 'fixed',
                    zIndex: '99',
                    bottom: '52px',
                    right: '22px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '6px',
                    cursor: 'pointer'
                }}
                onClick={handleTop}
            >
                BackToTop <ArrowUp size={12} color="#ffffff" />
            </div>
        )
    );
}
