import { CaretDown, CaretUp, ClockUser } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import '../css/ELeave.css';
import OverTime from '../Images/OverTime.png';
import SpreadHours from '../Images/SpreadHours.jpg';
import Interval from '../Images/Interval.jpg';
import { useNavigate } from 'react-router-dom';
import {useStateImages} from '../components/StateEmblem';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import {useStateMap} from '../components/StateEmblem';
import { CircularProgress } from '@mui/material';
function ELeave() {
    const [activeDropDown, setActiveDropDown] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedState, setSelectedState] = useState(null);
    const [stateDropDown, setStateDropDown] = useState(false);
    const [stateList, setStateList] = useState([]);
    const token = '';
    const stateImages = useStateImages(token);
    const navigate = useNavigate();
    const stateMap = useStateMap(token);
    const [loading, setLoading] = useState(false);
    const notification = [
        {
            id: 1,
            Name: 'Sick Leave',
            desc: 'Sick leave is provided to employees when they are ill or need medical attention. The number of sick leave days varies depending on the organization and state laws.',
        },
        {
            id: 2,
            Name: 'Casual Leave',
            desc: 'Casual leave is granted to employees for short-term needs, such as personal errands or unforeseen circumstances. Employees are typically entitled to a certain number of casual leave days per year.',
        },
        {
            id: 3,
            Name: 'Earned leave / Privilege leave',
            desc: 'Earned leave or privilege leave is accumulated based on the number of days worked. Employees can use this leave for vacations or personal reasons. Typically, employees earn a certain number of days off for every month worked.',
        },
    ];

    const policy = [
        {
            id: 1,
            Name: 'Overtime',
            desc: 'Overtime refers to the time worked in excess of one\'s regular working hours which, in India, is eight to nine hours per day and forty-eight to fifty hours per week. Overtime wages are to be paid at the rate of twice the ordinary rates of wages.',
            img: OverTime,
        },
        {
            id: 2,
            Name: 'Spread Over Hours',
            desc: 'It means, total working hours of an employee, inclusive of rest breaks and overtime.',
            img: SpreadHours,
        },
        {
            id: 3,
            Name: 'Interval For Rest',
            desc: 'It is a break taken during working hours.',
            img: Interval,
        },
    ];

    const getStateName = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                const sortedData = data.sort((a, b) => 
                    a.state.toLowerCase().localeCompare(b.state.toLowerCase())
                );
                setStateList(sortedData);
                setLoading(false)
            }
        } catch {
            console.log('error');
        }
    };

    useEffect(() => {
        getStateName();
    }, []);

    const handleNavigate = (id) => {
        navigate(`/StateLeave/${id}`);
    };

    const handleLeave = (id) => {
        setActiveDropDown((prevId) => (prevId === id ? null : id));
    };

    const handleState = (state) => {
        setSelectedState(state);
        setSearchQuery(state.state); // Set the search bar value to the selected state's name
        setStateDropDown(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setSelectedState(null); // Reset selected state when typing
        setStateDropDown(true); // Show dropdown on input change
    };

    const filteredStates = stateList.filter((state) =>
        state.state.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const normalizeStateName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
      };
      const handleOutsideClick = (e) => {
        if (e.target.closest('.ELeave-search') === null) {
            setStateDropDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);
    return (
        <div className='ELeaveTop'>
            <div className='ELeaveMain'>
                <div className='ELeaveHead'>
                    <div className='ELeaveName'>
                        <ClockUser className='ELeaveGavel' />
                        <div className='ELeaveNameFull'>Leave and working hours</div>
                    </div>
                    <div className='ELeaveDetail'>
                        In an organization in India, leave policies are designed to ensure that employees have adequate time off for personal needs, health, and well-being while maintaining organizational productivity. The concept of leaves is well-defined and regulated by both labor laws and organizational policies.
                    </div>
                </div>
                <div className='ELeave-state-search'>
                    <div className='ELeave-notif-types'>
                        <div className='ELeave-notif-head'>Different types of leaves commonly recognized in India:</div>
                        <div className='ELeave-all-detail'>
                            {notification.map((notif) => (
                                <div className='ELeave-all-notif' key={notif.id}>
                                    <div className='ELeave-notif-name'><span className='ELeave-notif-count'>{notif.id}</span>{notif.Name}</div>
                                    <div className='ELeave-notif-desc'>{notif.desc}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='ELeave-Search-head'>Search for Leave Details  {loading && (
          <div
            style={{
              display: "flex",
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}</div>
                   
                    <div className='ELeave-search'>
                        {stateDropDown ? (
                            <CaretUp size={20} color="#4d4d4d" onClick={() => setStateDropDown(false)} />
                        ) : (
                            <CaretDown size={20} color="#4d4d4d" onClick={() => setStateDropDown(true)} />
                        )}
                        <input
                            placeholder="Search for States or Union Territories"
                            type="search"
                            className="ELeave-searchbar"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onFocus={() => setStateDropDown(true)} // Show dropdown on input focus
                        />
                        {stateDropDown && (
                        <div className='ELeave-dropdown'>
                            {filteredStates.filter(option => option.state !== "Central").map((state, index) => (
                                <div key={index} className='ELeaveDropName' onClick={() => handleState(state)}>
                                    {state.state}
                                </div>
                            ))}
                        </div>
                    )}
                    </div>
                   
                    <div className='ELeaveData'>
                    {selectedState ? (
  <div
    className='ELeaveSingle'
    key={selectedState.id}
    onClick={() => handleNavigate(selectedState.id)}
    style={{
      backgroundImage: `url(${stateMap[selectedState.id]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(78, 175, 78, 0.15)',
    }}
  >
    <div className='ELeaveStateFLag'>
      {stateImages[selectedState.id] ? (
        <img
          src={stateImages[selectedState.id]}
          alt={selectedState.state}
          style={{ height: 'inherit', width: 'inherit' }}
        />
      ) : (
        <span>No image available</span>
      )}
    </div>
    <div className='ELeaveStateName1'>{selectedState.state}</div>
    {/* <div className='ELeaveUpdate'>Effective date</div> */}
  </div>
) : (
  filteredStates.filter(option => option.state !== "Central").sort((a, b) => a.state.localeCompare(b.state)).map((state) => (
    <div
      className='ELeaveSingle'
      key={state.id}
      onClick={() => handleNavigate(state.id)}
      style={{
        backgroundImage: `url(${stateMap[state.id]})`,
        backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
        backgroundColor: 'rgba(78, 175, 78, 0.15)',
      }}
    >
      <div className='ELeaveStateFLag'>
        {stateImages[state.id] ? (
          <img
            src={stateImages[state.id]}
            alt={state.state}
            style={{ height: 'inherit', width: 'inherit' }}
          />
        ) : (
          <span>No image available</span>
        )}
      </div>
      <div className='ELeaveStateName1'>{state.state}</div>
      {/* <div className='ELeaveUpdate'>Effective date</div> */}
    </div>
  ))
)}

                    </div>
                    <div className='ELeave-sector'>
                        <div className='ELeave-sector-head'>Leave policy in the Private Sector</div>
                        <div className='ELeave-sector-detail'>
                            Private sector leave policies can vary widely from one organization to another. However, they must comply with the minimum standards set by labor laws in the respective states. Understanding the leave policies and entitlements is crucial for both employers and employees to ensure compliance with the law and maintain a healthy work-life balance.
                        </div>
                        <div className='ELeaveSector-Detail'>
                            {policy.map((policy) => (
                                <div className='ELeave-sector-data' key={policy.id}>
                                    <div className='ELeave-secor-dataName'>
                                        {policy.Name}
                                        {activeDropDown === policy.id ? (
                                            <CaretUp size={32} color="#3d3e3b" onClick={() => handleLeave(policy.id)} style={{cursor:'pointer'}}/>
                                        ) : (
                                            <CaretDown size={32} color="#3d3e3b" onClick={() => handleLeave(policy.id)} style={{cursor:'pointer'}}/>
                                        )}
                                    </div>
                                    {activeDropDown === policy.id ? (
                                        <div className='ELeave-sector-desc'>{policy.desc}</div>
                                    ) : (
                                        <div className='ELeave-sector-image-cont'>
                                            <img src={policy.img} alt='Policy sector Image' className='ELeave-sector-image' />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ELeave;
