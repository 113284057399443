import React, { useRef, useState, useEffect, useContext } from "react";
import AdminMenu from "../components/AdminMenu";
import "../css/AdminNotification.css";
import {CaretDown,CloudArrowUp,FileArrowDown,FilePlus,Info,MagnifyingGlass,Paperclip,Trash,XCircle,} from "@phosphor-icons/react";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toast.css";
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { TestContext } from "../components/TestContext";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function AdminNotification() {
  const { isLogin } = useContext(TestContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(isLogin);
    if (!isLogin) {
      navigate("/AdminLogin");
    }
  }, []);

  const [StateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [StateUpload, setStateUpload] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleOutsideClick = (e) => {
    if (
      !e.target.closest(".Notifi-statefilter") &&
      !e.target.closest(".Notifidropdown-menu")
    ) {
      setStateDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stateName, setStateName] = useState("");
  const [stateChoose, setStateChoose] = useState([]);
  const [stateList, setStateList] = useState([]);
  const token = "";
  const [popOpen, setPopOpen] = useState(false);

  const handlePop = () => {
    setPopOpen(true);
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const fullName = file.name;
        const baseName = fullName.substring(0, fullName.lastIndexOf(".")) || fullName;
        
        // Check for special characters & and $
        const specialCharPattern = /[&$₹/^+*%!@#]/;
        if (specialCharPattern.test(fullName)) {
            console.log("Error: File name should not contain & or $ characters.");
            toast.error("File name should not contain (& $ ₹ / ^ + * % ! @ # )characters.");
            return;
        }
        
        setFileName(baseName);

        if (file.type === "application/pdf") {
            console.log("Uploaded file:", file);
        } else {
            console.log("Error: Only PDF files are allowed.");
        }
    }
    setFile(file.name);
}; 



  const toggleStateUpload = () => {
    setStateUpload(!StateUpload);
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);

    if (!selectAll) {
      const filteredFiles = FileData.filter(
        (file) =>
          selectedStates.length === 0 || selectedStates.includes(file.stateId)
      );

      setSelectedFiles(filteredFiles.map((file) => file.fileName));
    } else {
      setSelectedFiles([]);
    }
  };

  const downloadSelectedFiles = async () => {
    const filesToDownload = FileData.filter(
      (file) =>
        selectedFiles.includes(file.fileName) &&
        (selectedStates.length === 0 || selectedStates.includes(file.stateId))
    );

    for (let file of filesToDownload) {
      await handleFileDownload(file);
    }
  };

  const deleteSelectedFiles = async () => {
    const filesToDelete = FileData.filter(
      (file) =>
        selectedFiles.includes(file.fileName) &&
        (selectedStates.length === 0 || selectedStates.includes(file.stateId))
    );

    if (filesToDelete.length === 0) {
      toast.error("No files selected for deletion.");
      return;
    }

    for (let file of filesToDelete) {
      await handleDeleteSelected(file);
    }

    toast.success("File(s) deleted successfully!");

    getAllFile();

    const remainingSelectedFiles = selectedFiles.filter(
      (fileName) => !filesToDelete.some((file) => file.fileName === fileName)
    );

    setSelectedFiles(remainingSelectedFiles);
  };

  const getStateName = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        setStateList(data);
      }
    } catch {
      console.log("error");
    }
  };

  const [FileData, setFileData] = useState([]);
  const getAllFile = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${notificationApiUrl}/api/GetFileByStateId?FolderName=${"notification"}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        setFileData(data);
      }
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    getStateName();
    getAllFile();
  }, []);

  const handleFileDownload = async (file) => {
    console.log(file.fileName);

    const encodedFileName = encodeURIComponent(file.fileName);
    try {
      const response = await fetch(
        `${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${"notification"}&FileName=${encodedFileName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "text/plain",
          },
        }
      );
      console.log(response);

      if (response.ok) {
        console.log(response);

        const textResponse = await response.text();
        const fileUrl = textResponse.replace("Url=", "");
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(
          "Failed to fetch the file URL:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };

  const [additionalLink, setAdditionalLink] = useState("");
  const [desc, setDesc] = useState("");
  const [markImp, setMarkImp] = useState(false);




  // const createFormData = (file, id) => {
  //   const fileNameWithoutExtensions = file.name
  //     .split(".")
  //     .slice(0, -1)
  //     .join(".");
  //   const fileExtensions = file.name.split(".").pop();
  //   const uniqueFileName = `${fileNameWithoutExtensions}_${timestamp}.${fileExtensions}`;

  //   const fileExtension = file.name.match(/\.[\w\d]+$/i);
  //   const fileNameWithoutExtension = file.name.replace(fileExtension, "");

  //   const formData = new FormData();
  //   formData.append("Attachment", file);
  //   formData.append("FileNameWithExtension", uniqueFileName);
  //   formData.append("Folder", "notification");
  //   formData.append("State", id);
  //   formData.append("RequestedBy", "admin");
  //   formData.append("Title", fileNameWithoutExtension || "");
  //   formData.append("FileDiscription", desc || "");
  //   formData.append("IsImportant", markImp);
  //   formData.append("AdditionalLink", additionalLink || "");
  //   formData.append("Type", 1);
  //   formData.append("EffectiveDate", effectiveDate || "0001-01-01T00:00:00");
  //   formData.append("ReleasedDate", releaseDate || "0001-01-01T00:00:00");
  //   console.log(file, file.name, id, fileName, markImp, additionalLink, desc);
  //   return formData;
  // };

  const createFormData = (file, id, updatedFileName) => {
    const fileNameWithoutExtensions = updatedFileName || file.name
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExtensions = file.name.split(".").pop();
    const timestamp = new Date().getTime().toString().slice(-4);
    const uniqueFileName = `${fileNameWithoutExtensions}_${timestamp}.${fileExtensions}`;
  
    const fileExtension = file.name.match(/\.[\w\d]+$/i);
    const fileNameWithoutExtension = updatedFileName || file.name.replace(fileExtension, "");
  
    const formData = new FormData();
    formData.append("Attachment", file);
    formData.append("FileNameWithExtension", uniqueFileName); // Use the updated file name if provided
    formData.append("Folder", "notification");
    formData.append("State", id);
    formData.append("RequestedBy", "admin");
    formData.append("Title", fileNameWithoutExtension || ""); // Use the updated title
    formData.append("FileDiscription", desc || "");
    formData.append("IsImportant", markImp);
    formData.append("AdditionalLink", additionalLink || "");
    formData.append("Type", 1);
    formData.append("EffectiveDate", effectiveDate || "0001-01-01T00:00:00");
    formData.append("ReleasedDate", releaseDate || "0001-01-01T00:00:00");
  
    console.log(file, file.name, id, updatedFileName, markImp, additionalLink, desc);
    return formData;
  };
  

  const uploadFile = async (file, id , updatedFileName) => {
    try {
      setLoading(true);
      const formData = createFormData(file, id, updatedFileName);
      console.log(id);
      const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        setLoading(false);
        console.log("Success");
        setPopOpen(!popOpen);
        toast.success("File uploaded successfully!");
        setStateName([]);
        setFile("");
        setFileName("");
        setReleaseDate(null);
        setEffectiveDate(null);
        getAllFile();
        setStateChoose([]);
      }
    } catch {
      console.log("Error");
    }
  };
  const handleUploadForAll = () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    if (!stateChoose.length) {
      toast.error("No state selected. Please select a state before uploading.");

      return;
    }

    console.log(stateChoose);

    stateChoose.forEach((id, index) => {
      setTimeout(() => {
        uploadFile(file, id , fileName);
      }, index * 2000);
    });
  };





  // const createFormData = (file, id, fileName) => {

  //   const fileNameWithoutExtensions = fileName
  //     ? fileName.split(".").slice(0, -1).join(".")
  //     : file.name.split(".").slice(0, -1).join(".");
  //   const fileExtensions = fileName
  //     ? fileName.split(".").pop()
  //     : file.name.split(".").pop();
  //   const uniqueFileName = fileName || `${fileNameWithoutExtensions}_${timestamp}.${fileExtensions}`;
  
  //   const fileExtension = file.name.match(/\.[\w\d]+$/i);
  //   const fileNameWithoutExtension = fileName || file.name.replace(fileExtension, "");
  
  //   const formData = new FormData();
  //   formData.append("Attachment", file);
  //   formData.append("FileNameWithExtension", uniqueFileName);
  //   formData.append("Folder", "notification");
  //   formData.append("State", id);
  //   formData.append("RequestedBy", "admin");
  //   formData.append("Title", fileNameWithoutExtension || "");
  //   formData.append("FileDiscription", desc || "");
  //   formData.append("IsImportant", markImp);
  //   formData.append("AdditionalLink", additionalLink || "");
  //   formData.append("Type", 1);
  //   formData.append("EffectiveDate", effectiveDate || "0001-01-01T00:00:00");
  //   formData.append("ReleasedDate", releaseDate || "0001-01-01T00:00:00");
  
  //   console.log(file, file.name, id, fileName, markImp, additionalLink, desc);
  //   return formData;
  // };
  
  // const handleUploadForAll = () => {
  //   const file = fileInputRef.current.files[0];
  //   if (!file) {
  //     console.error("No file selected");
  //     return;
  //   }
  //   if (!stateChoose.length) {
  //     toast.error("No state selected. Please select a state before uploading.");
  //     return;
  //   }
  
  //   console.log("Selected states:", stateChoose);
  //   console.log("File name:", fileName);
  
  //   stateChoose.forEach((id, index) => {
  //     setTimeout(() => {
  //       uploadFile(file, id, fileName);  
  //     }, index * 2000);
  //   });
  // };
  

  // const uploadFile = async (file, id, fileName) => {
  //   try {
  //     setLoading(true);
  //     const formData = createFormData(file, id, fileName); 
  //     console.log(id);
  
  //     const response = await fetch(`${notificationApiUrl}/api/Uploadmedia`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formData,
  //     });
  
  //     if (response.ok) {
  //       setLoading(false);
  //       console.log("Success");
  //       setPopOpen(!popOpen);
  //       toast.success("File uploaded successfully!");
  //       setStateName([]);
  //       setFile("");
  //       setFileName("");
  //       setReleaseDate(null);
  //       setEffectiveDate(null);
  //       getAllFile();
  //       setStateChoose([]);
  //     } else {
  //       console.error("Upload failed:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  






  const handleStateClick = (state, id) => {
    setStateName((prev) =>
      prev.includes(state) ? prev.filter((s) => s !== state) : [...prev, state]
    );
    setStateChoose((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  const toggleImp = () => {
    setMarkImp(!markImp);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getTimeOnly = (dateString) => {
    const date = new Date(dateString);
    const indiaTime = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

    const hours = String(indiaTime.getHours()).padStart(2, "0");
    const minutes = String(indiaTime.getMinutes()).padStart(2, "0");
    const seconds = String(indiaTime.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const [selectedSortOption, setSelectedSortOption] = useState("All Uploads");
  const [SortDropdownOpen, setSortDropdownOpen] = useState(false);

  const sortOptions = ["Today", "Recent Upload", "All Uploads"];
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const [filteredFiles, setFilteredFiles] = useState([]);

  const getTodayUploadCount = (data) => {
    const today = new Date().toISOString().split("T")[0];
    return data.filter((item) => item.createDate.split("T")[0] === today)
      .length;
  };

  useEffect(() => {
    const filterFiles = () => {
      let filtered = FileData;
      console.log(selectedStates);

      if (selectedStates.length > 0) {
        filtered = filtered.filter(
          (file) => file.stateId && selectedStates.includes(file.stateId)
        );
      }

      if (searchQuery) {
        filtered = filtered.filter((file) =>
          file.fileTitle.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      setFilteredFiles(filtered);
    };

    filterFiles();
  }, [selectedStates, searchQuery, FileData]);

  const sortFiles = (files, sortOption) => {
    const today = new Date().toDateString();
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(new Date().getDate() - 3);
    const threeDaysAgoString = threeDaysAgo.toDateString();

    switch (sortOption) {
      case "Recent Upload":
        return files
          .filter(
            (file) =>
              new Date(file.createDate).toDateString() >= threeDaysAgoString
          )
          .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
      case "Today":
        return files.filter(
          (file) => new Date(file.createDate).toDateString() === today
        );
      case "All Uploads":
      default:
        return files;
    }
  };

  const sortedFiles = sortFiles(filteredFiles, selectedSortOption);

  const handleFileSelect = (fileName) => {
    setSelectedFiles((prev) =>
      prev.includes(fileName)
        ? prev.filter((name) => name !== fileName)
        : [...prev, fileName]
    );
  };


  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (SortDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [SortDropdownOpen]);



  const toggleSortDropdown = () => setSortDropdownOpen((prev) => !prev);
  const toggleStateDropdown = () => setStateDropdownOpen((prev) => !prev);

  const handleSortOptionClick = (option) => {
    setSelectedSortOption(option);
    setSortDropdownOpen(false);
  };

  const handleStateFilter = (id) => {
    console.log(id);

    setSelectedStates((prev) => {
      const alreadySelected = prev.includes(id);
      return alreadySelected
        ? prev.filter((stateId) => stateId !== id)
        : [...prev, id];
    });
  };

  // -------------------DATE PICKER ---------------------------

  const [showEffectiveDatePicker, setShowEffectiveDatePicker] = useState(false);
  const [showReleaseDatePicker, setShowReleaseDatePicker] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [releaseDate, setReleaseDate] = useState(null);

  const handleDateClick = (picker) => {
    if (picker === "effective") {
      setShowEffectiveDatePicker(!showEffectiveDatePicker);
      setShowReleaseDatePicker(false);
    } else if (picker === "release") {
      setShowReleaseDatePicker(!showReleaseDatePicker);
      setShowEffectiveDatePicker(false);
    }
  };
  const formatUploadDate = (date) => {
    console.log(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDateSelect = (date, picker) => {
    const selectedDate = new Date(date);
    const formattedDate = formatUploadDate(selectedDate);

    if (picker === "effective") {
      setEffectiveDate(formattedDate);
      setShowEffectiveDatePicker(false);
    } else if (picker === "release") {
      setReleaseDate(formattedDate);
      setShowReleaseDatePicker(false);
    }
  };

  const handleDeleteSingle = async (fileName) => {
    try {
      const response = await fetch(
        `${holidayApiUrl}/api/DeleteFileAndData?Folder=${"notification"}&FileName=${fileName}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to delete file with FileName ${fileName}:`,
          response.statusText
        );
        toast.error(`Failed to delete file: ${fileName}`);
      } else {
        console.log(`File with FileName ${fileName} deleted successfully.`);
        toast.success(`File deleted successfully: ${fileName}`);

        getAllFile();

        setFileData((prev) =>
          prev.filter((file) => file.fileName !== fileName)
        );
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast.error("Error deleting file.");
    }
  };

  const handleDeleteSelected = async (file) => {
    try {
      const response = await fetch(
        `${holidayApiUrl}/api/DeleteFileAndData?Folder=${"notification"}&FileName=${
          file.fileName
        }`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to delete file with FileName ${fileName}:`,
          response.statusText
        );
      } else {
        console.log(`File with FileName ${fileName} deleted successfully.`);
      }

      setSelectedFiles([]);
      setSelectAll(false);

      console.log("Deleted selected files.");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };


const handleFileNameChange = (e) => {
  setFileName(e.target.value);
};


const [showMessage, setShowMessage] = useState(false);

const toggleMessage = () => {
  setShowMessage(!showMessage);
};

const messageRef = useRef(null);


useEffect(() => {
  const handleClickOutside = (event) => {
    if (messageRef.current && !messageRef.current.contains(event.target)) {
      setShowMessage(false);
    }
  };

  if (showMessage) {
    document.addEventListener("mousedown", handleClickOutside);
  } else {
    document.removeEventListener("mousedown", handleClickOutside);
  }

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [showMessage]);

  return (
    <div className="AdminNotifi">
      {/* <AdminMenu  /> */}

      <div style={{ width: `calc(100% - 200px)` }}>
        <div className="Admin-Notifi">
          <ToastContainer />
          <div className="Notifi-detail">
            <div className="Notifi-detail1">
              <div className="Notifi-detail1-name">Notification</div>

              <div className="Actsinfo-design">
              <div className="Notifi-detail1-upload" onClick={handlePop}>
                <FilePlus size={24} color="#009746" /> Upload File
              </div>

              <div style={{ position: "relative", display: "inline-block" }}>
              <Info size={22} color="#009746" style={{cursor:'pointer'}} onClick={toggleMessage} />

              {showMessage && (
        <div className="info-pdf" ref={messageRef} >
          Upload .pdf or .xlsx Files
        </div>
      )}
              </div>

                
              </div>
           
            </div>
            <div className="Notifi-search">
              <MagnifyingGlass size={20} color="#4d4d4d" />
              <input
                placeholder="Search for Acts"
                type="search"
                className="Notifi-searchbar"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="Notifi-data">
            <div className="Notifi-countDetail">
              <div className="NotifiCount">
                {getTodayUploadCount(FileData)} Uploads
                <span className="Notifi-count">Today</span>
              </div>
              <div className="Notifi-Filter">
                <div
                  className="Notifi-statefilter"
                  onClick={toggleStateDropdown}
                >
                  State <CaretDown size={20} color="#4d4d4d" />
                  {StateDropdownOpen && (
                    <div className="Notifidropdown-menu">
                      {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((option, id) => (
                        <div className="Notifidropdown-item" key={id} >
                          <div onClick={() => handleStateFilter(option.id) }className="Notifidropdown-item">
                          <input
                            type="checkbox"
                            className="Notifidropdown-item-check"
                            
                            checked={selectedStates.includes(option.id)}
                          />
                            <div className="state-option">  {option.state} </div>
                            </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="Notifi-sortfilter" onClick={toggleSortDropdown} ref={dropdownRef}>
                  Sort By <CaretDown size={20} color="#4d4d4d" />
                  {SortDropdownOpen && (
                    <div className="Notifidropdown-menu">
                      {sortOptions.map((option, index) => (
                        <div
                          className="Notifidropdown-item"
                          key={index}
                          onClick={() => handleSortOptionClick(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {FileData.length !== 0 ? (
              <div className="Notifi-FilteringDetail">
                <div className="Notifi-select">
                  <input
                    type="checkbox"
                    className="Notifi-select-checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  Select All
                </div>
                <div className="Notifi-delete-download">
                  <Trash
                    size={20}
                    color="#898989"
                    onClick={deleteSelectedFiles}
                    style={{ cursor: "pointer" }}
                  />
                  <span
                    className="NotifiDownload"
                    onClick={downloadSelectedFiles}
                  >
                    <FileArrowDown
                      size={20}
                      color="#898989"
                      style={{ cursor: "pointer" }}
                    />{" "}
                    Download file
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="NotifiData-NoFilteringDetail"
                style={{
                  fontFamily: "Ariel,sans-serif,sanserif",
                  fontSize: "18px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No record
              </div>
            )}

            <div className="NotifiData-Detail">
              {sortedFiles.map((data) => {
                const state = stateList.find(
                  (state) => state.id === data.stateId
                );
                return (
                  <div key={data.id} className="Notifi-InfoAll">
                    <input
                      type="checkbox"
                      className="Upload-select-checkbox"
                      checked={selectedFiles.includes(data.fileName)}
                      onChange={() => handleFileSelect(data.fileName)}
                    />
                    <div className="Notifi-FileInfo">
                      <div className="Notifi-FileData">
                        <div className="Notifi-FileName">
                          <div style={{ display: "flex" }}>
                            {data.fileTitle}{" "}
                            {data.important && (
                              <span className="Notifi-Imp">
                                Marked as Important
                              </span>
                            )}
                          </div>

                          <div className="Desc-design">
                            {data.fileDescription}
                          </div>
                        </div>

                        <div className="add-trash">
                          <Trash
                            size={20}
                            color="#898989"
                            onClick={() => {
                              handleDeleteSingle(data.fileName);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <div
                            className="Notifi-FileDownload"
                            onClick={() => handleFileDownload(data)}
                          >
                            <FileArrowDown
                              size={20}
                              color="#898989"
                              style={{ cursor: "pointer" }}
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div className="Notifi-File-Detail">
                        <div className="Notifi-StateDate">
                          <div className="Notifi-file-state">
                            Applicable states -{" "}
                            {state ? state.state : "For all states"}
                          </div>
                          <div className="Notifi-file-date">
                            Updated as on {formatDate(data.createDate)}
                          </div>
                        </div>
                        <div className="Notifi-timeRecent">
                          <div></div>
                          <div style={{ display: "flex", gap: "16px" }}>
                            <div className="Notifi-recent">
                              {data.UploadedData}
                            </div>
                            <div className="Notifi-time">
                              Time {getTimeOnly(data.createDate)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {popOpen && <div className="Notification-overlay"></div>}
        {popOpen && (
          <div className="Notifi-popup">
            <div className="Notifi-pop-Uplaod">
              <span>Upload</span>
              <XCircle
                size={20}
                onClick={() => {
                  setPopOpen(false);
                  setStateName("");
                  setFileName("");
                  setReleaseDate(null);
                  setFile("");
                  setEffectiveDate(null);
                }}
              />
            </div>
            <div className="Notifi-pop-UplaodFile" onClick={handleUploadClick}>
              <CloudArrowUp size={60} color="#a8b8b9" weight="bold" style={{cursor:'pointer'}}/>
              {!file ? (
                <div style={{cursor:"pointer"}}> Uploaded file of 10MB limit</div>
              ) : (
                <div> File Selected Successfully</div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".pdf"
                onChange={handleFileChange}
              />
            </div>
            <div className="Notifi-pop-FileName">
              <input
                placeholder="File Name"
                className="Notifi-pop-input"
                value={fileName}
                onChange={handleFileNameChange} 
              />
              <div className="Notifi-pop-state" onClick={toggleStateUpload}>
                {stateName.length === 0 ? "Select Applicable State" : stateName}
                <CaretDown size={20} color="#4d4d4d" />
                {StateUpload && (
                  <div className="dropdown-menu-notifi">
                    {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      }).map((option, index) => (
                      <div
                        className="dropdown-item-notifi"
                        key={index}
                        onClick={() => {
                          handleStateClick(option.state, option.id);
                        }}
                      >
                        {option.state}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%", position: "relative" }}>
                <div
                  onClick={() => handleDateClick("effective")}
                  className="NotificationDateFil"
                >
                  {effectiveDate
                    ? `Effective Date: ${effectiveDate}`
                    : "Select Effective Date"}
                  <CaretDown size={20} />
                </div>
                {showEffectiveDatePicker && (
                  <div
                    className="date-picker-container"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      position: "absolute",
                      top: "35px",
                    }}
                  >
                    <DatePicker
                      calendarClassName="custom-calendar"
                      className="gc-withimage-dateinput"
                      selected={effectiveDate}
                      name="effectiveDate"
                      onChange={(date) => handleDateSelect(date, "effective")}
                      showPopperArrow={false}
                      dateFormat="dd/MM/yyyy"
                      inline
                    />
                  </div>
                )}
              </div>

              <div style={{ width: "48%", position: "relative" }}>
                <div
                  onClick={() => handleDateClick("release")}
                  className="NotificationDateFil"
                >
                  {releaseDate
                    ? `Release Date: ${releaseDate}`
                    : "Select Release Date"}
                  <CaretDown size={20} />
                </div>
                {showReleaseDatePicker && (
                  <div
                    className="date-picker-container"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      position: "absolute",
                      top: "35px",
                    }}
                  >
                    <DatePicker
                      calendarClassName="custom-calendar"
                      className="gc-withimage-dateinput"
                      selected={releaseDate}
                      name="releaseDate"
                      onChange={(date) => handleDateSelect(date, "release")}
                      showPopperArrow={false}
                      dateFormat="dd/MM/yyyy"
                      inline
                    />
                  </div>
                )}
              </div>
            </div>

            <input
              className="Notifi-pop-desc"
              placeholder="Enter description"
              onChange={(e) => setDesc(e.target.value)}
            />
            <div className="Notifi-pop-link">
              Add links <Paperclip size={20} color="#6c625b" weight="bold" />
              <input
                className="act-pop-url"
                onChange={(e) => setAdditionalLink(e.target.value)}
              />
            </div>
            <div className="Notifi-pop-mark">
              <div className="Notifi-pop-imp">
                <input
                  type="checkbox"
                  className="Notifi-pop-impCheck"
                  onClick={toggleImp}
                />{" "}
                Mark Important
              </div>
              <div className="Notifi-pop-upload" onClick={handleUploadForAll}>
                Upload
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminNotification;
