import React, { useEffect, useRef, useState } from 'react';
import { CalendarDots, CaretDown, CaretLeft, CaretUp, FileArrowDown, MagnifyingGlass } from '@phosphor-icons/react';
import '../css/ELabourState.css';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function ELabourState() {
  const { id } = useParams();
  const params = useParams();
  const uniqueId = params.id;
  const [stateName, setStateName] = useState('');
  const [stateId, setStateId] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileData, setfiledata] = useState([]);
  const [filePresent, setFilePresent] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectFileName,setSelectFileName]=useState('')
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
  const token = '';

  const handleStateClick = (name, id) => {
    console.log(id);
    setStateName(name);
    setStateId(id);
    setDropdownVisible(false);
  };

  const getStateName = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStateList(data);
        setStateName(data?.[uniqueId - 1]?.state);
        setLoading(false)
      }
    } catch {
      console.log('error');
    }
  };
  
  const getFile = async (id) => {
    console.log(id,"THIS IS ID!!!!!!!!!!!!!!!!");
    
    if(id !== null && id !== undefined && id !== ''){
      try {
        setLoading(true)
        const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'notification'}&StateId=${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setfiledata(data);
          setFilePresent(true);
        }
        setLoading(false)
      } catch {
        console.log("Error");
      }
    }
    
  };
  useEffect(() => {
    if (stateId) {
        getFile(stateId);
        
        navigate(`/StateLabour/${stateId}`, { replace: true }); 
    } else {
        getFile(id);
    }
}, [stateId]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const notificationFiles = fileData.filter((file) => file.fileSource === 'notification');
      setSelectedFiles(notificationFiles.map((file) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleFileSelect = (id,name) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter((fileId) => fileId !== id));
      setSelectFileName(name);
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  const handleFileDownload = async (file) => {
    // console.log(file);
    try {
      const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'notification'}&FileName=${file.fileName}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'text/plain'
        }
      });
  
      if (response.ok) {
        const textResponse = await response.text();
        const fileUrl = textResponse.replace("Url=", "");
  
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Failed to fetch the file URL:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };
//   const downloadSelectedFiles = async () => {
//     for (let id of selectedFiles) {
//         const file = fileData.find(file => file.id === id);
//         if (file) {
//             await handleFileDownload(file);
//         } else {
//             console.error(`File with ID ${id} not found.`);
//         }
//     }
// };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleback = () => {
    navigate('/LabourLaw');
  };
useEffect(()=>{
  getStateName();
},[])
  useEffect(() => {
  
    getFile(stateId);
   
  }, [stateId]);
  
  const [filteredFiles, setFilteredFiles] = useState(fileData);
  const [searchQuery, setSearchQuery] = useState('');
 // Inside useEffect
useEffect(() => {
  const filterFiles = () => {
    let filtered = fileData;

    if (searchQuery) {
      filtered = filtered.filter(file =>
        file.fileTitle && file.fileTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    filtered.sort((a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate));

    setFilteredFiles(filtered);
  };

  filterFiles();
}, [searchQuery, fileData]);

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};
  //-----------download file --------------------------//
 
  const notificationFiles = fileData.filter((file) => file.fileSource === 'notification');
  // const notificationFilesCount = notificationFiles.length;
 
// ------------- Date-------------------
const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(false);
  const datePickerRef = useRef(null);

  const handleDateClick = () => {
    setShowDatePicker(prev => !prev);
  };

  const handleDateSelect = (date) => {
    const selectedDate = new Date(date.setHours(0, 0, 0, 0)); 
    setStartDate(selectedDate);
    setShowDatePicker(false);
    setDateFilter(true);
  };

  useEffect(() => {
    // Handle clicks outside of the DatePicker to close it
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


// Helper function to format date as 'yyyy-MM-dd'
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const filterFilesByDate = (file, selectedDate) => {
  if (!file.effectiveDate || file.effectiveDate === '0001-01-01T00:00:00') {
    return false; // Skip files with an invalid or default effective date
  }

  // Convert effectiveDate string to a Date object for comparison
  const fileEffectiveDate = new Date(file.effectiveDate.split("T")[0]);

  // Compare formatted dates (both should be at midnight)
  return formatDate(fileEffectiveDate) === formatDate(selectedDate);
};

const stateBanner = useStateBanner(stateId);
const dropdownRef = useRef(null);
const datedownRef=useRef(null)
const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
    }
};
useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
const handleClickOutsidedate = (event) => {
  if (datedownRef.current && !datedownRef.current.contains(event.target)) {
    setShowDatePicker(false); 
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsidedate);
  return () => {
      document.removeEventListener('mousedown', handleClickOutsidedate);
  };
}, []);
const notificationFilesCount = dateFilter
  ? filteredFiles.filter((file) => filterFilesByDate(file, startDate) && file.fileSource === 'notification').length
  : filteredFiles.filter(file => file.fileSource === 'notification').length;


// ------------- Handle Download for Filtered Files ----------------
const downloadSelectedFiles = async () => {
  const filesToDownload = dateFilter
    ? filteredFiles.filter((file) => selectedFiles.includes(file.id) && filterFilesByDate(file, startDate))
    : selectedFiles.map(id => fileData.find(file => file.id === id));
  
  for (let file of filesToDownload) {
    if (file) {
      await handleFileDownload(file);
    } else {
      console.error(`File with ID not found.`);
    }
  }
};
const [backgroundImage, setBackgroundImage] = useState(null);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);

  return (
    <div className='ELabourState-Top' >
      <div className='ELabourState-main'>
        <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`,
           
               }}
               className='Elabour-back'>

         
          <div className='ELabourState-maintext' onClick={toggleDropdown}>
            {stateName}
            {!dropdownVisible ? <CaretDown size={24} /> : <CaretUp size={24} />}
          </div>
        </div>
        {dropdownVisible && (
  <div className='ELabourState-dropdown' ref={dropdownRef}>
    {stateList.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      })
      .map((states, index) => (
        <div key={index} className='ELabourStateNames' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

        <div className='ELabourState-Data'>
        <div className='ELabourBack' onClick={handleback}>
            <CaretLeft size={24} />Back
          </div>
          {loading && (
          <div
            style={{
              display: "flex",
              width: "80%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
          <div className='ELabourState-search'>
            <MagnifyingGlass size={20} color='#4d4d4d' />
            <input placeholder='Search for Notification' type='search' className='ELabourState-searchbar'value={searchQuery} onChange={handleSearchChange} />
          </div>
          <div className='ELabourState-FilteringDetail'>
            <div className='ELabourState-select'>
              <input
                type='checkbox'
                className='ELabourState-select-checkbox'
                checked={selectAll}
                onChange={handleSelectAll}
              />
              Select All
            </div>
        
            <div className='ELabourDateFil'>
      <div onClick={handleDateClick} className='date-trigger'>
        <CalendarDots size={20} />
        Effective Date
        {dateFilter && (
          <button
            className="clear-date-filter"
            onClick={() => {
              setStartDate(null); 
              setDateFilter(false); 
              setFilteredFiles(fileData);
            }}
          >
            x
          </button>
        )}
      </div>
      <div className='ELabourPicker' ref={datePickerRef}>
        {showDatePicker && (
          <div className="date-picker-container" style={{ display: 'flex', alignItems: 'start' }}>
            <DatePicker
              calendarClassName="custom-calendar"
              className="gc-withimage-dateinput"
              selected={startDate}
              onChange={handleDateSelect} // Use handleDateSelect to update date and close picker
              showPopperArrow={false}
              dateFormat="dd/MM/yyyy" // Use the correct date format for your needs
              inline
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2030-12-31")}
            />
          </div>
        )}
      </div>
    </div>


            
          </div>
          <div className='ELabourDate'>
          <div className='ELabourStateLength'>{notificationFilesCount} Results found</div>
          <div className='ELabourState-delete-download' >
              <span className='ELabourStateDownload'  onClick={downloadSelectedFiles}>
                <FileArrowDown size={20} color='#009746' />
                Download file
              </span>
            </div>
          </div>
      
          
          <div className='ELabourState-Detail'>
          {!dateFilter
            ? filePresent &&
              filteredFiles.map((file, id) => (
                file.fileSource === 'notification' && (
                  <div key={id} className='ELabourState-InfoAll'>
                    <input
                      type='checkbox'
                      className='ELabourState-select-checkbox'
                      checked={selectedFiles.includes(file.id)}
                      onChange={() => handleFileSelect(file.id, file.fileName)}
                    />
                    <div className='ELabourState-FileInfo'>
                      <div className='ELabourState-FileData'>
                        <div>
                        <div className='ELabourState-FileName'>
                          {file.fileTitle}
                          
                          {file.
important ? <span className='Notifi-ImpE'>Marked Important</span> : <div></div>}

                        
                        </div>
                        <div className="Desc-design">
                            {file.fileDescription}
                          </div>
                          </div>
                       
                        <div className='ELabourStateDownloadP' onClick={()=>{handleFileDownload(file)}}><FileArrowDown size={20} color='#FFFFFF '/>Download</div>
                      </div>
                      <div className='ELabourState-File-Detail'>
                        <div className='ELabourState-StateDate'>
                          <div className='ELabourState-file-date'>Effective Date {file.effectiveDate!=='0001-01-01T00:00:00'?file.effectiveDate.split("T")[0]:'NA'}</div>
                          <div className='ELabourState-file-date'>Release Date {file.releasedDate!=='0001-01-01T00:00:00'?file.releasedDate.split("T")[0]:'NA'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ))
            : filePresent &&
              filteredFiles
                .filter((file) => filterFilesByDate(file, startDate))
                .map((file, id) => (
                  file.fileSource === 'notification' && (
                    <div key={id} className='ELabourState-InfoAll'>
                      <input
                        type='checkbox'
                        className='ELabourState-select-checkbox'
                        checked={selectedFiles.includes(file.id)}
                        onChange={() => handleFileSelect(file.id, file.fileName)}
                      />
                      <div className='ELabourState-FileInfo'>
                        <div className='ELabourState-FileData'>
                          <div className='ELabourState-FileName'>
                            {file.fileTitle}
                            {file.IsImportant ? <span className='Notifi-ImpE'>Marked Important</span> : <div></div>}
                          </div>
                        </div>
                        <div className='ELabourState-File-Detail'>
                          <div className='ELabourState-StateDate'>
                            <div className='ELabourState-file-date'>Effective Date {file.effectiveDate!=='0001-01-01T00:00:00'?file.effectiveDate.split("T")[0]:'NA'}</div>
                            <div className='ELabourState-file-date'>Release Date {file.releasedDate!=='0001-01-01T00:00:00'?file.releasedDate.split("T")[0]:'NA'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ELabourState;
