import React, { useEffect, useRef, useState } from 'react';
import '../css/Navbar.css';
import TalentTraverseLogo from '../Images/TalentTraverseLogo.png';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useNavigate, useLocation } from 'react-router-dom';

function Navbar() {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current path
    const dropdownRef = useRef(null);
    const [selectedOptions, setSelectedOptions] = useState({});

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    const handleNavigate = (path) => {
        window.scrollTo(0, 0); 
        setIsMenuActive(false);
        navigate(path);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsMenuActive(false);
            setActiveDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDropdownClick = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const handleOptionClick = (dropdownIndex, option) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [dropdownIndex]: option
        }));
        handleNavigate(option.path);
    };

    // Helper function to check if a route is active
    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className='navbar-main'>
            <div className='nav-company' onClick={() => handleNavigate('/')}>
                <img src={TalentTraverseLogo} alt="Talent Traverse Logo" className='company-logo-img' />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div className='company-name'>Talent Traverse HR TECH Solutions PVT LTD</div>
                    <div className='beyond'>Beyond Boundaries</div>
                </div>
            </div>
            <div className='nav-services'>
                <div className='services-hamburger' onClick={toggleMenu}>
                    <span className='hamburger'></span>
                    <span className='hamburger'></span>
                    <span className='hamburger'></span>
                </div>
                <div ref={dropdownRef} className={`services-list ${isMenuActive ? 'active' : ''}`}>
                    <button className='close-btn' onClick={toggleMenu}>×</button>
                    <div className='all-services'>
                        <div onClick={() => handleNavigate('/')} className={`service-name ${isActive('/') ? 'highlighted' : ''}`}>
                            Home
                        </div>
                        <div className={`service-name ${activeDropdown === 1 ? 'active' : ''}`} onClick={() => handleDropdownClick(1)}>
                            <div className='mon-dropdown'>About Us
                            <div className='dropdown-content'>
                                <p className={`drop-down ${isActive('/AboutUs') ? 'highlighted' : ''}`} onClick={() => handleOptionClick(1, { path: '/AboutUs' })}>
                                    <span className='dropdown-head'>Our Company</span>
                                </p>
                                <p className={`drop-down ${isActive('/#OurMission') ? 'highlighted' : ''}`} onClick={() => handleOptionClick(1, { path: '/#OurMission' })}>
                                    <span className='dropdown-head'>Our Mission and Vision</span>
                                </p>
                                <p className={`drop-down ${isActive('/OurTeam') ? 'highlighted' : ''}`} onClick={() => handleOptionClick(1, { path: '/OurTeam' })}>
                                    <span className='dropdown-head'>Our Team</span>
                                </p>
                                <p className={`drop-down ${isActive('/Career') ? 'highlighted' : ''}`} onClick={() => handleOptionClick(1, { path: '/Career' })}>
                                    <span className='dropdown-head'>Careers</span>
                                </p>
                            </div>
                            </div>
                            <span className='dropdown-arrow'>
                                {activeDropdown === 1 ? <CaretUp className='arrow' /> : <CaretDown className='arrow' />}
                            </span>
                           
                        </div>

                        <div className={`service-name ${activeDropdown === 2 ? 'active' : ''}`} onClick={() => handleDropdownClick(2)}>
                           <div className='mon-dropdown'> Services
                           <div className='dropdown-content-service'>
                                <div style={{ width: '100%' }} className='dropdown-service-mobile'>
                                    <p className={`drop-down1 ${isActive('/EstablishCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/EstablishCompliance')}>
                                        <span className='dropdown-head'>Establishments Compliances</span>
                                    </p>
                                    <p className={`drop-down1 ${isActive('/factoryCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/factoryCompliance')}>
                                        <span className='dropdown-head'>Factory Compliances</span>
                                    </p>
                                    <p className={`drop-down1 ${isActive('/IndustryCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/IndustryCompliance')}>
                                        <span className='dropdown-head'>Industrial Licensing</span>
                                    </p>
                                </div>
                                <div style={{ width: '100%' }} className='dropdown-service-mobile'>
                                    <p className={`drop-down1 ${isActive('/PayrollCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/PayrollCompliance')}>
                                        <span className='dropdown-head'>Payroll Statutory Compliance</span>
                                    </p>
                                    <p className={`drop-down1 ${isActive('/RiskCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/RiskCompliance')}>
                                        <span className='dropdown-head'>Compliances Risk Audit</span>
                                    </p>
                                    <p className={`drop-down1 ${isActive('/VendorCompliance') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/VendorCompliance')}>
                                        <span className='dropdown-head'>Vendor compliance Management</span>
                                    </p>
                                    <p className={`drop-down1 ${isActive('/PayrollOutSource') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/PayrollOutSource')}>
                                        <span className='dropdown-head'>Payroll Outsourcing Service</span>
                                    </p>
                                </div>
                            </div>
                           </div>
                            <span className='dropdown-arrow'>
                                {activeDropdown === 2 ? <CaretUp className='arrow' /> : <CaretDown className='arrow' />}
                            </span>
                           
                        </div>
                        <div className={`service-name ${activeDropdown === 3 ? 'active' : ''}`} onClick={() => handleDropdownClick(3)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div className='mon-dropdown'>E-Library
                                <div className='dropdown-content'>
                                <p className={`drop-down ${isActive('/LabourLaw') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/LabourLaw')}>
                                    <span className='dropdown-head'>Notifications</span>
                                </p>
                                <p className={`drop-down ${isActive('/ActsandRules') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ActsandRules')}>
                                    <span className='dropdown-head'>Acts and Rules</span>
                                </p>
                                <p className={`drop-down ${isActive('/MinimumWage') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/MinimumWage')}>
                                    <span className='dropdown-head'>Minimum wages</span>
                                </p>
                                <p className={`drop-down ${isActive('/ProfessionalTax') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ProfessionalTax')}>
                                    <span className='dropdown-head'>Professional Tax</span>
                                </p>
                                <p className={`drop-down ${isActive('/LabourWelfare') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/LabourWelfare')}>
                                    <span className='dropdown-head'>Labour Welfare Fund</span>
                                </p>
                                <p className={`drop-down ${isActive('/LeaveAndWorkingHour') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/LeaveAndWorkingHour')}>
                                    <span className='dropdown-head'>Leave and Working Hour</span>
                                </p>
                                <p className={`drop-down ${isActive('/HolidayList') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/HolidayList')}>
                                    <span className='dropdown-head'>Holiday List</span>
                                </p>
                                <p className={`drop-down ${isActive('/LabourCode') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/LabourCode')}>
                                    <span className='dropdown-head'>Labour Codes</span>
                                </p>
                            </div>
                                </div>
                                <span className='dropdown-arrow'>
                                    {activeDropdown === 3 ? <CaretUp className='arrow' /> : <CaretDown className='arrow' />}
                                </span>
                            </div>
                            
                        </div>
                        <div className={`service-name ${activeDropdown === 4 ? 'active' : ''}`} onClick={() => handleDropdownClick(4)}>
                            <div className='mon-dropdown'>Resources
                            <div className='dropdown-content'>
                                <p className={`drop-down ${isActive('/ComingSoon') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ComingSoon')}><span className='dropdown-head'>Event</span></p>
                                <p className={`drop-down ${isActive('/ComingSoon') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ComingSoon')}><span className='dropdown-head'>Blog</span></p>
                                <p className={`drop-down ${isActive('/ComingSoon') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ComingSoon')}><span className='dropdown-head'>Webinar</span></p>
                                <p className={`drop-down ${isActive('/ComingSoon') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ComingSoon')}><span className='dropdown-head'>Testimonials</span></p>
                            </div>
                            </div>
                            <span className='dropdown-arrow'>
                                {activeDropdown === 4 ? <CaretUp className='arrow' /> : <CaretDown className='arrow' />}
                            </span>
                           
                        </div>
                        <div className={`service-name ${activeDropdown === 5 ? 'active' : ''}`} onClick={() => handleDropdownClick(5)}>
                            <div className='mon-dropdown'>Login
                            <div className='dropdown-content'>
                                <p className={`drop-down ${isActive('/ClientHome') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/ClientHome')}>
                                    <span className='dropdown-head'>Customer Login</span>
                                </p>
                                <p className='drop-down'>
                                    <span className='dropdown-head'>Employee Login</span>
                                </p>
                                <p className={`drop-down ${isActive('/AdminLogin') ? 'highlighted' : ''}`} onClick={() => handleNavigate('/AdminLogin')}>
                                    <span className='dropdown-head'>Admin Login</span>
                                </p>
                            </div>
                            </div>
                            <span className='dropdown-arrow'>
                                {activeDropdown === 5 ? <CaretUp className='arrow' /> : <CaretDown className='arrow' />}
                               
                            </span>
                           
                        </div>
                    </div>
                    <button className='enquire-button' onClick={() => handleNavigate('/#Query')}>Enquire Now</button>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
