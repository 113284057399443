import React, { useEffect, useRef, useState } from 'react';
import '../css/EMinimumWage.css';
import { BellRinging, Building, CaretDown, CaretUp, Coins, LinkedinLogo, MapPin, HandCoins, XCircle } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import {useStateImages} from '../components/StateEmblem';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import {useStateMap} from '../components/StateEmblem';
import coming from '../Images/ComingSoon.png'
import { CircularProgress } from '@mui/material';
function EMinimumWage() {
    const [stateList, setStateList] = useState([]);
    const [stateSelect, setStateSelect] = useState('');
    const [stateDropDown, setStateDropDown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStates, setFilteredStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedState, setSelectedState] = useState(null); 
    const navigate = useNavigate();
    const token = '';
    const stateImages = useStateImages(token);
    const getStateName = async () => {
       
        try {
            setLoading(true)
            const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                const sortedData = data.sort((a, b) => 
                    a.state.toLowerCase().localeCompare(b.state.toLowerCase())
                );
    
                setStateList(sortedData);
                setFilteredStates(sortedData);// Initialize filteredStates
                setLoading(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStateName();
    }, []);

    useEffect(() => {
        // Filter states based on the search term
        const filtered = stateList.filter(state => 
            state.state.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredStates(filtered);
    }, [searchTerm, stateList]);

   

    const toggleDropdown = () => {
        setStateDropDown(!stateDropDown);
    };

    const handleState = (name) => {
        setStateSelect(name);
        setSearchTerm(name); // Update search term with the selected state's name
        setStateDropDown(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (!stateDropDown) {
            setStateDropDown(true); // Show dropdown when search term is entered
        }
    };

    const handleOutsideClick = (e) => {
        if (e.target.closest('.EMinimum-search') === null) {
            setStateDropDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);
    const handleNavigate = (id) => {
        const state = filteredStates.find(state => state.id === id); // Find the selected state
        setSelectedState(state); // Set the selected state
        navigate(`/StateMinimumWage/${id}`);
    };
    
    const Wage = [
        {
            id: 1,
            icon: <Coins size={32} color="#403183" />,
            title: 'Cost of Living:',
            desc: 'Regional variations in the cost of living significantly impact the minimum wage rates.'
        },
        {
            id: 2,
            icon: <LinkedinLogo size={32} color="#403183" />,
            title: 'Skill Level:',
            desc: 'Minimum wages are often differentiated by the skill level of the worker.'
        },
        {
            id: 3,
            icon: <MapPin size={32} color="#403183" />,
            title: 'Region',
            desc: 'States may have different rates for different regions within the state to account for urban and rural cost differences.'
        },
        {
            id: 4,
            icon: <Building size={32} color="#403183" />,
            title: 'Industry Type',
            desc: 'Different industries may have different minimum wage rates. For instance, the wages for the construction sector may differ from those in the textile sector.'
        },
    ];
    const normalizeStateName = (name) => {
        return name.toLowerCase().replace(/\s+/g, '');
      };
      const stateMap = useStateMap(token);
      const [stateUpdates, setStateUpdates] = useState({});
      const hasFetchedData = useRef(false);
      const formatDate = (dateString) => {
        console.log(dateString);
        
        const date = new Date(dateString);
        console.log(Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }).format(date));
        
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }).format(date);
      }
      const fetchAllActsData = async () => {
        try {
            
          const response = await fetch(`${notificationApiUrl}/api/GetFileByStateId?FolderName=${'MinWages'}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": 'application/json'
            }
          });
          if (response.ok) {
            const data = await response.json();
            return data;
          }
        } catch (error) {
          console.error('Failed to fetch acts data:', error);
        }
        return [];
      };
      
      useEffect(() => {
        if (hasFetchedData.current) return; // Skip if data has been fetched
      
        const fetchData = async () => {
          if (filteredStates.length === 0) return; // Exit if there are no states
      
          const counts = {};
          const updates = {};
      
          const allData = await fetchAllActsData();
      
          for (const state of filteredStates) {
            const stateData = allData.filter(item => item.stateId === state.id);
            const stateCount = stateData.length;
            counts[state.id] = stateCount;
            console.log(stateData);
            
            // Store all effective dates in an array
            const effectiveDates = stateData.map(item => item.effectiveDate);
            
            // Get the last effective date from the array
            const lastEffectiveDate = effectiveDates.length > 0 ? effectiveDates[effectiveDates.length - 1] : '0001-01-01T00:00:00';
      
            // Check if the last effective date is the special case '0001-01-01T00:00:00'
            if (lastEffectiveDate === '0001-01-01T00:00:00') {
              updates[state.id] = 'NA';
            } else {
              // Use your existing formatDate function
              updates[state.id] = formatDate(lastEffectiveDate);
            }
          }
      
          // setFilteredStateCounts(counts);
          setStateUpdates(updates);
          hasFetchedData.current = true;
        };
      
        fetchData();
      }, [filteredStates]);
      
      
      const [reminder, setReminder]=useState(false)
const handleReminder=()=>{
    setReminder(!reminder)
}
    return (
        <div className='EMinimumTop' >
            <div className='EMinimumMain'>
                <div className='EMinimumHead'>
                    <div className='EMinimumName'>
                        <HandCoins className='EMinimumGavel' />
                        <div className='EMinimumNameFull'>Minimum Wages</div>
                    </div>
                    <div className='EMinimumDetail'>
                        Minimum wages in India are determined by both the central and state governments, taking into account various factors such as the cost of living, industry type, and the region. The minimum wage framework in India is guided by the Minimum Wages Act, 1948.
                    </div>
                </div>
                <div className='EMinimum-state-search'>
                    <div className='EMinimum-wage-data'>
                        <div className='EMinimum-wage-head'>Factors Influencing Minimum Wages</div>
                        <div className='EMinimum-wage-detail'>
                            {Wage.map((wage) => (
                                <div className='EMinimum-wage-single' key={wage.id}>
                                    <div className='EMinimum-wage-singleHead'>
                                        <div>{wage.icon}</div>{wage.title}
                                    </div>
                                    <div className='EMinimum-wage-singledesc'>{wage.desc}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='EMinimum-remainder'>
                        <div className='EMinimum-remainder-head'>
                            <BellRinging size={32} color="#403183" />
                            Set reminder for the upcoming notifications from different states now!
                        </div>
                        <div className='EMinimum-remainder-button'  onClick={handleReminder} >Set Reminder</div>
                        
                       
                    </div>
                    {reminder && <div className="EMin-overlay"></div>}
                    {reminder&&(
                <div className='EMinReminderPop'>
                  
                       <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}> <XCircle size={20} color="rgb(217, 214, 230)" weight="fill"  onClick={handleReminder} style={{cursor:'pointer'}}/></div>
                       <div className='ETaxRemButtons'>

                    <div >Coming Soon</div>
                    <div className='comingsoondiv'><img src={coming} style={{width:'100%',height:'100%'}}/></div>
                    </div>
                </div>
            )
            }
                    <div className='EMinimum-Search-head'>Search for Minimum Wage Details</div>
                         <div className="EMinimum-search" onClick={toggleDropdown}>
                        {stateDropDown ? (
                            <CaretUp size={20} color="#4d4d4d" onClick={toggleDropdown} />
                        ) : (
                            <CaretDown size={20} color="#4d4d4d" onClick={toggleDropdown} />
                        )}
                        <input
                            placeholder="Search for States and Union Territories"
                            type="search"
                            className="EMinimum-searchbar"
                            value={searchTerm }
                            onChange={handleSearchChange}
                            onFocus={() => setStateDropDown(true)}
                        />
                           {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
                        {stateDropDown && (
                                                <div className='EMinimum-dropdown'>
                                                    {filteredStates.length > 0 ? (
                                                        filteredStates.sort((a, b) => {
                                                            if (a.state === "Central") return -1; 
                                                            if (b.state === "Central") return 1;
                                                            return a.state.localeCompare(b.state); 
                                                          }).map((state, index) => (
                                                            <div key={index} className='EHMinimumDropName' onClick={() => handleState(state.state)}>
                                                                {state.state}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className='EHMinimumDropName'>No states available</div>
                                                    )}
                                                </div>
                                            )}
                    </div>
                 

                       
                   
                    <div className='EMinimumData'>
                        {loading && <div>Loading...</div>}
                        {error && <div>Error: {error}</div>}
                        {selectedState ? (
    <div
        className='EMinimumSingle'
        style={{
            backgroundImage: `url(${stateMap[selectedState.id]})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: 'rgba(78, 175, 78, 0.15)',
            minHeight:'250px' 
        }}
        key={selectedState.id}
        onClick={() => handleNavigate(selectedState.id)}
    >
        <div className='EMinimumStateFLag'>
            {stateImages[selectedState.id] ? (
                <img
                    src={stateImages[selectedState.id]}
                    alt={selectedState.state}
                    style={{ height: 'inherit', width: 'inherit' }}
                />
            ) : (
                <span>No image available</span>
            )}
        </div>
        <div className='EMinimumStateName1'>{selectedState.state}</div>
        {/* <div className='EMinimumUpdate'>Effective date {selectedState.createdDate}</div> */}
    </div>
) : (
    filteredStates.length > 0 && filteredStates.sort((a, b) => {
        if (a.state === "Central") return -1; 
        if (b.state === "Central") return 1;
        return a.state.localeCompare(b.state); 
      }).map((state) => (
        <div
            className='EMinimumSingle'
            style={{
                backgroundImage: `url(${stateMap[state.id]})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: 'rgba(78, 175, 78, 0.15)', 
                minHeight:'250px'
            }}
            key={state.id}
            onClick={() => handleNavigate(state.id)}
        >
            <div className='EMinimumStateFLag'>
                {stateImages[state.id] ? (
                    <img
                        src={stateImages[state.id]}
                        alt={state.state}
                        style={{ height: 'inherit', width: 'inherit' }}
                    />
                ) : (
                    <span>No image available</span>
                )}
            </div>
            <div className='EMinimumStateName1'>{state.state}</div>
            <div className='EMinimumUpdate'>Effective date {stateUpdates[state.id] || 'NA'}</div>
        </div>
    ))
)}


                    </div>
                </div>
            </div>
        </div>
    );
}

export default EMinimumWage;
