import React,{useEffect, useState} from 'react'
import '../css/EWelfareState.css'
import { CaretDown, CaretLeft, CaretUp, Paperclip } from '@phosphor-icons/react'
import { useNavigate, useParams } from 'react-router-dom';
import {useStateBanner} from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg'
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';
function EWelfareState() {
  const { id, name } = useParams();
  console.log(name);
  
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const states=['Karnataka','Kerala', 'Andra Pradesh', 'Madhya Pradesh']
    useEffect(()=>{
      window.scrollTo(0,0)
    },[]);
  const navigate=useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
    console.log("Hii");
  };
    const handleback=()=>{
      navigate('/LabourWelfare')
    }
    const params=useParams();
    const uniqueId=params.id;
    const [stateName, setStateName]=useState(name);
    const [stateId, setStateId]=useState(id);
    const [loading, setLoading] = useState(false);
    const handleStateClick = (name, id) => {
      // Check if the new state is different from the current state
      if (name !== stateName || id !== stateId) {
        console.log(id);
        setProf([]);
        setStateName(name);
        setStateId(id);
        setDropdownVisible(false);
        setAct('');
        setApplicability('');
        setFreq('');
        setARule('');
        setInfoLinkContent('');
        setInfoFileContent([]);
      }
    };
  
    const [stateList,setStateList]=useState([]);
  const token=''
  const getStateName=async()=>{
    try{
      const response=await fetch(`${notificationApiUrl}/api/GetAllState`,{
        method:'GET',
        headers:{
          Authorization:`Bearer${token}`,
          "Content-Type":'application/json',
        }, 
      })
    
      if(response.ok){
        const data=await response.json();
        setStateList(data);
        setStateName(data?.[uniqueId-1]?.state);
       
      }
    }
    catch{
  console.log("error");
    }
  }
  useEffect(()=>{
    getStateName();
  },[])
  //-GET ---
  const [info,setInfo]=useState([])
  const [act,setAct]=useState()
  const [rule,setARule]=useState()
  const [applicability,setApplicability]=useState()
  const [freq,setFreq]=useState()
  const [infoLinkContent,setInfoLinkContent]=useState(null)
  const [infoFileContent,setInfoFileContent]=useState(null);
  const [infoData,setInfoData]=useState(null)
  const [filteredData,setFilteredData]=useState(null);
  const [tableData,setTableData]=useState(null);
  const getinfo = async (id) => {
    console.log('Fetching info for state ID:', id);
    try {
      setLoading(true)
      const response = await fetch(`${holidayApiUrl}/api/GetTaxAndFundFile?StateId=${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0 && stateId !== 0 && stateId !== 'undefined') {
          const filtered = data.filter(item => item.folder === 'LabourWelfare' && item.act !== '');
          console.log(filtered,"This is filtered");
          console.log("This is filtered data");
          
          setFilteredData(filtered); 
          if (filtered.length > 0) {
            console.log(filtered.length-1);
            
              setAct(filtered[filtered.length-1].act || '');
              setApplicability(filtered[filtered.length-1]?.applicability || '');
              setFreq(filtered[filtered.length-1]?.frequency || '');
              setARule(filtered[filtered.length-1]?.rule || '');
              setInfoLinkContent(filtered[filtered.length-1]?.link || '');
              
          }
          const filedata=filtered.filter(item=>  item.fileName.split('.').pop()!=='txt')
          if (filedata.length > 0) {  // Check filedata.length instead of filedata > 0
            setInfoFileContent(filedata.map(item => item.fileName));
            
          }
         if(filtered.length!==0){
          console.log("SETTable TRue");
          
          setTableData(true);
         }
         else {
          console.log("SETTable false");
          setTableData(false);
         }
        
      }
      else{
        setTableData(false);
      }
      setLoading(false) 
      } else {
        
        console.error('Failed to fetch info:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching info:', error);
    }
  };
  

  const [prof,setProf]=useState([])
  const getProf=async(id)=>{
    try{
      setLoading(true)
      const response=await fetch(`${holidayApiUrl}/api/GetLabourWelfare?StateId=${id}`,
        {
          method:'GET',
          headers:{
            Authorization:`Bearer${token}`,
            "Content-Type":'application/json'
          }
        }
      )
      if(response.ok){
        const data=await response.json();
        setProf(data)
        setTableData(true);
        setLoading(false)
      }
    }
    catch{

    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (stateId) {
        console.log("If condition");
        
        await getinfo(stateId);
        await getProf(stateId);
        navigate(`/StateLabourWelfare/${stateId}`, { replace: true });
      } else {
        console.log("Else condition");
        
        await getProf(id);
        await getinfo(id);
      }
    };
  
    fetchData();
  }, [stateId]);
  

const stateBanner = useStateBanner(stateId);
console.log(stateBanner);

// const backgroundImage = stateBanner[id] || StateImage ; 
// console.log(backgroundImage);
const handleFileDownload = async (fileName) => {
  try {
    const response = await fetch(`${notificationApiUrl}/api/GetDownloadFileUrl?FolderName=${'LabourWelfare'}&FileName=${fileName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'text/plain'
      }
    });

    if (response.ok) {
      const textResponse = await response.text();
      const fileUrl = textResponse.replace("Url=", "");

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Failed to fetch the file URL:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error occurred while downloading the file:", error);
  }
};

const handleWebsiteClick = (url) => {
  const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
  window.open(formattedUrl, '_blank');
};
const [backgroundImage, setBackgroundImage] = useState(StateImage);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id] || StateImage);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);
const handleOutsideClick = (e) => {
  if (e.target.closest('.EWelfareState-maintext') === null) {
    setDropdownVisible(false);
  }
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => document.removeEventListener('click', handleOutsideClick);
}, []);
  return (
    <div style={{width:'1280px' , overflow:'hidden'}} >
    <div className='EWelfareState-main'>
        <div style={{
               background:`linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`
               }} className='EWelfare-back'>
            
            <div className='EWelfareState-maintext' onClick={toggleDropdown}>{stateName} {!dropdownVisible?<CaretDown size={32}/>:<CaretUp size={32}/>}
            {dropdownVisible && (
  <div className='EWelfareState-dropdown'>
    {stateList.filter(option => option.state !== "Central")
      ?.slice()
      .sort((a, b) => a.state.localeCompare(b.state))
      .map((states, index) => (
        <div key={index} className='EWelfareStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

            </div>
            
        </div>
       
        <div className='EWelfareState-Data'>
        <div className='EWelfareBack' onClick={handleback}><CaretLeft size={30} />Back</div>
        {tableData ? (
          <div className='EWelfareState-list'>
  <div className='EWelfareState-listHead'>Information</div>
  <div className='EWelfareStateDetail'>
    <div className='EWelfareStateDetailHead'>{stateName}</div>
    <div className='Ewelfarediv'>
    <table className='EWelfareStateDetailTable'>
      <tbody>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Act</td>
          <td className='EWelfareDetailSubCont'>{act}</td>
        </tr>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Rule</td>
          <td className='EWelfareDetailSubCont'>{rule}</td>
        </tr>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Applicability</td>
          <td className='EWelfareDetailSubCont'>{applicability}</td>
        </tr>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Frequency</td>
          <td className='EWelfareDetailSubCont'>{freq}</td>
        </tr>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Form</td>
          <td className='EWelfareDetailSubCont'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {infoFileContent?.map((info, id) => (
                <div key={id} className='EWelfareDetailSubCont1'>
                  <Paperclip size={18} color='#009746' />
                  <div
                    className='EWelfareFile'
                    onClick={() => handleFileDownload(info)}
                  >
                    {info.split('_').slice(0, -1).join('_') + '.' + info.split('_').slice(-1)[0].split('.').pop()}
                  </div>
                </div>
              ))}
            </div>
          </td>
        </tr>
        <tr className='EWelfareStateDetailSub'>
          <td className='EWelfareStateDetailName'>Website</td>
          <td className='EWelfareDetailSubCont'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='EWelfareDetailSubCont1'>
                <Paperclip size={18} color='#009746' />
                <div
                className='EWelfareFile'
                  onClick={() => handleWebsiteClick(infoLinkContent)}
                >
                  {infoLinkContent}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    </div>
   
  </div>
  {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
  <div className='Ewelfarediv'>
    <div className='EWelfareWrapper'>
  <table className='EWelfareTable'>
    <thead>
      <tr className='EWelfareTableHead'>
        <th className='EWelfareTHeading'>Category</th>
        <th className='EWelfareTHeading'>Employee Contribution</th>
        <th className='EWelfareTHeading'>Employer Contribution</th>
        <th className='EWelfareTHeading'>Total Contribution</th>
        <th className='EWelfareTHeading'>Late Date for Submission</th>
        <th className='EWelfareTHeading'>Date of Deduction</th>
      </tr>
    </thead>
    <tbody>
      {prof.length !== 0 && prof.map((item) => (
        <tr key={item.id} className='EWelfareTableData'>
          <td className='EWelfareTableDetail'>{item.category === null ? '1' : item.category}</td>
          <td className='EWelfareTableDetail'>{item.employeeContribution}</td>
          <td className='EWelfareTableDetail'>{item.employerContribution}</td>
          <td className='EWelfareTableDetail'>{item.totalContribution}</td>
          <td className='EWelfareTableDetail'>{item.deductiondate.split("T")[0]}</td>
          <td className='EWelfareTableDetail'>{item.latedateForsubmission.split("T")[0]}</td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
  </div>

 
</div>
        ):(
          <div className='EWelfareState-list'>
  <div className='EWelfareState-listHead'>No Information</div>
  {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
  </div>
        )}
        

        </div>
        {isDropdownVisible && (
        <div className='EWelfareState-dropdown'>
            {states?.map((states,id)=>(
                <div className='EWelfareState-dropdowndata' key={id}>{states}</div>
            ))}
        </div>
      )}
    </div>
</div>
  )
}

export default EWelfareState