import React, { useEffect, useRef, useState } from 'react'
import '../css/ELabourCode.css'
import { CaretDown, CaretUp, FilmScript } from '@phosphor-icons/react'
import LabourCode1 from '../Images/LabourCode1.jpg'
import LabourCode2 from '../Images/LabourCode2.jpg'
import LabourCode3 from '../Images/LabourCode3.jpg'
import LabourCode4 from '../Images/LabourCode4.jpg'
function ELabourCode() {
    const services=[{
        id:1,
        service:' The Code on Wages',
        year:'2019',
        desc:" Is one of the four labor codes introduced by the Indian government to consolidate and simplify the complex and outdated labor laws in India. This code consolidates four existing laws"
    },
    {
        id:2,
        service:'The Code on Social Security',
        year:'2020',
        desc:' Is part of the labor reforms introduced by the Indian government to streamline and modernize the complex framework of social security laws. It consolidates and amends several existing laws relating to social security with the aim of extending coverage to all workers, including those in the unorganized sector.'
    },
    {
        id:3,
        service:'The Industrial Relations Code',
        year:' 2020',
        desc:' Is one of the four labor codes aimed at reforming and consolidating labor laws in India. It merges and simplifies three major labor laws related to industrial relations, providing a framework for the regulation of labor and employment conditions.'
    },
    {
        id:4,
        service:'OSH Code',
        year:'2020',
        desc:"is part of the Indian government's initiative to simplify and consolidate labor laws. It aims to regulate the safety, health, and working conditions of workers across various sectors."
    },
   
]
    const  feature=[
        [
        ],
        [
            {
                id:1,
                name:'Uniform Definition of Wages',
                desc:'The code provides a uniform definition of wages across all labor laws, which include basic pay, dearness allowance, and retaining allowance. This helps in avoiding disputes and confusion over the calculation of wages.'
            },
            {
                id:2,
                name:'Coverage',
                desc:'The Code applies to all employees irrespective of the wage ceiling and sector, whether organized or unorganized.'
            },
            {
                id:3,
                name:'Minimum Wages',
                desc:'It mandates the central government to fix a floor wage, considering the living standards of workers. States cannot fix minimum wages below this floor wage. Different minimum wages can beset for different regions or sectors.'
            },
            {
                id:4,
                name:'Payment of Wages',
                desc:'The code stipulates that wages must be paid in a timely manner and without unauthorized deductions. It sets deadlines for wage payments, varying by employment type.'
            },
            {
                id:5,
                name:'Payment of Bonus',
                desc:'Employees earning wages up to a certain threshold are eligible for an annual bonus, the percentage of which is based on the company’s profits.'
            },
            {
                id:6,
                name:'Equal Remuneration',
                desc:' The code ensures equal pay for equal work to both male and female employees, preventing gender discrimination in wage payments.'
            },
            {
                id:7,
                name:'Advisory Boards',
                desc:'The code proposes the establishment of central and state advisory boards, comprising representatives of employees, employers, and independent persons, to advise on various matters including minimum wages, and working hours.'
            },
            {
                id:8,
                name:'Inspection and Compliance',
                desc:'The code introduces an inspector-cum-facilitator approach to ensure compliance. This aims to promote ease of compliance and reduce the inspector raj (inspector dominance).'
            },
            {
                id:9,
                name:'Grievance Redressal',
                desc:'It provides a mechanism for workers to raise disputes regarding wage payments, and specifies a 3-year time limit for filing claims related to wage disputes.'
            },
            {
                id:10,
                name:'Penalties for Non-compliance',
                desc:'The code outlines penalties for employers failing to comply with its provisions. These penalties vary based on the nature and severity of the non-compliance.'
            },
        ],
        [
            {
                id:1,
                name:'Consolidation of Laws',
                desc:"The code amalgamates nine central labor laws:1. The Employees’ Compensation Act, 19232. The Employees’ State Insurance Act, 19483. The Employees’ Provident Funds and Miscellaneous Provisions Act, 19524. The Employment Exchanges (Compulsory Notification of Vacancies) Act, 19595. The Maternity Benefit Act, 19616. The Payment of Gratuity Act, 19727. The Cine Workers Welfare Fund Act, 19818. The Building and Other Construction Workers Welfare Cess Act, 19969. The Unorganised Workers Social Security Act, 2008"
            },
            {
                id:2,
                name:'Extension of Social Security',
                desc:' The code aims to extend social security benefits to all employees and workers in both the organized and unorganized sectors, including gig workers and platform workers.'
            },
            {
                id:3,
                name:'Social Security Schemes',
                desc:" The central government may notify various social security schemes for workers, including provident fund, employees' state insurance, gratuity, maternity benefits, employment injury benefits, and others."
            },
            {
                id:4,
                name:'Gig and Platform Workers',
                desc:'The code recognizes gig and platform workers and mandates the formulation of schemes for their social security. This is a significant move towards inclusive social security coverage.'
            },
            {
                id:5,
                name:'National Social Security Board',
                desc:' A National Social Security Board will be established to recommend and monitor schemes for unorganized workers, gig workers, and platform workers. Similar boards will be set up at the state level.'
            },
            {
                id:6,
                name:'Registration and Aggregators',
                desc:' All unorganized workers, gig workers, and platform workers must register on a government portal to avail of social security benefits. Aggregators (digital platforms) employing gig and platform workers will contribute to their social security.'
            },
            {
                id:7,
                name:'Maternity Benefits',
                desc:'The code retains provisions for maternity benefits, including paid leave and medical bonus for women workers.'
            },
            {
                id:8,
                name:"Employees’ Provident Fund (EPF) and Employees’ State Insurance (ESI)",
                desc:'The code provides for the extension of EPF and ESI benefits to more establishments, reducing the threshold for coverage.'
            },
            {
                id:9,
                name:'Gratuity',
                desc:'The code continues the provision of gratuity for employees, payable on resignation, retirement, or death, with specific conditions for different categories of workers.'
            },
            {
                id:10,
                name:'Welfare Funds',
                desc:'The code allows the creation of welfare funds for different sectors, financed through contributions from employers, employees, and the government.'
            },
            {
                id:11,
                name:'Inspector-cum-Facilitators',
                desc:'The code introduces inspector-cum-facilitators to ensure compliance and provide guidance, promoting ease of compliance for employers.'
            },
            {
                id:12,
                name:'Digital Framework',
                desc:'A digital framework will be established for the registration of workers and to facilitate the implementation of social security schemes'
            },
            {
                id:13,
                name:'Penalties for Non-compliance',
                desc:'The code outlines penalties for employers who fail to comply with its provisions, including fines and imprisonment.'
            },
        ],
        [
            {
                id:1,
                name:'Consolidation of Laws',
                desc:'The code consolidates three major laws:1. The Trade Unions Act, 19262. The Industrial Employment (Standing Orders) Act, 19463. The Industrial Disputes Act, 1947'
            },
            {
                id:2,
                name:'Trade Unions',
                desc:'The code allows for the registration of trade unions and recognizes them as representatives of workers. It mandates that a trade union must have at least 10% of the workers or 100 workers, whichever is less, as members for registration.- It also introduces the concept of a sole negotiating union if it has 51% or more of the workers as members. If no single union meets this criterion, a negotiating council will be formed with representatives from various unions'
            },
            {
                id:3,
                name:'Standing Orders',
                desc:'Establishments with 300 or more workers must prepare standing orders on matters such as classification of workers, holidays, and termination of employment. These orders must be approved by the appropriate government.'
            },
            {
                id:4,
                name:'Strikes and Lockouts',
                desc:'The code introduces stricter provisions for strikes and lockouts. Workers in all industrial establishments are required to give a 60-day notice before going on strike. Similarly, employers must provide a notice of lockout.- Strikes and lockouts are prohibited during conciliation proceedings and for seven days after their conclusion, and during arbitration or tribunal proceedings and for 60 days after their conclusion.'
            },
            {
                id:5,
                name:'Trade Unions',
                desc:'The code sets up mechanisms for the resolution of industrial disputes, including conciliation officers, Industrial Tribunals, and a National Industrial Tribunal.- It emphasizes the role of voluntary arbitration for dispute resolution and mandates the formation of Grievance Redressal Committees in establishments with 20 or more workers'
            },
            {
                id:6,
                name:'Standing Orders',
                desc:'Establishments with 300 or more workers must seek prior permission from the appropriate government before lay-off, retrenchment, or closure. The threshold can be increased by the government through notification.- The code provides for a compensation mechanism for workers in case of lay-offs or retrenchment'
            },
            {
                id:7,
                name:'Strikes and Lockouts',
                desc:'A fund will be established to re-skill workers who are retrenched, with contributions from employers equal to 15 days’ wages for every retrenched worker.'
            },
            {
                id:8,
                name:'Resolution of Industrial Disputes',
                desc:'The code allows fixed-term employment, which ensures the same benefits and conditions as permanent workers for the duration of their contract.'
            },{
                id:9,
                name:'Lay-offs, Retrenchment, and Closure',
                desc:'The code provides for the establishment of Industrial Tribunals and a National Industrial Tribunal for the resolution of disputes and grievances.- It also introduces a two-member Industrial Tribunal (comprising a Judicial Member and an Administrative Member) for quicker dispute resolution'
            },{
                id:10,
                name:'Worker Re-skilling Fund',
                desc:'The code specifies penalties for various offenses, including illegal strikes and lockouts, non-compliance with standing orders, and non-payment of compensation to workers'
            },

        ],
        [
            {
                 id:1,
                name:'Consolidation of Laws',
                desc:'The OSH Code consolidates and replaces 13 existing labor laws:1. The Factories Act, 19482. The Mines Act, 19523. The Dock Workers (Safety, Health and Welfare) Act, 19864. The Building and Other Construction Workers (Regulation of Employment and Conditions of Service) Act, 19965. The Plantations Labour Act, 19516. The Contract Labour (Regulation and Abolition) Act, 19707. The Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Act, 19798. The Working Journalists and other Newspaper Employees (Conditions of Service and Miscellaneous Provisions) Act, 19559. The Working Journalists (Fixation of Rates of Wages) Act, 195810. The Motor Transport Workers Act, 196111. The Sales Promotion Employees (Conditions of Service) Act, 197612. The Beedi and Cigar Workers (Conditions of Employment) Act, 196613. The Cine Workers and Cinema Theatre Workers (Regulation of Employment) Act, 198'
            },
            {
                id:2,
                name:'Coverage',
                desc:'The code applies to all establishments employing 10 or more workers and all mines and docks.'
            },
            {
                id:3,
                name:'Registration and Licensing',
                desc:'Establishments covered under the code must register with the authorities. The code also mandates a single licensing system for contractors across different states, replacing multiple licenses.'
            },
            {
                id:4,
                name:'Health and Safety Standards',
                desc:'The code specifies duties of employers to ensure a safe working environment, which includes providing a safe workplace, safety training, and emergency health facilities.- It prescribes measures for the health and safety of workers in various sectors, such as factories, mines, and construction.'
            },
            {
                id:5,
                name:'Working Hours and Leave',
                desc:'The code regulates working hours, ensuring that no worker is required to work more than 8 hours a day and 48 hours a week. Overtime work is permissible with compensation.- It mandates provisions for annual leave with wages, sick leave, and other leave entitlements.'
            },
            {
                id:6,
                name:'Welfare Facilities',
                desc:'Employers are required to provide welfare facilities like canteens, first aid, crèches, and welfare officers, depending on the size of the establishment and the number of workers.'
            },
            {
                id:7,
                name:'Occupational Health Centers*',
                desc:'For establishments employing a large number of workers, the code mandates the establishment of occupational health centers to provide health services and surveillance.'
            },
            {
                id:8,
                name:'Contract Workers',
                desc:'The code provides protection to contract workers, including timely payment of wages, safety measures, and access to welfare facilities.'
            },
            {
                id:9,
                name:'Inter-State Migrant Workers*',
                desc:'The code introduces provisions to protect inter-state migrant workers, ensuring their registration and providing benefits such as displacement allowance and journey allowance'
            },
            {
                id:10,
                name:'Compliance and Enforcement',
                desc:'The code introduces a robust inspection mechanism, with provisions for self-certification and third-party certification to simplify compliance.- Inspector-cum-facilitators will be appointed to ensure compliance and assist employers and workers.'
            },
            {
                id:11,
                name:'Penalties for Non-Compliance',
                desc:'The code specifies penalties for various offenses, ranging from fines to imprisonment, depending on the severity of the violation.'
            },
            {
                id:12,
                name:'Grievance Redressal',
                desc:"Establishments must set up a grievance redressal committee to address workers' grievances and ensure their prompt resolution."
        },
    ]       
    ]
    const images=[{
        id:1,
        image:LabourCode1,
    },
    {
        id:2,
        image:LabourCode2,
    },
    {
        id:3,
        image:LabourCode3,
    },
    {
        id:4,
        image:LabourCode4,
    },
]
    const objectives=[
        [

        ],
        [
            {
                id:'01',
                name:'Simplification',
                desc:'Streamline the existing labor laws into a single code to make them more understandable and accessible.',
            },
            {
                id:'02',
                name:'Protection',
                desc:'Ensure minimum wage protection and timely payment of wages to all workers',
            },
            {
                id:'03',
                name:'Uniformity',
                desc:' Establish a uniform framework for wage regulation across various states and sectors.',
            },
            {
                id:'04',
                name:'Ease of Compliance',
                desc:'Simplify the compliance process for employers and reduce the regulatory burden',
            },
        ],
        [
            {
                id:'01',
                name:'Inclusivity',
                desc:'Extend social security benefits to all workers, especially those in the unorganized sector, gig economy, and platform economy.',
            },
            {
                id:'02',
                name:'Simplification',
                desc:'Consolidate and simplify existing social security laws to create a coherent and accessible framework.',
            },
            {
                id:'03',
                name:'Protection',
                desc:'Ensure comprehensive social security coverage, including health benefits, provident fund, and maternity benefits.',
            },
            {
                id:'04',
                name:'Digitalization',
                desc:'Leverage digital platforms for the registration of workers and the administration of social security schemes',
            },
        ], 
        [
            {
                id:'01',
                name:'Simplification',
                desc:'Consolidate and streamline existing laws to make them more comprehensible and less burdensome.',
            },
            {
                id:'02',
                name:'Flexibility',
                desc:"Provide flexibility to employers while ensuring workers' rights and welfare."
            },
            {
                id:'03',
                name:'Dispute Resolution',
                desc:'Establish clear mechanisms for the resolution of industrial disputes.',
            },
            {
                id:'04',
                name:'Worker Protection',
                desc:'Safeguard the interests of workers through provisions on standing orders, notice periods, and compensation',
            },
        ],
        [
            {
                id:'01',
                name:'Simplification',
                desc:'Consolidate multiple laws into a single code to make compliance easier for employers and improve the regulatory framework.',
            },
            {
                id:'02',
                name:'Safety',
                desc:'Worker  Ensure the safety, health, and welfare of workers across various sectors.',
            },
            {
                id:'03',
                name:'Uniform Standards',
                desc:'Establish uniform safety and health standards across industries.',
            },
            {
                id:'04',
                name:'Ease of Doing Business',
                desc:'Simplify registration and licensing processes to promote ease of doing business',
            },
        ]
]
    const [tabActive,setTabActive]=useState(1)
    const handleTabClick=(id)=>{
        console.log(id);
        setTabActive(id)
    }
   
console.log(tabActive);
const [visibleItems, setVisibleItems] = useState(4);
    const [viewMore, setViewMore] = useState(false);
    const containerRef = useRef(null);

// const handleViewMore = () => {
// if (viewMore) {
//   setVisibleItems(3); 
// } else {
//   setVisibleItems(feature[tabActive].length); 
// }
// setViewMore(!viewMore);
// };
// const closeDropdownOnScroll = (entries) => {
// const [entry] = entries;
// if (!entry.isIntersecting) {
//   setViewMore(false);
//   setVisibleItems(3);
// }
// };
const handleViewMore = () => {
    if (viewMore) {
        setVisibleItems(4); // When collapsing, show 3 items
    } else {
        setVisibleItems(feature[tabActive].length); // When expanding, show all items
    }
    setViewMore(!viewMore);
};

const closeDropdownOnScroll = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
        setViewMore(false);
        setVisibleItems(4);
    }
};

useEffect(() => {
    const observer = new IntersectionObserver(closeDropdownOnScroll, {
        root: null,
        threshold: 0,
    });

    if (containerRef.current) {
        observer.observe(containerRef.current);
    }

    return () => {
        if (containerRef.current) {
            observer.unobserve(containerRef.current);
        }
    };
}, [containerRef, viewMore]);

useEffect(() => {
const observer = new IntersectionObserver(closeDropdownOnScroll, {
  root: null,
  threshold: 0,
});

if (containerRef.current) {
  observer.observe(containerRef.current);
}

return () => {
  if (containerRef.current) {
    observer.unobserve(containerRef.current);
  }
};
}, [containerRef, viewMore]);
  return (
    <div>
        <div className='Code-Main'>
        <div className='Code-head'>
            <FilmScript size={32} color='#FECC07'/>
            Labour Codes
        </div>
        <div className='Code-component'>
        <div className='Code-allData'>
            <div className='Code-allhead'>
            Four types of Labour Codes in India
           </div>
            <div className='Code-content'>
            {services?.map((service, id) => (
                    <div className='Code-service-data' key={id}>
                        <div className='Code-service-idname'>
                            <div className='Code-service-id
                            '>{service.id}</div>
                            <div className='Code-content-name'>{service.service}</div>
                        </div>
                        <div className='Code-content-explain'>
                            {service.desc}
                        </div>
                        
                    </div>
                ))}
            </div>
        </div>
        <div className='Code-tab-alldata'>
            <div className='Code-alltab'>
                {services?.map((tabs,id)=>(
                    <div className={`Code-tab${tabActive===tabs.id?'active':''}`} onClick={()=>{handleTabClick(tabs.id)}}>{tabs.service}<spna>{tabs.year}</spna></div>
                ))}
            </div>
            <div className='Code-tab-detail'>
                <div className='Code-alltab-first' ref={containerRef}>
                    {tabActive !== null && services.filter((service) => service.id === tabActive)?.map((tabname,id)=>(
                        <div className='Code-alltab-text'>Key Feauture of {tabname.service} </div>
                    ))}
                    
                    <div className='Code-aspect-data'>
                        <div className='Code-aspect-detail'>
                        {tabActive !== null && feature[tabActive]?.slice(1, visibleItems).map((lawdata, index) => (
                <div key={index} className='codeKey'>
                  <div className='Code-aspect-title'>{lawdata.name}</div>
                  <div className='Code-aspect-exp'>{lawdata.desc}</div>
                </div>
              ))}
                                             {tabActive !== null && feature[tabActive].length > 3 && (
                <span onClick={()=>{handleViewMore(tabActive)}} className='Code-view-all'>
                  {viewMore ? 'View Less' : 'View All'}
                  {viewMore ? <CaretUp size={22} color="#000000" style={{ cursor: 'pointer' }} /> : <CaretDown size={22} color="#000000" style={{ cursor: 'pointer' }} />}
                </span>
              )}

                        </div>
                        {tabActive !== null && images.filter((image) => image.id === tabActive)
              .map((image, index)=> ( 
                        <div className='Code-aspect-image'><img src={image.image} className='Code-aspect-img'/></div>))}
                    </div>
                </div>
                <div className='Code-alltab-second'>
                    <div className='Code-alltab-text'>Objectives</div>
                    <div className='Code-alltab-secondData'>
                        {tabActive!==null && objectives[tabActive]?.map((objective,id)=>(
                            <div className='Code-alldata-objective'>
                            <div className='Code-alldata-ObjNum'>{objective.id}</div>
                            <div className='Code-alldata-ObjDesc'><span className='Code-alldata-ObjName'>{objective.name}</span>{objective.desc}</div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

        </div>
        
    </div>
    </div>
    
  )
}

export default ELabourCode