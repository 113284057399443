import { createContext, useEffect, useState } from "react";
 
export const TestContext = createContext();
 
export const TestContextProvider = ({ children }) => {
 
const [isLogin, setIsLogin] = useState(false);
useEffect(()=>{
    console.log(isLogin);
    
},[isLogin])
return(
<TestContext.Provider
      value={{
        isLogin, setIsLogin
       }}
    >
      {children}
    </TestContext.Provider>
);
};