import React, { createContext, useState  } from "react";
import Footer from "./Footer";
import AdminNavbar from "./AdminNavbar";
import AdminMenu from "./AdminMenu";
import { Outlet } from 'react-router-dom';

export const TabContext = createContext();
function AdminLayout(){
    return(
        <div>
            <AdminNavbar/>
            <div style={{width:'100%', display:'flex'}}>
                <AdminMenu className="AdminMenu" />
                <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                <Outlet />
                </div>
            </div>
            
            <Footer/>
        </div>
    );
}
export default AdminLayout;
{/* <TabContext.Provider value={{ tab, setTab }}>
             {children}
           </TabContext.Provider> */}