import React from 'react'
import '../css/AboutUs.css'
import { Chats } from '@phosphor-icons/react';
import Approach from '../Images/Approach.jpg' 
import BackToTop from '../components/BackToTop';
function AboutUs() {
  return (
    <div  style={{maxWidth:'100%'}}>
        <div className='AboutUs' >
        <div className='about-head'>
        <div className='about-head-maintext'>Our Company</div>
        <div className='about-head-text'>Innovative solutions tailored to your business needs.</div>
        </div>
        <div className='about-quote'>
            <div className='about-open-comma'>“</div>
            <div className='about-content'>With 25+ years of expertise, we offer reliable compliance services, tech-driven payroll solutions, and sustainable EHS advisory. Our industry knowledge keeps your business compliant, payroll accurate, and operations environmentally responsible. We tailor our services to your unique needs, streamlining processes, reducing errors, and saving you time, so you can focus on growing your business</div>
            {/* <div className='about-close-comma'>“</div> */}
        </div>
        <div className='about-story-service'>
            <div className='about-story'>
                <div>Our Story</div>
                <div className='about-story-ex'>
                <Chats className='about-chat' color="#fed226" weight="fill" />
                <div className='about-story-desc'>Talent Traverse HR Tech Solutions Pvt Ltd was founded with a mission to simplify HR management for businesses of all sizes. What started as a passionate endeavor has evolved into a dedicated team of professionals. We are committed to delivering unparalleled service and value, guiding our clients through the complexities of HR with expertise and care.</div>
                </div>
                
            </div>
            <div className='about-service'>
                <div>Our Services</div>
                <div className='about-service-ex'>
                <Chats className='about-chat' color="#fed226" weight="fill" />
                <div className='about-service-desc'>We provide a comprehensive suite of HR solutions designed to address your specific needs. Our offerings include compliance and risk management, talent acquisition, employee engagement, and payroll services. Each solution is customized to ensure we meet your unique requirements effectively.</div>
                </div>  
            </div>  
        </div>
        <div className='about-approach'>
            <div className='about-approach-text'>Our Approach</div>
            <div className='about-approach-detail' style={{display:'flex'}}>
                <div className='about-approach-explain'>At Talent Traverse HR Tech Solutions Pvt Ltd, we adopt a proactive stance on HR management, aiming to foresee and resolve potential issues before they arise. By collaborating closely with our clients, we gain a deep understanding of their unique challenges and craft tailored solutions to address them efficiently.</div>
                <img src={Approach} className='about-imgapproch'/>
                </div>
        </div>
     </div>
     
 </div>
  )
}

export default AboutUs;
