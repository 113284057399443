import React from 'react'
import '../css/Establishment.css'
import EstablishComp from '../Images/EstablishComp.png'
import EstHandshake from '../Images/EstHandshake.png'
import EstOrder from '../Images/EstOrder.png'
import EstNotedown from '../Images/EstNotedown.png'
import EstScale from '../Images/EstScale.png'
import EstScale2 from '../Images/EstScale2.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Establishment1 from '../Images/Establishment1.jpg'
import Establishment2 from '../Images/Establishment2.jpg'
import Establishment3 from '../Images/Establishment3.jpg'
import Establishment4 from '../Images/Establishment4.jpg'
import Establishment5 from '../Images/Establishment5.jpg'
import checklist1 from '../Images/checklist1.png'
import person2 from '../Images/person2.png'
import brain3 from '../Images/brain3.png'
import pin from '../Images/pin.png'
import folder from '../Images/folder.png'
import { Gavel, NewspaperClipping, Scroll, SuitcaseSimple, UsersThree } from '@phosphor-icons/react'

function Establishment() {
    const whyCompliance=[{
        id:1,
        image:Establishment1,
        descName:'Legal Obligations ',
        desc:'Compliance ensures businesses operate within the framework of Indian laws,  avoiding penalties and legal consequences for non-compliance',
    },
    {
        id:2,
        image:Establishment2,
        descName:'Business Continuity ',
        desc:'Adhering to regulations ensures uninterrupted operations, as non-compliance  can lead to financial penalties and fines, which can be costly for businesses',
    },
    {
        id:3,
        image:Establishment3,
        descName:'Reputation Management ',
        desc:"Compliance enhances trust among stakeholders, including customers,  investors, and regulators, safeguarding the company's reputation. ",
    },
    {
        id:4,
        image:Establishment4,
        descName:'Risk Mitigation ',
        desc:"Compliance practices help identify and mitigate risks associated with legal and  regulatory changes, protecting business interests ",
    },
    {
        id:5,
        image:Establishment5,
        descName:'Access to Markets ',
        desc:"Many sectors require compliance for market access, licenses, or permits, making it essential for growth and expansion within India.  ",
    },
]

const services=[{
    id:1,
    service:'Obtaining amending and renewing Registration Certificates'
},
{
    id:2,
    service:'Preparing and submitting Statutory returns and notices'
},
{
    id:3,
    service:'Remitting statutory payments '
},
{
    id:4,
    service:'Offering liaison coordination support for inspections '
},
{
    id:5,
    service:'Consulting on the formation of Statutory Committees'
},
{
    id:6,
    service:'Preparing and updating Statutory registers'
},
{
    id:7,
    service:'Displaying statutory abstracts and notices'
},
{
    id:8,
    service:'Providing support for accident-related compliance'
},
{
    id:9,
    service:'Delivering compliance support for the Principal Employer as per the specified scope '
},
{
    id:10,
    service:'Enabling Establishment Compliance Services with advanced automation tools '
}]
const safety=[{
    id:1,
    stepnum:'1',
    desc:'Create and Implement an Anti- Sexual Harassment Policy',
    img: checklist1,
},
{
    id:2,
    stepnum:'2',
    desc:'Constitute an Internal Committee and give them the necessary training to function efficiently',
    img:person2,
},
{
    id:3,
    stepnum:'3',
    desc:'Employee Awareness and Assistance',
    img:brain3,
},
{
    id:4,
    stepnum:'4',
    desc:'Statutory Annual Report Filing',
    img:pin,
},{
    id:5,
    stepnum:'5',
    desc:'Posters and Notices',
    img:folder,
}]

  return (
    <div>
        <div className='establish-main'>
        <div className='establish-head'>
            <div className='establish-head-maintext'>Establishment Compliances</div>
            <div className='establish-head-text'>Ensuring compliance through licensing and record-keeping across industries</div>
        </div>
        <div style={{display:'flex', flexDirection:'column', gap:'24px'}}>
            <div className='est-whoneed-text'>Who Needs Establishment Compliance Services?</div>
            <div className='est-whoneeds'>
            <div className='est-whoneed-data'>
                <div className='est-whoneed-detail'>Establishment Compliance Services are indispensable for businesses of all sizes and across various industries, ensuring adherence to regulatory requirements and standards. Small businesses rely on these services to comply with local and state regulations, while larger corporations utilize them to manage compliance across multiple locations and business units. These services aid businesses in meeting regulatory demands by assisting with license acquisition, record maintenance, and report submission. They are crucial in helping companies avoid penalties, safeguard their reputations, mitigate risks, and uphold quality standards. Tasks covered include registration, inspections, statutory payments, and technological support for automation. This comprehensive support is essential for effective compliance management across diverse locations and industries.</div>
            </div>
            <div className='est-whoneed-imgdiv'></div>
        </div>
        </div>

        <div className='est-scope'>
            <div className='est-scope-text'>Labour and Compliance Service Scope Covered</div>
            <div className='est-scope-detail'>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>1</div>
                    <div className='est-scope-explain'>The Shop and Commercial Establishment act</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>2</div>
                    <div className='est-scope-explain'>The Contract Labor Act - 1970</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>3</div>
                    <div className='est-scope-explain'>The Industrial Establishment  Act</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>4</div>
                    <div className='est-scope-explain'>The Employment Exchanges Act, 1959</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>5</div>
                    <div className='est-scope-explain'>The Maternity Benefit Act</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>6</div>
                    <div className='est-scope-explain'>The Employee’s Compensation Act 1923</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>7</div>
                    <div className='est-scope-explain'>The Child Labour Act 1986</div>
                </div>
                <div className='est-scope-data'>
                    <div className='est-scope-number'>8</div>
                    <div className='est-scope-explain'>The Rights of Persons with Disabilities Act 2016</div>
                </div>
                <div className='est-scope-data1'>
                    <div className='est-scope-number'>9</div>
                    <div className='est-scope-explain'>The Sexual Harassment of Woman at Workplace Act 2013</div>
                </div>
                <div className='est-scope-data1'>
                    <div className='est-scope-number'>10</div>
                    <div className='est-scope-explain'>The Payment of Bonus Act 1965</div>
                </div>
                <div className='est-scope-data1'>
                    <div className='est-scope-number'>11</div>
                    <div className='est-scope-explain'>The Payment of Gratuity Act 1972</div>
                </div>
                <div className='est-scope-data1'>
                    <div className='est-scope-number'>12</div>
                    <div className='est-scope-explain'>The Payment of Wages Act 1936</div>
                </div>
                <div className='est-scope-data1'>
                    <div className='est-scope-number'>13</div>
                    <div className='est-scope-explain'>The Minimum Wages Act 1948</div>
                </div>


            </div>
        </div>

        <div className='est-safety'>
            <div className='est-safety-heading'>5 Key Steps For Compliance Of Sexual Harrasment Of Woman</div>
            <div className='est-safety-data'>
            {safety.map((safety,id)=>(
                <div className='est-safety-alldetail'>
                <div className='est-safety-steps'>
                    <div className='est-safety-step'><span>{safety.stepnum}</span> Step</div>
                    <div className='est-safety-opimage'> <img src={safety.img} className='est-safety-image'/></div>
                </div>
                <div className='est-safety-detail'>{safety.desc}
                
                </div>
             
            </div>
            ))}
                
            </div>
        </div>

        <div className='est-services'> 
                <div className='est-service-text'>Our Services Under Different Compliances</div>
                <div className='est-service-detail'>
                    <div className='est-service-data-row'>
                        {services.filter(service => service.id <= 5).map((service) => (
                        <div className='est-service-data1' key={service.id}>
                            <div className='est-service-number'>{service.id}</div>
                            <div className='est-service-explain'>{service.service}</div>
                        </div>
                        ))}
                    </div>
                    
                    <div className='est-service-data-row'>
                        {services.filter(service => service.id > 5).map((service) => (
                        <div className='est-service-data2' key={service.id}>
                            <div className='est-service-number'>{service.id}</div>
                            <div className='est-service-explain'>{service.service}</div>
                        </div>
                        ))}
                    </div>
                    </div>
        </div>

        <div className='est-why'>
            <div className='est-why-text'>Why do you need Establishment Compliance Services?</div>
            {whyCompliance?.map((compliance,id)=>(
                <div key={id} className='est-why-detail'>
                <img src={compliance.image} className='est-why-image'/>
                <div className='est-why-desc'><span className='est-why-head'>{compliance.descName}</span>{compliance.desc}</div>
                </div>
            ))}
        </div>
        </div>
    </div>
  )
}

export default Establishment
