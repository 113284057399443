import React, { useEffect, useState } from 'react';
import '../css/ELeaveState.css';
import { useNavigate, useParams } from 'react-router-dom';
import { CaretDown, CaretLeft, CaretUp } from '@phosphor-icons/react';
import { useStateBanner } from '../components/StateEmblem';
import StateImage from '../Images/StateImage.jpg';
import { notificationApiUrl, holidayApiUrl } from "../Utils/GetUrl";
import { CircularProgress } from '@mui/material';

function ELeaveState() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [stateName, setStateName] = useState('');
  const [stateId, setStateId] = useState(id);
  const [stateList, setStateList] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [filePresent, setFilePresent] = useState(false);
  const [head, setHead] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = '';
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleBack = () => {
    navigate('/LeaveAndWorkingHour');
  };

  const handleStateClick = (name, id) => {
    setStateName(name);
    setStateId(id);
    setDropdownVisible(false);
  };

  const getStateName = async () => {
    try {
      
      const response = await fetch(`${notificationApiUrl}/api/GetAllState`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStateList(data);
        setStateName(data?.[id - 1]?.state);
        
      }
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    getStateName();
  }, []);

  const getFile = async (id) => {
    try {

      const response = await fetch(`${holidayApiUrl}/api/GetLeaveByStateId?StateId=${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
console.log(response);

      if (response.ok) {
        const data = await response.json();
       
        setFileData(data);
        
        setHead(Object.keys(data[0] || {}));
       
          
        setFilePresent(true);
        
      } else {
        console.error(`HTTP error: ${response.status}`);
        setFilePresent(false);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setFilePresent(false);
    }
  };

  useEffect(() => {
    setFileData([]);
    setHead([]);
    getFile(stateId);
  }, [stateId]);

  useEffect(() => {
    if (stateId) {
      getFile(stateId);
      navigate(`/StateLeave/${stateId}`, { replace: true });
    } else {
      getFile(id);
    }
  }, [stateId]);

  const stateBanner = useStateBanner(stateId);
  // const backgroundImage = stateBanner[id] || StateImage;

  // Separate the columns for Leave and Working
  const leaveColumns = head.slice(0, 3);
  const workingColumns = head.slice(3, 5);

  // Separate the data for Leave and Working
  const leaveData = fileData.map(row => leaveColumns.reduce((acc, col) => {
    acc[col] = row[col];
    return acc;
  }, {}));
  const workingData = fileData.map(row => workingColumns.reduce((acc, col) => {
    acc[col] = row[col];
    return acc;
  }, {}));
const [backgroundImage, setBackgroundImage] = useState(null);
useEffect(() => {
  // Function to update background image
  const updateBackgroundImage = () => {
      setBackgroundImage(stateBanner[id]);
  };

  // Call the update function initially
  updateBackgroundImage();

  // Set up interval to periodically update the background image
  const intervalId = setInterval(updateBackgroundImage, 30000); // Adjust the interval as needed

  // Cleanup interval on component unmount
  return () => clearInterval(intervalId);
}, [id, stateId, stateBanner]);

  return (
    <div style={{ width: '1280px',overflow:'hidden' }}>
      <div className='ELeaveState-main'>
        <div style={{
          background: `linear-gradient(176deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 130.04%), url(${backgroundImage}) 0px 0px / 100% 100% no-repeat`,
         
        }} className='ELeave-back'>
          
          <div className='ELeaveState-maintext' onClick={toggleDropdown}>
            {stateName}{!dropdownVisible ? <CaretDown size={32} /> : <CaretUp size={32} />}
          </div>
        </div>
        {dropdownVisible && (
  <div className='ELeaveState-dropdown'>
    {stateList.filter(option => option.state !== "Central")
      ?.slice()
      .sort((a, b) => a.state.localeCompare(b.state))
      .map((states, index) => (
        <div key={index} className='ELeaveStateName' onClick={() => handleStateClick(states.state, states.id)}>
          {states.state}
        </div>
      ))}
  </div>
)}

        <div className='ELeaveState-Data'>
        <div className='ELeaveBack' onClick={handleBack}><CaretLeft size={30} />Back</div>
        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
          <div className='ELeaveState-list'>
            {stateName !== '' ? (
              !filePresent ? (
                <div>
                  <div className="ELeave-detail2">
                  </div>
                  <div className="ELeave-state">
                    <div className="ELeave-State-Record">No Record</div>
                  </div>
                  {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "2",
              position:'absolute'
            }}
          >
            <CircularProgress style={{ color: "rgba(64, 49, 131, 1)" }} />
          </div>
        )}
                </div>
              ) : (
                <div className="WageApplicable12">
                  <div className='ELeave-table'>
                  
                  {leaveData.length > 0 && (
                    <>
                      <div className="Leave-detail1-nameP1"> Leaves for Shops and Establishments</div>
                    <div className='ELeaveOne'>
                      <table className="Eresponsive-table">
                        <thead className='LeaveStick'>
                          <tr className='EtableRow'>
                            {leaveColumns.map((header, index) => (
                              <th key={index} className='LeaveTHead'>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className='Etablebody'>
                          {leaveData .filter(row => Object.values(row).some(value => value)).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {leaveColumns.map((header, colIndex) => (
                                <td key={colIndex} className='LeaveTData' style={colIndex === 0 ? { fontWeight: "600" } : {}}>{row[header]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    </>
                  
                  )}
                  </div>   
                  <div className='Leave-table'>
                  {workingData.length > 0 && (
                    <div className='ELeaveTwo'>
                      <div className="Leave-detail1-nameP1"> Working Hours for Shops and Establishments</div>
                      <div className='ELeavetwo2'>
                      <table className="Eresponsive-table">
                        <thead className='LeaveStick'>
                          <tr className='EtableRow'>
                            {workingColumns.map((header, index) => (
                              <th key={index} className='LeaveTHead'>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className='Etablebody'>
                          {workingData .filter(row => Object.values(row).some(value => value)).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {workingColumns.map((header, colIndex) => (
                                <td key={colIndex} className='LeaveTData' style={colIndex === 0 ? { fontWeight: "600" } : {}}>{row[header]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                         </div>
                      
                    </div>
                  )}
                  </div>
                 
                </div>
              )
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ELeaveState;
